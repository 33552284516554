/**
 * @param {String} value
 * @return {Boolean}
 */
export const isSecuritySocialNumberValid = (value) => {
  if (value.length !== 15) return false;

  const firstChar = value[0];
  const lastTwoChars = value.slice(-2);
  const firstThirteenChars = value.slice(0, 13);

  // check if first digit is 1 or 2
  if (firstChar !== "1" && firstChar !== "2") return false;

  // calculate security key and compare with last two characters
  const calculatedKey = 97 - (firstThirteenChars % 97);

  if (calculatedKey !== parseInt(lastTwoChars, 10)) return false;

  return true;
};
