var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.$screen.width <= 576
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass: "container-mobile animated fadeIn pl-0 pr-0",
                  staticStyle: { top: "95px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "container-myProfile-mobile" },
                    [
                      !_vm.allMandatoryIsFilled
                        ? _c("div", { staticClass: "alert alert-warning" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(
                                    55055,
                                    "Field with * are mandatory field you must fill. Some field are still empty."
                                  )
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "b-row",
                        [
                          _vm.menuActiveForMobile !== ""
                            ? _c(
                                "b-col",
                                {
                                  staticClass: "px-0",
                                  attrs: { cols: !_vm.isFilmSingle ? 12 : 8 },
                                  on: {
                                    click: function ($event) {
                                      _vm.menuActiveForMobile = ""
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "back-with-title cursor-pointer",
                                      class: { "mt-16": _vm.$isPwa() },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "icon" },
                                        [
                                          _c(_vm.getIconLucide("ArrowLeft"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#06263E",
                                              size: 22,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("h1", [
                                        _vm._v(_vm._s(_vm.pageMobileTitle)),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                cols: "4",
                                offset: _vm.menuActiveForMobile === "" ? 4 : 0,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "float-right" },
                                [
                                  _vm.isFilmSingle
                                    ? _c("ShareInformation", {
                                        staticStyle: {
                                          margin: "16px 16px 16px 0",
                                        },
                                        attrs: {
                                          tag: "is-lucide",
                                          "btn-label": _vm.FormMSG(
                                            215,
                                            "Share"
                                          ),
                                          "variant-btn": "light",
                                          "title-modal": _vm.FormMSG(
                                            170,
                                            "Send your personal informations sheet"
                                          ),
                                          "send-a-copy": "",
                                          "msg-success": _vm.FormMSG(
                                            250,
                                            "Profile successfully sent"
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "form",
                        { staticClass: "form", on: { submit: _vm.submitForm } },
                        [
                          _vm.menuActiveForMobile === ""
                            ? _c(
                                "div",
                                {
                                  class: {
                                    "mt-3": _vm.menuActiveForMobile === "",
                                  },
                                },
                                [
                                  _c("list-menu", {
                                    attrs: {
                                      "mandatory-count":
                                        _vm.personalDataMandatoryCount,
                                      "icon-lucide": "User",
                                      title: _vm.FormMSG(1211, "Personal Data"),
                                      description: _vm.FormMSG(
                                        1212,
                                        "Update your basic information, address or emergency contact."
                                      ),
                                      path: "personal-data",
                                    },
                                    on: {
                                      "list-menu:click":
                                        _vm.handleMenuMobileClick,
                                    },
                                  }),
                                  _vm.useField("NationalRegistrationNumber") ||
                                  _vm.useField("CompanyNumber") ||
                                  _vm.useField("CongesSpectacleId") ||
                                  _vm.useField("SignaturePicture") ||
                                  _vm.useField("IdName") ||
                                  _vm.useField("IdNumber") ||
                                  _vm.useField("IdPictureFront") ||
                                  _vm.useField("IdPictureBack") ||
                                  _vm.useField("DriverLicense") ||
                                  _vm.useField("DriverLicenseNumber") ||
                                  _vm.useField("DriverLicensePictureFront") ||
                                  _vm.useField("DriverLicensePictureBack")
                                    ? _c("list-menu", {
                                        attrs: {
                                          "mandatory-count":
                                            _vm.identificationMandatoryCount,
                                          "icon-lucide": "ClipboardList",
                                          title: _vm.FormMSG(
                                            4,
                                            "Identification"
                                          ),
                                          description: _vm.FormMSG(
                                            1214,
                                            "Update your identification numbers, bank information or family composition"
                                          ),
                                          path: "legal-information",
                                        },
                                        on: {
                                          "list-menu:click":
                                            _vm.handleMenuMobileClick,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.financeDisplaySection
                                    ? _c("list-menu", {
                                        attrs: {
                                          "mandatory-count":
                                            _vm.financeMandatoryCount,
                                          "icon-lucide": "Wallet",
                                          title: _vm.FormMSG(1306, "Finance"),
                                          path: "finance",
                                        },
                                        on: {
                                          "list-menu:click":
                                            _vm.handleMenuMobileClick,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.addressDisplaySection
                                    ? _c("list-menu", {
                                        attrs: {
                                          "mandatory-count":
                                            _vm.addressMandatoryCount,
                                          "icon-lucide": "MapPin",
                                          title: _vm.FormMSG(11, "Address"),
                                          path: "address",
                                        },
                                        on: {
                                          "list-menu:click":
                                            _vm.handleMenuMobileClick,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.healthDisplaySection
                                    ? _c("list-menu", {
                                        attrs: {
                                          "mandatory-count":
                                            _vm.healthMandatoryCount,
                                          "icon-lucide": "HeartPulse",
                                          title: _vm.FormMSG(1252, "Health"),
                                          path: "health",
                                        },
                                        on: {
                                          "list-menu:click":
                                            _vm.handleMenuMobileClick,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.familyDisplaySection
                                    ? _c("list-menu", {
                                        attrs: {
                                          "mandatory-count":
                                            _vm.familyMandatoryCount,
                                          "icon-lucide": "Users",
                                          title: _vm.FormMSG(13, "Family"),
                                          path: "family",
                                        },
                                        on: {
                                          "list-menu:click":
                                            _vm.handleMenuMobileClick,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.personalVehicleDisplaySection
                                    ? _c("list-menu", {
                                        attrs: {
                                          "mandatory-count":
                                            _vm.personalVehicleMandatoryCount,
                                          "icon-lucide": "Car",
                                          title: _vm.FormMSG(
                                            1226,
                                            "Personal vehicle"
                                          ),
                                          path: "personal-vehicle",
                                        },
                                        on: {
                                          "list-menu:click":
                                            _vm.handleMenuMobileClick,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.otherDisplaySection
                                    ? _c("list-menu", {
                                        attrs: {
                                          "mandatory-count":
                                            _vm.otherMandatoryCount,
                                          "icon-lucide": "List",
                                          title: _vm.FormMSG(16, "Other"),
                                          path: "other",
                                        },
                                        on: {
                                          "list-menu:click":
                                            _vm.handleMenuMobileClick,
                                        },
                                      })
                                    : _vm._e(),
                                  _c("list-menu", {
                                    attrs: {
                                      "icon-lucide": "Settings",
                                      title: _vm.FormMSG(1215, "Settings"),
                                      description: _vm.FormMSG(
                                        1216,
                                        "Change your password and communication language."
                                      ),
                                      path: "settings",
                                    },
                                    on: {
                                      "list-menu:click":
                                        _vm.handleMenuMobileClick,
                                    },
                                  }),
                                  _vm.isDeterProject === true
                                    ? _c("list-menu", {
                                        attrs: {
                                          "icon-lucide": "UserCog",
                                          title: _vm.FormMSG(
                                            131,
                                            "Team session"
                                          ),
                                          description: _vm.FormMSG(
                                            1257,
                                            "Configure your team session."
                                          ),
                                          path: "team-session",
                                        },
                                        on: {
                                          "list-menu:click":
                                            _vm.handleMenuMobileClick,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.menuActiveForMobile === "personal-data"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(1182, "Basic Information")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "b-row",
                                      [
                                        _vm.useField("Title")
                                          ? _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1240,
                                                                "Title"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm.isMandatory("Title")
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-form-select",
                                                      {
                                                        model: {
                                                          value:
                                                            _vm.myProfile.title,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "title",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.title",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.menuTitle,
                                                        function (
                                                          title,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "b-form-select-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                value:
                                                                  title.value,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    title.text
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.useField("PersonalNumber")
                                          ? _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "reference",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  1,
                                                                  "Reference"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.isMandatory(
                                                          "PersonalNumber"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "reference",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1704,
                                                            "Personal number."
                                                          ),
                                                        disabled: "",
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .reference,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "reference",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.reference",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "7" } },
                                          [
                                            _vm.useField("FirstName")
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1041,
                                                                "Name"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm.isMandatory(
                                                          "FirstName"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1003,
                                                            "Enter Name"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .firstName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "firstName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.firstName",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm.useField("LastName")
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                40,
                                                                "Last Name"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm.isMandatory(
                                                          "LastName"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1143,
                                                            "Enter Last Name"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile.name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.name",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm.useField("MaidenName")
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "maidenName",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  1239,
                                                                  " Maiden name (if different)"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.isMandatory(
                                                          "MaidenName"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "maidenName",
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .maidenName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "maidenName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.maidenName",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm.useField("ProfilePicture")
                                          ? _c(
                                              "b-col",
                                              { attrs: { cols: "5" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      height: "100px",
                                                      width: "100px",
                                                      "margin-left": "auto",
                                                      "margin-right": "auto",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "container-img-mobile",
                                                        staticStyle: {
                                                          height: "100%",
                                                          width: "100%",
                                                        },
                                                      },
                                                      [
                                                        _vm.isMandatory(
                                                          "ProfilePicture"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                                staticStyle: {
                                                                  position:
                                                                    "absolute",
                                                                },
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                        _vm.myProfile.picture &&
                                                        _vm.myProfile.picture
                                                          .length > 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  height:
                                                                    "100%",
                                                                  width: "100%",
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "96px",
                                                                    height:
                                                                      "96px",
                                                                  },
                                                                  attrs: {
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .picture,
                                                                      "picture"
                                                                    ),
                                                                  },
                                                                }),
                                                                _vm.myProfile
                                                                  .picture &&
                                                                _vm.myProfile
                                                                  .picture
                                                                  .length > 0
                                                                  ? _c(
                                                                      _vm.getIconLucide(
                                                                        "XCircle"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        staticClass:
                                                                          "cursor-pointer icon-remove-img-profile",
                                                                        staticStyle:
                                                                          {
                                                                            top: "-33px",
                                                                            right:
                                                                              "0px",
                                                                          },
                                                                        attrs: {
                                                                          size: 25,
                                                                          "stroke-width": 1.75,
                                                                          color:
                                                                            "#ea4e2c",
                                                                          id: "tooltip-remove-picture",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteImg(
                                                                                "picture"
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.myProfile
                                                                  .picture &&
                                                                _vm.myProfile
                                                                  .picture
                                                                  .length > 0
                                                                  ? _c(
                                                                      "b-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          target:
                                                                            "tooltip-remove-picture",
                                                                          triggers:
                                                                            "hover",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                255,
                                                                                "Remove picture"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "avatar",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "User"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    staticClass:
                                                                      "vertical-center",
                                                                    attrs: {
                                                                      fill: "#E2E6EB",
                                                                      color:
                                                                        "#E2E6EB",
                                                                      size: 45,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                        _vm.isMandatory(
                                                          "ProfilePicture"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "MobileProfilePicCaptureBtn",
                                                          {
                                                            attrs: {
                                                              "parent-type":
                                                                "user",
                                                              "crop-type":
                                                                "avatar",
                                                              parentId:
                                                                _vm.myProfile
                                                                  .id,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeFieldPic(
                                                                  "picture"
                                                                )
                                                              },
                                                              change:
                                                                _vm.handleImgsTaked,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c("div", [
                                                  _vm.useTask &&
                                                  !_vm.isFilmSingle
                                                    ? _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "scheduler-border",
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "scheduler-border",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      525,
                                                                      "Color"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                              _vm.isMandatory(
                                                                "Color"
                                                              )
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "required",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " * "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  staticClass:
                                                                    "text-center",
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c("verte", {
                                                                    attrs: {
                                                                      picker:
                                                                        "square",
                                                                      model:
                                                                        "hex",
                                                                      menuPosition:
                                                                        "top",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .color,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "color",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.color",
                                                                    },
                                                                  }),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.useField("Email")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "email" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(42, "Email")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("Email")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c(
                                              "b-input-group",
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "append",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.showChangeMailModal = true
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("Pencil", {
                                                                attrs: {
                                                                  color:
                                                                    "#ffffff",
                                                                  size: 16,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  680477981
                                                ),
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    state:
                                                      _vm.myProfile.email &&
                                                      _vm.myProfile.email
                                                        .length > 0,
                                                    value: _vm.myProfile.email,
                                                    type: "text",
                                                    id: "email",
                                                    placeholder: _vm.FormMSG(
                                                      1102,
                                                      "Enter your email"
                                                    ),
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("Phone")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "phone" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(44, "Phone")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("Phone")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c(
                                              "b-input-group",
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "append",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.showChangePhoneModal = true
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("Pencil", {
                                                                attrs: {
                                                                  color:
                                                                    "#ffffff",
                                                                  size: 16,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2770077608
                                                ),
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    value: _vm.myProfile.phone,
                                                    type: "text",
                                                    id: "phone",
                                                    disabled: "",
                                                    placeholder: _vm.FormMSG(
                                                      45,
                                                      "Enter your phone number"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-row",
                                      [
                                        _vm.useField("Nationality")
                                          ? _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                46,
                                                                "Nationality"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm.isMandatory(
                                                          "Nationality"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            46,
                                                            "Nationality"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .nationality,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "nationality",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.nationality",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.useField("Language")
                                          ? _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1305,
                                                                "Language"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm.isMandatory(
                                                          "Language"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("b-form-select", {
                                                      attrs: {
                                                        options:
                                                          _vm.optionsForLanguages,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .language,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "language",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.language",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _vm.useField("Birthdate")
                                          ? _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                48,
                                                                "Birthdate"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm.isMandatory(
                                                          "BirthDate"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("v-date-picker", {
                                                      attrs: {
                                                        locale: _vm.lang,
                                                        masks: _vm.masks,
                                                        attributes: _vm.attrs,
                                                      },
                                                      on: {
                                                        dayclick: (payload) =>
                                                          _vm.handleInputCalendar(
                                                            payload,
                                                            "birthDate"
                                                          ),
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              inputValue,
                                                              togglePopover,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "b-input-group",
                                                                  [
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "mask",
                                                                              rawName:
                                                                                "v-mask",
                                                                              value:
                                                                                "##/##/####",
                                                                              expression:
                                                                                "'##/##/####'",
                                                                            },
                                                                          ],
                                                                        class: {
                                                                          "temp-invalid":
                                                                            _vm.isMandatory(
                                                                              "Birthdate"
                                                                            ) &&
                                                                            !_vm.isDateValid(
                                                                              "birthDate"
                                                                            )[
                                                                              "birthDate"
                                                                            ],
                                                                        },
                                                                        attrs: {
                                                                          value:
                                                                            inputValue,
                                                                          placeholder:
                                                                            _vm.FormMSG(
                                                                              8960,
                                                                              "DD/MM/YYYY"
                                                                            ),
                                                                          readonly:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return togglePopover()
                                                                            },
                                                                          input:
                                                                            (
                                                                              value
                                                                            ) =>
                                                                              _vm.handleChangeDate(
                                                                                value,
                                                                                "birthDate"
                                                                              ),
                                                                          keydown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "enter",
                                                                                  13,
                                                                                  $event.key,
                                                                                  "Enter"
                                                                                )
                                                                              )
                                                                                return null
                                                                              $event.preventDefault()
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "b-input-group-append",
                                                                      [
                                                                        _c(
                                                                          "b-input-group-text",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                            class:
                                                                              {
                                                                                "temp-invalid":
                                                                                  _vm.isMandatory(
                                                                                    "Birthdate"
                                                                                  ) &&
                                                                                  !_vm.isDateValid(
                                                                                    "birthDate"
                                                                                  )[
                                                                                    "birthDate"
                                                                                  ],
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return togglePopover()
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getLucideIcon(
                                                                                "Calendar"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "rgba(6, 38, 62, .6)",
                                                                                    size: 18,
                                                                                    "stroke-width": 2.25,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1455693153
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .birthDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "birthDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.birthDate",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.useField("CountryOfBirth")
                                          ? _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1241,
                                                                "Country of birth"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm.isMandatory(
                                                          "CountryOfBirth"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1315,
                                                            "Enter your country of ..."
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .birthCountry,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "birthCountry",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.birthCountry",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _vm.useField("CityOfBirthZip")
                                          ? _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1152,
                                                                "City of Birth"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm.isMandatory(
                                                          "PlaceOfBirth"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1153,
                                                            "Enter City of Birth"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .placeOfBirth,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "placeOfBirth",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.placeOfBirth",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.useField("CityOfBirthZip")
                                          ? _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1154,
                                                                "Zip Code (City of Birth)"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm.isMandatory(
                                                          "CityOfBirthZip"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            1155,
                                                            "Enter Zip Code"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .placeOfBirthZip,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "placeOfBirthZip",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.placeOfBirthZip",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.useField("Gender")
                                      ? _c("b-form-group", {
                                          staticClass: "gender-group",
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  ariaDescribedby,
                                                }) {
                                                  return [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                164,
                                                                "Gender"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm.isMandatory(
                                                          "Gender"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-form-radio-group",
                                                      {
                                                        attrs: {
                                                          "aria-describedby":
                                                            ariaDescribedby,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .gender,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "gender",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.gender",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.menuGenders,
                                                        function (
                                                          gender,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticStyle: {
                                                                display: "flex",
                                                              },
                                                            },
                                                            [
                                                              gender.value !==
                                                              null
                                                                ? _c(
                                                                    "b-form-radio",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          gender.value,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            gender.text
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2877822230
                                          ),
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "legal-information"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "legal-information" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(
                                            1187,
                                            "Identification Numbers"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm.useField("NationalRegistrationNumber")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      55,
                                                      "National Registration Number"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory(
                                                "NationalRegistrationNumber"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("InputMask", {
                                              staticClass: "form-control",
                                              class: {
                                                "bg-color-grams-hair":
                                                  _vm.disableAll,
                                              },
                                              attrs: {
                                                mask: "99.99.99-999-99",
                                                placeholder: _vm.FormMSG(
                                                  56,
                                                  "Enter your National Registration Number "
                                                ),
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile
                                                    .nationalRegistryNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "nationalRegistryNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.nationalRegistryNumber",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("CompanyNumber")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      57,
                                                      "Company Number"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("CompanyNumber")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  58,
                                                  "Enter your Company official Number "
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.companyNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "companyNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.companyNumber",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("CongesSpectacleId")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "conge_spectacles_id",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1242,
                                                        '"Congés spectacles" ID'
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory(
                                                "CongesSpectacleId"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "conge_spectacles_id",
                                                placeholder: _vm.FormMSG(
                                                  1243,
                                                  "Enter your conges spectacles id"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.congesSpectacle,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "congesSpectacle",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.congesSpectacle",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("IdName")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "idName" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        216,
                                                        "ID Name"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("IdName")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "idName",
                                                placeholder: _vm.FormMSG(
                                                  217,
                                                  "Enter your ID Name ..."
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.idName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "idName",
                                                    $$v
                                                  )
                                                },
                                                expression: "myProfile.idName",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("IdNumber")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "idNumber" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        32,
                                                        "ID Number"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("IdNumber")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "idNumber",
                                                placeholder: _vm.FormMSG(
                                                  33,
                                                  "Enter your ID Number ..."
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.idNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "idNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.idNumber",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("DriverLicense")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      166,
                                                      "Driver licence"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("DriverLicense")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  167,
                                                  "Enter your driver license"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.driverLicense,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "driverLicense",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.driverLicense",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("DriverLicenseNumber")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1314,
                                                      "Driver licence Number"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory(
                                                "DriverLicenseNumber"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.FormMSG(
                                                  1316,
                                                  "Enter your driver license number"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile
                                                    .driverLicenseNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "driverLicenseNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.driverLicenseNumber",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("SignaturePicture")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                {
                                                  attrs: { for: "signatureId" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        223,
                                                        "My signature"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory(
                                                "SignaturePicture"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c(
                                              "b-row",
                                              { staticClass: "ml-0" },
                                              [
                                                _vm.checkFile(
                                                  _vm.myProfile.signatureId
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.onOpenUrl(
                                                              _vm.getImage(
                                                                _vm.myProfile
                                                                  .signatureId,
                                                                "signatureId"
                                                              )
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "file-earmark-pdf-fill",
                                                            "font-scale": 1.6,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "b-img",
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "cursor-pointer",
                                                          attrs: {
                                                            rounded: "circle",
                                                            alt: "Signature ID",
                                                            src: _vm.getImage(
                                                              _vm.myProfile
                                                                .signatureId,
                                                              "signatureId"
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showImage(
                                                                _vm.myProfile
                                                                  .signatureId
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "b-img",
                                                        _vm.imgPropSignatureId,
                                                        false
                                                      )
                                                    ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex ml-3 justify-content-center align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "MobileProfilePicCaptureBtn",
                                                      {
                                                        attrs: {
                                                          "parent-type": "user",
                                                          id: "pictureID",
                                                          parentId:
                                                            _vm.myProfile.id,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeFieldPic(
                                                              "signatureId"
                                                            )
                                                          },
                                                          change:
                                                            _vm.handleImgsTaked,
                                                        },
                                                      }
                                                    ),
                                                    _vm.myProfile.signatureId &&
                                                    _vm.myProfile.signatureId
                                                      .length > 0
                                                      ? _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent pl-0 ml-2",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                $event.stopPropagation()
                                                                return _vm.deleteImg(
                                                                  "signatureId"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getIconLucide(
                                                                "Trash2"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 24,
                                                                  "stroke-width": 1.5,
                                                                  color:
                                                                    "#ea4e2c",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _vm.useField("IdPictureFront")
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "pictureId",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  218,
                                                                  "ID picture - Front"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.isMandatory(
                                                          "IdPictureFront"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .pictureId
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureId,
                                                                          "pictureId"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale": 1.6,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded:
                                                                      "circle",
                                                                    alt: "ID picture - front",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .pictureId,
                                                                      "pictureId"
                                                                    ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureId
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropPictureId,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex ml-3 justify-content-center align-items-center",
                                                          },
                                                          [
                                                            _c(
                                                              "MobileProfilePicCaptureBtn",
                                                              {
                                                                attrs: {
                                                                  "parent-type":
                                                                    "user",
                                                                  id: "pictureID",
                                                                  parentId:
                                                                    _vm
                                                                      .myProfile
                                                                      .id,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeFieldPic(
                                                                        "pictureId"
                                                                      )
                                                                    },
                                                                  change:
                                                                    _vm.handleImgsTaked,
                                                                },
                                                              }
                                                            ),
                                                            _vm.myProfile
                                                              .pictureId &&
                                                            _vm.myProfile
                                                              .pictureId
                                                              .length > 0
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-transparent pl-0 ml-2",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.deleteImg(
                                                                            "pictureId"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getIconLucide(
                                                                        "Trash2"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          size: 24,
                                                                          "stroke-width": 1.5,
                                                                          color:
                                                                            "#ea4e2c",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          [
                                            _vm.useField("IdPictureBack")
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "pictureIdVerso",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  219,
                                                                  "ID picture - Back"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.isMandatory(
                                                          "IdPictureBack"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .pictureIdVerso
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureIdVerso,
                                                                          "pictureIdVerso"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale": 1.6,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded:
                                                                      "circle",
                                                                    alt: "ID picture - Back",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .pictureIdVerso,
                                                                      "pictureIdVerso"
                                                                    ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureIdVerso
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropPictureIdVerso,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex ml-3 justify-content-center align-items-center",
                                                          },
                                                          [
                                                            _c(
                                                              "MobileProfilePicCaptureBtn",
                                                              {
                                                                attrs: {
                                                                  "parent-type":
                                                                    "user",
                                                                  id: "pictureIdVerso",
                                                                  parentId:
                                                                    _vm
                                                                      .myProfile
                                                                      .id,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeFieldPic(
                                                                        "pictureIdVerso"
                                                                      )
                                                                    },
                                                                  change:
                                                                    _vm.handleImgsTaked,
                                                                },
                                                              }
                                                            ),
                                                            _vm.myProfile
                                                              .pictureIdVerso &&
                                                            _vm.myProfile
                                                              .pictureIdVerso
                                                              .length > 0
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-transparent pl-0 ml-2",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.deleteImg(
                                                                            "pictureIdVerso"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getIconLucide(
                                                                        "Trash2"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          size: 24,
                                                                          "stroke-width": 1.5,
                                                                          color:
                                                                            "#ea4e2c",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _vm.useField(
                                              "DriverLicensePictureFront"
                                            )
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "pictureDriverLicense",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  221,
                                                                  "Driver license picture - Front"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.isMandatory(
                                                          "DriverLicensePictureFront"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .pictureDriverLicense
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureDriverLicense,
                                                                          "pictureDriverLicense"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale": 1.6,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded: "",
                                                                    alt: "Driver license picture - Front",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .pictureDriverLicense,
                                                                      "pictureDriverLicense"
                                                                    ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureDriverLicense
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropPictureDriverLicense,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex ml-3 justify-content-center align-items-center",
                                                          },
                                                          [
                                                            _c(
                                                              "MobileProfilePicCaptureBtn",
                                                              {
                                                                attrs: {
                                                                  "parent-type":
                                                                    "user",
                                                                  id: "pictureDriverLicense",
                                                                  parentId:
                                                                    _vm
                                                                      .myProfile
                                                                      .id,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeFieldPic(
                                                                        "pictureDriverLicense"
                                                                      )
                                                                    },
                                                                  change:
                                                                    _vm.handleImgsTaked,
                                                                },
                                                              }
                                                            ),
                                                            _vm.myProfile
                                                              .pictureDriverLicense &&
                                                            _vm.myProfile
                                                              .pictureDriverLicense
                                                              .length > 0
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-transparent pl-0 ml-2",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.deleteImg(
                                                                            "pictureDriverLicense"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getIconLucide(
                                                                        "Trash2"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          size: 24,
                                                                          "stroke-width": 1.5,
                                                                          color:
                                                                            "#ea4e2c",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          [
                                            _vm.useField(
                                              "DriverLicensePictureBack"
                                            )
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "pictureDriverLicenseVerso",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  222,
                                                                  "Driver license picture - Back"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.isMandatory(
                                                          "DriverLicensePictureBack"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .pictureDriverLicenseVerso
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureDriverLicenseVerso,
                                                                          "pictureDriverLicenseVerso"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale": 1.6,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded: "",
                                                                    alt: "Driver license picture - Back",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .pictureDriverLicenseVerso,
                                                                      "pictureDriverLicenseVerso"
                                                                    ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .pictureDriverLicenseVerso
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropPictureIdVerso,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex ml-3 justify-content-center align-items-center",
                                                          },
                                                          [
                                                            _c(
                                                              "MobileProfilePicCaptureBtn",
                                                              {
                                                                attrs: {
                                                                  "parent-type":
                                                                    "user",
                                                                  id: "pictureDriverLicenseVerso",
                                                                  parentId:
                                                                    _vm
                                                                      .myProfile
                                                                      .id,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeFieldPic(
                                                                        "pictureDriverLicenseVerso"
                                                                      )
                                                                    },
                                                                  change:
                                                                    _vm.handleImgsTaked,
                                                                },
                                                              }
                                                            ),
                                                            _vm.myProfile
                                                              .pictureDriverLicenseVerso &&
                                                            _vm.myProfile
                                                              .pictureDriverLicenseVerso
                                                              .length > 0
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-transparent pl-0 ml-2",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.deleteImg(
                                                                            "pictureDriverLicenseVerso"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getIconLucide(
                                                                        "Trash2"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          size: 24,
                                                                          "stroke-width": 1.5,
                                                                          color:
                                                                            "#ea4e2c",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.isPme && _vm.myProfile.vehicule
                                      ? _c("div", [
                                          _c("div", { staticClass: "divider" }),
                                          _c("h2", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(1227, "Vehicle")
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "switch-group" },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1228,
                                                      "Name"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.FormMSG(
                                                        1229,
                                                        "Vehicle name ..."
                                                      ),
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.name",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1230,
                                                      "Brand"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.FormMSG(
                                                        1231,
                                                        "Vehicle brand ..."
                                                      ),
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .brand,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "brand",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.brand",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1232,
                                                      "Model"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.FormMSG(
                                                        1233,
                                                        "Vehicle model ..."
                                                      ),
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .model,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "model",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.model",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1234,
                                                      "Number plate"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.FormMSG(
                                                        1235,
                                                        "Your number plate ..."
                                                      ),
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .numberPlate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "numberPlate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.numberPlate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1236,
                                                      "Type"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-select", {
                                                    attrs: {
                                                      options: _vm.vehicleTypes,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.type",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      1237,
                                                      "Energy"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("b-form-select", {
                                                    attrs: {
                                                      options:
                                                        _vm.vehicleEnergies,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .energy,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "energy",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.energy",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          1238,
                                                          "Kg CO2 per"
                                                        )
                                                      ) +
                                                        " " +
                                                        _vm._s(_vm.distanceUnit)
                                                    ),
                                                  ]),
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "number",
                                                      step: "0.01",
                                                      min: "0",
                                                      placeholder: "0",
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        $event.preventDefault()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.vehicule
                                                          .kgCoTwoPerKm,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile
                                                            .vehicule,
                                                          "kgCoTwoPerKm",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.vehicule.kgCoTwoPerKm",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "finance"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _vm.financeDisplayBankSection
                                      ? _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                1188,
                                                "Bank Information"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.useField("Iban")
                                      ? _c(
                                          "b-form-group",
                                          {
                                            class: {
                                              "is-warning":
                                                _vm.userBankWarning.iban,
                                            },
                                          },
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(49, "IBAN")
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("Iban")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              class: {
                                                "is-warning":
                                                  _vm.userBankWarning.iban,
                                              },
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  1189,
                                                  "Enter IBAN"
                                                ),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.handleSupplierIbanChange(
                                                    $event,
                                                    "iban"
                                                  )
                                                },
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.iban,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "iban",
                                                    $$v
                                                  )
                                                },
                                                expression: "myProfile.iban",
                                              },
                                            }),
                                            _vm.userBankWarning.iban
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "warning-feedback",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            97986,
                                                            "IBAN seems not valid, please make sure it's corrrect"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("Bic")
                                      ? _c(
                                          "b-form-group",
                                          {
                                            class: {
                                              "is-warning":
                                                _vm.userBankWarning.bic,
                                            },
                                          },
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(_vm.FormMSG(51, "BIC"))
                                                ),
                                              ]),
                                              _vm.isMandatory("Bic")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  1190,
                                                  "Enter BIC"
                                                ),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.handleSupplierBicChange(
                                                    $event,
                                                    "bic"
                                                  )
                                                },
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.bic,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "bic",
                                                    $$v
                                                  )
                                                },
                                                expression: "myProfile.bic",
                                              },
                                            }),
                                            _vm.userBankWarning.bic
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "warning-feedback",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            909097986,
                                                            "BIC seems not valid, please make sure it's corrrect"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("BankAccountOwnerName")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "bic" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        1245,
                                                        "Bank account owner name"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory(
                                                "BankAccountOwnerName"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "bic",
                                                placeholder: this.FormMSG(
                                                  1246,
                                                  "Enter your bank account owner name"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile
                                                    .bankAccountOwnerName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "bankAccountOwnerName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.bankAccountOwnerName",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("BankDetailDocument")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "bankDetailDocument",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1247,
                                                        "Bank detail document"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory(
                                                "BankDetailDocument"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c(
                                              "b-row",
                                              { staticClass: "ml-0" },
                                              [
                                                _vm.checkFile(
                                                  _vm.myProfile.bankDetailXid
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.onOpenUrl(
                                                              _vm.getImage(
                                                                _vm.myProfile
                                                                  .bankDetailXid,
                                                                "bankDetailXid"
                                                              )
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "file-earmark-pdf-fill",
                                                            "font-scale": 1.6,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "b-img",
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "cursor-pointer",
                                                          attrs: {
                                                            rounded: "",
                                                            alt: "Bank detail document",
                                                            src: _vm.getImage(
                                                              _vm.myProfile
                                                                .bankDetailXid,
                                                              "bankDetailXid"
                                                            ),
                                                            title: _vm.FormMSG(
                                                              254,
                                                              "View Image"
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showImage(
                                                                _vm.myProfile
                                                                  .bankDetailXid
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "b-img",
                                                        _vm.imgPropSignatureId,
                                                        false
                                                      )
                                                    ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex ml-3 justify-content-center align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "MobileProfilePicCaptureBtn",
                                                      {
                                                        attrs: {
                                                          "parent-type": "user",
                                                          id: "pictureRib",
                                                          parentId:
                                                            _vm.myProfile.id,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeFieldPic(
                                                              "bankDetailXid"
                                                            )
                                                          },
                                                          change:
                                                            _vm.handleImgsTaked,
                                                        },
                                                      }
                                                    ),
                                                    _vm.myProfile
                                                      .bankDetailXid &&
                                                    _vm.myProfile.bankDetailXid
                                                      .length > 0
                                                      ? _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent pl-0 ml-2",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                $event.stopPropagation()
                                                                return _vm.deleteImg(
                                                                  "bankDetailXid"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getIconLucide(
                                                                "Trash2"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 24,
                                                                  "stroke-width": 1.5,
                                                                  color:
                                                                    "#ea4e2c",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-mobile-sub-title sub-title",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.FormMSG(
                                              5000,
                                              "Expense Rembursement Bank Information"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm.useField("SecondIban")
                                      ? _c(
                                          "b-form-group",
                                          {
                                            class: {
                                              "is-warning":
                                                _vm.userBankWarning.secondIban,
                                            },
                                          },
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(49, "IBAN")
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("SecondIban")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              class: {
                                                "is-warning":
                                                  _vm.userBankWarning
                                                    .secondIban,
                                              },
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  1189,
                                                  "Enter IBAN"
                                                ),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.handleSupplierIbanChange(
                                                    $event,
                                                    "secondIban"
                                                  )
                                                },
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.secondIban,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "secondIban",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.secondIban",
                                              },
                                            }),
                                            _vm.userBankWarning.secondIban
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "warning-feedback",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            97986,
                                                            "IBAN seems not valid, please make sure it's corrrect"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("SecondBic")
                                      ? _c(
                                          "b-form-group",
                                          {
                                            class: {
                                              "is-warning":
                                                _vm.userBankWarning.secondBic,
                                            },
                                          },
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(_vm.FormMSG(51, "BIC"))
                                                ),
                                              ]),
                                              _vm.isMandatory("SecondBic")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  1190,
                                                  "Enter BIC"
                                                ),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.handleSupplierBicChange(
                                                    $event,
                                                    "secondBic"
                                                  )
                                                },
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.secondBic,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "secondBic",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.secondBic",
                                              },
                                            }),
                                            _vm.userBankWarning.secondBic
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "warning-feedback",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            909097986,
                                                            "BIC seems not valid, please make sure it's corrrect"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("SecondBankAccountOwnerName")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "bic" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        1245,
                                                        "Bank account owner name"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory(
                                                "SecondBankAccountOwnerName"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "bic",
                                                placeholder: this.FormMSG(
                                                  1246,
                                                  "Enter your bank account owner name"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile
                                                    .secondBankAccountOwnerName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "secondBankAccountOwnerName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.secondBankAccountOwnerName",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("SecondBankDetailDocument")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "bankDetailDocument",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1247,
                                                        "Bank detail document"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory(
                                                "SecondBankDetailDocument"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c(
                                              "b-row",
                                              { staticClass: "ml-0" },
                                              [
                                                _vm.checkFile(
                                                  _vm.myProfile
                                                    .secondBankDetailXid
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.onOpenUrl(
                                                              _vm.getImage(
                                                                _vm.myProfile
                                                                  .secondBankDetailXid,
                                                                "bankDetailXid"
                                                              )
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "file-earmark-pdf-fill",
                                                            "font-scale": 1.6,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "b-img",
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "cursor-pointer",
                                                          attrs: {
                                                            rounded: "",
                                                            alt: "Bank detail document",
                                                            src: _vm.getImage(
                                                              _vm.myProfile
                                                                .secondBankDetailXid,
                                                              "secondBankDetailXid"
                                                            ),
                                                            title: _vm.FormMSG(
                                                              254,
                                                              "View Image"
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showImage(
                                                                _vm.myProfile
                                                                  .secondBankDetailXid
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "b-img",
                                                        _vm.imgPropSignatureId,
                                                        false
                                                      )
                                                    ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex ml-3 justify-content-center align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "MobileProfilePicCaptureBtn",
                                                      {
                                                        attrs: {
                                                          "parent-type": "user",
                                                          id: "pictureRib",
                                                          parentId:
                                                            _vm.myProfile.id,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeFieldPic(
                                                              "secondBankDetailXid"
                                                            )
                                                          },
                                                          change:
                                                            _vm.handleImgsTaked,
                                                        },
                                                      }
                                                    ),
                                                    _vm.myProfile
                                                      .secondBankDetailXid &&
                                                    _vm.myProfile
                                                      .secondBankDetailXid
                                                      .length > 0
                                                      ? _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent pl-0 ml-2",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                $event.stopPropagation()
                                                                return _vm.deleteImg(
                                                                  "secondBankDetailXid"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getIconLucide(
                                                                "Trash2"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 24,
                                                                  "stroke-width": 1.5,
                                                                  color:
                                                                    "#ea4e2c",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.financeDisplayBankSection &&
                                    _vm.useField("Taxes")
                                      ? _c("div", { staticClass: "divider" })
                                      : _vm._e(),
                                    _vm.useField("Taxes")
                                      ? _c("h2", [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(260, "Taxes"))
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.useField("Taxes")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1310,
                                                      "Taxes percentage"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("Taxes")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "number",
                                                    step: "1",
                                                    min: "0",
                                                    max: "100",
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .taxesPercentage,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "taxesPercentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.taxesPercentage",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      [
                                                        _c(
                                                          _vm.getIconLucide(
                                                            "Percent"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color: "#06263E",
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("TaxDeclarationCountry")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "bic" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        1248,
                                                        "Tax declaration country"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory(
                                                "TaxDeclarationCountry"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "taxCountry",
                                                placeholder: this.FormMSG(
                                                  1249,
                                                  "Enter your tax declaration country"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.taxCountry,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "taxCountry",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.taxCountry",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "address"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(1308, "Legal Address")
                                        )
                                      ),
                                    ]),
                                    _vm.useField("Country")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "country" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        106,
                                                        "Country"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("Country")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("v-select", {
                                              attrs: {
                                                clearable: false,
                                                options: _vm.countries,
                                                label: "text",
                                                reduce: (option) =>
                                                  option.value,
                                              },
                                              on: { input: _vm.getCountry },
                                              model: {
                                                value: _vm.countryKey,
                                                callback: function ($$v) {
                                                  _vm.countryKey = $$v
                                                },
                                                expression: "countryKey",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("InternetResearch")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("google-auto-complete", {
                                              ref: "google-search-address",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  12,
                                                  "Internet search"
                                                ),
                                                country: _vm.countryCode,
                                                "clear-input":
                                                  _vm.clearLegalAddress,
                                              },
                                              on: {
                                                placechanged: (item) =>
                                                  _vm.handleAutoComplete(
                                                    item,
                                                    "legalAddress"
                                                  ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.useField("Street")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(100, "Street")
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("Street")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  1156,
                                                  "Enter Street Name"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.address.street,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.address,
                                                    "street",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.address.street",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("Number")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(101, "Number")
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("Number")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  1157,
                                                  "Enter Number"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.address.number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.address,
                                                    "number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.address.number",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("StateRegion")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1170,
                                                      "State/Region"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("StateRegion")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  1171,
                                                  "Enter State/Region"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.address.state,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.address,
                                                    "state",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.address.state",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("City")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(103, "City")
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("City")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  1172,
                                                  "Enter City"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.address.city,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.address,
                                                    "city",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.address.city",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("ZipCode")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1167,
                                                      "ZIP Code"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("ZipCode")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  1168,
                                                  "Enter ZIP Code"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.address.zip,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.address,
                                                    "zip",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.address.zip",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("Box")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(102, "Box")
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("Box")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  1169,
                                                  "Enter Box Number"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.address.box,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.address,
                                                    "box",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.address.box",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "personal-data mb-4" },
                                  [
                                    _c(
                                      "b-collapse",
                                      {
                                        model: {
                                          value: _vm.isShowPostalAddress,
                                          callback: function ($$v) {
                                            _vm.isShowPostalAddress = $$v
                                          },
                                          expression: "isShowPostalAddress",
                                        },
                                      },
                                      [
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                1312,
                                                "Postal address"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "country" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        106,
                                                        "Country"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("Country")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("v-select", {
                                              attrs: {
                                                clearable: false,
                                                options: _vm.countries,
                                                label: "text",
                                                reduce: (option) =>
                                                  option.value,
                                              },
                                              on: {
                                                input:
                                                  _vm.getCountryPostalAddress,
                                              },
                                              model: {
                                                value:
                                                  _vm.countryKeyPostalAddress,
                                                callback: function ($$v) {
                                                  _vm.countryKeyPostalAddress =
                                                    $$v
                                                },
                                                expression:
                                                  "countryKeyPostalAddress",
                                              },
                                            }),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("google-auto-complete", {
                                              ref: "google-search-address",
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  12,
                                                  "Internet search"
                                                ),
                                                country:
                                                  _vm.countryCodePostalAddress,
                                                "clear-input":
                                                  _vm.clearPostalAddress,
                                              },
                                              on: {
                                                placechanged: (item) =>
                                                  _vm.handleAutoComplete(
                                                    item,
                                                    "postalAddress"
                                                  ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "address" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(100, "Street")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("Street")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "street",
                                                placeholder: _vm.FormMSG(
                                                  1103,
                                                  "Enter your street"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.postalAddress
                                                    .street,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.postalAddress,
                                                    "street",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.postalAddress.street",
                                              },
                                            }),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "number" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(101, "Number")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("Number")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "number",
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.postalAddress
                                                    .number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.postalAddress,
                                                    "number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.postalAddress.number",
                                              },
                                            }),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "state" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(105, "State")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("StateRegion")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "state",
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.postalAddress
                                                    .state,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.postalAddress,
                                                    "state",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.postalAddress.state",
                                              },
                                            }),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "city" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(103, "City")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("City")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "city",
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.postalAddress
                                                    .city,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.postalAddress,
                                                    "city",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.postalAddress.city",
                                              },
                                            }),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "zip" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        104,
                                                        "Postal Code"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("ZipCode")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "zip",
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.postalAddress
                                                    .zip,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.postalAddress,
                                                    "zip",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.postalAddress.zip",
                                              },
                                            }),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "box" }, [
                                              _c(
                                                "label",
                                                { attrs: { for: "zip" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(102, "Box")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("Box")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "box",
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.postalAddress
                                                    .box,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile.postalAddress,
                                                    "box",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.postalAddress.box",
                                              },
                                            }),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("b-row", [
                                      _c(
                                        "div",
                                        { staticClass: "ml-3" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "d-flex flex-row justify-content-center gap-2 align-items-center w-auto h-auto",
                                              staticStyle: {
                                                height: "40px",
                                                "border-radius": "0.5rem",
                                                "background-color": "#fff",
                                                cursor: "pointer",
                                              },
                                              attrs: {
                                                size: "sm",
                                                variant: _vm.isShowPostalAddress
                                                  ? "outline-danger"
                                                  : "outline-secondary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.isShowPostalAddress =
                                                    !_vm.isShowPostalAddress
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row align-items-center justify-content-center btn-address",
                                                  staticStyle: {
                                                    color: "#06263e",
                                                  },
                                                },
                                                [
                                                  _vm.isShowPostalAddress
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.X_CLEAR
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .X_CLEAR
                                                                    .color,
                                                                size: 22,
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  1313,
                                                                  "Close"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              "PlusCircle"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  "#47C7BF",
                                                                size: 22,
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  1251,
                                                                  "Add postal address (if different)"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "health"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "health" },
                                  [
                                    _vm.useField("SocialSecurityNumber")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      54,
                                                      "Social Security Number"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory(
                                                "SocialSecurityNumber"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              class: {
                                                "is-warning":
                                                  _vm.isSocSecNumberWarning,
                                              },
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  154,
                                                  "Enter your Social Security Number"
                                                ),
                                              },
                                              on: {
                                                input:
                                                  _vm.handleSocSecNumberChange,
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.socSecNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "socSecNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.socSecNumber",
                                              },
                                            }),
                                            _vm.isSocSecNumberWarning
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "warning-feedback",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            8979997,
                                                            "The social security number seems not valid"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("SocialSecurityCenter")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "socialSecurityCenter",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.FormMSG(
                                                        1700,
                                                        "Social security center"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory(
                                                "SocialSecurityCenter"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                id: "socialSecurityCenter",
                                                placeholder: this.FormMSG(
                                                  1258,
                                                  "Enter your social security center"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile
                                                    .socialSecurityCenter,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "socialSecurityCenter",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.socialSecurityCenter",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("SocialSecurityCenterAddress")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1259,
                                                      "Social security center address"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory(
                                                "SocialSecurityCenterAddress"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("v-address", {
                                              attrs: {
                                                params: {
                                                  title:
                                                    "Social security center address",
                                                },
                                                "address-id":
                                                  _vm.myProfile
                                                    .socialSecurityCenterAddressId,
                                              },
                                              on: {
                                                change:
                                                  _vm.handleDynamicAddressChange,
                                                delete:
                                                  _vm.handleDynamicAddressDelete,
                                                "address-maplink-change":
                                                  _vm.handleAddressMapLinkChange,
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-row",
                                      [
                                        _vm.useField("SocialSecurityCard")
                                          ? _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "socialSecurityCardXid",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  1255,
                                                                  "Social security card"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.isMandatory(
                                                          "SocialSecurityCard"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .socialSecurityCardXid
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .socialSecurityCardXid,
                                                                          "socialSecurityCardXid"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale": 1.6,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded: "",
                                                                    alt: "socialSecurityCardXid",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .socialSecurityCardXid,
                                                                      "socialSecurityCardXid"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .socialSecurityCardXid
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropSignatureId,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex ml-3 justify-content-center align-items-center",
                                                          },
                                                          [
                                                            _c(
                                                              "MobileProfilePicCaptureBtn",
                                                              {
                                                                attrs: {
                                                                  "parent-type":
                                                                    "user",
                                                                  id: "pictureRib",
                                                                  parentId:
                                                                    _vm
                                                                      .myProfile
                                                                      .id,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeFieldPic(
                                                                        "socialSecurityCardXid"
                                                                      )
                                                                    },
                                                                  change:
                                                                    _vm.handleImgsTaked,
                                                                },
                                                              }
                                                            ),
                                                            _vm.myProfile
                                                              .socialSecurityCardXid &&
                                                            _vm.myProfile
                                                              .socialSecurityCardXid
                                                              .length > 0
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-transparent pl-0 ml-2",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.deleteImg(
                                                                            "socialSecurityCardXid"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getIconLucide(
                                                                        "Trash2"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          size: 24,
                                                                          "stroke-width": 1.5,
                                                                          color:
                                                                            "#ea4e2c",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.useField("InsuranceCertificate")
                                          ? _c(
                                              "b-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "insuranceCertificate",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  1256,
                                                                  "Insurance certificate"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.isMandatory(
                                                          "InsuranceCertificate"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "ml-0" },
                                                      [
                                                        _vm.checkFile(
                                                          _vm.myProfile
                                                            .insuranceCertificateXid
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-center align-items-center cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.onOpenUrl(
                                                                        _vm.getImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .insuranceCertificateXid,
                                                                          "insuranceCertificateXid"
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "file-earmark-pdf-fill",
                                                                    "font-scale": 1.6,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-img",
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer",
                                                                  attrs: {
                                                                    rounded: "",
                                                                    alt: "insuranceCertificate",
                                                                    src: _vm.getImage(
                                                                      _vm
                                                                        .myProfile
                                                                        .insuranceCertificateXid,
                                                                      "insuranceCertificateXid"
                                                                    ),
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        254,
                                                                        "View Image"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImage(
                                                                          _vm
                                                                            .myProfile
                                                                            .insuranceCertificateXid
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "b-img",
                                                                _vm.imgPropSignatureId,
                                                                false
                                                              )
                                                            ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex ml-3 justify-content-center align-items-center",
                                                          },
                                                          [
                                                            _c(
                                                              "MobileProfilePicCaptureBtn",
                                                              {
                                                                attrs: {
                                                                  "parent-type":
                                                                    "user",
                                                                  id: "insuranceCertificateXid",
                                                                  parentId:
                                                                    _vm
                                                                      .myProfile
                                                                      .id,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeFieldPic(
                                                                        "insuranceCertificateXid"
                                                                      )
                                                                    },
                                                                  change:
                                                                    _vm.handleImgsTaked,
                                                                },
                                                              }
                                                            ),
                                                            _vm.myProfile
                                                              .insuranceCertificateXid &&
                                                            _vm.myProfile
                                                              .insuranceCertificateXid
                                                              .length > 0
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-transparent pl-0 ml-2",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.deleteImg(
                                                                            "insuranceCertificateXid"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getIconLucide(
                                                                        "Trash2"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          size: 24,
                                                                          "stroke-width": 1.5,
                                                                          color:
                                                                            "#ea4e2c",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.useField("LastMedicalVisitDate")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1260,
                                                      "Last medical visit date"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory(
                                                "LastMedicalVisitDate"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("v-date-picker", {
                                              attrs: {
                                                locale: _vm.lang,
                                                masks: _vm.masks,
                                                attributes: _vm.attrs,
                                              },
                                              on: {
                                                dayclick: (payload) =>
                                                  _vm.handleInputCalendar(
                                                    payload,
                                                    "lastMedicalVisit"
                                                  ),
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      inputValue,
                                                      togglePopover,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "b-input-group",
                                                          [
                                                            _c("b-form-input", {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value:
                                                                    "##/##/####",
                                                                  expression:
                                                                    "'##/##/####'",
                                                                },
                                                              ],
                                                              class: {
                                                                "temp-invalid":
                                                                  _vm.isMandatory(
                                                                    "LastMedicalVisitDate"
                                                                  ) &&
                                                                  !_vm.isDateValid(
                                                                    "lastMedicalVisit"
                                                                  )[
                                                                    "lastMedicalVisit"
                                                                  ],
                                                              },
                                                              attrs: {
                                                                value:
                                                                  inputValue,
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    8960,
                                                                    "DD/MM/YYYY"
                                                                  ),
                                                                readonly: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return togglePopover()
                                                                  },
                                                                input: (
                                                                  value
                                                                ) =>
                                                                  _vm.handleChangeDate(
                                                                    value,
                                                                    "lastMedicalVisit"
                                                                  ),
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                            }),
                                                            _c(
                                                              "b-input-group-append",
                                                              [
                                                                _c(
                                                                  "b-input-group-text",
                                                                  {
                                                                    staticClass:
                                                                      "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                    class: {
                                                                      "temp-invalid":
                                                                        _vm.isMandatory(
                                                                          "LastMedicalVisitDate"
                                                                        ) &&
                                                                        !_vm.isDateValid(
                                                                          "lastMedicalVisit"
                                                                        )[
                                                                          "lastMedicalVisit"
                                                                        ],
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return togglePopover()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        "Calendar"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            "rgba(6, 38, 62, .6)",
                                                                          size: 18,
                                                                          "stroke-width": 2.25,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                716293065
                                              ),
                                              model: {
                                                value:
                                                  _vm.myProfile
                                                    .lastMedicalVisit,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "lastMedicalVisit",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.lastMedicalVisit",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("MedicalVisitValidity")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1262,
                                                      "Medical visit validity"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory(
                                                "MedicalVisitValidity"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("v-date-picker", {
                                              attrs: {
                                                locale: _vm.lang,
                                                masks: _vm.masks,
                                                attributes: _vm.attrs,
                                              },
                                              on: {
                                                dayclick: (payload) =>
                                                  _vm.handleInputCalendar(
                                                    payload,
                                                    "medicalVisitValidity"
                                                  ),
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      inputValue,
                                                      togglePopover,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "b-input-group",
                                                          [
                                                            _c("b-form-input", {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value:
                                                                    "##/##/####",
                                                                  expression:
                                                                    "'##/##/####'",
                                                                },
                                                              ],
                                                              class: {
                                                                "temp-invalid":
                                                                  _vm.isMandatory(
                                                                    "MedicalVisitValidity"
                                                                  ) &&
                                                                  !_vm.isDateValid(
                                                                    "medicalVisitValidity"
                                                                  )[
                                                                    "medicalVisitValidity"
                                                                  ],
                                                              },
                                                              attrs: {
                                                                value:
                                                                  inputValue,
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    8960,
                                                                    "DD/MM/YYYY"
                                                                  ),
                                                                readonly: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return togglePopover()
                                                                  },
                                                                input: (
                                                                  value
                                                                ) =>
                                                                  _vm.handleChangeDate(
                                                                    value,
                                                                    "medicalVisitValidity"
                                                                  ),
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                            }),
                                                            _c(
                                                              "b-input-group-append",
                                                              [
                                                                _c(
                                                                  "b-input-group-text",
                                                                  {
                                                                    staticClass:
                                                                      "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return togglePopover()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        "Calendar"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            "rgba(6, 38, 62, .6)",
                                                                          size: 18,
                                                                          "stroke-width": 2.25,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1341641791
                                              ),
                                              model: {
                                                value:
                                                  _vm.myProfile
                                                    .medicalVisitValidity,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "medicalVisitValidity",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.medicalVisitValidity",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("MedicalVisitDocument")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "MedicalVisitDocument",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1264,
                                                        "Medical visit document"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory(
                                                "MedicalVisitDocument"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c(
                                              "b-row",
                                              { staticClass: "ml-0" },
                                              [
                                                _vm.checkFile(
                                                  _vm.myProfile
                                                    .medicalVisitDocumentXid
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.onOpenUrl(
                                                              _vm.getImage(
                                                                _vm.myProfile
                                                                  .medicalVisitDocumentXid,
                                                                "medicalVisitDocumentXid"
                                                              )
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "file-earmark-pdf-fill",
                                                            "font-scale": 1.6,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "b-img",
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "cursor-pointer",
                                                          attrs: {
                                                            rounded: "",
                                                            alt: "MedicalVisitDocument",
                                                            src: _vm.getImage(
                                                              _vm.myProfile
                                                                .medicalVisitDocumentXid,
                                                              "medicalVisitDocumentXid"
                                                            ),
                                                            title: _vm.FormMSG(
                                                              254,
                                                              "View Image"
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showImage(
                                                                _vm.myProfile
                                                                  .medicalVisitDocumentXid
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "b-img",
                                                        _vm.imgPropSignatureId,
                                                        false
                                                      )
                                                    ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex ml-3 justify-content-center align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "MobileProfilePicCaptureBtn",
                                                      {
                                                        attrs: {
                                                          "parent-type": "user",
                                                          id: "medicalVisitDocumentXid",
                                                          parentId:
                                                            _vm.myProfile.id,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeFieldPic(
                                                              "medicalVisitDocumentXid"
                                                            )
                                                          },
                                                          change:
                                                            _vm.handleImgsTaked,
                                                        },
                                                      }
                                                    ),
                                                    _vm.myProfile
                                                      .medicalVisitDocumentXid &&
                                                    _vm.myProfile
                                                      .medicalVisitDocumentXid
                                                      .length > 0
                                                      ? _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent pl-0 ml-2",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                $event.stopPropagation()
                                                                return _vm.deleteImg(
                                                                  "medicalVisitDocumentXid"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getIconLucide(
                                                                "Trash2"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 24,
                                                                  "stroke-width": 1.5,
                                                                  color:
                                                                    "#ea4e2c",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("WorkerWithADisability")
                                      ? _c(
                                          "b-form-group",
                                          { staticClass: "text-start" },
                                          [
                                            _c(
                                              "template",
                                              { slot: "label" },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1265,
                                                            "Worker with a disability"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm.isMandatory(
                                                      "WorkerWithADisability"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "required",
                                                          },
                                                          [_vm._v(" *")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _vm.isMandatory(
                                                  "WorkerWithADisability"
                                                )
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "required",
                                                      },
                                                      [_vm._v(" *")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start",
                                                attrs: {
                                                  size: "lg",
                                                  switch: "",
                                                },
                                                model: {
                                                  value: _vm.myProfile.disabled,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.myProfile,
                                                      "disabled",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "myProfile.disabled",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-redired",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.myProfile.disabled
                                                          ? _vm.FormMSG(
                                                              1276,
                                                              "Yes"
                                                            )
                                                          : _vm.FormMSG(
                                                              1277,
                                                              "No"
                                                            )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.myProfile.disabled
                                      ? _c(
                                          "div",
                                          { staticClass: "card-gray" },
                                          [
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1266,
                                                            "Disability notification date"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("v-date-picker", {
                                                      attrs: {
                                                        locale: _vm.lang,
                                                        masks: _vm.masks,
                                                        attributes: _vm.attrs,
                                                      },
                                                      on: {
                                                        dayclick: (payload) =>
                                                          _vm.handleInputCalendar(
                                                            payload,
                                                            "disabilityNotification"
                                                          ),
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              inputValue,
                                                              togglePopover,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "b-input-group",
                                                                  [
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "mask",
                                                                              rawName:
                                                                                "v-mask",
                                                                              value:
                                                                                "##/##/####",
                                                                              expression:
                                                                                "'##/##/####'",
                                                                            },
                                                                          ],
                                                                        class: {
                                                                          "temp-invalid":
                                                                            _vm.isMandatory(
                                                                              "DisabilityNotificationDate"
                                                                            ) &&
                                                                            !_vm.isDateValid(
                                                                              "disabilityNotification"
                                                                            )[
                                                                              "disabilityNotification"
                                                                            ],
                                                                        },
                                                                        attrs: {
                                                                          value:
                                                                            inputValue,
                                                                          placeholder:
                                                                            _vm.FormMSG(
                                                                              8960,
                                                                              "DD/MM/YYYY"
                                                                            ),
                                                                          readonly:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return togglePopover()
                                                                            },
                                                                          input:
                                                                            (
                                                                              value
                                                                            ) =>
                                                                              _vm.handleChangeDate(
                                                                                value,
                                                                                "disabilityNotification"
                                                                              ),
                                                                          keydown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "enter",
                                                                                  13,
                                                                                  $event.key,
                                                                                  "Enter"
                                                                                )
                                                                              )
                                                                                return null
                                                                              $event.preventDefault()
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "b-input-group-append",
                                                                      [
                                                                        _c(
                                                                          "b-input-group-text",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return togglePopover()
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getLucideIcon(
                                                                                "Calendar"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "rgba(6, 38, 62, .6)",
                                                                                    size: 18,
                                                                                    "stroke-width": 2.25,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2818636555
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .disabilityNotification,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "disabilityNotification",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.disabilityNotification",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "type" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              1268,
                                                              "Type"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "type",
                                                        placeholder:
                                                          this.FormMSG(
                                                            1269,
                                                            "Enter type"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .disabilityType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "disabilityType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.disabilityType",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "status",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              1270,
                                                              "Status"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "status",
                                                        placeholder:
                                                          this.FormMSG(
                                                            1271,
                                                            "Enter status"
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .disabilityStatus,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "disabilityStatus",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.disabilityStatus",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        1272,
                                                        "Invalidity"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-select",
                                                      {
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .invalidity,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "invalidity",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.invalidity",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.menuInvalidity,
                                                        function (
                                                          gender,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "b-form-select-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                value:
                                                                  gender.value,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    gender.text
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-form-group",
                                                  { staticClass: "text-start" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1273,
                                                            "RQTH"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-start",
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          switch: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile.rqth,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "rqth",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.rqth",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-redired",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.myProfile
                                                                  .rqth
                                                                  ? _vm.FormMSG(
                                                                      1276,
                                                                      "Yes"
                                                                    )
                                                                  : _vm.FormMSG(
                                                                      1277,
                                                                      "No"
                                                                    )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.myProfile.disabled
                                              ? _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        1274,
                                                        "Invalidity percentage"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group",
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "number",
                                                            step: "1",
                                                            min: "0",
                                                            max: "100",
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.myProfile
                                                                .invalidityPourcentage,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.myProfile,
                                                                "invalidityPourcentage",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "myProfile.invalidityPourcentage",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          [
                                                            _c(
                                                              "b-input-group-text",
                                                              {
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#00a09c",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getIconLucide(
                                                                    "Percent"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      color:
                                                                        "#ffffff",
                                                                      size: 16,
                                                                      "stroke-width": 3.5,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.useField("HaveAllergies")
                                      ? _c(
                                          "b-form-group",
                                          { staticClass: "text-start" },
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      25,
                                                      "Have Allergies?"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("HaveAllergies")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-start",
                                                attrs: {
                                                  size: "lg",
                                                  switch: "",
                                                },
                                                model: {
                                                  value: _vm.haveAllergies,
                                                  callback: function ($$v) {
                                                    _vm.haveAllergies = $$v
                                                  },
                                                  expression: "haveAllergies",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-redired",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.haveAllergies
                                                          ? _vm.FormMSG(
                                                              1276,
                                                              "Yes"
                                                            )
                                                          : _vm.FormMSG(
                                                              1277,
                                                              "No"
                                                            )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.haveAllergies &&
                                    _vm.useField("AllergiesDetails")
                                      ? _c(
                                          "div",
                                          { staticClass: "card-gray" },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            26,
                                                            "Allergies details"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm.isMandatory(
                                                      "AllergiesDetails"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "required",
                                                          },
                                                          [_vm._v(" *")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("b-form-textarea", {
                                                  attrs: {
                                                    type: "textarea",
                                                    rows: "2",
                                                    placeholder: _vm.FormMSG(
                                                      6,
                                                      "Enter allergies details..."
                                                    ),
                                                    autocomplete: "off",
                                                    disabled:
                                                      _vm.haveAllergies ===
                                                      false,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.allergies,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "allergies",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.allergies",
                                                  },
                                                }),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "family"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(14, "Family composition")
                                        )
                                      ),
                                    ]),
                                    _vm.useField("CivilStatus")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "optionsForCivilStatus",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        53,
                                                        "Civil status"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.isMandatory("CivilStatus")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-select", {
                                              attrs: {
                                                options:
                                                  _vm.optionsForCivilStatus,
                                                id: "optionsForCivilStatus",
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile.civilStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "civilStatus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.civilStatus",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.myProfile.civilStatus === 3
                                      ? _c(
                                          "div",
                                          { staticClass: "card-gray" },
                                          [
                                            _vm.useField("SpouseFirstName")
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                174,
                                                                "Spouse first name"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm.isMandatory(
                                                          "SpouseFirstName"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            176,
                                                            "Enter your Spouse first name "
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .spouseFirstName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "spouseFirstName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.spouseFirstName",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm.useField("SpouseLastName")
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                172,
                                                                "Spouse name"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm.isMandatory(
                                                          "SpouseLastName"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            173,
                                                            "Enter your Spouse name "
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .spouseName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "spouseName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.spouseName",
                                                      },
                                                    }),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm.useField("SpouseBirthdate")
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            177,
                                                            "Spouse birth date"
                                                          )
                                                        ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                      _vm.isMandatory(
                                                        "SpouseBirthdate"
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "required",
                                                            },
                                                            [_vm._v(" *")]
                                                          )
                                                        : _vm._e(),
                                                    ]),
                                                    _c("v-date-picker", {
                                                      attrs: {
                                                        locale: _vm.lang,
                                                        masks: _vm.masks,
                                                        attributes: _vm.attrs,
                                                      },
                                                      on: {
                                                        dayclick: (payload) =>
                                                          _vm.handleInputCalendar(
                                                            payload,
                                                            "spouseBirthDate"
                                                          ),
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              inputValue,
                                                              togglePopover,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "b-input-group",
                                                                  [
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "mask",
                                                                              rawName:
                                                                                "v-mask",
                                                                              value:
                                                                                "##/##/####",
                                                                              expression:
                                                                                "'##/##/####'",
                                                                            },
                                                                          ],
                                                                        class: {
                                                                          "temp-invalid":
                                                                            _vm.isMandatory(
                                                                              "SpouseBirthdate"
                                                                            ) &&
                                                                            !_vm.isDateValid(
                                                                              "spouseBirthDate"
                                                                            )[
                                                                              "spouseBirthDate"
                                                                            ],
                                                                        },
                                                                        attrs: {
                                                                          value:
                                                                            inputValue,
                                                                          placeholder:
                                                                            _vm.FormMSG(
                                                                              8960,
                                                                              "DD/MM/YYYY"
                                                                            ),
                                                                          readonly:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return togglePopover()
                                                                            },
                                                                          change:
                                                                            (
                                                                              value
                                                                            ) =>
                                                                              _vm.handleChangeDate(
                                                                                value,
                                                                                "spouseBirthDate"
                                                                              ),
                                                                          keydown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "enter",
                                                                                  13,
                                                                                  $event.key,
                                                                                  "Enter"
                                                                                )
                                                                              )
                                                                                return null
                                                                              $event.preventDefault()
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "b-input-group-append",
                                                                      [
                                                                        _c(
                                                                          "b-input-group-text",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                            class:
                                                                              {
                                                                                "temp-invalid":
                                                                                  _vm.isMandatory(
                                                                                    "SpouseBirthdate"
                                                                                  ) &&
                                                                                  !_vm.isDateValid(
                                                                                    "spouseBirthDate"
                                                                                  )[
                                                                                    "spouseBirthDate"
                                                                                  ],
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return togglePopover()
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getLucideIcon(
                                                                                "Calendar"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "rgba(6, 38, 62, .6)",
                                                                                    size: 18,
                                                                                    "stroke-width": 2.25,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        18063607
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .spouseBirthDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "spouseBirthDate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.spouseBirthDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.useField("SpouseHasSalary")
                                              ? _c(
                                                  "b-form-group",
                                                  { staticClass: "text-start" },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "label" },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "firstname",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  181,
                                                                  "Spouse has salary"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.isMandatory(
                                                          "SpouseHasSalary"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "required",
                                                              },
                                                              [_vm._v(" *")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-start",
                                                        attrs: {
                                                          size: "lg",
                                                          switch: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .spouseHasSalary,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "spouseHasSalary",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.spouseHasSalary",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-redired",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.myProfile
                                                                  .spouseHasSalary
                                                                  ? _vm.FormMSG(
                                                                      1276,
                                                                      "Yes"
                                                                    )
                                                                  : _vm.FormMSG(
                                                                      1277,
                                                                      "No"
                                                                    )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm.useField("SpouseProfession")
                                              ? _c(
                                                  "b-form-group",
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            179,
                                                            "Spouse profession"
                                                          )
                                                        )
                                                      ),
                                                      _vm.isMandatory(
                                                        "SpouseProfession"
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "required",
                                                            },
                                                            [_vm._v(" *")]
                                                          )
                                                        : _vm._e(),
                                                    ]),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          _vm.FormMSG(
                                                            180,
                                                            "Enter your Spouse profession "
                                                          ),
                                                      },
                                                      on: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.preventDefault()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.myProfile
                                                            .spouseProfession,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.myProfile,
                                                            "spouseProfession",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "myProfile.spouseProfession",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "mt-3" },
                                      [
                                        _vm.useField(
                                          "NumberOfDependentChildren"
                                        ) ||
                                        _vm.useField("NumberOfDisabledChildren")
                                          ? _c(
                                              "b-form-group",
                                              { staticClass: "text-start" },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1192,
                                                        "Do you have children ?"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-start",
                                                    attrs: {
                                                      size: "lg",
                                                      switch: "",
                                                    },
                                                    model: {
                                                      value: _vm.haveChild,
                                                      callback: function ($$v) {
                                                        _vm.haveChild = $$v
                                                      },
                                                      expression: "haveChild",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-redired",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.haveChild
                                                              ? _vm.FormMSG(
                                                                  1276,
                                                                  "Yes"
                                                                )
                                                              : _vm.FormMSG(
                                                                  1277,
                                                                  "No"
                                                                )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.haveChild
                                      ? _c(
                                          "div",
                                          { staticClass: "card-gray" },
                                          [
                                            _vm.useField(
                                              "NumberOfDependentChildren"
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "px-0 d-flex justify-content-start align-items-center",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "little-btn mr-2 px-1",
                                                            attrs: {
                                                              variant: "light",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.decrementValue(
                                                                  "numberOfDependentChild"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getIconLucide(
                                                                "Minus"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "#06263EA3",
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("b-form-input", {
                                                          staticClass:
                                                            "little-input mt-2",
                                                          attrs: {
                                                            value:
                                                              _vm.myProfile
                                                                .numberOfDependentChild,
                                                            type: "number",
                                                            step: "1",
                                                            min: "0",
                                                            placeholder: "0",
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "little-btn ml-2 px-1",
                                                            attrs: {
                                                              variant: "light",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.incrementValue(
                                                                  "numberOfDependentChild"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getIconLucide(
                                                                "Plus"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "#06263EA3",
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                        staticStyle: {
                                                          "margin-top":
                                                            "0.85rem",
                                                        },
                                                        attrs: { cols: "8" },
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "m-0 pl-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    183,
                                                                    "Number of dependent child"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                            _vm.isMandatory(
                                                              "NumberOfDependentChildren"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "required",
                                                                  },
                                                                  [_vm._v(" *")]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.useField(
                                              "NumberOfDisabledChildren"
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mt-3 d-flex flex-row align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "px-0 d-flex justify-content-start align-items-center",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "little-btn mr-2 px-1",
                                                            attrs: {
                                                              variant: "light",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.decrementValue(
                                                                  "numberOfDisabledChild"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getIconLucide(
                                                                "Minus"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "#06263EA3",
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("b-form-input", {
                                                          staticClass:
                                                            "little-input mt-2",
                                                          attrs: {
                                                            value:
                                                              _vm.myProfile
                                                                .numberOfDisabledChild,
                                                            type: "number",
                                                            step: "1",
                                                            min: "0",
                                                            placeholder: "0",
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "little-btn ml-2 px-1",
                                                            attrs: {
                                                              variant: "light",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.incrementValue(
                                                                  "numberOfDisabledChild"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getIconLucide(
                                                                "Plus"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "#06263EA3",
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                        staticStyle: {
                                                          "margin-top":
                                                            "0.85rem",
                                                        },
                                                        attrs: { cols: "8" },
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "m-0 pl-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    184,
                                                                    "Number of disabled child"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                            _vm.isMandatory(
                                                              "NumberOfDisabledChildren"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "required",
                                                                  },
                                                                  [_vm._v(" *")]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.useField("NumberOfDependentPerson") ||
                                    _vm.useField("NumberOfDisabledPerson")
                                      ? _c(
                                          "div",
                                          { staticClass: "mt-3" },
                                          [
                                            _c(
                                              "b-form-group",
                                              { staticClass: "text-start" },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1193,
                                                        "Do you have other dependents ?"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-start",
                                                    attrs: {
                                                      size: "lg",
                                                      switch: "",
                                                    },
                                                    model: {
                                                      value: _vm.haveDependents,
                                                      callback: function ($$v) {
                                                        _vm.haveDependents = $$v
                                                      },
                                                      expression:
                                                        "haveDependents",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-redired",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.haveDependents
                                                              ? _vm.FormMSG(
                                                                  1276,
                                                                  "Yes"
                                                                )
                                                              : _vm.FormMSG(
                                                                  1277,
                                                                  "No"
                                                                )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.haveDependents
                                      ? _c(
                                          "div",
                                          { staticClass: "card-gray" },
                                          [
                                            _vm.useField(
                                              "NumberOfDependentPerson"
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "px-0 d-flex justify-content-start align-items-center",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "little-btn mr-2 px-1",
                                                            attrs: {
                                                              variant: "light",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.decrementValue(
                                                                  "numberOfDependentPerson"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getIconLucide(
                                                                "Minus"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "#06263EA3",
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("b-form-input", {
                                                          staticClass:
                                                            "little-input mt-2",
                                                          attrs: {
                                                            value:
                                                              _vm.myProfile
                                                                .numberOfDependentPerson,
                                                            type: "number",
                                                            step: "1",
                                                            min: "0",
                                                            placeholder: "0",
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "little-btn ml-2 px-1",
                                                            attrs: {
                                                              variant: "light",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.incrementValue(
                                                                  "numberOfDependentPerson"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getIconLucide(
                                                                "Plus"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "#06263EA3",
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                        staticStyle: {
                                                          "margin-top":
                                                            "0.85rem",
                                                        },
                                                        attrs: { cols: "8" },
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "m-0 pl-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1221,
                                                                    "Number of dependent persons"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                            _vm.isMandatory(
                                                              "NumberOfDependentPerson"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "required",
                                                                  },
                                                                  [_vm._v(" *")]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.useField(
                                              "NumberOfDisabledPerson"
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mt-3 d-flex flex-row align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "px-0 d-flex justify-content-start align-items-center",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "little-btn mr-2 px-1",
                                                            attrs: {
                                                              variant: "light",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.decrementValue(
                                                                  "numberOfDisabledPerson"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getIconLucide(
                                                                "Minus"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "#06263EA3",
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("b-form-input", {
                                                          staticClass:
                                                            "little-input mt-2",
                                                          attrs: {
                                                            value:
                                                              _vm.myProfile
                                                                .numberOfDisabledPerson,
                                                            type: "number",
                                                            step: "1",
                                                            min: "0",
                                                            placeholder: "0",
                                                          },
                                                          on: {
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "little-btn ml-2 px-1",
                                                            attrs: {
                                                              variant: "light",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.incrementValue(
                                                                  "numberOfDisabledPerson"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getIconLucide(
                                                                "Plus"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    "#06263EA3",
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                        staticStyle: {
                                                          "margin-top":
                                                            "0.85rem",
                                                        },
                                                        attrs: { cols: "8" },
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "m-0 pl-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1222,
                                                                    "Number of disabled persons"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                            _vm.isMandatory(
                                                              "NumberOfDisabledPerson"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "required",
                                                                  },
                                                                  [_vm._v(" *")]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.useField("ContactName") ||
                                _vm.useField("ContactPhoneNumber")
                                  ? _c("div", {
                                      staticClass: "divider mr-4 ml-4",
                                    })
                                  : _vm._e(),
                                _vm.useField("ContactName") ||
                                _vm.useField("ContactPhoneNumber")
                                  ? _c(
                                      "div",
                                      { staticClass: "personal-data" },
                                      [
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                1176,
                                                "Emergency Contact"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm.useField("ContactName")
                                          ? _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1177,
                                                            "Contact Name"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm.isMandatory(
                                                      "ContactName"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "required",
                                                          },
                                                          [_vm._v(" *")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    placeholder: _vm.FormMSG(
                                                      1178,
                                                      "Enter Contact Name"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .personToContact,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "personToContact",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.personToContact",
                                                  },
                                                }),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _vm.useField("ContactPhoneNumber")
                                          ? _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1179,
                                                            "Contact Phone Number"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm.isMandatory(
                                                      "ContactPhoneNumber"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "required",
                                                          },
                                                          [_vm._v(" *")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    placeholder: _vm.FormMSG(
                                                      1180,
                                                      "Enter Contact Phone Number"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .personToContactPhone,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "personToContactPhone",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.personToContactPhone",
                                                  },
                                                }),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "personal-vehicle"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(1226, "Personal vehicle")
                                        )
                                      ),
                                    ]),
                                    _c("vehicule-form", {
                                      attrs: {
                                        "vehicule-data": _vm.myProfile.vehicule,
                                        useVehicleName: _vm.useVehicleName,
                                        useVehicleBrand: _vm.useVehicleBrand,
                                        useVehicleModel: _vm.useVehicleModel,
                                        useVehiclePlateNumber:
                                          _vm.useVehiclePlateNumber,
                                        useVehicleTransportationMean:
                                          _vm.useVehicleTransportationMean,
                                        useVehicleTransportationMeanDetail:
                                          _vm.useVehicleTransportationMeanDetail,
                                        useVehicleKgCoTwo:
                                          _vm.useVehicleKgCoTwo,
                                        usePower: _vm.userFieldOption.usePower,
                                        useGrayCardFrontPicture:
                                          _vm.userFieldOption
                                            .useGrayCardFrontPicture,
                                        useGrayCardBackPicture:
                                          _vm.userFieldOption
                                            .useGrayCardBackPicture,
                                        mandatoryVehicleName:
                                          _vm.userFieldOption
                                            .mandatoryVehicleName,
                                        mandatoryVehicleBrand:
                                          _vm.userFieldOption
                                            .mandatoryVehicleBrand,
                                        mandatoryVehicleModel:
                                          _vm.userFieldOption
                                            .mandatoryVehicleModel,
                                        mandatoryVehiclePlateNumber:
                                          _vm.userFieldOption
                                            .mandatoryVehiclePlateNumber,
                                        mandatoryVehicleTransportationMean:
                                          _vm.userFieldOption
                                            .mandatoryVehicleTransportationMean,
                                        mandatoryVehicleKgCoTwo:
                                          _vm.userFieldOption
                                            .mandatoryVehicleKgCoTwo,
                                        mandatoryPower:
                                          _vm.userFieldOption.mandatoryPower,
                                        mandatoryGrayCardFrontPicture:
                                          _vm.userFieldOption
                                            .mandatoryGrayCardFrontPicture,
                                        mandatoryGrayCardBackPicture:
                                          _vm.userFieldOption
                                            .mandatoryGrayCardBackPicture,
                                      },
                                      on: {
                                        "vehicule-form:change":
                                          _vm.handleVehiculeFormChange,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "other"
                            ? _c("div", [
                                _vm.useField("showMyEmailInCrewList") ||
                                _vm.useField("showMyPhoneInCrewList")
                                  ? _c(
                                      "div",
                                      { staticClass: "personal-data" },
                                      [
                                        _vm.useField("showMyEmailInCrewList") ||
                                        _vm.useField("showMyPhoneInCrewList")
                                          ? _c("h2", [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(16, "Other"))
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.useField("showMyEmailInCrewList")
                                          ? _c(
                                              "b-form-group",
                                              { staticClass: "text-start" },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        18,
                                                        "Show my email in crew list"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-start",
                                                    attrs: {
                                                      size: "lg",
                                                      switch: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile
                                                          .showMyEmailInCrew,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile,
                                                          "showMyEmailInCrew",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.showMyEmailInCrew",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-redired",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.myProfile
                                                              .showMyEmailInCrew
                                                              ? _vm.FormMSG(
                                                                  1276,
                                                                  "Yes"
                                                                )
                                                              : _vm.FormMSG(
                                                                  1277,
                                                                  "No"
                                                                )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.useField("showMyPhoneInCrewList")
                                          ? _c(
                                              "b-form-group",
                                              { staticClass: "text-start" },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        19,
                                                        "Show my phone in crew list"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-start",
                                                    attrs: {
                                                      size: "lg",
                                                      switch: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile
                                                          .showMyPhoneInCrew,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile,
                                                          "showMyPhoneInCrew",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.showMyPhoneInCrew",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-redired",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.myProfile
                                                              .showMyPhoneInCrew
                                                              ? _vm.FormMSG(
                                                                  1276,
                                                                  "Yes"
                                                                )
                                                              : _vm.FormMSG(
                                                                  1277,
                                                                  "No"
                                                                )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.useField("SncfLoyaltyCard") ||
                                _vm.useField("SncfDiscountCard") ||
                                _vm.useField("FlyingBlueCard") ||
                                _vm.useField("OtherAirlineCard")
                                  ? _c("div", {
                                      staticClass: "divider mr-4 ml-4",
                                    })
                                  : _vm._e(),
                                _vm.useField("SncfLoyaltyCard") ||
                                _vm.useField("SncfDiscountCard") ||
                                _vm.useField("FlyingBlueCard") ||
                                _vm.useField("OtherAirlineCard")
                                  ? _c(
                                      "div",
                                      { staticClass: "personal-data" },
                                      [
                                        _vm.useField("SncfLoyaltyCard") ||
                                        _vm.useField("SncfDiscountCard") ||
                                        _vm.useField("FlyingBlueCard") ||
                                        _vm.useField("OtherAirlineCard")
                                          ? _c("h2", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1280,
                                                      "Loyalty cards"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.useField("SncfLoyaltyCard")
                                          ? _c(
                                              "b-form-group",
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c("div", [
                                                            _c("span", [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      1281,
                                                                      "SNCF loyalty card"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                              _vm.isMandatory(
                                                                "SncfLoyaltyCard"
                                                              )
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "required",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " *"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-fin",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1282,
                                                                        "(Type + number)"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1145629471
                                                ),
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      1283,
                                                      "Enter your sncf loyalty card"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .trainLoyaltyCard,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "trainLoyaltyCard",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.trainLoyaltyCard",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.useField("SncfDiscountCard")
                                          ? _c(
                                              "b-form-group",
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c("div", [
                                                            _c("span", [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      1284,
                                                                      "SNCF discount card"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                              _vm.isMandatory(
                                                                "SncfDiscountCard"
                                                              )
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "required",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " *"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-fin",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1282,
                                                                        "(Type + number)"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3935501018
                                                ),
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      1285,
                                                      "Enter your sncf discount card"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .trainDiscountCard,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "trainDiscountCard",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.trainDiscountCard",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.useField("FlyingBlueCard")
                                          ? _c(
                                              "b-form-group",
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c("div", [
                                                            _c("span", [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      1286,
                                                                      "Flying blue card"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                              _vm.isMandatory(
                                                                "FlyingBlueCard"
                                                              )
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "required",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " *"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-fin",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1287,
                                                                        "(Number)"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3018601710
                                                ),
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      1288,
                                                      "Enter your flying blue card"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .flyingLoyaltyCard,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "flyingLoyaltyCard",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.flyingLoyaltyCard",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.useField("OtherAirlineCard")
                                          ? _c(
                                              "b-form-group",
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c("div", [
                                                            _c("span", [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      1289,
                                                                      "Other airline card"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                              _vm.isMandatory(
                                                                "OtherAirlineCard"
                                                              )
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "required",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " *"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-fin",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1287,
                                                                        "(Number)"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1790862241
                                                ),
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      1288,
                                                      "Enter your other airline card"
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .otherAirlineCard,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "otherAirlineCard",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.otherAirlineCard",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.useField("ResidencePermitNumber") ||
                                _vm.useField("ResidenceValidityEnd") ||
                                _vm.useField("ResidencePermit") ||
                                _vm.useField("WorkCard")
                                  ? _c("div", {
                                      staticClass: "divider mr-4 ml-4",
                                    })
                                  : _vm._e(),
                                !_vm.hideInfoOther
                                  ? _c("div", [
                                      _vm.useField("ResidencePermitNumber") ||
                                      _vm.useField("ResidenceValidityEnd") ||
                                      _vm.useField("ResidencePermit") ||
                                      _vm.useField("WorkCard")
                                        ? _c(
                                            "div",
                                            { staticClass: "personal-data" },
                                            [
                                              _vm.useField(
                                                "ResidencePermitNumber"
                                              ) ||
                                              _vm.useField(
                                                "ResidenceValidityEnd"
                                              ) ||
                                              _vm.useField("ResidencePermit") ||
                                              _vm.useField("WorkCard")
                                                ? _c("h2", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1290,
                                                              "Foreign worker"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                      _vm.isMandatory(
                                                        "ResidencePermitNumber"
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "required",
                                                            },
                                                            [_vm._v(" *")]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-fin",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1309,
                                                                "(section to fill only if you're a foreign worker)"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ])
                                                : _vm._e(),
                                              _vm.useField(
                                                "ResidencePermitNumber"
                                              )
                                                ? _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          1292,
                                                          "Residence permit number"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          placeholder:
                                                            _vm.FormMSG(
                                                              1293,
                                                              "Enter your residence permit number"
                                                            ),
                                                        },
                                                        on: {
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            $event.preventDefault()
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .residencePermitNumber,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "residencePermitNumber",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.residencePermitNumber",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.useField(
                                                "ResidenceValidityEnd"
                                              )
                                                ? _c(
                                                    "b-form-group",
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "label" },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  1294,
                                                                  "Residence permit validity end"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _vm.isMandatory(
                                                            "ResidenceValidityEnd"
                                                          )
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "required",
                                                                },
                                                                [_vm._v(" *")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c("v-date-picker", {
                                                        attrs: {
                                                          locale: _vm.lang,
                                                          masks: _vm.masks,
                                                          attributes: _vm.attrs,
                                                        },
                                                        on: {
                                                          dayclick: (payload) =>
                                                            _vm.handleInputCalendar(
                                                              payload,
                                                              "residencePermitEndValidity"
                                                            ),
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                inputValue,
                                                                togglePopover,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "b-input-group",
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "mask",
                                                                                rawName:
                                                                                  "v-mask",
                                                                                value:
                                                                                  "##/##/####",
                                                                                expression:
                                                                                  "'##/##/####'",
                                                                              },
                                                                            ],
                                                                          class:
                                                                            {
                                                                              "temp-invalid":
                                                                                _vm.isMandatory(
                                                                                  "ResidenceValidityEnd"
                                                                                ) &&
                                                                                !_vm.isDateValid(
                                                                                  "residencePermitEndValidity"
                                                                                )[
                                                                                  "residencePermitEndValidity"
                                                                                ],
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              value:
                                                                                inputValue,
                                                                              placeholder:
                                                                                _vm.FormMSG(
                                                                                  8960,
                                                                                  "DD/MM/YYYY"
                                                                                ),
                                                                              readonly:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return togglePopover()
                                                                              },
                                                                            input:
                                                                              (
                                                                                value
                                                                              ) =>
                                                                                _vm.handleChangeDate(
                                                                                  value,
                                                                                  "residencePermitEndValidity"
                                                                                ),
                                                                            keydown:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "enter",
                                                                                    13,
                                                                                    $event.key,
                                                                                    "Enter"
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                $event.preventDefault()
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-input-group-append",
                                                                        [
                                                                          _c(
                                                                            "b-input-group-text",
                                                                            {
                                                                              staticClass:
                                                                                "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                              class:
                                                                                {
                                                                                  "temp-invalid":
                                                                                    _vm.isMandatory(
                                                                                      "ResidenceValidityEnd"
                                                                                    ) &&
                                                                                    !_vm.isDateValid(
                                                                                      "residencePermitEndValidity"
                                                                                    )[
                                                                                      "residencePermitEndValidity"
                                                                                    ],
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return togglePopover()
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  "Calendar"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "rgba(6, 38, 62, .6)",
                                                                                      size: 18,
                                                                                      "stroke-width": 2.25,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          778970494
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .residencePermitEndValidity,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "residencePermitEndValidity",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.residencePermitEndValidity",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "b-row",
                                                [
                                                  _vm.useField(
                                                    "ResidencePermit"
                                                  )
                                                    ? _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "6" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for: "residentPermitXid",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1296,
                                                                        "Resident permit"
                                                                      )
                                                                    ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                  _vm.isMandatory(
                                                                    "ResidencePermit"
                                                                  )
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "required",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " *"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c(
                                                                "b-row",
                                                                {
                                                                  staticClass:
                                                                    "ml-0",
                                                                },
                                                                [
                                                                  _vm.checkFile(
                                                                    _vm
                                                                      .myProfile
                                                                      .residentPermitXid
                                                                  )
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.onOpenUrl(
                                                                                  _vm.getImage(
                                                                                    _vm
                                                                                      .myProfile
                                                                                      .residentPermitXid,
                                                                                    "residentPermitXid"
                                                                                  )
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  icon: "file-earmark-pdf-fill",
                                                                                  "font-scale": 1.6,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _c(
                                                                        "b-img",
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "cursor-pointer",
                                                                            attrs:
                                                                              {
                                                                                rounded:
                                                                                  "",
                                                                                alt: "residentPermitXid",
                                                                                src: _vm.getImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .residentPermitXid,
                                                                                  "residentPermitXid"
                                                                                ),
                                                                                title:
                                                                                  _vm.FormMSG(
                                                                                    254,
                                                                                    "View Image"
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.showImage(
                                                                                    _vm
                                                                                      .myProfile
                                                                                      .residentPermitXid
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          "b-img",
                                                                          _vm.imgPropSignatureId,
                                                                          false
                                                                        )
                                                                      ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex ml-3 justify-content-center align-items-center",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "MobileProfilePicCaptureBtn",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "parent-type":
                                                                                "user",
                                                                              id: "residentPermitXid",
                                                                              parentId:
                                                                                _vm
                                                                                  .myProfile
                                                                                  .id,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.changeFieldPic(
                                                                                  "residentPermitXid"
                                                                                )
                                                                              },
                                                                            change:
                                                                              _vm.handleImgsTaked,
                                                                          },
                                                                        }
                                                                      ),
                                                                      _vm
                                                                        .myProfile
                                                                        .residentPermitXid &&
                                                                      _vm
                                                                        .myProfile
                                                                        .residentPermitXid
                                                                        .length >
                                                                        0
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn-transparent pl-0 ml-2",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    $event.stopPropagation()
                                                                                    return _vm.deleteImg(
                                                                                      "residentPermitXid"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getIconLucide(
                                                                                  "Trash2"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      size: 24,
                                                                                      "stroke-width": 1.5,
                                                                                      color:
                                                                                        "#ea4e2c",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.useField("WorkCard")
                                                    ? _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "6" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for: "workCardXid",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1297,
                                                                        "Work card"
                                                                      )
                                                                    ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                  _vm.isMandatory(
                                                                    "WorkCard"
                                                                  )
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "required",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " *"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c(
                                                                "b-row",
                                                                {
                                                                  staticClass:
                                                                    "ml-0",
                                                                },
                                                                [
                                                                  _vm.checkFile(
                                                                    _vm
                                                                      .myProfile
                                                                      .workCardXid
                                                                  )
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-content-center align-items-center cursor-pointer",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.onOpenUrl(
                                                                                  _vm.getImage(
                                                                                    _vm
                                                                                      .myProfile
                                                                                      .workCardXid,
                                                                                    "workCardXid"
                                                                                  )
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  icon: "file-earmark-pdf-fill",
                                                                                  "font-scale": 1.6,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _c(
                                                                        "b-img",
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "cursor-pointer",
                                                                            attrs:
                                                                              {
                                                                                rounded:
                                                                                  "",
                                                                                alt: "workCardXid",
                                                                                src: _vm.getImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .workCardXid,
                                                                                  "workCardXid"
                                                                                ),
                                                                                title:
                                                                                  _vm.FormMSG(
                                                                                    254,
                                                                                    "View Image"
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.showImage(
                                                                                    _vm
                                                                                      .myProfile
                                                                                      .workCardXid
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          "b-img",
                                                                          _vm.imgPropSignatureId,
                                                                          false
                                                                        )
                                                                      ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex ml-3 justify-content-center align-items-center",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "MobileProfilePicCaptureBtn",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "parent-type":
                                                                                "user",
                                                                              id: "workCardXid",
                                                                              parentId:
                                                                                _vm
                                                                                  .myProfile
                                                                                  .id,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.changeFieldPic(
                                                                                  "workCardXid"
                                                                                )
                                                                              },
                                                                            change:
                                                                              _vm.handleImgsTaked,
                                                                          },
                                                                        }
                                                                      ),
                                                                      _vm
                                                                        .myProfile
                                                                        .workCardXid &&
                                                                      _vm
                                                                        .myProfile
                                                                        .workCardXid
                                                                        .length >
                                                                        0
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn-transparent pl-0 ml-2",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    $event.stopPropagation()
                                                                                    return _vm.deleteImg(
                                                                                      "workCardXid"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getIconLucide(
                                                                                  "Trash2"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      size: 24,
                                                                                      "stroke-width": 1.5,
                                                                                      color:
                                                                                        "#ea4e2c",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "personal-data" },
                                  [
                                    _vm.useField(
                                      "GenericMentionProfessionalAssociation"
                                    ) || _vm.useField("Pseudonym")
                                      ? _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(24, "Miscellanous")
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.useField("Pseudonym")
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1298,
                                                      "Pseudonym"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory("Pseudonym")
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.FormMSG(
                                                  1299,
                                                  "Enter your pseudonym"
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value: _vm.myProfile.pseudonym,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "pseudonym",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.pseudonym",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField(
                                      "GenericMentionProfessionalAssociation"
                                    )
                                      ? _c(
                                          "b-form-group",
                                          [
                                            _c("template", { slot: "label" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1300,
                                                      "Generic mention professional association"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm.isMandatory(
                                                "GenericMentionProfessionalAssociation"
                                              )
                                                ? _c(
                                                    "span",
                                                    { staticClass: "required" },
                                                    [_vm._v(" *")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.FormMSG(
                                                  1301,
                                                  "Enter your generic mention ..."
                                                ),
                                              },
                                              on: {
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.preventDefault()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.myProfile
                                                    .professionalAssociation,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.myProfile,
                                                    "professionalAssociation",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "myProfile.professionalAssociation",
                                              },
                                            }),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm.useField("Retired")
                                      ? _c("div", { staticClass: "divider" })
                                      : _vm._e(),
                                    _vm.useField("Retired")
                                      ? _c(
                                          "div",
                                          { staticClass: "mt-3" },
                                          [
                                            _c("h2", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1705,
                                                    "Retirement"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "b-form-group",
                                              { staticClass: "text-start" },
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1275,
                                                        "Retired ?"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-start",
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      switch: "",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeRetired,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.myProfile.retired,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.myProfile,
                                                          "retired",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "myProfile.retired",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-redired",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.myProfile
                                                              .retired
                                                              ? _vm.FormMSG(
                                                                  1276,
                                                                  "Yes"
                                                                )
                                                              : _vm.FormMSG(
                                                                  1277,
                                                                  "No"
                                                                )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.myProfile.retired &&
                                    _vm.useField("Retired")
                                      ? _c(
                                          "div",
                                          { staticClass: "card-gray" },
                                          [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c("label", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1278,
                                                        "Retirement date"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("v-date-picker", {
                                                  attrs: {
                                                    locale: _vm.lang,
                                                    masks: _vm.masks,
                                                    attributes: _vm.attrs,
                                                  },
                                                  on: {
                                                    dayclick: (payload) =>
                                                      _vm.handleInputCalendar(
                                                        payload,
                                                        "retirementDate"
                                                      ),
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          inputValue,
                                                          togglePopover,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "b-input-group",
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "mask",
                                                                          rawName:
                                                                            "v-mask",
                                                                          value:
                                                                            "##/##/####",
                                                                          expression:
                                                                            "'##/##/####'",
                                                                        },
                                                                      ],
                                                                    class: {
                                                                      "temp-invalid":
                                                                        !_vm.isDateValid(
                                                                          "retirementDate"
                                                                        )[
                                                                          "retirementDate"
                                                                        ],
                                                                    },
                                                                    attrs: {
                                                                      value:
                                                                        inputValue,
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          8960,
                                                                          "DD/MM/YYYY"
                                                                        ),
                                                                      readonly:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return togglePopover()
                                                                        },
                                                                      input: (
                                                                        value
                                                                      ) =>
                                                                        _vm.handleChangeDate(
                                                                          value,
                                                                          "retirementDate"
                                                                        ),
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "b-input-group-append",
                                                                  [
                                                                    _c(
                                                                      "b-input-group-text",
                                                                      {
                                                                        staticClass:
                                                                          "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return togglePopover()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "Calendar"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "rgba(6, 38, 62, .6)",
                                                                                size: 18,
                                                                                "stroke-width": 2.25,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    4279526856
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.myProfile
                                                        .retirementDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "retirementDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.retirementDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      [
                                        _vm.useField("StudyLevel")
                                          ? _c("div", {
                                              staticClass: "divider",
                                            })
                                          : _vm._e(),
                                        _vm.useField("StudyLevel")
                                          ? _c("h2", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(1706, "Study")
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.useField("StudyLevel")
                                          ? _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            262,
                                                            "Study level"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm.isMandatory(
                                                      "StudyLevel"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "required",
                                                          },
                                                          [_vm._v(" *")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("b-form-select", {
                                                  attrs: {
                                                    options:
                                                      _vm.studyLeveOptions,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.studyLevel,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "studyLevel",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.studyLevel",
                                                  },
                                                }),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _vm.useField("Size") ||
                                        _vm.useField("PantSize") ||
                                        _vm.useField("JacketSize") ||
                                        _vm.useField("ShoeSize") ||
                                        _vm.useField("TshirtSize")
                                          ? _c("div", {
                                              staticClass: "divider",
                                            })
                                          : _vm._e(),
                                        _vm.useField("Size") ||
                                        _vm.useField("PantSize") ||
                                        _vm.useField("JacketSize") ||
                                        _vm.useField("ShoeSize") ||
                                        _vm.useField("TshirtSize")
                                          ? _c("h2", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(1707, "Clothes")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.useField("Size")
                                          ? _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            263,
                                                            "Size"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm.isMandatory("Size")
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "required",
                                                          },
                                                          [_vm._v(" *")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      267,
                                                      "Enter your size ..."
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.myProfile.size,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "size",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.size",
                                                  },
                                                }),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _vm.useField("PantSize")
                                          ? _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            264,
                                                            "Pant size"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm.isMandatory("PantSize")
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "required",
                                                          },
                                                          [_vm._v(" *")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      268,
                                                      "Enter your pant size ..."
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.pantSize,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "pantSize",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.pantSize",
                                                  },
                                                }),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _vm.useField("JacketSize")
                                          ? _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            265,
                                                            "Jacket size"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm.isMandatory(
                                                      "JacketSize"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "required",
                                                          },
                                                          [_vm._v(" *")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      269,
                                                      "Enter your jacket size ..."
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.jacketSize,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "jacketSize",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.jacketSize",
                                                  },
                                                }),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _vm.useField("ShoeSize")
                                          ? _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            27,
                                                            "Shoe size"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm.isMandatory("ShoeSize")
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "required",
                                                          },
                                                          [_vm._v(" *")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      28,
                                                      "Enter your shoe size ..."
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.shoeSize,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "shoeSize",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.shoeSize",
                                                  },
                                                }),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _vm.useField("TshirtSize")
                                          ? _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            29,
                                                            "Teeshirt size"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm.isMandatory(
                                                      "TshirtSize"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "required",
                                                          },
                                                          [_vm._v(" *")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: _vm.FormMSG(
                                                      30,
                                                      "Enter your teeshirt size ..."
                                                    ),
                                                  },
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.myProfile.tShirtSize,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.myProfile,
                                                        "tShirtSize",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "myProfile.tShirtSize",
                                                  },
                                                }),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _vm.isEditable
                                          ? _c(
                                              "div",
                                              [
                                                _c("div", {
                                                  staticClass: "divider",
                                                }),
                                                _c("h2", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          1714,
                                                          "Custom fields"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]),
                                                _c(
                                                  "b-row",
                                                  { staticClass: "pb-2" },
                                                  _vm._l(
                                                    _vm.customFields,
                                                    function (
                                                      customField,
                                                      index
                                                    ) {
                                                      return _c(
                                                        "b-col",
                                                        {
                                                          key: index,
                                                          staticClass:
                                                            "d-flex mb-2 w-100",
                                                          attrs: { sm: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "custom-field-template",
                                                            {
                                                              staticClass:
                                                                "w-100",
                                                              attrs: {
                                                                field:
                                                                  customField,
                                                                "name-model":
                                                                  _vm
                                                                    .customFieldModels[
                                                                    index
                                                                  ],
                                                                "name-group":
                                                                  customField.nameGroup,
                                                                index: index,
                                                              },
                                                              on: {
                                                                "custom-field-template:payload":
                                                                  _vm.onFieldTemplateChanged,
                                                              },
                                                              model: {
                                                                value:
                                                                  customField[
                                                                    _vm
                                                                      .customFieldModels[
                                                                      index
                                                                    ]
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      customField,
                                                                      _vm
                                                                        .customFieldModels[
                                                                        index
                                                                      ],
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "customField[customFieldModels[index]]",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "settings"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "settings" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(1198, "Language"))
                                      ),
                                    ]),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            1209,
                                            "Select a communication language"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-select", {
                                          attrs: {
                                            options: _vm.optionsForLanguages,
                                          },
                                          model: {
                                            value: _vm.myProfile.language,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.myProfile,
                                                "language",
                                                $$v
                                              )
                                            },
                                            expression: "myProfile.language",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "divider" }),
                                    _c("h2", { staticClass: "mt-3" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(22, "Change Password")
                                        )
                                      ),
                                    ]),
                                    _c("p", { staticClass: "mt-3" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              107,
                                              "Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter."
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          size: "lg",
                                          variant: "outline-primary",
                                          disabled:
                                            _vm.statusSendCodeValidation.global,
                                          block: "",
                                        },
                                        on: { click: _vm.handleChangePassword },
                                      },
                                      [
                                        !_vm.statusSendCodeValidation
                                          .progress &&
                                        !_vm.statusSendCodeValidation.success
                                          ? _c("span", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      22,
                                                      "Change Password"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.statusSendCodeValidation.progress &&
                                        !_vm.statusSendCodeValidation.success
                                          ? _c(
                                              "span",
                                              [
                                                _c("b-spinner", {
                                                  attrs: {
                                                    label: "Loading...",
                                                    small: "",
                                                  },
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        146,
                                                        "Send code validation"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.statusSendCodeValidation
                                          .progress &&
                                        _vm.statusSendCodeValidation.success
                                          ? _c("span", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      147,
                                                      "Code validation sended"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile === "team-session" &&
                          _vm.isDeterProject === true
                            ? _c("div", [
                                _c("div", { staticClass: "settings" }, [
                                  _c("p", { staticClass: "fw-400" }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            814,
                                            "Here the list of sessions and teams"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "b-table-simple",
                                        {
                                          attrs: {
                                            id: "deterSessionTeamTable",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-thead",
                                            {
                                              attrs: { "head-variant": "dark" },
                                            },
                                            [
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th"),
                                                  _c("b-th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          465,
                                                          "Team A"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _c("b-th", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          466,
                                                          "Team B"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-tbody",
                                            [
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            457,
                                                            "Session 1"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session1A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session1A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session1A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session1B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session1B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session1B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            458,
                                                            "Session 2"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session2A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session2A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session2A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session2B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session2B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session2B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            459,
                                                            "Session 3"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session3A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session3A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session3A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session3B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session3B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session3B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            460,
                                                            "Session 4"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session4A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session4A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session4A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session4B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session4B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session4B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            461,
                                                            "Session 5"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session5A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session5A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session5A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session5B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session5B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session5B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            462,
                                                            "Session 6"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session6A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session6A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session6A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session6B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session6B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session6B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            463,
                                                            "Session 7"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session7A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session7A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session7A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session7B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session7B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session7B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-tr",
                                                [
                                                  _c("b-th", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            464,
                                                            "Session 8"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session8A,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session8A",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session8A",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-td",
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          size: "lg",
                                                          value: true,
                                                          "unchecked-value": false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.myProfile
                                                              .session8B,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.myProfile,
                                                              "session8B",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "myProfile.session8B",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.menuActiveForMobile !== ""
                            ? _c(
                                "b-row",
                                { staticClass: "footer-fixed" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticStyle: {
                                            "font-size": "16px",
                                            "font-weight": "700",
                                          },
                                          attrs: {
                                            variant: "primary",
                                            type: "submit",
                                            block: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  1210,
                                                  "Save Changes"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("loading", {
                    attrs: {
                      active: _vm.isLoading,
                      "is-full-page": true,
                      color: "#00A09C",
                    },
                    on: {
                      "update:active": function ($event) {
                        _vm.isLoading = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          : _c("div", [
              _c(
                "div",
                { staticClass: "animated fadeIn" },
                [
                  _c("b-row", [
                    _c("div", { staticClass: "container-layout" }, [
                      _c(
                        "div",
                        { staticClass: "card py-4" },
                        [
                          !_vm.allMandatoryIsFilled
                            ? _c(
                                "div",
                                { staticClass: "alert alert-warning" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          55055,
                                          "Field with * are mandatory field you must fill. Some field are still empty."
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isDeterProject && _vm.isDeterAction
                            ? _c(
                                "div",
                                { staticClass: "p-2" },
                                [
                                  _c(
                                    "b-alert",
                                    {
                                      attrs: {
                                        variant: "warning",
                                        show: "",
                                        dismissible: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center flex-row",
                                          staticStyle: { gap: "10px" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                _vm.getIconLucide(
                                                  _vm.ICONS.ALERT_TRIANGLE.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color: "#ffc107",
                                                    size: 22,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(1708, "Dear")
                                                ) +
                                                " " +
                                                _vm._s(_vm.userNameActif) +
                                                ", " +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1709,
                                                    "Please add"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(_vm.messageContext)
                                              ),
                                            ]),
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1710,
                                                    "information in your profile. Thank you for using TheGreenShot services."
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("b-form", { on: { submit: _vm.submitForm } }, [
                            _c(
                              "div",
                              { staticClass: "card-collapsible" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickHeader(
                                          "personnal-data"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "title w-90" },
                                      [
                                        _c("b-icon", {
                                          staticStyle: {
                                            color: "rgba(6, 38, 62, 0.84)",
                                          },
                                          attrs: { icon: "person-check-fill" },
                                        }),
                                        _c("span", { staticClass: "ml-3" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(3, "Personal data")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end w-20",
                                      },
                                      [
                                        _vm.personalDataMandatoryCount > 0
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  pill: "",
                                                  variant: "warning",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.personalDataMandatoryCount +
                                                      " " +
                                                      _vm.FormMSG(
                                                        2365,
                                                        "field(s) left"
                                                      )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.configAccordion["personnal-data"]
                                          .isVisible
                                          ? _c(
                                              _vm.getIconLucide("ChevronDown"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#06263E",
                                                  size: 22,
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                        _vm.configAccordion["personnal-data"]
                                          .isVisible
                                          ? _c(_vm.getIconLucide("ChevronUp"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#06263E",
                                                size: 22,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: {
                                      visible:
                                        _vm.configAccordion["personnal-data"]
                                          .isVisible,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-card-collapsible",
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                attrs: {
                                                  sm: "8",
                                                  md: "8",
                                                  lg: "9",
                                                  xl: "9",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField(
                                                      "PersonalNumber"
                                                    )
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "reference",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            this.FormMSG(
                                                                              1,
                                                                              "Reference"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "PersonalNumber"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "reference",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          2,
                                                                          "Enter your personal number."
                                                                        ),
                                                                      disabled:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .reference,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "reference",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.reference",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("Email")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "email",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              42,
                                                                              "Email"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Email"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-input-group",
                                                                  [
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        class: {
                                                                          "is-invalid":
                                                                            _vm.isSubmitted &&
                                                                            _vm
                                                                              .$v
                                                                              .myProfile
                                                                              .email
                                                                              .$error,
                                                                        },
                                                                        staticStyle:
                                                                          {
                                                                            "border-right":
                                                                              "none",
                                                                          },
                                                                        attrs: {
                                                                          value:
                                                                            _vm
                                                                              .myProfile
                                                                              .email,
                                                                          type: "text",
                                                                          id: "email",
                                                                          placeholder:
                                                                            _vm.FormMSG(
                                                                              1102,
                                                                              "Enter your email"
                                                                            ),
                                                                          disabled:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          keydown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "enter",
                                                                                  13,
                                                                                  $event.key,
                                                                                  "Enter"
                                                                                )
                                                                              )
                                                                                return null
                                                                              $event.preventDefault()
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "b-input-group-append",
                                                                      [
                                                                        _vm
                                                                          .myProfile
                                                                          .email &&
                                                                        _vm
                                                                          .myProfile
                                                                          .email
                                                                          .length <=
                                                                          0
                                                                          ? _c(
                                                                              "b-input-group-text",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "border-radius":
                                                                                      "0",
                                                                                    "background-color":
                                                                                      "#d5d6d7",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  _vm.getIconLucide(
                                                                                    "Check"
                                                                                  ),
                                                                                  {
                                                                                    tag: "component",
                                                                                    attrs:
                                                                                      {
                                                                                        color:
                                                                                          "#007A77",
                                                                                        size: 16,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "b-input-group-text",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-pointer",
                                                                            staticStyle:
                                                                              {
                                                                                "background-color":
                                                                                  "#00a09c",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.showChangeMailModal = true
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getIconLucide(
                                                                                "Pencil"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "#FFFFFF",
                                                                                    size: 16,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm.isSubmitted &&
                                                                    !_vm.$v
                                                                      .myProfile
                                                                      .email
                                                                      .emailValid
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "invalid-feedback",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  _vm.FormMSG(
                                                                                    252,
                                                                                    "Please, enter valid email"
                                                                                  )
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("Phone")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "phone",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              44,
                                                                              "Phone"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Phone"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-input-group",
                                                                  [
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "border-right":
                                                                              "none",
                                                                          },
                                                                        attrs: {
                                                                          value:
                                                                            _vm
                                                                              .myProfile
                                                                              .phone,
                                                                          type: "text",
                                                                          id: "phone",
                                                                          placeholder:
                                                                            _vm.FormMSG(
                                                                              45,
                                                                              "Enter your phone number"
                                                                            ),
                                                                          disabled:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          keydown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "enter",
                                                                                  13,
                                                                                  $event.key,
                                                                                  "Enter"
                                                                                )
                                                                              )
                                                                                return null
                                                                              $event.preventDefault()
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "b-input-group-append",
                                                                      [
                                                                        _c(
                                                                          "b-input-group-text",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "border-radius":
                                                                                  "0",
                                                                                "background-color":
                                                                                  "#d5d6d7",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getIconLucide(
                                                                                "Check"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "#007A77",
                                                                                    size: 16,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "b-input-group-text",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-pointer",
                                                                            staticStyle:
                                                                              {
                                                                                "background-color":
                                                                                  "#00a09c",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.showChangePhoneModal = true
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getIconLucide(
                                                                                "Pencil"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "#FFFFFF",
                                                                                    size: 16,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField("FirstName")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "firstname",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              41,
                                                                              "First Name"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "FirstName"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "is-invalid":
                                                                        _vm.isMandatory(
                                                                          "FirstName"
                                                                        ) &&
                                                                        _vm
                                                                          .myProfile
                                                                          .firstName
                                                                          .length <=
                                                                          1,
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "firstname",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          1101,
                                                                          "Enter your first name."
                                                                        ),
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .firstName,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "firstName",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.firstName",
                                                                    },
                                                                  }
                                                                ),
                                                                _vm.isMandatory(
                                                                  "FirstName"
                                                                ) &&
                                                                _vm.myProfile
                                                                  .firstName
                                                                  .length <= 1
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "invalid-feedback",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                251,
                                                                                "Please, length should be at least 2"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("LastName")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "name",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              40,
                                                                              "Last Name"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "LastName"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                      "is-invalid":
                                                                        _vm.isMandatory(
                                                                          "LastName"
                                                                        ) &&
                                                                        _vm
                                                                          .myProfile
                                                                          .name
                                                                          .length <=
                                                                          1,
                                                                    },
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "name",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          1100,
                                                                          "Enter your name."
                                                                        ),
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .name,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "name",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.name",
                                                                    },
                                                                  }
                                                                ),
                                                                _vm.isMandatory(
                                                                  "LastName"
                                                                ) &&
                                                                _vm.myProfile
                                                                  .name
                                                                  .length <= 1
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "invalid-feedback",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                251,
                                                                                "Please, length should be at least 2"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField(
                                                      "MaidenName"
                                                    ) && !_vm.hideMaidenName
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "maidenName",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              1239,
                                                                              " Maiden name (if different)"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "MaidenName"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "maidenName",
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .maidenName,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "maidenName",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.maidenName",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("Color") &&
                                                    !_vm.hidePlanningColor
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-center",
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              525,
                                                                              "Color"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Color"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "w-100 d-flex justify-content-start",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "verte",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm.disableAll,
                                                                          picker:
                                                                            "square",
                                                                          model:
                                                                            "hex",
                                                                          menuPosition:
                                                                            "top",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .myProfile
                                                                              .color,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.myProfile,
                                                                                "color",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "myProfile.color",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField("Nationality")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "nationality",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              46,
                                                                              "Nationality"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Nationality"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "nationality",
                                                                      placeholder:
                                                                        this.FormMSG(
                                                                          47,
                                                                          "Enter your nationality"
                                                                        ),
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .nationality,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "nationality",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.nationality",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("Language")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "optionsForLanguages",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              80,
                                                                              "Langue"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Language"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-select",
                                                                  {
                                                                    attrs: {
                                                                      options:
                                                                        _vm.optionsForLanguages,
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                      id: "optionsForLanguages",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .language,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "language",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.language",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("Gender")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              164,
                                                                              "Gender"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Gender"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c("v-select", {
                                                                  attrs: {
                                                                    options:
                                                                      _vm.menuGenders,
                                                                    label:
                                                                      "text",
                                                                    reduce: (
                                                                      option
                                                                    ) =>
                                                                      option.value,
                                                                    placeholder:
                                                                      _vm.FormMSG(
                                                                        700,
                                                                        "Select a type"
                                                                      ),
                                                                    clearable: false,
                                                                    disabled:
                                                                      _vm.disableAll,
                                                                    selectable:
                                                                      _vm.selectableTypeItem,
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "option",
                                                                          fn: function (
                                                                            option
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  class: `${
                                                                                    (option.disabled &&
                                                                                      option.disabled ===
                                                                                        true) ||
                                                                                    (option.selectable &&
                                                                                      option.selectable ===
                                                                                        true)
                                                                                      ? "text-bold"
                                                                                      : "text-color ml-3"
                                                                                  }`,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                      _vm._s(
                                                                                        option.text
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      2190658229
                                                                    ),
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .myProfile
                                                                        .gender,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.myProfile,
                                                                          "gender",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "myProfile.gender",
                                                                  },
                                                                }),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("Title")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              1240,
                                                                              "Title"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Title"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-select",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .title,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "title",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.title",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.menuTitle,
                                                                    function (
                                                                      title,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "b-form-select-option",
                                                                        {
                                                                          key: index,
                                                                          attrs:
                                                                            {
                                                                              value:
                                                                                title.value,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                title.text
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField("Birthdate")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "birthDate",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              48,
                                                                              "Birthdate"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Birthdate"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-date-picker",
                                                                  {
                                                                    attrs: {
                                                                      locale:
                                                                        _vm.lang,
                                                                      masks:
                                                                        _vm.masks,
                                                                      attributes:
                                                                        _vm.attrs,
                                                                    },
                                                                    on: {
                                                                      dayclick:
                                                                        (
                                                                          payload
                                                                        ) =>
                                                                          _vm.handleInputCalendar(
                                                                            payload,
                                                                            "birthDate"
                                                                          ),
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "default",
                                                                            fn: function ({
                                                                              inputValue,
                                                                              togglePopover,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "b-input-group",
                                                                                  [
                                                                                    _c(
                                                                                      "b-form-input",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "mask",
                                                                                              rawName:
                                                                                                "v-mask",
                                                                                              value:
                                                                                                "##/##/####",
                                                                                              expression:
                                                                                                "'##/##/####'",
                                                                                            },
                                                                                          ],
                                                                                        class:
                                                                                          {
                                                                                            "temp-invalid":
                                                                                              _vm.isMandatory(
                                                                                                "Birthdate"
                                                                                              ) &&
                                                                                              !_vm.isDateValid(
                                                                                                "birthDate"
                                                                                              )[
                                                                                                "birthDate"
                                                                                              ],
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            value:
                                                                                              inputValue,
                                                                                            placeholder:
                                                                                              _vm.FormMSG(
                                                                                                8960,
                                                                                                "DD/MM/YYYY"
                                                                                              ),
                                                                                            readonly:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return togglePopover()
                                                                                            },
                                                                                          input:
                                                                                            (
                                                                                              value
                                                                                            ) =>
                                                                                              _vm.handleChangeDate(
                                                                                                value,
                                                                                                "birthDate"
                                                                                              ),
                                                                                          keydown:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              if (
                                                                                                !$event.type.indexOf(
                                                                                                  "key"
                                                                                                ) &&
                                                                                                _vm._k(
                                                                                                  $event.keyCode,
                                                                                                  "enter",
                                                                                                  13,
                                                                                                  $event.key,
                                                                                                  "Enter"
                                                                                                )
                                                                                              )
                                                                                                return null
                                                                                              $event.preventDefault()
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "b-input-group-append",
                                                                                      [
                                                                                        _c(
                                                                                          "b-input-group-text",
                                                                                          {
                                                                                            staticClass:
                                                                                              "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                                            class:
                                                                                              {
                                                                                                "temp-invalid":
                                                                                                  _vm.isMandatory(
                                                                                                    "Birthdate"
                                                                                                  ) &&
                                                                                                  !_vm.isDateValid(
                                                                                                    "birthDate"
                                                                                                  )[
                                                                                                    "birthDate"
                                                                                                  ],
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return togglePopover()
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              _vm.getLucideIcon(
                                                                                                "Calendar"
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "rgba(6, 38, 62, .6)",
                                                                                                    size: 18,
                                                                                                    "stroke-width": 2.25,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        3228944235
                                                                      ),
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .birthDate,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "birthDate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.birthDate",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("PlaceOfBirth")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "placeOfBirth",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              159,
                                                                              "Place of birth"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "PlaceOfBirth"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      id: "placeOfBirth",
                                                                      type: "text",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          161,
                                                                          "Place of birthstartCapture"
                                                                        ),
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .placeOfBirth,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "placeOfBirth",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.placeOfBirth",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField(
                                                      "CityOfBirthZip"
                                                    ) &&
                                                    !_vm.hidePlaceOfBirthZip
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "placeOfBirthZip",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              160,
                                                                              "Place of birth ZIP"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "CityOfBirthZip"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      id: "placeOfBirthZip",
                                                                      type: "text",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          162,
                                                                          "Place of birth zip"
                                                                        ),
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .placeOfBirthZip,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "placeOfBirthZip",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.placeOfBirthZip",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField(
                                                      "CountryOfBirth"
                                                    )
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              1241,
                                                                              "Country of birth"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "CountryOfBirth"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          1315,
                                                                          "Enter your country of ..."
                                                                        ),
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .birthCountry,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "birthCountry",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.birthCountry",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.useField("ProfilePicture")
                                              ? _c(
                                                  "b-col",
                                                  {
                                                    staticClass: "text-center",
                                                    attrs: {
                                                      sm: "4",
                                                      md: "4",
                                                      lg: "3",
                                                      xl: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "profile-picture-block",
                                                        staticStyle: {
                                                          display: "flex",
                                                          "flex-direction":
                                                            "column",
                                                          "align-items":
                                                            "center",
                                                          "justify-content":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            model: {
                                                              value:
                                                                _vm.myProfile
                                                                  .picture,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.myProfile,
                                                                    "picture",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "myProfile.picture",
                                                            },
                                                          },
                                                          [
                                                            _vm.myProfile
                                                              .picture &&
                                                            _vm.myProfile
                                                              .picture.length >
                                                              0
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        position:
                                                                          "relative",
                                                                        height:
                                                                          "100%",
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-img",
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer",
                                                                          staticStyle:
                                                                            {
                                                                              display:
                                                                                "flex",
                                                                              "justify-content":
                                                                                "center",
                                                                              "align-items":
                                                                                "center",
                                                                              width:
                                                                                "175px",
                                                                              height:
                                                                                "175px",
                                                                              "border-radius":
                                                                                "50%",
                                                                              border:
                                                                                "1px solid #cad0d7",
                                                                              "background-color":
                                                                                "#f5f7f9",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              rounded:
                                                                                "circle",
                                                                              alt: "My picture",
                                                                              src: _vm.getImage(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .picture,
                                                                                "picture"
                                                                              ),
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  254,
                                                                                  "View Image"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .picture
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "b-img",
                                                                        _vm.imgPropPicture,
                                                                        false
                                                                      )
                                                                    ),
                                                                    _vm
                                                                      .myProfile
                                                                      .picture &&
                                                                    _vm
                                                                      .myProfile
                                                                      .picture
                                                                      .length >
                                                                      0
                                                                      ? _c(
                                                                          _vm.getIconLucide(
                                                                            "XCircle"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            staticClass:
                                                                              "cursor-pointer icon-remove-img-profile",
                                                                            staticStyle:
                                                                              {
                                                                                bottom:
                                                                                  "85%",
                                                                                right:
                                                                                  "10%",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                size: 32,
                                                                                "stroke-width": 1.75,
                                                                                color:
                                                                                  "#ea4e2c",
                                                                                id: "tooltip-remove-picture",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteImg(
                                                                                    "picture"
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm
                                                                      .myProfile
                                                                      .picture &&
                                                                    _vm
                                                                      .myProfile
                                                                      .picture
                                                                      .length >
                                                                      0
                                                                      ? _c(
                                                                          "b-tooltip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                target:
                                                                                  "tooltip-remove-picture",
                                                                                triggers:
                                                                                  "hover",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  _vm.FormMSG(
                                                                                    255,
                                                                                    "Remove picture"
                                                                                  )
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "avatar",
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "flex",
                                                                        "justify-content":
                                                                          "center",
                                                                        "align-items":
                                                                          "center",
                                                                        width:
                                                                          "175px",
                                                                        height:
                                                                          "175px",
                                                                        "border-radius":
                                                                          "50%",
                                                                        border:
                                                                          "1px solid #cad0d7",
                                                                        "background-color":
                                                                          "#f5f7f9",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getIconLucide(
                                                                        "User"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        staticClass:
                                                                          "vertical-center",
                                                                        attrs: {
                                                                          fill: "#E2E6EB",
                                                                          color:
                                                                            "#E2E6EB",
                                                                          size: 120,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            !_vm.$isPwa() &&
                                                            !_vm.hideActionChangePicture
                                                              ? _c(
                                                                  "b-button",
                                                                  {
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-secondary",
                                                                      size: "md",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.startCapture(
                                                                            "picture"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "icon-camera",
                                                                    }),
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            43,
                                                                            "Change your picture"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm.isMandatory(
                                                              "ProfilePicture"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "required",
                                                                  },
                                                                  [_vm._v(" *")]
                                                                )
                                                              : _vm._e(),
                                                            _vm.$isPwa()
                                                              ? _c(
                                                                  "MobileProfilePicCaptureBtn",
                                                                  {
                                                                    attrs: {
                                                                      "crop-type":
                                                                        "avatar",
                                                                      "label-button":
                                                                        _vm.FormMSG(
                                                                          43,
                                                                          "Change your picture"
                                                                        ),
                                                                      "parent-type":
                                                                        "user",
                                                                      parentId:
                                                                        _vm
                                                                          .myProfile
                                                                          .id,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.startCapture(
                                                                            "picture"
                                                                          )
                                                                        },
                                                                      change:
                                                                        _vm.handleImgsTaked,
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.isFilmSingle
                                                      ? _c("ShareInformation", {
                                                          attrs: {
                                                            "btn-label":
                                                              _vm.FormMSG(
                                                                169,
                                                                "Share my Information"
                                                              ),
                                                            "title-modal":
                                                              _vm.FormMSG(
                                                                170,
                                                                "Send your personal informations sheet"
                                                              ),
                                                            "msg-success":
                                                              _vm.FormMSG(
                                                                250,
                                                                "Profile successfully sent"
                                                              ),
                                                            "send-a-copy": "",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm.useField("NationalRegistrationNumber") ||
                            _vm.useField("CompanyNumber") ||
                            _vm.useField("CongesSpectacleId") ||
                            _vm.useField("SignaturePicture") ||
                            _vm.useField("IdName") ||
                            _vm.useField("IdNumber") ||
                            _vm.useField("IdPictureFront") ||
                            _vm.useField("IdPictureBack") ||
                            _vm.useField("DriverLicense") ||
                            _vm.useField("DriverLicenseNumber") ||
                            _vm.useField("DriverLicensePictureFront") ||
                            _vm.useField("DriverLicensePictureBack")
                              ? _c(
                                  "div",
                                  { staticClass: "card-collapsible mt-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickHeader(
                                              "identification"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "title w-90" },
                                          [
                                            _c(
                                              _vm.getLucideIcon(
                                                "ClipboardList"
                                              ),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color:
                                                    "rgba(6, 38, 62, 0.84)",
                                                  size: 22,
                                                },
                                              }
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      4,
                                                      "Identification"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end w-20",
                                          },
                                          [
                                            _vm.identificationMandatoryCount > 0
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      pill: "",
                                                      variant: "warning",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.identificationMandatoryCount +
                                                          " " +
                                                          _vm.FormMSG(
                                                            2365,
                                                            "field(s) left"
                                                          )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.configAccordion[
                                              "identification"
                                            ].isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronDown"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.configAccordion[
                                              "identification"
                                            ].isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronUp"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          visible:
                                            _vm.configAccordion[
                                              "identification"
                                            ].isVisible,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "sub-title" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    5,
                                                    "Identification Numbers"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-card-collapsible",
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField(
                                                  "NationalRegistrationNumber"
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        55,
                                                                        "National Registration Number"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "NationalRegistrationNumber"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("InputMask", {
                                                              staticClass:
                                                                "form-control",
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                mask: "99.99.99-999-99",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    56,
                                                                    "Enter your National Registration Number "
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .nationalRegistryNumber,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "nationalRegistryNumber",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.nationalRegistryNumber",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField("CompanyNumber") &&
                                                !_vm.hideInfoIdentification
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "companyNumber",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          57,
                                                                          "Company Number"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "CompanyNumber"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "companyNumber",
                                                                disabled:
                                                                  _vm.disableAll,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .companyNumber,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "companyNumber",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.companyNumber",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "CongesSpectacleId"
                                                ) && !_vm.hideInfoIdentification
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "conge_spectacles_id",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          1242,
                                                                          '"Congés spectacles" ID'
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "CongesSpectacleId"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "conge_spectacles_id",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    1243,
                                                                    "Enter your conges spectacles id"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .congesSpectacle,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "congesSpectacle",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.congesSpectacle",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "SignaturePicture"
                                                ) && !_vm.hideInfoIdentification
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "signatureId",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          223,
                                                                          "My signature"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "SignaturePicture"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              {
                                                                staticClass:
                                                                  "ml-0",
                                                              },
                                                              [
                                                                _vm.checkFile(
                                                                  _vm.myProfile
                                                                    .signatureId
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.onOpenUrl(
                                                                                _vm.getImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .signatureId,
                                                                                  "signatureId"
                                                                                )
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "file-earmark-pdf-fill",
                                                                                "font-scale":
                                                                                  "2",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "b-img",
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer",
                                                                          attrs:
                                                                            {
                                                                              rounded:
                                                                                "circle",
                                                                              alt: "My signature",
                                                                              src: _vm.getImage(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .signatureId,
                                                                                "signatureId"
                                                                              ),
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  254,
                                                                                  "View Image"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .signatureId
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "b-img",
                                                                        _vm.imgPropSignatureId,
                                                                        false
                                                                      )
                                                                    ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-secondary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.startCapture(
                                                                            "signatureId"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Camera"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pl-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-bottom":
                                                                                  "-1px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  220,
                                                                                  "Picture"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.myProfile
                                                                  .signatureId &&
                                                                _vm.myProfile
                                                                  .signatureId
                                                                  .length > 0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn-transparent pl-0 ml-2",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              return _vm.deleteImg(
                                                                                "signatureId"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Trash2"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 24,
                                                                                "stroke-width": 1.5,
                                                                                color:
                                                                                  "#ea4e2c",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField("IdName") &&
                                                !_vm.hideInfoIdentification
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "idName",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          216,
                                                                          "ID Name"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "IdName"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "idName",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    217,
                                                                    "Enter your ID Name ..."
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .idName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "idName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.idName",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField("IdNumber") &&
                                                !_vm.hideInfoIdentification
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "idNumber",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          7,
                                                                          "ID Number"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "IdNumber"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "idNumber",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    8,
                                                                    "Enter your ID Number ..."
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .idNumber,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "idNumber",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.idNumber",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "IdPictureFront"
                                                ) && !_vm.hideInfoIdentification
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "pictureId",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          218,
                                                                          "ID picture - Front"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "IdPictureFront"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              {
                                                                staticClass:
                                                                  "ml-0",
                                                              },
                                                              [
                                                                _vm.checkFile(
                                                                  _vm.myProfile
                                                                    .pictureId
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.onOpenUrl(
                                                                                _vm.getImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .pictureId,
                                                                                  "pictureId"
                                                                                )
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "file-earmark-pdf-fill",
                                                                                "font-scale":
                                                                                  "2",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "b-img",
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer",
                                                                          attrs:
                                                                            {
                                                                              rounded:
                                                                                "circle",
                                                                              alt: "ID picture - front",
                                                                              src: _vm.getImage(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .pictureId,
                                                                                "pictureId"
                                                                              ),
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  254,
                                                                                  "View Image"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .pictureId
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "b-img",
                                                                        _vm.imgPropPictureId,
                                                                        false
                                                                      )
                                                                    ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-secondary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.startCapture(
                                                                            "pictureId"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Camera"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pl-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-bottom":
                                                                                  "-1px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  220,
                                                                                  "Picture"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.myProfile
                                                                  .pictureId &&
                                                                _vm.myProfile
                                                                  .pictureId
                                                                  .length > 0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn-transparent pl-0 ml-2",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              return _vm.deleteImg(
                                                                                "pictureId"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Trash2"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 24,
                                                                                "stroke-width": 1.5,
                                                                                color:
                                                                                  "#ea4e2c",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField("IdPictureBack") &&
                                                !_vm.hideInfoIdentification
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "pictureIdVerso",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          219,
                                                                          "ID picture - Back"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "IdPictureBack"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              {
                                                                staticClass:
                                                                  "ml-0",
                                                              },
                                                              [
                                                                _vm.checkFile(
                                                                  _vm.myProfile
                                                                    .pictureIdVerso
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.onOpenUrl(
                                                                                _vm.getImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .pictureIdVerso,
                                                                                  "pictureIdVerso"
                                                                                )
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "file-earmark-pdf-fill",
                                                                                "font-scale":
                                                                                  "2",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "b-img",
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer",
                                                                          attrs:
                                                                            {
                                                                              rounded:
                                                                                "circle",
                                                                              alt: "ID picture - back",
                                                                              src: _vm.getImage(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .pictureIdVerso,
                                                                                "pictureIdVerso"
                                                                              ),
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  254,
                                                                                  "View Image"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .pictureIdVerso
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "b-img",
                                                                        _vm.imgPropPictureIdVerso,
                                                                        false
                                                                      )
                                                                    ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-secondary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.startCapture(
                                                                            "pictureIdVerso"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Camera"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pl-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-bottom":
                                                                                  "-1px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  220,
                                                                                  "Picture"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.myProfile
                                                                  .pictureIdVerso &&
                                                                _vm.myProfile
                                                                  .pictureIdVerso
                                                                  .length > 0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn-transparent pl-0 ml-2",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              return _vm.deleteImg(
                                                                                "pictureIdVerso"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Trash2"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 24,
                                                                                "stroke-width": 1.5,
                                                                                color:
                                                                                  "#ea4e2c",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField("DriverLicense") &&
                                                !_vm.hideInfoIdentification
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        166,
                                                                        "Driver licence"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "DriverLicense"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    167,
                                                                    "Enter your driver license"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .driverLicense,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "driverLicense",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.driverLicense",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "DriverLicenseNumber"
                                                ) && !_vm.hideInfoIdentification
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1314,
                                                                        "Driver licence Number"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "DriverLicenseNumber"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    1316,
                                                                    "Enter your driver license number"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .driverLicenseNumber,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "driverLicenseNumber",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.driverLicenseNumber",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "DriverLicensePictureFront"
                                                ) && !_vm.hideInfoIdentification
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "pictureDriverLicense",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          221,
                                                                          "Driver license picture - Front"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "DriverLicensePictureFront"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              {
                                                                staticClass:
                                                                  "ml-0",
                                                              },
                                                              [
                                                                _vm.checkFile(
                                                                  _vm.myProfile
                                                                    .pictureDriverLicense
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.onOpenUrl(
                                                                                _vm.getImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .pictureDriverLicense,
                                                                                  "pictureDriverLicense"
                                                                                )
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "file-earmark-pdf-fill",
                                                                                "font-scale":
                                                                                  "2",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "b-img",
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer",
                                                                          attrs:
                                                                            {
                                                                              rounded:
                                                                                "",
                                                                              alt: "Driver license picture - Front",
                                                                              src: _vm.getImage(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .pictureDriverLicense,
                                                                                "pictureDriverLicense"
                                                                              ),
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  254,
                                                                                  "View Image"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .pictureDriverLicense
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "b-img",
                                                                        _vm.imgPropPictureDriverLicense,
                                                                        false
                                                                      )
                                                                    ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-secondary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.startCapture(
                                                                            "pictureDriverLicense"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Camera"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pl-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-bottom":
                                                                                  "-1px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  220,
                                                                                  "Picture"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.myProfile
                                                                  .pictureDriverLicense &&
                                                                _vm.myProfile
                                                                  .pictureDriverLicense
                                                                  .length > 0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn-transparent pl-0 ml-2",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              return _vm.deleteImg(
                                                                                "pictureDriverLicense"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Trash2"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 24,
                                                                                "stroke-width": 1.5,
                                                                                color:
                                                                                  "#ea4e2c",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "DriverLicensePictureBack"
                                                ) && !_vm.hideInfoIdentification
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "pictureDriverLicenseVerso",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          222,
                                                                          "Driver license picture - Back"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "DriverLicensePictureBack"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              {
                                                                staticClass:
                                                                  "ml-0",
                                                              },
                                                              [
                                                                _vm.checkFile(
                                                                  _vm.myProfile
                                                                    .pictureDriverLicenseVerso
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.onOpenUrl(
                                                                                _vm.getImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .pictureDriverLicenseVerso,
                                                                                  "pictureDriverLicenseVerso"
                                                                                )
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "file-earmark-pdf-fill",
                                                                                "font-scale":
                                                                                  "2",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "b-img",
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer",
                                                                          attrs:
                                                                            {
                                                                              rounded:
                                                                                "",
                                                                              alt: "Driver license picture - Back",
                                                                              src: _vm.getImage(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .pictureDriverLicenseVerso,
                                                                                "pictureDriverLicenseVerso"
                                                                              ),
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  254,
                                                                                  "View Image"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .pictureDriverLicenseVerso
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "b-img",
                                                                        _vm.imgPropPictureDriverLicenseVerso,
                                                                        false
                                                                      )
                                                                    ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-secondary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.startCapture(
                                                                            "pictureDriverLicenseVerso"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Camera"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pl-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-bottom":
                                                                                  "-1px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  220,
                                                                                  "Picture"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.myProfile
                                                                  .pictureDriverLicenseVerso &&
                                                                _vm.myProfile
                                                                  .pictureDriverLicenseVerso
                                                                  .length > 0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn-transparent pl-0 ml-2",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              return _vm.deleteImg(
                                                                                "pictureDriverLicenseVerso"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Trash2"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 24,
                                                                                "stroke-width": 1.5,
                                                                                color:
                                                                                  "#ea4e2c",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.financeDisplaySection
                              ? _c(
                                  "div",
                                  { staticClass: "card-collapsible mt-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickHeader(
                                              "finance"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "title w-90" },
                                          [
                                            _c(_vm.getLucideIcon("Wallet"), {
                                              tag: "component",
                                              attrs: {
                                                color: "rgba(6, 38, 62, 0.84)",
                                                size: 22,
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(1244, "Finance")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end w-20",
                                          },
                                          [
                                            _vm.financeMandatoryCount > 0
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      pill: "",
                                                      variant: "warning",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.financeMandatoryCount +
                                                          " " +
                                                          _vm.FormMSG(
                                                            2365,
                                                            "field(s) left"
                                                          )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.configAccordion["finance"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronDown"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.configAccordion["finance"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronUp"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          visible:
                                            _vm.configAccordion["finance"]
                                              .isVisible,
                                        },
                                      },
                                      [
                                        _vm.financeDisplayBankSection
                                          ? _c(
                                              "div",
                                              { staticClass: "sub-title" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      9,
                                                      "Bank information"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-card-collapsible",
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField("Iban")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: _vm.hideBankAccountOwnerName
                                                            ? 4
                                                            : 6,
                                                          xl: _vm.hideBankAccountOwnerName
                                                            ? 4
                                                            : 6,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            class: {
                                                              "is-warning":
                                                                _vm
                                                                  .userBankWarning
                                                                  .iban,
                                                            },
                                                            attrs: {
                                                              "label-for":
                                                                "iban",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        49,
                                                                        "IBAN"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "Iban"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                id: "iban",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    50,
                                                                    "Enter your bank account number"
                                                                  ),
                                                                disabled:
                                                                  _vm.disableAll,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleSupplierIbanChange(
                                                                      $event,
                                                                      "iban"
                                                                    )
                                                                  },
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .iban,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "iban",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.iban",
                                                              },
                                                            }),
                                                            _vm.userBankWarning
                                                              .iban
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "warning-feedback",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            97986,
                                                                            "IBAN seems not valid, please make sure it's corrrect"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField("Bic")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: _vm.hideBankAccountOwnerName
                                                            ? 4
                                                            : 6,
                                                          xl: _vm.hideBankAccountOwnerName
                                                            ? 4
                                                            : 6,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            class: {
                                                              "is-warning":
                                                                _vm
                                                                  .userBankWarning
                                                                  .bic,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "bic",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          51,
                                                                          "BIC"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "Bic"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                id: "bic",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    52,
                                                                    "Enter your bank BIC"
                                                                  ),
                                                                disabled:
                                                                  _vm.disableAll,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleSupplierBicChange(
                                                                      $event,
                                                                      "bic"
                                                                    )
                                                                  },
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .bic,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "bic",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.bic",
                                                              },
                                                            }),
                                                            _vm.userBankWarning
                                                              .bic
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "warning-feedback",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            909097986,
                                                                            "BIC seems not valid, please make sure it's corrrect"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "BankDetailDocument"
                                                ) &&
                                                _vm.hideBankAccountOwnerName
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "bankDetailDocument",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      1247,
                                                                      "Bank detail document"
                                                                    ) +
                                                                      `${
                                                                        _vm.isMandatory(
                                                                          "BankDetailDocument"
                                                                        )
                                                                          ? " *"
                                                                          : ""
                                                                      }`
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              {
                                                                staticClass:
                                                                  "ml-0",
                                                              },
                                                              [
                                                                _vm.checkFile(
                                                                  _vm.myProfile
                                                                    .bankDetailXid
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.onOpenUrl(
                                                                                _vm.getImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .bankDetailXid,
                                                                                  "bankDetailXid"
                                                                                )
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "file-earmark-pdf-fill",
                                                                                "font-scale":
                                                                                  "2",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "b-img",
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer",
                                                                          attrs:
                                                                            {
                                                                              rounded:
                                                                                "",
                                                                              alt: "Bank detail document",
                                                                              src: _vm.getImage(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .bankDetailXid,
                                                                                "bankDetailXid"
                                                                              ),
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  254,
                                                                                  "View Image"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .bankDetailXid
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .myProfile
                                                                                  .bankDetailXid,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.myProfile,
                                                                                    "bankDetailXid",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "myProfile.bankDetailXid",
                                                                            },
                                                                        },
                                                                        "b-img",
                                                                        _vm.imgPropSignatureId,
                                                                        false
                                                                      )
                                                                    ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-secondary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.startCapture(
                                                                            "bankDetailXid"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Camera"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pl-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-bottom":
                                                                                  "-1px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  220,
                                                                                  "Picture"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.myProfile
                                                                  .bankDetailXid &&
                                                                _vm.myProfile
                                                                  .bankDetailXid
                                                                  .length > 0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn-transparent pl-0 ml-2",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              return _vm.deleteImg(
                                                                                "bankDetailXid"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Trash2"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 24,
                                                                                "stroke-width": 1.5,
                                                                                color:
                                                                                  "#ea4e2c",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                                _vm.isDeterAction &&
                                                                _vm.myProfile
                                                                  .bankDetailXid
                                                                  .length === 0
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "b-tooltip",
                                                                                  rawName:
                                                                                    "v-b-tooltip.hover.bottom.html",
                                                                                  value:
                                                                                    _vm.FormMSG(
                                                                                      1750,
                                                                                      "Bank detail document is not downloaded"
                                                                                    ),
                                                                                  expression:
                                                                                    "FormMSG(1750, 'Bank detail document is not downloaded')",
                                                                                  modifiers:
                                                                                    {
                                                                                      hover: true,
                                                                                      bottom: true,
                                                                                      html: true,
                                                                                    },
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "btn bg-transparent border-0",
                                                                            attrs:
                                                                              {
                                                                                size: "sm",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getIconLucide(
                                                                                _vm
                                                                                  .ICONS
                                                                                  .ALERT_TRIANGLE
                                                                                  .name
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "#ffc107",
                                                                                    size: 30,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            !_vm.hideBankAccountOwnerName
                                              ? _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField(
                                                      "BankAccountOwnerName"
                                                    )
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "6",
                                                              xl: "6",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "bic",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            this.FormMSG(
                                                                              1245,
                                                                              "Bank account owner name"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "BankAccountOwnerName"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "bic",
                                                                      placeholder:
                                                                        this.FormMSG(
                                                                          1246,
                                                                          "Enter your bank account owner name"
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .bankAccountOwnerName,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "bankAccountOwnerName",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.bankAccountOwnerName",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField(
                                                      "BankDetailDocument"
                                                    )
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "6",
                                                              xl: "6",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "bankDetailDocument",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              1247,
                                                                              "Bank detail document"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "BankDetailDocument"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    staticClass:
                                                                      "ml-0",
                                                                  },
                                                                  [
                                                                    _vm.checkFile(
                                                                      _vm
                                                                        .myProfile
                                                                        .bankDetailXid
                                                                    )
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex justify-content-center align-items-center cursor-pointer",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.onOpenUrl(
                                                                                    _vm.getImage(
                                                                                      _vm
                                                                                        .myProfile
                                                                                        .bankDetailXid,
                                                                                      "bankDetailXid"
                                                                                    )
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    icon: "file-earmark-pdf-fill",
                                                                                    "font-scale":
                                                                                      "2",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _c(
                                                                          "b-img",
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "cursor-pointer",
                                                                              attrs:
                                                                                {
                                                                                  rounded:
                                                                                    "",
                                                                                  alt: "Bank detail document",
                                                                                  src: _vm.getImage(
                                                                                    _vm
                                                                                      .myProfile
                                                                                      .bankDetailXid,
                                                                                    "bankDetailXid"
                                                                                  ),
                                                                                  title:
                                                                                    _vm.FormMSG(
                                                                                      254,
                                                                                      "View Image"
                                                                                    ),
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.showImage(
                                                                                      _vm
                                                                                        .myProfile
                                                                                        .bankDetailXid
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .myProfile
                                                                                      .bankDetailXid,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.myProfile,
                                                                                        "bankDetailXid",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "myProfile.bankDetailXid",
                                                                                },
                                                                            },
                                                                            "b-img",
                                                                            _vm.imgPropSignatureId,
                                                                            false
                                                                          )
                                                                        ),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        attrs: {
                                                                          variant:
                                                                            "outline-secondary",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.startCapture(
                                                                                "bankDetailXid"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex justify-content-center align-items-center",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getIconLucide(
                                                                                "Camera"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    size: 20,
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "pl-2",
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-bottom":
                                                                                      "-1px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      220,
                                                                                      "Picture"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm
                                                                      .myProfile
                                                                      .bankDetailXid &&
                                                                    _vm
                                                                      .myProfile
                                                                      .bankDetailXid
                                                                      .length >
                                                                      0
                                                                      ? _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "btn-transparent pl-0 ml-2",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.preventDefault()
                                                                                  $event.stopPropagation()
                                                                                  return _vm.deleteImg(
                                                                                    "bankDetailXid"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getIconLucide(
                                                                                "Trash2"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    size: 24,
                                                                                    "stroke-width": 1.5,
                                                                                    color:
                                                                                      "#ea4e2c",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                    _vm.isDeterAction &&
                                                                    _vm
                                                                      .myProfile
                                                                      .bankDetailXid
                                                                      .length ===
                                                                      0
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex align-items-center",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-button",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "b-tooltip",
                                                                                      rawName:
                                                                                        "v-b-tooltip.hover.bottom.html",
                                                                                      value:
                                                                                        _vm.FormMSG(
                                                                                          1750,
                                                                                          "Bank detail document is not downloaded"
                                                                                        ),
                                                                                      expression:
                                                                                        "FormMSG(1750, 'Bank detail document is not downloaded')",
                                                                                      modifiers:
                                                                                        {
                                                                                          hover: true,
                                                                                          bottom: true,
                                                                                          html: true,
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "btn bg-transparent border-0",
                                                                                attrs:
                                                                                  {
                                                                                    size: "sm",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  _vm.getIconLucide(
                                                                                    _vm
                                                                                      .ICONS
                                                                                      .ALERT_TRIANGLE
                                                                                      .name
                                                                                  ),
                                                                                  {
                                                                                    tag: "component",
                                                                                    attrs:
                                                                                      {
                                                                                        color:
                                                                                          "#ffc107",
                                                                                        size: 30,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm.financeDisplayBankSection
                                          ? _c(
                                              "div",
                                              { staticClass: "sub-title" },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1945,
                                                        "Expense Reimbursement Bank Information"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-card-collapsible",
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField("SecondIban")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: _vm.useField(
                                                            "SecondBankAccountOwnerName"
                                                          )
                                                            ? 6
                                                            : 4,
                                                          xl: _vm.useField(
                                                            "SecondBankAccountOwnerName"
                                                          )
                                                            ? 6
                                                            : 4,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            class: {
                                                              "is-warning":
                                                                _vm
                                                                  .userBankWarning
                                                                  .secondIban,
                                                            },
                                                            attrs: {
                                                              "label-for":
                                                                "iban",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        49,
                                                                        "IBAN"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "SecondIban"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                id: "secondIban",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    50,
                                                                    "Enter your bank account number"
                                                                  ),
                                                                disabled:
                                                                  _vm.disableAll,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleSupplierIbanChange(
                                                                      $event,
                                                                      "secondIban"
                                                                    )
                                                                  },
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .secondIban,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "secondIban",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.secondIban",
                                                              },
                                                            }),
                                                            _vm.userBankWarning
                                                              .secondIban
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "warning-feedback",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            97986,
                                                                            "IBAN seems not valid, please make sure it's corrrect"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField("SecondBic")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: _vm.useField(
                                                            "SecondBankAccountOwnerName"
                                                          )
                                                            ? 6
                                                            : 4,
                                                          xl: _vm.useField(
                                                            "SecondBankAccountOwnerName"
                                                          )
                                                            ? 6
                                                            : 4,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            class: {
                                                              "is-warning":
                                                                _vm
                                                                  .userBankWarning
                                                                  .secondBic,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        51,
                                                                        "BIC"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "SecondBic"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                id: "secondBic",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    52,
                                                                    "Enter your bank BIC"
                                                                  ),
                                                                disabled:
                                                                  _vm.disableAll,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleSupplierBicChange(
                                                                      $event,
                                                                      "secondBic"
                                                                    )
                                                                  },
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .secondBic,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "secondBic",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.secondBic",
                                                              },
                                                            }),
                                                            _vm.userBankWarning
                                                              .secondBic
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "warning-feedback",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            909097986,
                                                                            "BIC seems not valid, please make sure it's corrrect"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField(
                                                  "SecondBankAccountOwnerName"
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "6",
                                                          xl: "6",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      this.FormMSG(
                                                                        1245,
                                                                        "Bank account owner name"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "SecondBankAccountOwnerName"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "bic",
                                                                placeholder:
                                                                  this.FormMSG(
                                                                    1246,
                                                                    "Enter your bank account owner name"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .secondBankAccountOwnerName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "secondBankAccountOwnerName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.secondBankAccountOwnerName",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "SecondBankDetailDocument"
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "6",
                                                          xl: "6",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1247,
                                                                        "Bank detail document"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "SecondBankDetailDocument"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              {
                                                                staticClass:
                                                                  "ml-0",
                                                              },
                                                              [
                                                                _vm.checkFile(
                                                                  _vm.myProfile
                                                                    .secondBankDetailXid
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.onOpenUrl(
                                                                                _vm.getImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .secondBankDetailXid,
                                                                                  "secondBankDetailXid"
                                                                                )
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "file-earmark-pdf-fill",
                                                                                "font-scale":
                                                                                  "2",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "b-img",
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer",
                                                                          attrs:
                                                                            {
                                                                              rounded:
                                                                                "",
                                                                              alt: "Bank detail document",
                                                                              src: _vm.getImage(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .secondBankDetailXid,
                                                                                "secondBankDetailXid"
                                                                              ),
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  254,
                                                                                  "View Image"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .secondBankDetailXid
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .myProfile
                                                                                  .secondBankDetailXid,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.myProfile,
                                                                                    "secondBankDetailXid",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "myProfile.secondBankDetailXid",
                                                                            },
                                                                        },
                                                                        "b-img",
                                                                        _vm.imgPropSignatureId,
                                                                        false
                                                                      )
                                                                    ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-secondary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.startCapture(
                                                                            "secondBankDetailXid"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Camera"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pl-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-bottom":
                                                                                  "-1px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  220,
                                                                                  "Picture"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.myProfile
                                                                  .secondBankDetailXid &&
                                                                _vm.myProfile
                                                                  .secondBankDetailXid
                                                                  .length > 0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn-transparent pl-0 ml-2",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              return _vm.deleteImg(
                                                                                "secondBankDetailXid"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Trash2"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 24,
                                                                                "stroke-width": 1.5,
                                                                                color:
                                                                                  "#ea4e2c",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                                _vm.isDeterAction &&
                                                                _vm.myProfile
                                                                  .secondBankDetailXid
                                                                  .length === 0
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "b-tooltip",
                                                                                  rawName:
                                                                                    "v-b-tooltip.hover.bottom.html",
                                                                                  value:
                                                                                    _vm.FormMSG(
                                                                                      1750,
                                                                                      "Bank detail document is not downloaded"
                                                                                    ),
                                                                                  expression:
                                                                                    "FormMSG(1750, 'Bank detail document is not downloaded')",
                                                                                  modifiers:
                                                                                    {
                                                                                      hover: true,
                                                                                      bottom: true,
                                                                                      html: true,
                                                                                    },
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "btn bg-transparent border-0",
                                                                            attrs:
                                                                              {
                                                                                size: "sm",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getIconLucide(
                                                                                _vm
                                                                                  .ICONS
                                                                                  .ALERT_TRIANGLE
                                                                                  .name
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "#ffc107",
                                                                                    size: 30,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        (_vm.useField("Taxes") &&
                                          _vm.useField("Iban")) ||
                                        _vm.useField("Bic")
                                          ? _c("div", {
                                              staticClass: "divider",
                                            })
                                          : _vm._e(),
                                        _vm.useField("Taxes")
                                          ? _c(
                                              "div",
                                              { staticClass: "sub-title" },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(260, "Taxes")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-card-collapsible",
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField("Taxes")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        261,
                                                                        "Taxe"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "Taxes"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-input-group",
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "number",
                                                                      step: "1",
                                                                      min: "0",
                                                                      max: "100",
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .taxesPercentage,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "taxesPercentage",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.taxesPercentage",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "b-input-group-append",
                                                                  [
                                                                    _c(
                                                                      "b-input-group-text",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "background-color":
                                                                              "#00a09c",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Percent"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#ffffff",
                                                                                size: 16,
                                                                                "stroke-width": 3.5,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "TaxDeclarationCountry"
                                                ) && !_vm.hideDeclarationCountry
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "bic",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        this.FormMSG(
                                                                          1248,
                                                                          "Tax declaration country"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "TaxDeclarationCountry"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "taxCountry",
                                                                placeholder:
                                                                  this.FormMSG(
                                                                    1249,
                                                                    "Enter your tax declaration country"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .taxCountry,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "taxCountry",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.taxCountry",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.addressDisplaySection
                              ? _c(
                                  "div",
                                  { staticClass: "card-collapsible mt-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickHeader(
                                              "address"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "title w-90" },
                                          [
                                            _c("b-icon", {
                                              staticStyle: {
                                                color: "rgba(6, 38, 62, 0.84)",
                                              },
                                              attrs: { icon: "geo-fill" },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(11, "Address")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end w-20",
                                          },
                                          [
                                            _vm.addressMandatoryCount > 0
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      pill: "",
                                                      variant: "warning",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.addressMandatoryCount +
                                                          " " +
                                                          _vm.FormMSG(
                                                            2365,
                                                            "field(s) left"
                                                          )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.configAccordion["address"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronDown"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.configAccordion["address"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronUp"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm.myProfile.address
                                      ? _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              visible:
                                                _vm.configAccordion["address"]
                                                  .isVisible,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "sub-title" },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1250,
                                                        "Legal address"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-card-collapsible",
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField("Country")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "6",
                                                              xl: "6",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "country",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              106,
                                                                              "Country"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Country"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c("v-select", {
                                                                  attrs: {
                                                                    clearable: false,
                                                                    options:
                                                                      _vm.countries,
                                                                    label:
                                                                      "text",
                                                                    reduce: (
                                                                      option
                                                                    ) =>
                                                                      option.value,
                                                                    disabled:
                                                                      _vm.disableAll,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      _vm.getCountry,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.countryKey,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.countryKey =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "countryKey",
                                                                  },
                                                                }),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField(
                                                      "InternetResearch"
                                                    )
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "6",
                                                              xl: "6",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "google-auto-complete",
                                                                  {
                                                                    ref: "google-search-address",
                                                                    attrs: {
                                                                      label:
                                                                        _vm.FormMSG(
                                                                          12,
                                                                          "Internet search"
                                                                        ),
                                                                      country:
                                                                        _vm.countryCode,
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                      "clear-input":
                                                                        _vm.clearLegalAddress,
                                                                    },
                                                                    on: {
                                                                      placechanged:
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          _vm.handleAutoComplete(
                                                                            item,
                                                                            "legalAddress"
                                                                          ),
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField("Street")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "10",
                                                              md: "10",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "address",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              100,
                                                                              "Street"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Street"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "street",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          1103,
                                                                          "Enter your street"
                                                                        ),
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .address
                                                                          .street,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .myProfile
                                                                              .address,
                                                                            "street",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.address.street",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("Number")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "2",
                                                              md: "2",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "number",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              101,
                                                                              "Number"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Number"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "number",
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .address
                                                                          .number,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .myProfile
                                                                              .address,
                                                                            "number",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.address.number",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("Box")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "box",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            this.FormMSG(
                                                                              102,
                                                                              "Box"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Box"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "box",
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .address
                                                                          .box,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .myProfile
                                                                              .address,
                                                                            "box",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.address.box",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField("ZipCode")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "zip",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              104,
                                                                              "Postal Code"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "ZipCode"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "zip",
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .address
                                                                          .zip,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .myProfile
                                                                              .address,
                                                                            "zip",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.address.zip",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("City")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "city",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              103,
                                                                              "City"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "City"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "city",
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .address
                                                                          .city,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .myProfile
                                                                              .address,
                                                                            "city",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.address.city",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("StateRegion")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "state",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              105,
                                                                              "State"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "StateRegion"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      type: "text",
                                                                      id: "state",
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .address
                                                                          .state,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .myProfile
                                                                              .address,
                                                                            "state",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.address.state",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-collapse",
                                              {
                                                model: {
                                                  value:
                                                    _vm.isShowPostalAddress,
                                                  callback: function ($$v) {
                                                    _vm.isShowPostalAddress =
                                                      $$v
                                                  },
                                                  expression:
                                                    "isShowPostalAddress",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "sub-title" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            1312,
                                                            "Postal address"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                                _vm.myProfile.postalAddress
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "content-card-collapsible",
                                                      },
                                                      [
                                                        _c(
                                                          "b-row",
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "12",
                                                                  md: "12",
                                                                  lg: "6",
                                                                  xl: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot: "label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                for: "country",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  106,
                                                                                  "Country"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm.isMandatory(
                                                                          "Country"
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "required",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " *"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-select",
                                                                      {
                                                                        attrs: {
                                                                          clearable: false,
                                                                          options:
                                                                            _vm.countries,
                                                                          label:
                                                                            "text",
                                                                          reduce:
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option.value,
                                                                        },
                                                                        on: {
                                                                          input:
                                                                            _vm.getCountryPostalAddress,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.countryKeyPostalAddress,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.countryKeyPostalAddress =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "countryKeyPostalAddress",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "12",
                                                                  md: "12",
                                                                  lg: "6",
                                                                  xl: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  [
                                                                    _c(
                                                                      "google-auto-complete",
                                                                      {
                                                                        ref: "google-search-address",
                                                                        attrs: {
                                                                          label:
                                                                            _vm.FormMSG(
                                                                              12,
                                                                              "Internet search"
                                                                            ),
                                                                          country:
                                                                            _vm.countryCodePostalAddress,
                                                                          "clear-input":
                                                                            _vm.clearPostalAddress,
                                                                        },
                                                                        on: {
                                                                          placechanged:
                                                                            (
                                                                              item
                                                                            ) =>
                                                                              _vm.handleAutoComplete(
                                                                                item,
                                                                                "postalAddress"
                                                                              ),
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-row",
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "10",
                                                                  md: "10",
                                                                  lg: "4",
                                                                  xl: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot: "label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                for: "address",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  100,
                                                                                  "Street"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm.isMandatory(
                                                                          "Street"
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "required",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " *"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        attrs: {
                                                                          type: "text",
                                                                          id: "street",
                                                                          placeholder:
                                                                            _vm.FormMSG(
                                                                              1103,
                                                                              "Enter your street"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          keydown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "enter",
                                                                                  13,
                                                                                  $event.key,
                                                                                  "Enter"
                                                                                )
                                                                              )
                                                                                return null
                                                                              $event.preventDefault()
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .myProfile
                                                                              .postalAddress
                                                                              .street,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .postalAddress,
                                                                                "street",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "myProfile.postalAddress.street",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "2",
                                                                  md: "2",
                                                                  lg: "4",
                                                                  xl: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot: "label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                for: "number",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  101,
                                                                                  "Number"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm.isMandatory(
                                                                          "Number"
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "required",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " *"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        attrs: {
                                                                          type: "text",
                                                                          id: "number",
                                                                        },
                                                                        on: {
                                                                          keydown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "enter",
                                                                                  13,
                                                                                  $event.key,
                                                                                  "Enter"
                                                                                )
                                                                              )
                                                                                return null
                                                                              $event.preventDefault()
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .myProfile
                                                                              .postalAddress
                                                                              .number,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .postalAddress,
                                                                                "number",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "myProfile.postalAddress.number",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "12",
                                                                  md: "12",
                                                                  lg: "4",
                                                                  xl: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot: "label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                for: "box",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                this.FormMSG(
                                                                                  102,
                                                                                  "Box"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm.isMandatory(
                                                                          "Box"
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "required",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " *"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        attrs: {
                                                                          type: "text",
                                                                          id: "box",
                                                                        },
                                                                        on: {
                                                                          keydown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "enter",
                                                                                  13,
                                                                                  $event.key,
                                                                                  "Enter"
                                                                                )
                                                                              )
                                                                                return null
                                                                              $event.preventDefault()
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .myProfile
                                                                              .postalAddress
                                                                              .box,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .postalAddress,
                                                                                "box",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "myProfile.postalAddress.box",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-row",
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "12",
                                                                  md: "12",
                                                                  lg: "4",
                                                                  xl: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot: "label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                for: "zip",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  104,
                                                                                  "Postal Code"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm.isMandatory(
                                                                          "ZipCode"
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "required",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " *"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        attrs: {
                                                                          type: "text",
                                                                          id: "zip",
                                                                        },
                                                                        on: {
                                                                          keydown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "enter",
                                                                                  13,
                                                                                  $event.key,
                                                                                  "Enter"
                                                                                )
                                                                              )
                                                                                return null
                                                                              $event.preventDefault()
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .myProfile
                                                                              .postalAddress
                                                                              .zip,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .postalAddress,
                                                                                "zip",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "myProfile.postalAddress.zip",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "12",
                                                                  md: "12",
                                                                  lg: "4",
                                                                  xl: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot: "label",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                for: "city",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  103,
                                                                                  "City"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm.isMandatory(
                                                                          "City"
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "required",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " *"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        attrs: {
                                                                          type: "text",
                                                                          id: "city",
                                                                        },
                                                                        on: {
                                                                          keydown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "enter",
                                                                                  13,
                                                                                  $event.key,
                                                                                  "Enter"
                                                                                )
                                                                              )
                                                                                return null
                                                                              $event.preventDefault()
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .myProfile
                                                                              .postalAddress
                                                                              .city,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .postalAddress,
                                                                                "city",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "myProfile.postalAddress.city",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm.useField(
                                                              "StateRegion"
                                                            )
                                                              ? _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      sm: "12",
                                                                      md: "12",
                                                                      lg: "4",
                                                                      xl: "4",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-group",
                                                                      [
                                                                        _c(
                                                                          "template",
                                                                          {
                                                                            slot: "label",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    for: "state",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      105,
                                                                                      "State"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm.isMandatory(
                                                                              "StateRegion"
                                                                            )
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "required",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " *"
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "text",
                                                                                id: "state",
                                                                              },
                                                                            on: {
                                                                              keydown:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    !$event.type.indexOf(
                                                                                      "key"
                                                                                    ) &&
                                                                                    _vm._k(
                                                                                      $event.keyCode,
                                                                                      "enter",
                                                                                      13,
                                                                                      $event.key,
                                                                                      "Enter"
                                                                                    )
                                                                                  )
                                                                                    return null
                                                                                  $event.preventDefault()
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .postalAddress
                                                                                    .state,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .myProfile
                                                                                        .postalAddress,
                                                                                      "state",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "myProfile.postalAddress.state",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "28px",
                                                  },
                                                },
                                                [
                                                  !_vm.hideAddPostalAddress
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row justify-content-center gap-2 align-items-center w-auto h-auto",
                                                          staticStyle: {
                                                            height: "40px",
                                                            "border-radius":
                                                              "0.5rem",
                                                            "background-color":
                                                              "#fff",
                                                            cursor: "pointer",
                                                          },
                                                          attrs: {
                                                            size: "sm",
                                                            variant:
                                                              _vm.isShowPostalAddress
                                                                ? "outline-danger"
                                                                : "outline-secondary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.isShowPostalAddress =
                                                                !_vm.isShowPostalAddress
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row align-items-center justify-content-center btn-address",
                                                              staticStyle: {
                                                                color:
                                                                  "#06263e",
                                                              },
                                                            },
                                                            [
                                                              _vm.isShowPostalAddress
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .X_CLEAR
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .X_CLEAR
                                                                                  .color,
                                                                              size: 22,
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              1313,
                                                                              "Close"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          "PlusCircle"
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#47C7BF",
                                                                              size: 22,
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              1251,
                                                                              "Add postal address (if different)"
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.healthDisplaySection
                              ? _c(
                                  "div",
                                  { staticClass: "card-collapsible mt-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickHeader(
                                              "health"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "title w-90" },
                                          [
                                            _c(
                                              _vm.getLucideIcon("HeartPulse"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color:
                                                    "rgba(6, 38, 62, 0.84)",
                                                  size: 22,
                                                },
                                              }
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(1252, "Health")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end w-20",
                                          },
                                          [
                                            _vm.healthMandatoryCount > 0
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      pill: "",
                                                      variant: "warning",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.healthMandatoryCount +
                                                          " " +
                                                          _vm.FormMSG(
                                                            2365,
                                                            "field(s) left"
                                                          )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.configAccordion["health"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronDown"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.configAccordion["health"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronUp"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          visible:
                                            _vm.configAccordion["health"]
                                              .isVisible,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-card-collapsible",
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField(
                                                  "SocialSecurityNumber"
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        54,
                                                                        "Social Security Number"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "SocialSecurityNumber"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "is-warning":
                                                                  _vm.isSocSecNumberWarning,
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disableAll,
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    154,
                                                                    "Enter your Social Security Number"
                                                                  ),
                                                              },
                                                              on: {
                                                                input:
                                                                  _vm.handleSocSecNumberChange,
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .socSecNumber,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "socSecNumber",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.socSecNumber",
                                                              },
                                                            }),
                                                            _vm.isSocSecNumberWarning
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "warning-feedback",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          _vm.FormMSG(
                                                                            8979997,
                                                                            "The social security number seems not valid"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "SocialSecurityCard"
                                                ) && !_vm.hideInfoHealth
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "socialSecurityCardXid",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          1255,
                                                                          "Social security card"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "SocialSecurityCard"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              {
                                                                staticClass:
                                                                  "ml-0",
                                                              },
                                                              [
                                                                _vm.checkFile(
                                                                  _vm.myProfile
                                                                    .socialSecurityCardXid
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.onOpenUrl(
                                                                                _vm.getImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .socialSecurityCardXid,
                                                                                  "socialSecurityCardXid"
                                                                                )
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "file-earmark-pdf-fill",
                                                                                "font-scale":
                                                                                  "2",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "b-img",
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer",
                                                                          attrs:
                                                                            {
                                                                              rounded:
                                                                                "",
                                                                              alt: "socialSecurityCardXid",
                                                                              src: _vm.getImage(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .socialSecurityCardXid,
                                                                                "socialSecurityCardXid"
                                                                              ),
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  254,
                                                                                  "View Image"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .socialSecurityCardXid
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "b-img",
                                                                        _vm.imgPropSignatureId,
                                                                        false
                                                                      )
                                                                    ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-secondary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.startCapture(
                                                                            "socialSecurityCardXid"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Camera"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pl-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-bottom":
                                                                                  "-1px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  220,
                                                                                  "Picture"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.myProfile
                                                                  .socialSecurityCardXid &&
                                                                _vm.myProfile
                                                                  .socialSecurityCardXid
                                                                  .length > 0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn-transparent pl-0 ml-2",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              return _vm.deleteImg(
                                                                                "socialSecurityCardXid"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Trash2"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 24,
                                                                                "stroke-width": 1.5,
                                                                                color:
                                                                                  "#ea4e2c",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "InsuranceCertificate"
                                                ) && !_vm.hideInfoHealth
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "insuranceCertificate",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          1256,
                                                                          "Insurance certificate"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "InsuranceCertificate"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              {
                                                                staticClass:
                                                                  "ml-0",
                                                              },
                                                              [
                                                                _vm.checkFile(
                                                                  _vm.myProfile
                                                                    .insuranceCertificateXid
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.onOpenUrl(
                                                                                _vm.getImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .insuranceCertificateXid,
                                                                                  "insuranceCertificateXid"
                                                                                )
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "file-earmark-pdf-fill",
                                                                                "font-scale":
                                                                                  "2",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "b-img",
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer",
                                                                          attrs:
                                                                            {
                                                                              rounded:
                                                                                "",
                                                                              alt: "inscuranceCertificate",
                                                                              src: _vm.getImage(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .insuranceCertificateXid,
                                                                                "insuranceCertificateXid"
                                                                              ),
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  254,
                                                                                  "View Image"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .insuranceCertificateXid
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        "b-img",
                                                                        _vm.imgPropSignatureId,
                                                                        false
                                                                      )
                                                                    ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-secondary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.startCapture(
                                                                            "insuranceCertificateXid"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Camera"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pl-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-bottom":
                                                                                  "-1px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  220,
                                                                                  "Picture"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.myProfile
                                                                  .insuranceCertificateXid &&
                                                                _vm.myProfile
                                                                  .insuranceCertificateXid
                                                                  .length > 0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn-transparent pl-0 ml-2",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              return _vm.deleteImg(
                                                                                "insuranceCertificateXid"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Trash2"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 24,
                                                                                "stroke-width": 1.5,
                                                                                color:
                                                                                  "#ea4e2c",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField(
                                                  "SocialSecurityCenter"
                                                ) && !_vm.hideInfoHealth
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "socialSecurityCenter",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        this.FormMSG(
                                                                          1700,
                                                                          "Social security center"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "SocialSecurityCenter"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "socialSecurityCenter",
                                                                placeholder:
                                                                  this.FormMSG(
                                                                    1258,
                                                                    "Enter your social security center"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .socialSecurityCenter,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "socialSecurityCenter",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.socialSecurityCenter",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "SocialSecurityCenterAddress"
                                                ) && !_vm.hideInfoHealth
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "8",
                                                          xl: "8",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1259,
                                                                        "Social security center address"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "SocialSecurityCenterAddress"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("v-address", {
                                                              attrs: {
                                                                params: {
                                                                  title:
                                                                    "Social security center address",
                                                                },
                                                                "address-id":
                                                                  _vm.myProfile
                                                                    .socialSecurityCenterAddressId,
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.handleDynamicAddressChange,
                                                                delete:
                                                                  _vm.handleDynamicAddressDelete,
                                                                "address-maplink-change":
                                                                  _vm.handleAddressMapLinkChange,
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField(
                                                  "LastMedicalVisitDate"
                                                ) && !_vm.hideInfoHealth
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1260,
                                                                        "Last medical visit date"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "LastMedicalVisitDate"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-date-picker",
                                                              {
                                                                attrs: {
                                                                  locale:
                                                                    _vm.lang,
                                                                  masks:
                                                                    _vm.masks,
                                                                  attributes:
                                                                    _vm.attrs,
                                                                },
                                                                on: {
                                                                  dayclick: (
                                                                    payload
                                                                  ) =>
                                                                    _vm.handleInputCalendar(
                                                                      payload,
                                                                      "lastMedicalVisit"
                                                                    ),
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          inputValue,
                                                                          togglePopover,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "b-input-group",
                                                                              [
                                                                                _c(
                                                                                  "b-form-input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "mask",
                                                                                          rawName:
                                                                                            "v-mask",
                                                                                          value:
                                                                                            "##/##/####",
                                                                                          expression:
                                                                                            "'##/##/####'",
                                                                                        },
                                                                                      ],
                                                                                    class:
                                                                                      {
                                                                                        "temp-invalid":
                                                                                          _vm.isMandatory(
                                                                                            "LastMedicalVisitDate"
                                                                                          ) &&
                                                                                          !_vm.isDateValid(
                                                                                            "lastMedicalVisit"
                                                                                          )[
                                                                                            "lastMedicalVisit"
                                                                                          ],
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        value:
                                                                                          inputValue,
                                                                                        placeholder:
                                                                                          _vm.FormMSG(
                                                                                            8960,
                                                                                            "DD/MM/YYYY"
                                                                                          ),
                                                                                        readonly:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return togglePopover()
                                                                                        },
                                                                                      input:
                                                                                        (
                                                                                          value
                                                                                        ) =>
                                                                                          _vm.handleChangeDate(
                                                                                            value,
                                                                                            "lastMedicalVisit"
                                                                                          ),
                                                                                      keydown:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          if (
                                                                                            !$event.type.indexOf(
                                                                                              "key"
                                                                                            ) &&
                                                                                            _vm._k(
                                                                                              $event.keyCode,
                                                                                              "enter",
                                                                                              13,
                                                                                              $event.key,
                                                                                              "Enter"
                                                                                            )
                                                                                          )
                                                                                            return null
                                                                                          $event.preventDefault()
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "b-input-group-append",
                                                                                  [
                                                                                    _c(
                                                                                      "b-input-group-text",
                                                                                      {
                                                                                        staticClass:
                                                                                          "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                                        class:
                                                                                          {
                                                                                            "temp-invalid":
                                                                                              _vm.isMandatory(
                                                                                                "LastMedicalVisitDate"
                                                                                              ) &&
                                                                                              !_vm.isDateValid(
                                                                                                "lastMedicalVisit"
                                                                                              )[
                                                                                                "lastMedicalVisit"
                                                                                              ],
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return togglePopover()
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            "Calendar"
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "rgba(6, 38, 62, .6)",
                                                                                                size: 18,
                                                                                                "stroke-width": 2.25,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    1847962496
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .lastMedicalVisit,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "lastMedicalVisit",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.lastMedicalVisit",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "MedicalVisitValidity"
                                                ) && !_vm.hideInfoHealth
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1262,
                                                                        "Medical visit validity"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "MedicalVisitValidity"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-date-picker",
                                                              {
                                                                attrs: {
                                                                  locale:
                                                                    _vm.lang,
                                                                  masks:
                                                                    _vm.masks,
                                                                  attributes:
                                                                    _vm.attrs,
                                                                },
                                                                on: {
                                                                  dayclick: (
                                                                    payload
                                                                  ) =>
                                                                    _vm.handleInputCalendar(
                                                                      payload,
                                                                      "medicalVisitValidity"
                                                                    ),
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          inputValue,
                                                                          togglePopover,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "b-input-group",
                                                                              [
                                                                                _c(
                                                                                  "b-form-input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "mask",
                                                                                          rawName:
                                                                                            "v-mask",
                                                                                          value:
                                                                                            "##/##/####",
                                                                                          expression:
                                                                                            "'##/##/####'",
                                                                                        },
                                                                                      ],
                                                                                    class:
                                                                                      {
                                                                                        "temp-invalid":
                                                                                          _vm.isMandatory(
                                                                                            "MedicalVisitValidity"
                                                                                          ) &&
                                                                                          !_vm.isDateValid(
                                                                                            "medicalVisitValidity"
                                                                                          )[
                                                                                            "medicalVisitValidity"
                                                                                          ],
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        value:
                                                                                          inputValue,
                                                                                        placeholder:
                                                                                          _vm.FormMSG(
                                                                                            8960,
                                                                                            "DD/MM/YYYY"
                                                                                          ),
                                                                                        readonly:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return togglePopover()
                                                                                        },
                                                                                      input:
                                                                                        (
                                                                                          value
                                                                                        ) =>
                                                                                          _vm.handleChangeDate(
                                                                                            value,
                                                                                            "medicalVisitValidity"
                                                                                          ),
                                                                                      keydown:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          if (
                                                                                            !$event.type.indexOf(
                                                                                              "key"
                                                                                            ) &&
                                                                                            _vm._k(
                                                                                              $event.keyCode,
                                                                                              "enter",
                                                                                              13,
                                                                                              $event.key,
                                                                                              "Enter"
                                                                                            )
                                                                                          )
                                                                                            return null
                                                                                          $event.preventDefault()
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "b-input-group-append",
                                                                                  [
                                                                                    _c(
                                                                                      "b-input-group-text",
                                                                                      {
                                                                                        staticClass:
                                                                                          "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                                        class:
                                                                                          {
                                                                                            "temp-invalid":
                                                                                              _vm.isMandatory(
                                                                                                "MedicalVisitValidity"
                                                                                              ) &&
                                                                                              !_vm.isDateValid(
                                                                                                "medicalVisitValidity"
                                                                                              )[
                                                                                                "medicalVisitValidity"
                                                                                              ],
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return togglePopover()
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            "Calendar"
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "rgba(6, 38, 62, .6)",
                                                                                                size: 18,
                                                                                                "stroke-width": 2.25,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    4290708984
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .medicalVisitValidity,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "medicalVisitValidity",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.medicalVisitValidity",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "MedicalVisitDocument"
                                                ) && !_vm.hideInfoHealth
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "MedicalVisitDocument",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          1264,
                                                                          "Medical visit document"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "MedicalVisitDocument"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              {
                                                                staticClass:
                                                                  "ml-0",
                                                              },
                                                              [
                                                                _vm.checkFile(
                                                                  _vm.myProfile
                                                                    .medicalVisitDocumentXid
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center cursor-pointer",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.onOpenUrl(
                                                                                _vm.getImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .medicalVisitDocumentXid,
                                                                                  "medicalVisitDocumentXid"
                                                                                )
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "file-earmark-pdf-fill",
                                                                                "font-scale":
                                                                                  "2",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "b-img",
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "cursor-pointer",
                                                                          attrs:
                                                                            {
                                                                              rounded:
                                                                                "",
                                                                              alt: "medicalVisitDocument",
                                                                              src: _vm.getImage(
                                                                                _vm
                                                                                  .myProfile
                                                                                  .medicalVisitDocumentXid,
                                                                                "medicalVisitDocumentXid"
                                                                              ),
                                                                              title:
                                                                                _vm.FormMSG(
                                                                                  254,
                                                                                  "View Image"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showImage(
                                                                                  _vm
                                                                                    .myProfile
                                                                                    .medicalVisitDocumentXid
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .myProfile
                                                                                  .medicalVisitDocumentXid,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.myProfile,
                                                                                    "medicalVisitDocumentXid",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "myProfile.medicalVisitDocumentXid",
                                                                            },
                                                                        },
                                                                        "b-img",
                                                                        _vm.imgPropSignatureId,
                                                                        false
                                                                      )
                                                                    ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      variant:
                                                                        "outline-secondary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.startCapture(
                                                                            "medicalVisitDocumentXid"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-content-center align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Camera"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "pl-2",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-bottom":
                                                                                  "-1px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  220,
                                                                                  "Picture"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.myProfile
                                                                  .medicalVisitDocumentXid &&
                                                                _vm.myProfile
                                                                  .medicalVisitDocumentXid
                                                                  .length > 0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn-transparent pl-0 ml-2",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              return _vm.deleteImg(
                                                                                "medicalVisitDocumentXid"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Trash2"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                size: 24,
                                                                                "stroke-width": 1.5,
                                                                                color:
                                                                                  "#ea4e2c",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                                _vm.isDeterAction &&
                                                                _vm.myProfile
                                                                  .medicalVisitDocumentXid
                                                                  .length === 0
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "b-tooltip",
                                                                                  rawName:
                                                                                    "v-b-tooltip.hover.bottom.html",
                                                                                  value:
                                                                                    _vm.FormMSG(
                                                                                      1751,
                                                                                      "Medical visit document is not downloaded"
                                                                                    ),
                                                                                  expression:
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tFormMSG(1751, 'Medical visit document is not downloaded')\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                                                                  modifiers:
                                                                                    {
                                                                                      hover: true,
                                                                                      bottom: true,
                                                                                      html: true,
                                                                                    },
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "btn bg-transparent border-0",
                                                                            attrs:
                                                                              {
                                                                                size: "sm",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getIconLucide(
                                                                                _vm
                                                                                  .ICONS
                                                                                  .ALERT_TRIANGLE
                                                                                  .name
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "#ffc107",
                                                                                    size: 30,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField(
                                                  "WorkerWithADisability"
                                                ) && !_vm.hideInfoHealth
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-start",
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            staticClass:
                                                              "text-start",
                                                          },
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1265,
                                                                        "Worker with a disability"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "WorkerWithADisability"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-start",
                                                                attrs: {
                                                                  size: "lg",
                                                                  switch: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .disabled,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "disabled",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.disabled",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-redired",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .myProfile
                                                                          .disabled
                                                                          ? _vm.FormMSG(
                                                                              1276,
                                                                              "Yes"
                                                                            )
                                                                          : _vm.FormMSG(
                                                                              1277,
                                                                              "No"
                                                                            )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.myProfile.disabled &&
                                                _vm.useField(
                                                  "DisabilityNotificationDate"
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c("label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    1266,
                                                                    "Disability notification date"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                            _vm.isMandatory(
                                                              "DisabilityNotificationDate"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "required",
                                                                  },
                                                                  [_vm._v(" *")]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "v-date-picker",
                                                              {
                                                                attrs: {
                                                                  locale:
                                                                    _vm.lang,
                                                                  masks:
                                                                    _vm.masks,
                                                                  attributes:
                                                                    _vm.attrs,
                                                                },
                                                                on: {
                                                                  dayclick: (
                                                                    payload
                                                                  ) =>
                                                                    _vm.handleInputCalendar(
                                                                      payload,
                                                                      "disabilityNotification"
                                                                    ),
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          inputValue,
                                                                          togglePopover,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "b-input-group",
                                                                              [
                                                                                _c(
                                                                                  "b-form-input",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "mask",
                                                                                          rawName:
                                                                                            "v-mask",
                                                                                          value:
                                                                                            "##/##/####",
                                                                                          expression:
                                                                                            "'##/##/####'",
                                                                                        },
                                                                                      ],
                                                                                    class:
                                                                                      {
                                                                                        "temp-invalid":
                                                                                          _vm.isMandatory(
                                                                                            "DisabilityNotificationDate"
                                                                                          ) &&
                                                                                          !_vm.isDateValid(
                                                                                            "disabilityNotification"
                                                                                          )[
                                                                                            "disabilityNotification"
                                                                                          ],
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        value:
                                                                                          inputValue,
                                                                                        placeholder:
                                                                                          _vm.FormMSG(
                                                                                            8960,
                                                                                            "DD/MM/YYYY"
                                                                                          ),
                                                                                        readonly:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return togglePopover()
                                                                                        },
                                                                                      input:
                                                                                        (
                                                                                          value
                                                                                        ) =>
                                                                                          _vm.handleChangeDate(
                                                                                            value,
                                                                                            "disabilityNotification"
                                                                                          ),
                                                                                      keydown:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          if (
                                                                                            !$event.type.indexOf(
                                                                                              "key"
                                                                                            ) &&
                                                                                            _vm._k(
                                                                                              $event.keyCode,
                                                                                              "enter",
                                                                                              13,
                                                                                              $event.key,
                                                                                              "Enter"
                                                                                            )
                                                                                          )
                                                                                            return null
                                                                                          $event.preventDefault()
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "b-input-group-append",
                                                                                  [
                                                                                    _c(
                                                                                      "b-input-group-text",
                                                                                      {
                                                                                        staticClass:
                                                                                          "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return togglePopover()
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          _vm.getLucideIcon(
                                                                                            "Calendar"
                                                                                          ),
                                                                                          {
                                                                                            tag: "component",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "rgba(6, 38, 62, .6)",
                                                                                                size: 18,
                                                                                                "stroke-width": 2.25,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm.isMandatory(
                                                                              "DisabilityNotificationDate"
                                                                            ) &&
                                                                            !_vm.isDateValid(
                                                                              "disabilityNotification"
                                                                            )[
                                                                              "disabilityNotification"
                                                                            ]
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "invalid-feedback",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          _vm.FormMSG(
                                                                                            900,
                                                                                            "Disability notification date is required"
                                                                                          )
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    2847130323
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .disabilityNotification,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "disabilityNotification",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.disabilityNotification",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.myProfile.disabled &&
                                                _vm.useField("Type")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "type",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    this.FormMSG(
                                                                      1268,
                                                                      "Type"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm.isMandatory(
                                                              "Type"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "required",
                                                                  },
                                                                  [_vm._v(" *")]
                                                                )
                                                              : _vm._e(),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "type",
                                                                placeholder:
                                                                  this.FormMSG(
                                                                    1269,
                                                                    "Enter type"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .disabilityType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "disabilityType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.disabilityType",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.myProfile.disabled &&
                                                _vm.useField("Status")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "status",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    this.FormMSG(
                                                                      1270,
                                                                      "Status"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm.isMandatory(
                                                              "Status"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "required",
                                                                  },
                                                                  [_vm._v(" *")]
                                                                )
                                                              : _vm._e(),
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                id: "status",
                                                                placeholder:
                                                                  this.FormMSG(
                                                                    1271,
                                                                    "Enter status"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .disabilityStatus,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "disabilityStatus",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.disabilityStatus",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _vm.myProfile.disabled &&
                                                _vm.useField("Invalidity")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "status",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    this.FormMSG(
                                                                      1272,
                                                                      "Invalidity"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm.isMandatory(
                                                              "Invalidity"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "required",
                                                                  },
                                                                  [_vm._v(" *")]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "b-form-select",
                                                              {
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .invalidity,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "invalidity",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.invalidity",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.menuInvalidity,
                                                                function (
                                                                  gender,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "b-form-select-option",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        value:
                                                                          gender.value,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            gender.text
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.myProfile.disabled &&
                                                _vm.useField("Rqth")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center mt-2",
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-checkbox",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center",
                                                            attrs: {
                                                              size: "lg",
                                                              value: true,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.myProfile
                                                                  .rqth,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.myProfile,
                                                                    "rqth",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "myProfile.rqth",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mt-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1273,
                                                                        "RQTH"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                                _vm.isMandatory(
                                                                  "Rqth"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.myProfile.disabled &&
                                                _vm.useField(
                                                  "InvalidityPercentage"
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "status",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    this.FormMSG(
                                                                      1274,
                                                                      "Invalidity percentage"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm.isMandatory(
                                                              "InvalidityPercentage"
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "required",
                                                                  },
                                                                  [_vm._v(" *")]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "b-input-group",
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "number",
                                                                      step: "1",
                                                                      min: "0",
                                                                      max: "100",
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .invalidityPourcentage,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "invalidityPourcentage",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.invalidityPourcentage",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "b-input-group-append",
                                                                  [
                                                                    _c(
                                                                      "b-input-group-text",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "background-color":
                                                                              "#00a09c",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getIconLucide(
                                                                            "Percent"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#ffffff",
                                                                                size: 16,
                                                                                "stroke-width": 3.5,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField("HaveAllergies") &&
                                                !_vm.hideInfoHealth
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        25,
                                                                        "Have Allergies?"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "HaveAllergies"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  switch: "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.handleChangeHaveAllergies,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.haveAllergies,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.haveAllergies =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "haveAllergies",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "AllergiesDetails"
                                                ) && !_vm.hideInfoHealth
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        26,
                                                                        "Allergies details"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "AllergiesDetails"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-textarea",
                                                              {
                                                                attrs: {
                                                                  type: "textarea",
                                                                  rows: "2",
                                                                  placeholder:
                                                                    _vm.FormMSG(
                                                                      6,
                                                                      "Enter allergies details..."
                                                                    ),
                                                                  autocomplete:
                                                                    "off",
                                                                  disabled:
                                                                    _vm.haveAllergies ===
                                                                    false,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .allergies,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "allergies",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.allergies",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.familyDisplaySection
                              ? _c(
                                  "div",
                                  { staticClass: "card-collapsible mt-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickHeader(
                                              "family"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "title w-90" },
                                          [
                                            _c("b-icon", {
                                              staticStyle: {
                                                color: "rgba(6, 38, 62, 0.84)",
                                              },
                                              attrs: { icon: "people-fill" },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(13, "Family")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end w-20",
                                          },
                                          [
                                            _vm.familyMandatoryCount > 0
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      pill: "",
                                                      variant: "warning",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.familyMandatoryCount +
                                                          " " +
                                                          _vm.FormMSG(
                                                            2365,
                                                            "field(s) left"
                                                          )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.configAccordion["family"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronDown"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.configAccordion["family"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronUp"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          visible:
                                            _vm.configAccordion["family"]
                                              .isVisible,
                                        },
                                      },
                                      [
                                        _vm.useField("CivilStatus") ||
                                        _vm.useField("SpouseFirstName") ||
                                        _vm.useField("SpouseLastName") ||
                                        _vm.useField("SpouseBirthdate") ||
                                        _vm.useField("SpouseHasSalary") ||
                                        _vm.useField("SpouseProfession") ||
                                        _vm.useField(
                                          "NumberOfDependentChildren"
                                        ) ||
                                        _vm.useField(
                                          "NumberOfDisabledChildren"
                                        ) ||
                                        _vm.useField(
                                          "NumberOfDependentPerson"
                                        ) ||
                                        _vm.useField("NumberOfDisabledPerson")
                                          ? _c(
                                              "div",
                                              { staticClass: "sub-title" },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        14,
                                                        "Family composition"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-card-collapsible",
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField("CivilStatus")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "optionsForCivilStatus",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          53,
                                                                          "Civil status"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isMandatory(
                                                                  "CivilStatus"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-select",
                                                              {
                                                                attrs: {
                                                                  options:
                                                                    _vm.optionsForCivilStatus,
                                                                  disabled:
                                                                    _vm.disableAll,
                                                                  id: "optionsForCivilStatus",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .civilStatus,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "civilStatus",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.civilStatus",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                (_vm.myProfile.civilStatus ===
                                                  3 ||
                                                  _vm.myProfile.civilStatus ===
                                                    8) &&
                                                _vm.useField("SpouseFirstName")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        174,
                                                                        "Spouse first name"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "SpouseFirstName"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                disabled:
                                                                  _vm.disableAll,
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    176,
                                                                    "Enter your Spouse first name "
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .spouseFirstName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "spouseFirstName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.spouseFirstName",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                (_vm.myProfile.civilStatus ===
                                                  3 ||
                                                  _vm.myProfile.civilStatus ===
                                                    8) &&
                                                _vm.useField("SpouseLastName")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "4",
                                                          xl: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        172,
                                                                        "Spouse name"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "SpouseLastName"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                disabled:
                                                                  _vm.disableAll,
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    173,
                                                                    "Enter your Spouse name "
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .spouseName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "spouseName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.spouseName",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _vm.myProfile.civilStatus === 3 ||
                                            _vm.myProfile.civilStatus === 8
                                              ? _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField(
                                                      "SpouseBirthdate"
                                                    )
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        177,
                                                                        "Spouse birth date"
                                                                      )
                                                                    )
                                                                  ),
                                                                  _vm.isMandatory(
                                                                    "SpouseBirthdate"
                                                                  )
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "required",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " *"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]),
                                                                _c(
                                                                  "v-date-picker",
                                                                  {
                                                                    attrs: {
                                                                      locale:
                                                                        _vm.lang,
                                                                      masks:
                                                                        _vm.masks,
                                                                      attributes:
                                                                        _vm.attrs,
                                                                    },
                                                                    on: {
                                                                      dayclick:
                                                                        (
                                                                          payload
                                                                        ) =>
                                                                          _vm.handleInputCalendar(
                                                                            payload,
                                                                            "spouseBirthDate"
                                                                          ),
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "default",
                                                                            fn: function ({
                                                                              inputValue,
                                                                              togglePopover,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "b-input-group",
                                                                                  [
                                                                                    _c(
                                                                                      "b-form-input",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "mask",
                                                                                              rawName:
                                                                                                "v-mask",
                                                                                              value:
                                                                                                "##/##/####",
                                                                                              expression:
                                                                                                "'##/##/####'",
                                                                                            },
                                                                                          ],
                                                                                        class:
                                                                                          {
                                                                                            "temp-invalid":
                                                                                              _vm.isMandatory(
                                                                                                "SpouseBirthdate"
                                                                                              ) &&
                                                                                              !_vm.isDateValid(
                                                                                                "spouseBirthDate"
                                                                                              )[
                                                                                                "spouseBirthDate"
                                                                                              ],
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            value:
                                                                                              inputValue,
                                                                                            disabled:
                                                                                              _vm.disableAll,
                                                                                            placeholder:
                                                                                              _vm.FormMSG(
                                                                                                8960,
                                                                                                "DD/MM/YYYY"
                                                                                              ),
                                                                                            readonly:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return togglePopover()
                                                                                            },
                                                                                          change:
                                                                                            (
                                                                                              value
                                                                                            ) =>
                                                                                              _vm.handleChangeDate(
                                                                                                value,
                                                                                                "spouseBirthDate"
                                                                                              ),
                                                                                          keydown:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              if (
                                                                                                !$event.type.indexOf(
                                                                                                  "key"
                                                                                                ) &&
                                                                                                _vm._k(
                                                                                                  $event.keyCode,
                                                                                                  "enter",
                                                                                                  13,
                                                                                                  $event.key,
                                                                                                  "Enter"
                                                                                                )
                                                                                              )
                                                                                                return null
                                                                                              $event.preventDefault()
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "b-input-group-append",
                                                                                      [
                                                                                        _c(
                                                                                          "b-input-group-text",
                                                                                          {
                                                                                            staticClass:
                                                                                              "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                                            class:
                                                                                              {
                                                                                                "temp-invalid":
                                                                                                  _vm.isMandatory(
                                                                                                    "SpouseBirthdate"
                                                                                                  ) &&
                                                                                                  !_vm.isDateValid(
                                                                                                    "spouseBirthDate"
                                                                                                  )[
                                                                                                    "spouseBirthDate"
                                                                                                  ],
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return togglePopover()
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              _vm.getLucideIcon(
                                                                                                "Calendar"
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "rgba(6, 38, 62, .6)",
                                                                                                    size: 18,
                                                                                                    "stroke-width": 2.25,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        3924954989
                                                                      ),
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .spouseBirthDate,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "spouseBirthDate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.spouseBirthDate",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.myProfile
                                                      .civilStatus === 3 ||
                                                    _vm.myProfile
                                                      .civilStatus === 8 ||
                                                    _vm.useField(
                                                      "SpouseHasSalary"
                                                    )
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              {
                                                                staticClass:
                                                                  "text-start",
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        attrs: {
                                                                          for: "firstname",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              181,
                                                                              "Spouse has salary"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "SpouseHasSalary"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-content-start",
                                                                    attrs: {
                                                                      size: "lg",
                                                                      switch:
                                                                        "",
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .spouseHasSalary,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "spouseHasSalary",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.spouseHasSalary",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-redired",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .myProfile
                                                                              .spouseHasSalary
                                                                              ? _vm.FormMSG(
                                                                                  1276,
                                                                                  "Yes"
                                                                                )
                                                                              : _vm.FormMSG(
                                                                                  1277,
                                                                                  "No"
                                                                                )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.myProfile
                                                      .civilStatus === 3 ||
                                                    _vm.myProfile
                                                      .civilStatus === 8 ||
                                                    _vm.useField(
                                                      "SpouseProfession"
                                                    )
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        179,
                                                                        "Spouse profession"
                                                                      )
                                                                    ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                  _vm.isMandatory(
                                                                    "SpouseProfession"
                                                                  )
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "required",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " *"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    class: {
                                                                      "bg-color-grams-hair":
                                                                        _vm.disableAll,
                                                                    },
                                                                    attrs: {
                                                                      type: "text",
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          180,
                                                                          "Enter your Spouse profession "
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .spouseProfession,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "spouseProfession",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.spouseProfession",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField(
                                                  "NumberOfDependentChildren"
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        183,
                                                                        "Number of dependent child"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "NumberOfDependentChildren"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                type: "number",
                                                                step: "1",
                                                                min: "0",
                                                                placeholder:
                                                                  "0",
                                                                disabled:
                                                                  _vm.disableAll,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .numberOfDependentChild,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "numberOfDependentChild",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.numberOfDependentChild",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "NumberOfDisabledChildren"
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        184,
                                                                        "Number of disabled child"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "NumberOfDisabledChildren"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                type: "number",
                                                                step: "1",
                                                                min: "0",
                                                                placeholder:
                                                                  "0",
                                                                disabled:
                                                                  _vm.disableAll,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .numberOfDisabledChild,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "numberOfDisabledChild",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.numberOfDisabledChild",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "NumberOfDependentPerson"
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        185,
                                                                        "Number of dependent person"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "NumberOfDependentPerson"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                type: "number",
                                                                step: "1",
                                                                min: "0",
                                                                placeholder:
                                                                  "0",
                                                                disabled:
                                                                  _vm.disableAll,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .numberOfDependentPerson,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "numberOfDependentPerson",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.numberOfDependentPerson",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "NumberOfDisabledPerson"
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "3",
                                                          xl: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        186,
                                                                        "Number of disabled person"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "NumberOfDisabledPerson"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                type: "number",
                                                                step: "1",
                                                                min: "0",
                                                                placeholder:
                                                                  "0",
                                                                disabled:
                                                                  _vm.disableAll,
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .numberOfDisabledPerson,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "numberOfDisabledPerson",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.numberOfDisabledPerson",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.useField("ContactName") ||
                                        _vm.useField("ContactPhoneNumber")
                                          ? _c("div", {
                                              staticClass: "divider",
                                            })
                                          : _vm._e(),
                                        _vm.useField("ContactName") ||
                                        _vm.useField("ContactPhoneNumber")
                                          ? _c(
                                              "div",
                                              { staticClass: "sub-title" },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        15,
                                                        "Emergency contact"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-card-collapsible",
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _vm.useField("ContactName")
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "6",
                                                          xl: "6",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        188,
                                                                        "Person to contact"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "ContactName"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                disabled:
                                                                  _vm.disableAll,
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    189,
                                                                    "Enter person to contact"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .personToContact,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "personToContact",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.personToContact",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.useField(
                                                  "ContactPhoneNumber"
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "6",
                                                          xl: "6",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-group",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "label" },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        190,
                                                                        "Person to contact phone"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.isMandatory(
                                                                  "ContactPhoneNumber"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "required",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " *"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c("b-form-input", {
                                                              class: {
                                                                "bg-color-grams-hair":
                                                                  _vm.disableAll,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                disabled:
                                                                  _vm.disableAll,
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    191,
                                                                    "Enter person to contact phone"
                                                                  ),
                                                              },
                                                              on: {
                                                                keydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    )
                                                                      return null
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.myProfile
                                                                    .personToContactPhone,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.myProfile,
                                                                      "personToContactPhone",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "myProfile.personToContactPhone",
                                                              },
                                                            }),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.personalVehicleDisplaySection &&
                            !_vm.hidePersonalVehicleSection
                              ? _c(
                                  "div",
                                  { staticClass: "card-collapsible mt-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickHeader(
                                              "personal-vehicle"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "title w-90" },
                                          [
                                            _c("span", [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "1em",
                                                    height: "1.15em",
                                                    fill: "rgba(6, 38, 62, 0.84)",
                                                    viewBox: "0 0 16 16",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1226,
                                                      "Personal vehicle"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end w-20",
                                          },
                                          [
                                            _vm.personalVehicleMandatoryCount >
                                            0
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      pill: "",
                                                      variant: "warning",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.personalVehicleMandatoryCount +
                                                          " " +
                                                          _vm.FormMSG(
                                                            2365,
                                                            "field(s) left"
                                                          )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.configAccordion[
                                              "personal-vehicle"
                                            ].isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronDown"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.configAccordion[
                                              "personal-vehicle"
                                            ].isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronUp"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    !_vm.hidePersonalVehicleSection
                                      ? _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              visible:
                                                _vm.configAccordion[
                                                  "personal-vehicle"
                                                ].isVisible,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-card-collapsible",
                                              },
                                              [
                                                _c("vehicule-form", {
                                                  attrs: {
                                                    useVehicleName:
                                                      _vm.userFieldOption
                                                        .useVehicleName,
                                                    useVehicleBrand:
                                                      _vm.userFieldOption
                                                        .useVehicleBrand,
                                                    useVehicleModel:
                                                      _vm.userFieldOption
                                                        .useVehicleModel,
                                                    useVehiclePlateNumber:
                                                      _vm.userFieldOption
                                                        .useVehiclePlateNumber,
                                                    useVehicleTransportationMean:
                                                      _vm.userFieldOption
                                                        .useVehicleTransportationMean,
                                                    useVehicleTransportationMeanDetail:
                                                      _vm.userFieldOption
                                                        .useVehicleTransportationMeanDetail,
                                                    useVehicleKgCoTwo:
                                                      _vm.userFieldOption
                                                        .useVehicleKgCoTwo,
                                                    usePower:
                                                      _vm.userFieldOption
                                                        .usePower,
                                                    useGrayCardFrontPicture:
                                                      _vm.userFieldOption
                                                        .useGrayCardFrontPicture,
                                                    useGrayCardBackPicture:
                                                      _vm.userFieldOption
                                                        .useGrayCardBackPicture,
                                                    "vehicule-data":
                                                      _vm.myProfile.vehicule,
                                                    mandatoryVehicleName:
                                                      _vm.userFieldOption
                                                        .mandatoryVehicleName,
                                                    mandatoryVehicleBrand:
                                                      _vm.userFieldOption
                                                        .mandatoryVehicleBrand,
                                                    mandatoryVehicleModel:
                                                      _vm.userFieldOption
                                                        .mandatoryVehicleModel,
                                                    mandatoryVehiclePlateNumber:
                                                      _vm.userFieldOption
                                                        .mandatoryVehiclePlateNumber,
                                                    mandatoryVehicleTransportationMean:
                                                      _vm.userFieldOption
                                                        .mandatoryVehicleTransportationMean,
                                                    mandatoryVehicleKgCoTwo:
                                                      _vm.userFieldOption
                                                        .mandatoryVehicleKgCoTwo,
                                                    mandatoryPower:
                                                      _vm.userFieldOption
                                                        .mandatoryPower,
                                                    mandatoryGrayCardFrontPicture:
                                                      _vm.userFieldOption
                                                        .mandatoryGrayCardFrontPicture,
                                                    mandatoryGrayCardBackPicture:
                                                      _vm.userFieldOption
                                                        .mandatoryGrayCardBackPicture,
                                                  },
                                                  on: {
                                                    "vehicule-form:change":
                                                      _vm.handleVehiculeFormChange,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.otherDisplaySection
                              ? _c(
                                  "div",
                                  { staticClass: "card-collapsible mt-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickHeader(
                                              "other"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "title w-90" },
                                          [
                                            _c("b-icon", {
                                              staticStyle: {
                                                color: "rgba(6, 38, 62, 0.84)",
                                              },
                                              attrs: { icon: "list" },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(16, "Other")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end w-20",
                                          },
                                          [
                                            _vm.otherMandatoryCount > 0
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      pill: "",
                                                      variant: "warning",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.otherMandatoryCount +
                                                          " " +
                                                          _vm.FormMSG(
                                                            2365,
                                                            "field(s) left"
                                                          )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.configAccordion["other"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronDown"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.configAccordion["other"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronUp"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          visible:
                                            _vm.configAccordion["other"]
                                              .isVisible,
                                        },
                                      },
                                      [
                                        _vm.otherDisplayPrivacyOption &&
                                        !_vm.hideInfoOther
                                          ? _c(
                                              "div",
                                              { staticClass: "sub-title" },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        17,
                                                        "Privacy options"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.otherDisplayPrivacyOption
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-card-collapsible",
                                              },
                                              [
                                                !_vm.hideInfoOther
                                                  ? _c(
                                                      "b-row",
                                                      [
                                                        _vm.useField(
                                                          "showMyEmailInCrewList"
                                                        )
                                                          ? _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "12",
                                                                  md: "12",
                                                                  lg: "3",
                                                                  xl: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pj-cb pb-1",
                                                                    attrs: {
                                                                      size: "lg",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .showMyEmailInCrew,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "showMyEmailInCrew",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.showMyEmailInCrew",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          18,
                                                                          "Show my email in crew list"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.useField(
                                                          "showMyPhoneInCrewList"
                                                        )
                                                          ? _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  sm: "12",
                                                                  md: "12",
                                                                  lg: "3",
                                                                  xl: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pj-cb pb-1",
                                                                    attrs: {
                                                                      size: "lg",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .showMyPhoneInCrew,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "showMyPhoneInCrew",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.showMyPhoneInCrew",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          19,
                                                                          "Show my phone in crew list"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.otherDisplayLoyaltyCard
                                          ? _c("div", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.hideInfoOther,
                                                  expression: "!hideInfoOther",
                                                },
                                              ],
                                              staticClass: "divider",
                                            })
                                          : _vm._e(),
                                        !_vm.hideInfoOther
                                          ? _c("div", [
                                              _vm.otherDisplayLoyaltyCard
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "sub-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1280,
                                                              "Loyalty cards"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.otherDisplayLoyaltyCard
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "content-card-collapsible",
                                                    },
                                                    [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _vm.useField(
                                                            "SncfLoyaltyCard"
                                                          )
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "12",
                                                                    md: "12",
                                                                    lg: "3",
                                                                    xl: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "label",
                                                                              fn: function () {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                              _vm._s(
                                                                                                _vm.FormMSG(
                                                                                                  1281,
                                                                                                  "SNCF loyalty card"
                                                                                                )
                                                                                              ) +
                                                                                              "\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                          ),
                                                                                          _vm.isMandatory(
                                                                                            "SncfLoyaltyCard"
                                                                                          )
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "required",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " *"
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-fin",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.FormMSG(
                                                                                                    1282,
                                                                                                    "(Type + number)"
                                                                                                  )
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          4092749997
                                                                        ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              placeholder:
                                                                                _vm.FormMSG(
                                                                                  1283,
                                                                                  "Enter your sncf loyalty card"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            keydown:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "enter",
                                                                                    13,
                                                                                    $event.key,
                                                                                    "Enter"
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                $event.preventDefault()
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .myProfile
                                                                                  .trainLoyaltyCard,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.myProfile,
                                                                                    "trainLoyaltyCard",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "myProfile.trainLoyaltyCard",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.useField(
                                                            "SncfDiscountCard"
                                                          )
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "12",
                                                                    md: "12",
                                                                    lg: "3",
                                                                    xl: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "label",
                                                                              fn: function () {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                              _vm._s(
                                                                                                _vm.FormMSG(
                                                                                                  1284,
                                                                                                  "SNCF discount card"
                                                                                                )
                                                                                              ) +
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                          ),
                                                                                          _vm.isMandatory(
                                                                                            "SncfDiscountCard"
                                                                                          )
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "required",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " *"
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-fin",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.FormMSG(
                                                                                                    1282,
                                                                                                    "(Type + number)"
                                                                                                  )
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          3542118362
                                                                        ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              placeholder:
                                                                                _vm.FormMSG(
                                                                                  1285,
                                                                                  "Enter your sncf discount card"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            keydown:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "enter",
                                                                                    13,
                                                                                    $event.key,
                                                                                    "Enter"
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                $event.preventDefault()
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .myProfile
                                                                                  .trainDiscountCard,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.myProfile,
                                                                                    "trainDiscountCard",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "myProfile.trainDiscountCard",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.useField(
                                                            "FlyingBlueCard"
                                                          )
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "12",
                                                                    md: "12",
                                                                    lg: "3",
                                                                    xl: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "label",
                                                                              fn: function () {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                              _vm._s(
                                                                                                _vm.FormMSG(
                                                                                                  1286,
                                                                                                  "Flying blue card"
                                                                                                )
                                                                                              ) +
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                          ),
                                                                                          _vm.isMandatory(
                                                                                            "FlyingBlueCard"
                                                                                          )
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "required",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " *"
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-fin",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.FormMSG(
                                                                                                    1287,
                                                                                                    "(Number)"
                                                                                                  )
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          4272390126
                                                                        ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              placeholder:
                                                                                _vm.FormMSG(
                                                                                  1288,
                                                                                  "Enter your flying blue card"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            keydown:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "enter",
                                                                                    13,
                                                                                    $event.key,
                                                                                    "Enter"
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                $event.preventDefault()
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .myProfile
                                                                                  .flyingLoyaltyCard,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.myProfile,
                                                                                    "flyingLoyaltyCard",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "myProfile.flyingLoyaltyCard",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.useField(
                                                            "OtherAirlineCard"
                                                          )
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "12",
                                                                    md: "12",
                                                                    lg: "3",
                                                                    xl: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "label",
                                                                              fn: function () {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                              _vm._s(
                                                                                                _vm.FormMSG(
                                                                                                  1289,
                                                                                                  "Other airline card"
                                                                                                )
                                                                                              ) +
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                          ),
                                                                                          _vm.isMandatory(
                                                                                            "OtherAirlineCard"
                                                                                          )
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "required",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " *"
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-fin",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.FormMSG(
                                                                                                    1287,
                                                                                                    "(Number)"
                                                                                                  )
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          3052682145
                                                                        ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              placeholder:
                                                                                _vm.FormMSG(
                                                                                  1288,
                                                                                  "Enter your other airline card"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            keydown:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "enter",
                                                                                    13,
                                                                                    $event.key,
                                                                                    "Enter"
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                $event.preventDefault()
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .myProfile
                                                                                  .otherAirlineCard,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.myProfile,
                                                                                    "otherAirlineCard",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "myProfile.otherAirlineCard",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _vm.otherDisplayForeignWorker
                                          ? _c("div", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.hideInfoOther,
                                                  expression: "!hideInfoOther",
                                                },
                                              ],
                                              staticClass: "divider",
                                            })
                                          : _vm._e(),
                                        !_vm.hideInfoOther
                                          ? _c("div", [
                                              _vm.otherDisplayForeignWorker
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "sub-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1290,
                                                              "Foreign worker"
                                                            )
                                                          )
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-fin",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1291,
                                                                "(to fill only if you're a foraign worker)"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.otherDisplayForeignWorker
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "content-card-collapsible",
                                                    },
                                                    [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _vm.useField(
                                                            "ResidencePermitNumber"
                                                          )
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "12",
                                                                    md: "12",
                                                                    lg: "3",
                                                                    xl: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    [
                                                                      _c(
                                                                        "template",
                                                                        {
                                                                          slot: "label",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.FormMSG(
                                                                                    1292,
                                                                                    "Residence permit number"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm.isMandatory(
                                                                            "ResidencePermitNumber"
                                                                          )
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "required",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " *"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              placeholder:
                                                                                _vm.FormMSG(
                                                                                  1293,
                                                                                  "Enter your residence permit number"
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            keydown:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "enter",
                                                                                    13,
                                                                                    $event.key,
                                                                                    "Enter"
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                $event.preventDefault()
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .myProfile
                                                                                  .residencePermitNumber,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.myProfile,
                                                                                    "residencePermitNumber",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "myProfile.residencePermitNumber",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    2
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.useField(
                                                            "ResidenceValidityEnd"
                                                          )
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "12",
                                                                    md: "12",
                                                                    lg: "3",
                                                                    xl: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    [
                                                                      _c(
                                                                        "template",
                                                                        {
                                                                          slot: "label",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.FormMSG(
                                                                                    1294,
                                                                                    "Residence permit validity end"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm.isMandatory(
                                                                            "ResidenceValidityEnd"
                                                                          )
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "required",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " *"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-date-picker",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              locale:
                                                                                _vm.lang,
                                                                              masks:
                                                                                _vm.masks,
                                                                              attributes:
                                                                                _vm.attrs,
                                                                            },
                                                                          on: {
                                                                            dayclick:
                                                                              (
                                                                                payload
                                                                              ) =>
                                                                                _vm.handleInputCalendar(
                                                                                  payload,
                                                                                  "residencePermitEndValidity"
                                                                                ),
                                                                          },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "default",
                                                                                  fn: function ({
                                                                                    inputValue,
                                                                                    togglePopover,
                                                                                  }) {
                                                                                    return [
                                                                                      _c(
                                                                                        "b-input-group",
                                                                                        [
                                                                                          _c(
                                                                                            "b-form-input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "mask",
                                                                                                    rawName:
                                                                                                      "v-mask",
                                                                                                    value:
                                                                                                      "##/##/####",
                                                                                                    expression:
                                                                                                      "'##/##/####'",
                                                                                                  },
                                                                                                ],
                                                                                              class:
                                                                                                {
                                                                                                  "temp-invalid":
                                                                                                    _vm.isMandatory(
                                                                                                      "ResidenceValidityEnd"
                                                                                                    ) &&
                                                                                                    !_vm.isDateValid(
                                                                                                      "residencePermitEndValidity"
                                                                                                    )[
                                                                                                      "residencePermitEndValidity"
                                                                                                    ],
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  value:
                                                                                                    inputValue,
                                                                                                  placeholder:
                                                                                                    _vm.FormMSG(
                                                                                                      8960,
                                                                                                      "DD/MM/YYYY"
                                                                                                    ),
                                                                                                  readonly:
                                                                                                    "",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return togglePopover()
                                                                                                  },
                                                                                                input:
                                                                                                  (
                                                                                                    value
                                                                                                  ) =>
                                                                                                    _vm.handleChangeDate(
                                                                                                      value,
                                                                                                      "residencePermitEndValidity"
                                                                                                    ),
                                                                                                keydown:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      !$event.type.indexOf(
                                                                                                        "key"
                                                                                                      ) &&
                                                                                                      _vm._k(
                                                                                                        $event.keyCode,
                                                                                                        "enter",
                                                                                                        13,
                                                                                                        $event.key,
                                                                                                        "Enter"
                                                                                                      )
                                                                                                    )
                                                                                                      return null
                                                                                                    $event.preventDefault()
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _c(
                                                                                            "b-input-group-append",
                                                                                            [
                                                                                              _c(
                                                                                                "b-input-group-text",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                                                  class:
                                                                                                    {
                                                                                                      "temp-invalid":
                                                                                                        _vm.isMandatory(
                                                                                                          "ResidenceValidityEnd"
                                                                                                        ) &&
                                                                                                        !_vm.isDateValid(
                                                                                                          "residencePermitEndValidity"
                                                                                                        )[
                                                                                                          "residencePermitEndValidity"
                                                                                                        ],
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return togglePopover()
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    _vm.getLucideIcon(
                                                                                                      "Calendar"
                                                                                                    ),
                                                                                                    {
                                                                                                      tag: "component",
                                                                                                      attrs:
                                                                                                        {
                                                                                                          color:
                                                                                                            "rgba(6, 38, 62, .6)",
                                                                                                          size: 18,
                                                                                                          "stroke-width": 2.25,
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              false,
                                                                              1740933335
                                                                            ),
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .myProfile
                                                                                  .residencePermitEndValidity,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.myProfile,
                                                                                    "residencePermitEndValidity",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "myProfile.residencePermitEndValidity",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    2
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.useField(
                                                            "ResidencePermit"
                                                          )
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "12",
                                                                    md: "12",
                                                                    lg: "3",
                                                                    xl: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              for: "residentPermitXid",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1296,
                                                                                "Resident permit"
                                                                              )
                                                                            ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                          _vm.isMandatory(
                                                                            "ResidencePermit"
                                                                          )
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "required",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " *"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "b-row",
                                                                        {
                                                                          staticClass:
                                                                            "ml-0",
                                                                        },
                                                                        [
                                                                          _vm.checkFile(
                                                                            _vm
                                                                              .myProfile
                                                                              .residentPermitXid
                                                                          )
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex justify-content-center align-items-center cursor-pointer",
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.onOpenUrl(
                                                                                          _vm.getImage(
                                                                                            _vm
                                                                                              .myProfile
                                                                                              .residentPermitXid,
                                                                                            "residentPermitXid"
                                                                                          )
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "b-icon",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          icon: "file-earmark-pdf-fill",
                                                                                          "font-scale":
                                                                                            "2",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _c(
                                                                                "b-img",
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "cursor-pointer",
                                                                                    attrs:
                                                                                      {
                                                                                        rounded:
                                                                                          "",
                                                                                        alt: "residentPermitXid",
                                                                                        src: _vm.getImage(
                                                                                          _vm
                                                                                            .myProfile
                                                                                            .residentPermitXid,
                                                                                          "residentPermitXid"
                                                                                        ),
                                                                                        title:
                                                                                          _vm.FormMSG(
                                                                                            254,
                                                                                            "View Image"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.showImage(
                                                                                            _vm
                                                                                              .myProfile
                                                                                              .residentPermitXid
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  "b-img",
                                                                                  _vm.imgPropSignatureId,
                                                                                  false
                                                                                )
                                                                              ),
                                                                          _c(
                                                                            "b-button",
                                                                            {
                                                                              staticClass:
                                                                                "ml-2",
                                                                              attrs:
                                                                                {
                                                                                  variant:
                                                                                    "outline-secondary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.startCapture(
                                                                                      "residentPermitXid"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex justify-content-center align-items-center",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getIconLucide(
                                                                                      "Camera"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 20,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "pl-2",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-bottom":
                                                                                            "-1px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.FormMSG(
                                                                                            220,
                                                                                            "Picture"
                                                                                          )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm
                                                                            .myProfile
                                                                            .residentPermitXid &&
                                                                          _vm
                                                                            .myProfile
                                                                            .residentPermitXid
                                                                            .length >
                                                                            0
                                                                            ? _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn-transparent pl-0 ml-2",
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        $event.preventDefault()
                                                                                        $event.stopPropagation()
                                                                                        return _vm.deleteImg(
                                                                                          "residentPermitXid"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getIconLucide(
                                                                                      "Trash2"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 24,
                                                                                          "stroke-width": 1.5,
                                                                                          color:
                                                                                            "#ea4e2c",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.useField(
                                                            "WorkCard"
                                                          )
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "12",
                                                                    md: "12",
                                                                    lg: "3",
                                                                    xl: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              for: "workCardXid",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                1297,
                                                                                "Work card"
                                                                              )
                                                                            ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                          _vm.isMandatory(
                                                                            "WorkCard"
                                                                          )
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "required",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " *"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "b-row",
                                                                        {
                                                                          staticClass:
                                                                            "ml-0",
                                                                        },
                                                                        [
                                                                          _vm.checkFile(
                                                                            _vm
                                                                              .myProfile
                                                                              .workCardXid
                                                                          )
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex justify-content-center align-items-center cursor-pointer",
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.onOpenUrl(
                                                                                          _vm.getImage(
                                                                                            _vm
                                                                                              .myProfile
                                                                                              .workCardXid,
                                                                                            "workCardXid"
                                                                                          )
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "b-icon",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          icon: "file-earmark-pdf-fill",
                                                                                          "font-scale":
                                                                                            "2",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _c(
                                                                                "b-img",
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "cursor-pointer",
                                                                                    attrs:
                                                                                      {
                                                                                        rounded:
                                                                                          "",
                                                                                        alt: "workCardXid",
                                                                                        src: _vm.getImage(
                                                                                          _vm
                                                                                            .myProfile
                                                                                            .workCardXid,
                                                                                          "workCardXid"
                                                                                        ),
                                                                                        title:
                                                                                          _vm.FormMSG(
                                                                                            254,
                                                                                            "View Image"
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.showImage(
                                                                                            _vm
                                                                                              .myProfile
                                                                                              .workCardXid
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  "b-img",
                                                                                  _vm.imgPropSignatureId,
                                                                                  false
                                                                                )
                                                                              ),
                                                                          _c(
                                                                            "b-button",
                                                                            {
                                                                              staticClass:
                                                                                "ml-2",
                                                                              attrs:
                                                                                {
                                                                                  variant:
                                                                                    "outline-secondary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.startCapture(
                                                                                      "workCardXid"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex justify-content-center align-items-center",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getIconLucide(
                                                                                      "Camera"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 20,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "pl-2",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-bottom":
                                                                                            "-1px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.FormMSG(
                                                                                            220,
                                                                                            "Picture"
                                                                                          )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm
                                                                            .myProfile
                                                                            .workCardXid &&
                                                                          _vm
                                                                            .myProfile
                                                                            .workCardXid
                                                                            .length >
                                                                            0
                                                                            ? _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn-transparent pl-0 ml-2",
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        $event.preventDefault()
                                                                                        $event.stopPropagation()
                                                                                        return _vm.deleteImg(
                                                                                          "workCardXid"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getIconLucide(
                                                                                      "Trash2"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 24,
                                                                                          "stroke-width": 1.5,
                                                                                          color:
                                                                                            "#ea4e2c",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _vm.otherDisplayMiscellanous
                                          ? _c("div", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.hideInfoOther,
                                                  expression: "!hideInfoOther",
                                                },
                                              ],
                                              staticClass: "divider",
                                            })
                                          : _vm._e(),
                                        !_vm.hideInfoOther
                                          ? _c("div", [
                                              !_vm.hideInfoOther &&
                                              _vm.otherDisplayMiscellanous
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "sub-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              24,
                                                              "Miscellanous"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        !_vm.hideInfoOther
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-card-collapsible",
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField("Pseudonym")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              1298,
                                                                              "Pseudonym"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Pseudonym"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          1299,
                                                                          "Enter your pseudonym"
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .pseudonym,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "pseudonym",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.pseudonym",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField(
                                                      "GenericMentionProfessionalAssociation"
                                                    )
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              1300,
                                                                              "Generic mention professional association"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "GenericMentionProfessionalAssociation"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          1301,
                                                                          "Enter your generic mention ..."
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .professionalAssociation,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "professionalAssociation",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.professionalAssociation",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.hideInfoOther &&
                                        _vm.useField("Retired")
                                          ? _c("div", {
                                              staticClass: "divider",
                                            })
                                          : _vm._e(),
                                        !_vm.hideInfoOther &&
                                        _vm.useField("Retired")
                                          ? _c(
                                              "div",
                                              { staticClass: "sub-title" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      1705,
                                                      "Retirement"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.hideInfoOther &&
                                        _vm.useField("Retired")
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-card-collapsible",
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField("Retired")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-start",
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              {
                                                                staticClass:
                                                                  "text-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              1275,
                                                                              "Retired ?"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Retired"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-content-start",
                                                                    attrs: {
                                                                      size: "lg",
                                                                      value: true,
                                                                      switch:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.handleChangeRetired,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .retired,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "retired",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.retired",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-redired",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .myProfile
                                                                              .retired
                                                                              ? _vm.FormMSG(
                                                                                  1276,
                                                                                  "Yes"
                                                                                )
                                                                              : _vm.FormMSG(
                                                                                  1277,
                                                                                  "No"
                                                                                )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.myProfile.retired
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        1278,
                                                                        "Retirement date"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "v-date-picker",
                                                                  {
                                                                    attrs: {
                                                                      locale:
                                                                        _vm.lang,
                                                                      masks:
                                                                        _vm.masks,
                                                                      attributes:
                                                                        _vm.attrs,
                                                                    },
                                                                    on: {
                                                                      dayclick:
                                                                        (
                                                                          payload
                                                                        ) =>
                                                                          _vm.handleInputCalendar(
                                                                            payload,
                                                                            "retirementDate"
                                                                          ),
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "default",
                                                                            fn: function ({
                                                                              inputValue,
                                                                              togglePopover,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "b-input-group",
                                                                                  [
                                                                                    _c(
                                                                                      "b-form-input",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "mask",
                                                                                              rawName:
                                                                                                "v-mask",
                                                                                              value:
                                                                                                "##/##/####",
                                                                                              expression:
                                                                                                "'##/##/####'",
                                                                                            },
                                                                                          ],
                                                                                        class:
                                                                                          {
                                                                                            "temp-invalid":
                                                                                              !_vm.isDateValid(
                                                                                                "retirementDate"
                                                                                              )[
                                                                                                "retirementDate"
                                                                                              ],
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            value:
                                                                                              inputValue,
                                                                                            placeholder:
                                                                                              _vm.FormMSG(
                                                                                                8960,
                                                                                                "DD/MM/YYYY"
                                                                                              ),
                                                                                            readonly:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return togglePopover()
                                                                                            },
                                                                                          input:
                                                                                            (
                                                                                              value
                                                                                            ) =>
                                                                                              _vm.handleChangeDate(
                                                                                                value,
                                                                                                "retirementDate"
                                                                                              ),
                                                                                          keydown:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              if (
                                                                                                !$event.type.indexOf(
                                                                                                  "key"
                                                                                                ) &&
                                                                                                _vm._k(
                                                                                                  $event.keyCode,
                                                                                                  "enter",
                                                                                                  13,
                                                                                                  $event.key,
                                                                                                  "Enter"
                                                                                                )
                                                                                              )
                                                                                                return null
                                                                                              $event.preventDefault()
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "b-input-group-append",
                                                                                      [
                                                                                        _c(
                                                                                          "b-input-group-text",
                                                                                          {
                                                                                            staticClass:
                                                                                              "cursor-pointer bg-color-white input-group-text-bg-white",
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return togglePopover()
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              _vm.getLucideIcon(
                                                                                                "Calendar"
                                                                                              ),
                                                                                              {
                                                                                                tag: "component",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "rgba(6, 38, 62, .6)",
                                                                                                    size: 18,
                                                                                                    "stroke-width": 2.25,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        898321992
                                                                      ),
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .retirementDate,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "retirementDate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.retirementDate",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.hideInfoOther &&
                                        _vm.useField("StudyLevel")
                                          ? _c("div", {
                                              staticClass: "divider",
                                            })
                                          : _vm._e(),
                                        _vm.useField("StudyLevel")
                                          ? _c(
                                              "div",
                                              { staticClass: "sub-title" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(1706, "Study")
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.useField("StudyLevel")
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-card-collapsible",
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField("StudyLevel")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "4",
                                                              xl: "4",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    _vm.FormMSG(
                                                                      1587,
                                                                      "Are you currently a student ?"
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      switch:
                                                                        "",
                                                                      size: "lg",
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .studyCurrently,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "studyCurrently",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.studyCurrently",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("StudyLevel")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              262,
                                                                              "Study level"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "StudyLevel"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-select",
                                                                  {
                                                                    attrs: {
                                                                      options:
                                                                        _vm.studyLeveOptions,
                                                                      disabled:
                                                                        _vm.disableAll,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .studyLevel,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "studyLevel",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.studyLevel",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.hideInfoOther &&
                                        _vm.otherDisplayClothes
                                          ? _c("div", {
                                              staticClass: "divider",
                                            })
                                          : _vm._e(),
                                        !_vm.hideInfoOther &&
                                        _vm.otherDisplayClothes
                                          ? _c(
                                              "div",
                                              { staticClass: "sub-title" },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1707,
                                                        "Clothes"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.hideInfoOther
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-card-collapsible",
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField("Size")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              263,
                                                                              "Size"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "Size"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          267,
                                                                          "Enter your size ..."
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .size,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "size",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.size",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("PantSize")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              264,
                                                                              "Pant size"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "PantSize"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          268,
                                                                          "Enter your pant size ..."
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .pantSize,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "pantSize",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.pantSize",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("JacketSize")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              265,
                                                                              "Jacket size"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "JacketSize"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          269,
                                                                          "Enter your jacket size ..."
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .jacketSize,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "jacketSize",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.jacketSize",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.useField("ShoeSize")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              27,
                                                                              "Shoe size"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "ShoeSize"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          28,
                                                                          "Enter your shoe size ..."
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .shoeSize,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "shoeSize",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.shoeSize",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.useField("TshirtSize")
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "12",
                                                              lg: "3",
                                                              xl: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "label",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              29,
                                                                              "Teeshirt size"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isMandatory(
                                                                      "TshirtSize"
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "required",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " *"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      type: "text",
                                                                      placeholder:
                                                                        _vm.FormMSG(
                                                                          30,
                                                                          "Enter your teeshirt size ..."
                                                                        ),
                                                                    },
                                                                    on: {
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            !$event.type.indexOf(
                                                                              "key"
                                                                            ) &&
                                                                            _vm._k(
                                                                              $event.keyCode,
                                                                              "enter",
                                                                              13,
                                                                              $event.key,
                                                                              "Enter"
                                                                            )
                                                                          )
                                                                            return null
                                                                          $event.preventDefault()
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .myProfile
                                                                          .tShirtSize,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.myProfile,
                                                                            "tShirtSize",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "myProfile.tShirtSize",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isEditable
                                          ? _c("div", [
                                              _c("div", {
                                                staticClass: "divider",
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "sub-title" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        1714,
                                                        "Custom fields"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "content-card-collapsible",
                                                },
                                                [
                                                  _c(
                                                    "b-row",
                                                    { staticClass: "pb-2" },
                                                    _vm._l(
                                                      _vm.customFields,
                                                      function (
                                                        customField,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "b-col",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "d-flex mb-2 w-100",
                                                            attrs: {
                                                              xl: _vm.sizeFlagInput(
                                                                _vm.customFields
                                                                  .length
                                                              ),
                                                              lg: _vm.sizeFlagInput(
                                                                _vm.customFields
                                                                  .length
                                                              ),
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "custom-field-template",
                                                              {
                                                                staticClass:
                                                                  "w-100",
                                                                attrs: {
                                                                  field:
                                                                    customField,
                                                                  "name-model":
                                                                    _vm
                                                                      .customFieldModels[
                                                                      index
                                                                    ],
                                                                  "name-group":
                                                                    customField.nameGroup,
                                                                  index: index,
                                                                },
                                                                on: {
                                                                  "custom-field-template:payload":
                                                                    _vm.onFieldTemplateChanged,
                                                                },
                                                                model: {
                                                                  value:
                                                                    customField[
                                                                      _vm
                                                                        .customFieldModels[
                                                                        index
                                                                      ]
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        customField,
                                                                        _vm
                                                                          .customFieldModels[
                                                                          index
                                                                        ],
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "customField[customFieldModels[index]]",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.hidePasswordSection
                              ? _c(
                                  "div",
                                  { staticClass: "card-collapsible mt-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickHeader(
                                              "password"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "title w-90" },
                                          [
                                            _c("b-icon", {
                                              staticStyle: {
                                                color: "rgba(6, 38, 62, 0.84)",
                                              },
                                              attrs: { icon: "key-fill" },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(31, "Password")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end w-10",
                                          },
                                          [
                                            !_vm.configAccordion["password"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronDown"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.configAccordion["password"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronUp"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    !_vm.hidePasswordSection
                                      ? _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              visible:
                                                _vm.configAccordion["password"]
                                                  .isVisible,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-card-collapsible",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t*\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            107,
                                                            "Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter."
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant:
                                                        "outline-primary",
                                                      disabled:
                                                        _vm
                                                          .statusSendCodeValidation
                                                          .global,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleChangePassword,
                                                    },
                                                  },
                                                  [
                                                    !_vm
                                                      .statusSendCodeValidation
                                                      .progress &&
                                                    !_vm
                                                      .statusSendCodeValidation
                                                      .success
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  22,
                                                                  "Change Password"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm.statusSendCodeValidation
                                                      .progress &&
                                                    !_vm
                                                      .statusSendCodeValidation
                                                      .success
                                                      ? _c(
                                                          "span",
                                                          [
                                                            _c("b-spinner", {
                                                              attrs: {
                                                                label:
                                                                  "Loading...",
                                                                small: "",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    146,
                                                                    "Send code validation"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    !_vm
                                                      .statusSendCodeValidation
                                                      .progress &&
                                                    _vm.statusSendCodeValidation
                                                      .success
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  147,
                                                                  "Code validation sended"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isDeterProject === true
                              ? _c(
                                  "div",
                                  { staticClass: "card-collapsible mt-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickHeader(
                                              "deter"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "title w-90" },
                                          [
                                            _c(_vm.getLucideIcon("UserCog"), {
                                              tag: "component",
                                              attrs: { size: 20 },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      131,
                                                      "Team session"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end w-10",
                                          },
                                          [
                                            !_vm.configAccordion["deter"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronDown"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.configAccordion["deter"]
                                              .isVisible
                                              ? _c(
                                                  _vm.getIconLucide(
                                                    "ChevronUp"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#06263E",
                                                      size: 22,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          visible:
                                            _vm.configAccordion["deter"]
                                              .isVisible,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "content-card-collapsible",
                                          },
                                          [
                                            _c(
                                              "b-table-simple",
                                              {
                                                attrs: {
                                                  id: "deterSessionTeamTable",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-thead",
                                                  {
                                                    attrs: {
                                                      "head-variant": "dark",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-tr",
                                                      [
                                                        _c("b-th"),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                457,
                                                                "Session 1"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                458,
                                                                "Session 2"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                459,
                                                                "Session 3"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                460,
                                                                "Session 4"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                461,
                                                                "Session 5"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                462,
                                                                "Session 6"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                463,
                                                                "Session 7"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                464,
                                                                "Session 8"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-tbody",
                                                  [
                                                    _c(
                                                      "b-tr",
                                                      [
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                465,
                                                                "Team A"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session1A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session1A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session1A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session2A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session2A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session2A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session3A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session3A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session3A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session4A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session4A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session4A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session5A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session5A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session5A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session6A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session6A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session6A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session7A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session7A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session7A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session8A,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session8A",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session8A",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-tr",
                                                      [
                                                        _c("b-th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                466,
                                                                "Team B"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session1B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session1B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session1B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session2B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session2B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session2B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session3B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session3B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session3B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session4B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session4B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session4B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session5B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session5B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session5B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session6B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session6B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session6B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session7B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session7B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session7B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-td",
                                                          [
                                                            _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "lg",
                                                                  value: true,
                                                                  "unchecked-value": false,
                                                                  disabled: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .myProfile
                                                                      .session8B,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.myProfile,
                                                                        "session8B",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "myProfile.session8B",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.hideBtnSave
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mt-4 px-2 d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mr-1 w-200-px",
                                        staticStyle: {
                                          "font-size": "1.1rem",
                                          "letter-spacing": "0.05rem",
                                        },
                                        attrs: {
                                          type: "submit",
                                          variant: "primary",
                                          size: "md",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.FormMSG(23, "Save my changes")
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("NewCapture", {
                    key: _vm.refreshTokenCaptureItem,
                    attrs: {
                      parentType: "user",
                      parentId: _vm.myProfile.id,
                      "auto-crop-capture": "",
                      "cam-mode-first": "",
                      "only-one-crop-mode": "",
                    },
                    on: { change: _vm.handleImgsTaked },
                    model: {
                      value: _vm.capturePict,
                      callback: function ($$v) {
                        _vm.capturePict = $$v
                      },
                      expression: "capturePict",
                    },
                  }),
                  _c(
                    "b-modal",
                    {
                      staticClass: "modal-warning",
                      attrs: {
                        "header-class": "header-class-modal-doc-package",
                        title: _vm.FormMSG(8979800, "Success!"),
                        "ok-variant": "success",
                        "ok-only": "",
                        "cancel-title": "close",
                      },
                      on: {
                        ok: function ($event) {
                          _vm.successModal = false
                        },
                      },
                      model: {
                        value: _vm.successModal,
                        callback: function ($$v) {
                          _vm.successModal = $$v
                        },
                        expression: "successModal",
                      },
                    },
                    [
                      _c("h6", [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                8979799,
                                "Your information has been saved."
                              )
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      staticClass: "modal-warning",
                      attrs: {
                        "header-class": "header-class-modal-doc-package",
                        title: _vm.FormMSG(8979801, "Success with warning!"),
                        "header-bg-variant": "danger",
                        "header-border-variant": "danger",
                        "ok-variant": "danger",
                        "ok-only": "",
                        "cancel-title": "close",
                      },
                      on: {
                        ok: function ($event) {
                          _vm.warningModal = false
                        },
                      },
                      model: {
                        value: _vm.warningModal,
                        callback: function ($$v) {
                          _vm.warningModal = $$v
                        },
                        expression: "warningModal",
                      },
                    },
                    [
                      _c("h6", [
                        _vm._v(
                          _vm._s(
                            _vm.FormMSG(
                              12205,
                              "Profile updated successfully, but some fields are still incomplete. Please consider filling them"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
      ]),
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: "Crop Picture",
            size: "cropSize",
            "ok-variant": "success",
            "ok-title": "Confirm Save",
            "cancel-title": "Cancel",
          },
          on: { ok: _vm.endCrop, cancel: _vm.endCropCancel },
          model: {
            value: _vm.cropPict,
            callback: function ($$v) {
              _vm.cropPict = $$v
            },
            expression: "cropPict",
          },
        },
        [_c("CropPicture", { ref: "myCropPicture" })],
        1
      ),
      _c("change-mail-modal", {
        attrs: { open: _vm.showChangeMailModal, profile: _vm.myProfile },
        on: {
          "modal:close": function ($event) {
            _vm.showChangeMailModal = false
          },
          "change-mail-modal:save": _vm.handleMailModalSave,
        },
      }),
      _c("change-phone-modal", {
        attrs: { open: _vm.showChangePhoneModal, profile: _vm.myProfile },
        on: {
          "modal:close": function ($event) {
            _vm.showChangePhoneModal = false
          },
          "change-phone-modal:save": _vm.handlePhoneModalSave,
        },
      }),
      _c("validation-code-modal", {
        attrs: {
          open: _vm.showValidationCodeModal,
          "email-for-validation": _vm.myProfile.email,
        },
        on: {
          "validation-code-modal:save": _vm.handleValidationCodeModalSave,
          "modal:close": _vm.handleValidationCodeModalClose,
        },
      }),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(253, "Picture"),
            "ok-variant": "success",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.openPictureModal = false
            },
          },
          model: {
            value: _vm.openPictureModal,
            callback: function ($$v) {
              _vm.openPictureModal = $$v
            },
            expression: "openPictureModal",
          },
        },
        [
          _c(
            "div",
            [_c("b-img", { attrs: { src: _vm.imgToShow, "fluid-grow": "" } })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }