<template>
	<errorContainer :error="erreur">
		<transition name="slide">
			<div v-if="$screen.width <= 576">
				<!-- <h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ FormMSG(10, 'My Profile') }}</h1> -->
				<div class="container-mobile animated fadeIn pl-0 pr-0" style="top: 95px">
					<div class="container-myProfile-mobile">
						<div v-if="!allMandatoryIsFilled" class="alert alert-warning">
							{{ FormMSG(55055, 'Field with * are mandatory field you must fill. Some field are still empty.') }}
						</div>
						<b-row>
							<b-col v-if="menuActiveForMobile !== ''" :cols="!isFilmSingle ? 12 : 8" @click="menuActiveForMobile = ''" class="px-0">
								<div class="back-with-title cursor-pointer" :class="{ 'mt-16': $isPwa() }">
									<div class="icon">
										<component :is="getIconLucide('ArrowLeft')" color="#06263E" :size="22" />
									</div>
									<h1>{{ pageMobileTitle }}</h1>
								</div>
							</b-col>
							<b-col cols="4" :offset="menuActiveForMobile === '' ? 4 : 0">
								<div class="float-right">
									<ShareInformation
										v-if="isFilmSingle"
										tag="is-lucide"
										:btn-label="FormMSG(215, 'Share')"
										variant-btn="light"
										:title-modal="FormMSG(170, 'Send your personal informations sheet')"
										send-a-copy
										:msg-success="FormMSG(250, 'Profile successfully sent')"
										style="margin: 16px 16px 16px 0"
									/>
								</div>
							</b-col>
						</b-row>
						<form class="form" @submit="submitForm">
							<div
								v-if="menuActiveForMobile === ''"
								:class="{
									'mt-3': menuActiveForMobile === ''
								}"
							>
								<list-menu
									:mandatory-count="personalDataMandatoryCount"
									icon-lucide="User"
									:title="FormMSG(1211, 'Personal Data')"
									:description="FormMSG(1212, 'Update your basic information, address or emergency contact.')"
									path="personal-data"
									@list-menu:click="handleMenuMobileClick"
								/>
								<list-menu
									:mandatory-count="identificationMandatoryCount"
									v-if="
										useField('NationalRegistrationNumber') ||
										useField('CompanyNumber') ||
										useField('CongesSpectacleId') ||
										useField('SignaturePicture') ||
										useField('IdName') ||
										useField('IdNumber') ||
										useField('IdPictureFront') ||
										useField('IdPictureBack') ||
										useField('DriverLicense') ||
										useField('DriverLicenseNumber') ||
										useField('DriverLicensePictureFront') ||
										useField('DriverLicensePictureBack')
									"
									icon-lucide="ClipboardList"
									:title="FormMSG(4, 'Identification')"
									:description="FormMSG(1214, 'Update your identification numbers, bank information or family composition')"
									path="legal-information"
									@list-menu:click="handleMenuMobileClick"
								/>
								<!--Finance-->
								<list-menu
									:mandatory-count="financeMandatoryCount"
									icon-lucide="Wallet"
									:title="FormMSG(1306, 'Finance')"
									path="finance"
									@list-menu:click="handleMenuMobileClick"
									v-if="financeDisplaySection"
								/>
								<!--Address-->
								<list-menu
									:mandatory-count="addressMandatoryCount"
									icon-lucide="MapPin"
									:title="FormMSG(11, 'Address')"
									path="address"
									@list-menu:click="handleMenuMobileClick"
									v-if="addressDisplaySection"
								/>

								<!--health-->
								<list-menu
									:mandatory-count="healthMandatoryCount"
									icon-lucide="HeartPulse"
									:title="FormMSG(1252, 'Health')"
									path="health"
									@list-menu:click="handleMenuMobileClick"
									v-if="healthDisplaySection"
								/>
								<!--Family-->
								<list-menu
									:mandatory-count="familyMandatoryCount"
									icon-lucide="Users"
									:title="FormMSG(13, 'Family')"
									path="family"
									@list-menu:click="handleMenuMobileClick"
									v-if="familyDisplaySection"
								/>
								<!--Personal vehicle-->
								<list-menu
									:mandatory-count="personalVehicleMandatoryCount"
									v-if="personalVehicleDisplaySection"
									icon-lucide="Car"
									:title="FormMSG(1226, 'Personal vehicle')"
									path="personal-vehicle"
									@list-menu:click="handleMenuMobileClick"
								/>
								<!--Other-->
								<list-menu
									:mandatory-count="otherMandatoryCount"
									icon-lucide="List"
									:title="FormMSG(16, 'Other')"
									path="other"
									@list-menu:click="handleMenuMobileClick"
									v-if="otherDisplaySection"
								/>
								<!--Setting-->
								<list-menu
									icon-lucide="Settings"
									:title="FormMSG(1215, 'Settings')"
									:description="FormMSG(1216, 'Change your password and communication language.')"
									path="settings"
									@list-menu:click="handleMenuMobileClick"
								/>
								<list-menu
									v-if="isDeterProject === true"
									icon-lucide="UserCog"
									:title="FormMSG(131, 'Team session')"
									:description="FormMSG(1257, 'Configure your team session.')"
									path="team-session"
									@list-menu:click="handleMenuMobileClick"
								/>
							</div>
							<!--Address navigate-->
							<div v-if="menuActiveForMobile === 'personal-data'">
								<div class="personal-data">
									<h2>{{ FormMSG(1182, 'Basic Information') }}</h2>
									<b-row>
										<b-col v-if="useField('Title')" cols="6">
											<b-form-group>
												<template slot="label">
													<label>{{ FormMSG(1240, 'Title') }}</label>
													<span class="required" v-if="isMandatory('Title')"> *</span>
												</template>
												<b-form-select v-model="myProfile.title">
													<b-form-select-option v-for="(title, index) in menuTitle" :key="index" :value="title.value">
														{{ title.text }}
													</b-form-select-option>
												</b-form-select>
											</b-form-group>
										</b-col>
										<b-col v-if="useField('PersonalNumber')" cols="6">
											<b-form-group>
												<template slot="label">
													<label for="reference">{{ this.FormMSG(1, 'Reference') }}</label>
													<span class="required" v-if="isMandatory('PersonalNumber')"> *</span>
												</template>
												<b-form-input
													v-model="myProfile.reference"
													type="text"
													id="reference"
													:placeholder="FormMSG(1704, 'Personal number.')"
													@keydown.enter.prevent
													disabled
												></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="7">
											<b-form-group v-if="useField('FirstName')">
												<template slot="label">
													<label>{{ FormMSG(1041, 'Name') }}</label>
													<span class="required" v-if="isMandatory('FirstName')"> *</span>
												</template>
												<b-form-input v-model="myProfile.firstName" :placeholder="FormMSG(1003, 'Enter Name')" @keydown.enter.prevent />
											</b-form-group>
											<b-form-group v-if="useField('LastName')">
												<template slot="label">
													<label>{{ FormMSG(40, 'Last Name') }}</label>
													<span class="required" v-if="isMandatory('LastName')"> *</span>
												</template>
												<b-form-input v-model="myProfile.name" :placeholder="FormMSG(1143, 'Enter Last Name')" @keydown.enter.prevent />
											</b-form-group>
											<b-form-group v-if="useField('MaidenName')">
												<template slot="label">
													<label for="maidenName">{{ FormMSG(1239, ' Maiden name (if different)') }}</label>
													<span class="required" v-if="isMandatory('MaidenName')"> *</span>
												</template>
												<b-form-input v-model="myProfile.maidenName" type="text" id="maidenName" @keydown.enter.prevent></b-form-input>
											</b-form-group>
										</b-col>
										<b-col v-if="useField('ProfilePicture')" cols="5">
											<div style="height: 100px; width: 100px; margin-left: auto; margin-right: auto">
												<div class="container-img-mobile" style="height: 100%; width: 100%">
													<span style="position: absolute" class="required" v-if="isMandatory('ProfilePicture')"> *</span>
													<div v-if="myProfile.picture && myProfile.picture.length > 0" style="height: 100%; width: 100%">
														<img style="width: 96px; height: 96px" :src="getImage(myProfile.picture, 'picture')" />
														<component
															:is="getIconLucide('XCircle')"
															:size="25"
															style="top: -33px; right: 0px"
															:stroke-width="1.75"
															color="#ea4e2c"
															class="cursor-pointer icon-remove-img-profile"
															id="tooltip-remove-picture"
															@click="deleteImg('picture')"
															v-if="myProfile.picture && myProfile.picture.length > 0"
														/>
														<b-tooltip
															target="tooltip-remove-picture"
															triggers="hover"
															v-if="myProfile.picture && myProfile.picture.length > 0"
														>
															{{ FormMSG(255, 'Remove picture') }}
														</b-tooltip>
													</div>
													<div v-else class="avatar">
														<component
															:is="getIconLucide('User')"
															fill="#E2E6EB"
															color="#E2E6EB"
															:size="45"
															class="vertical-center"
														/>
													</div>
													<span class="required" v-if="isMandatory('ProfilePicture')"> *</span>
													<MobileProfilePicCaptureBtn
														parent-type="user"
														crop-type="avatar"
														:parentId="myProfile.id"
														@click="changeFieldPic('picture')"
														@change="handleImgsTaked"
													/>
												</div>
											</div>
											<div>
												<fieldset v-if="useTask && !isFilmSingle" class="scheduler-border">
													<legend class="scheduler-border">
														{{ FormMSG(525, 'Color') }}
														<span v-if="isMandatory('Color')" class="required"> * </span>
													</legend>
													<b-row>
														<b-col cols="12" class="text-center">
															<verte v-model="myProfile.color" picker="square" model="hex" menuPosition="top" />
														</b-col>
													</b-row>
												</fieldset>
											</div>
										</b-col>
									</b-row>
									<b-form-group v-if="useField('Email')">
										<template slot="label">
											<label for="email">{{ FormMSG(42, 'Email') }}</label>
											<span class="required" v-if="isMandatory('Email')"> *</span>
										</template>
										<b-input-group>
											<b-form-input
												:state="myProfile.email && myProfile.email.length > 0"
												:value="myProfile.email"
												type="text"
												id="email"
												:placeholder="FormMSG(1102, 'Enter your email')"
												@keydown.enter.prevent
												disabled
											/>
											<template #append>
												<b-button variant="primary" @click="showChangeMailModal = true">
													<Pencil color="#ffffff" :size="16" />
												</b-button>
											</template>
										</b-input-group>
									</b-form-group>
									<b-form-group v-if="useField('Phone')">
										<template slot="label">
											<label for="phone">{{ FormMSG(44, 'Phone') }}</label>
											<span class="required" v-if="isMandatory('Phone')"> *</span>
										</template>
										<b-input-group>
											<b-form-input
												:value="myProfile.phone"
												type="text"
												id="phone"
												@keydown.enter.prevent
												disabled
												:placeholder="FormMSG(45, 'Enter your phone number')"
											/>
											<template #append>
												<b-button variant="primary" @click="showChangePhoneModal = true">
													<Pencil color="#ffffff" :size="16" />
												</b-button>
											</template>
										</b-input-group>
									</b-form-group>
									<b-row>
										<b-col v-if="useField('Nationality')" cols="6">
											<b-form-group>
												<template slot="label">
													<label>{{ FormMSG(46, 'Nationality') }}</label>
													<span class="required" v-if="isMandatory('Nationality')"> *</span>
												</template>
												<b-form-input
													v-model="myProfile.nationality"
													:placeholder="FormMSG(46, 'Nationality')"
													@keydown.enter.prevent
												/>
											</b-form-group>
										</b-col>
										<b-col v-if="useField('Language')" cols="6">
											<b-form-group>
												<template slot="label">
													<label>{{ FormMSG(1305, 'Language') }}</label>
													<span class="required" v-if="isMandatory('Language')"> *</span>
												</template>
												<b-form-select v-model="myProfile.language" :options="optionsForLanguages" />
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col v-if="useField('Birthdate')" cols="6">
											<b-form-group>
												<template slot="label">
													<label>{{ FormMSG(48, 'Birthdate') }}</label>
													<span class="required" v-if="isMandatory('BirthDate')"> *</span>
												</template>
												<v-date-picker
													v-model="myProfile.birthDate"
													:locale="lang"
													:masks="masks"
													:attributes="attrs"
													@dayclick="(payload) => handleInputCalendar(payload, 'birthDate')"
												>
													<template v-slot="{ inputValue, togglePopover }">
														<b-input-group>
															<b-form-input
																:value="inputValue"
																:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
																v-mask="'##/##/####'"
																readonly
																:class="{
																	'temp-invalid': isMandatory('Birthdate') && !isDateValid('birthDate')['birthDate']
																}"
																@click="togglePopover()"
																@input="(value) => handleChangeDate(value, 'birthDate')"
																@keydown.enter.prevent
															/>
															<b-input-group-append>
																<b-input-group-text
																	class="cursor-pointer bg-color-white input-group-text-bg-white"
																	@click="togglePopover()"
																	:class="{
																		'temp-invalid': isMandatory('Birthdate') && !isDateValid('birthDate')['birthDate']
																	}"
																>
																	<component
																		:is="getLucideIcon('Calendar')"
																		color="rgba(6, 38, 62, .6)"
																		:size="18"
																		:stroke-width="2.25"
																	/>
																</b-input-group-text>
															</b-input-group-append>
														</b-input-group>
														<!-- <div v-if="isMandatory('Birthdate') && !isDateValid('birthDate')['birthDate']" class="invalid-feedback">
															{{ FormMSG(897, 'Birthdate is required') }}
														</div> -->
													</template>
												</v-date-picker>
											</b-form-group>
										</b-col>
										<b-col v-if="useField('CountryOfBirth')" cols="6">
											<b-form-group>
												<template slot="label">
													<label>{{ FormMSG(1241, 'Country of birth') }}</label>
													<span class="required" v-if="isMandatory('CountryOfBirth')"> *</span>
												</template>
												<b-form-input
													v-model="myProfile.birthCountry"
													:placeholder="FormMSG(1315, 'Enter your country of ...')"
													@keydown.enter.prevent
												/>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col v-if="useField('CityOfBirthZip')" cols="6">
											<b-form-group>
												<template slot="label">
													<label>{{ FormMSG(1152, 'City of Birth') }}</label>
													<span class="required" v-if="isMandatory('PlaceOfBirth')"> *</span>
												</template>
												<b-form-input
													v-model="myProfile.placeOfBirth"
													:placeholder="FormMSG(1153, 'Enter City of Birth')"
													@keydown.enter.prevent
												/>
											</b-form-group>
										</b-col>
										<b-col v-if="useField('CityOfBirthZip')" cols="6">
											<b-form-group>
												<template slot="label">
													<label>{{ FormMSG(1154, 'Zip Code (City of Birth)') }}</label>
													<span class="required" v-if="isMandatory('CityOfBirthZip')"> *</span>
												</template>
												<b-form-input
													v-model="myProfile.placeOfBirthZip"
													:placeholder="FormMSG(1155, 'Enter Zip Code')"
													@keydown.enter.prevent
												/>
											</b-form-group>
										</b-col>
									</b-row>
									<b-form-group v-if="useField('Gender')" v-slot="{ ariaDescribedby }" class="gender-group">
										<template slot="label">
											<label>{{ FormMSG(164, 'Gender') }}</label>
											<span class="required" v-if="isMandatory('Gender')"> *</span>
										</template>

										<b-form-radio-group v-model="myProfile.gender" :aria-describedby="ariaDescribedby">
											<div style="display: flex" v-for="(gender, index) in menuGenders" :key="index">
												<b-form-radio :value="gender.value" v-if="gender.value !== null">
													{{ gender.text }}
												</b-form-radio>
											</div>
										</b-form-radio-group>
									</b-form-group>
									<!--end of personal data-->
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'legal-information'">
								<div class="legal-information">
									<h2>{{ FormMSG(1187, 'Identification Numbers') }}</h2>
									<b-form-group v-if="useField('NationalRegistrationNumber')">
										<template slot="label">
											<label>{{ FormMSG(55, 'National Registration Number') }}</label>
											<span class="required" v-if="isMandatory('NationalRegistrationNumber')"> *</span>
										</template>
										<InputMask
											class="form-control"
											v-model="myProfile.nationalRegistryNumber"
											mask="99.99.99-999-99"
											:class="{ 'bg-color-grams-hair': disableAll }"
											:placeholder="FormMSG(56, 'Enter your National Registration Number ')"
										/>
									</b-form-group>
									<b-form-group v-if="useField('CompanyNumber')">
										<template slot="label">
											<label>{{ FormMSG(57, 'Company Number') }}</label>
											<span class="required" v-if="isMandatory('CompanyNumber')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.companyNumber"
											:placeholder="FormMSG(58, 'Enter your Company official Number ')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group v-if="useField('CongesSpectacleId')">
										<template slot="label">
											<label for="conge_spectacles_id">{{ FormMSG(1242, '"Congés spectacles" ID') }}</label>
											<span class="required" v-if="isMandatory('CongesSpectacleId')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.congesSpectacle"
											type="text"
											id="conge_spectacles_id"
											:placeholder="FormMSG(1243, 'Enter your conges spectacles id')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group v-if="useField('IdName')">
										<template slot="label">
											<label for="idName">{{ FormMSG(216, 'ID Name') }}</label>
											<span class="required" v-if="isMandatory('IdName')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.idName"
											type="text"
											id="idName"
											:placeholder="FormMSG(217, 'Enter your ID Name ...')"
											@keydown.enter.prevent
										></b-form-input>
									</b-form-group>
									<b-form-group v-if="useField('IdNumber')">
										<template slot="label">
											<label for="idNumber">{{ FormMSG(32, 'ID Number') }}</label>
											<span class="required" v-if="isMandatory('IdNumber')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.idNumber"
											type="text"
											id="idNumber"
											:placeholder="FormMSG(33, 'Enter your ID Number ...')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group v-if="useField('DriverLicense')">
										<template slot="label">
											<label>{{ FormMSG(166, 'Driver licence') }}</label>
											<span class="required" v-if="isMandatory('DriverLicense')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.driverLicense"
											:placeholder="FormMSG(167, 'Enter your driver license')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group v-if="useField('DriverLicenseNumber')">
										<template slot="label">
											<label>{{ FormMSG(1314, 'Driver licence Number') }}</label>
											<span class="required" v-if="isMandatory('DriverLicenseNumber')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.driverLicenseNumber"
											type="text"
											:placeholder="FormMSG(1316, 'Enter your driver license number')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group v-if="useField('SignaturePicture')">
										<template slot="label">
											<label for="signatureId">{{ this.FormMSG(223, 'My signature') }}</label>
											<span class="required" v-if="isMandatory('SignaturePicture')"> *</span>
										</template>
										<b-row class="ml-0">
											<div
												@click="onOpenUrl(getImage(myProfile.signatureId, 'signatureId'))"
												class="d-flex justify-content-center align-items-center cursor-pointer"
												v-if="checkFile(myProfile.signatureId)"
											>
												<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
											</div>
											<b-img
												v-else
												v-bind="imgPropSignatureId"
												rounded="circle"
												alt="Signature ID"
												class="cursor-pointer"
												:src="getImage(myProfile.signatureId, 'signatureId')"
												@click="showImage(myProfile.signatureId)"
											></b-img>
											<div class="d-flex ml-3 justify-content-center align-items-center">
												<MobileProfilePicCaptureBtn
													parent-type="user"
													id="pictureID"
													@click="changeFieldPic('signatureId')"
													:parentId="myProfile.id"
													@change="handleImgsTaked"
												/>
												<button
													class="btn-transparent pl-0 ml-2"
													@click.prevent.stop="deleteImg('signatureId')"
													v-if="myProfile.signatureId && myProfile.signatureId.length > 0"
												>
													<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
												</button>
											</div>
										</b-row>
									</b-form-group>
									<b-row>
										<b-col>
											<b-form-group v-if="useField('IdPictureFront')">
												<template slot="label">
													<label for="pictureId">{{ FormMSG(218, 'ID picture - Front') }}</label>
													<span class="required" v-if="isMandatory('IdPictureFront')"> *</span>
												</template>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.pictureId, 'pictureId'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.pictureId)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropPictureId"
														rounded="circle"
														alt="ID picture - front"
														class="cursor-pointer"
														:src="getImage(myProfile.pictureId, 'pictureId')"
														@click="showImage(myProfile.pictureId)"
													/>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="pictureID"
															@click="changeFieldPic('pictureId')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('pictureId')"
															v-if="myProfile.pictureId && myProfile.pictureId.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
										<b-col>
											<b-form-group v-if="useField('IdPictureBack')">
												<template slot="label">
													<label for="pictureIdVerso">{{ FormMSG(219, 'ID picture - Back') }}</label>
													<span class="required" v-if="isMandatory('IdPictureBack')"> *</span>
												</template>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.pictureIdVerso, 'pictureIdVerso'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.pictureIdVerso)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropPictureIdVerso"
														rounded="circle"
														alt="ID picture - Back"
														class="cursor-pointer"
														:src="getImage(myProfile.pictureIdVerso, 'pictureIdVerso')"
														@click="showImage(myProfile.pictureIdVerso)"
													/>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="pictureIdVerso"
															@click="changeFieldPic('pictureIdVerso')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('pictureIdVerso')"
															v-if="myProfile.pictureIdVerso && myProfile.pictureIdVerso.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-form-group v-if="useField('DriverLicensePictureFront')">
												<template slot="label">
													<label for="pictureDriverLicense">{{ FormMSG(221, 'Driver license picture - Front') }}</label>
													<span class="required" v-if="isMandatory('DriverLicensePictureFront')"> *</span>
												</template>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.pictureDriverLicense, 'pictureDriverLicense'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.pictureDriverLicense)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropPictureDriverLicense"
														rounded
														alt="Driver license picture - Front"
														class="cursor-pointer"
														:src="getImage(myProfile.pictureDriverLicense, 'pictureDriverLicense')"
														@click="showImage(myProfile.pictureDriverLicense)"
													/>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="pictureDriverLicense"
															@click="changeFieldPic('pictureDriverLicense')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('pictureDriverLicense')"
															v-if="myProfile.pictureDriverLicense && myProfile.pictureDriverLicense.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
										<b-col>
											<b-form-group v-if="useField('DriverLicensePictureBack')">
												<template slot="label">
													<label for="pictureDriverLicenseVerso">{{ FormMSG(222, 'Driver license picture - Back') }}</label>
													<span class="required" v-if="isMandatory('DriverLicensePictureBack')"> *</span>
												</template>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.pictureDriverLicenseVerso, 'pictureDriverLicenseVerso'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.pictureDriverLicenseVerso)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropPictureIdVerso"
														rounded
														alt="Driver license picture - Back"
														class="cursor-pointer"
														:src="getImage(myProfile.pictureDriverLicenseVerso, 'pictureDriverLicenseVerso')"
														@click="showImage(myProfile.pictureDriverLicenseVerso)"
													/>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="pictureDriverLicenseVerso"
															@click="changeFieldPic('pictureDriverLicenseVerso')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('pictureDriverLicenseVerso')"
															v-if="myProfile.pictureDriverLicenseVerso && myProfile.pictureDriverLicenseVerso.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
									</b-row>
									<div v-if="isPme && myProfile.vehicule">
										<div class="divider" />
										<h2>{{ FormMSG(1227, 'Vehicle') }}</h2>
										<div class="switch-group">
											<b-form-group :label="FormMSG(1228, 'Name')">
												<b-form-input
													v-model="myProfile.vehicule.name"
													type="text"
													:placeholder="FormMSG(1229, 'Vehicle name ...')"
													@keydown.enter.prevent
												/>
											</b-form-group>
											<b-form-group :label="FormMSG(1230, 'Brand')">
												<b-form-input
													v-model="myProfile.vehicule.brand"
													type="text"
													:placeholder="FormMSG(1231, 'Vehicle brand ...')"
													@keydown.enter.prevent
												/>
											</b-form-group>
											<b-form-group :label="FormMSG(1232, 'Model')">
												<b-form-input
													v-model="myProfile.vehicule.model"
													type="text"
													:placeholder="FormMSG(1233, 'Vehicle model ...')"
													@keydown.enter.prevent
												/>
											</b-form-group>
											<b-form-group :label="FormMSG(1234, 'Number plate')">
												<b-form-input
													v-model="myProfile.vehicule.numberPlate"
													type="text"
													:placeholder="FormMSG(1235, 'Your number plate ...')"
													@keydown.enter.prevent
												/>
											</b-form-group>
											<b-form-group :label="FormMSG(1236, 'Type')">
												<b-form-select v-model="myProfile.vehicule.type" :options="vehicleTypes" />
											</b-form-group>
											<b-form-group :label="FormMSG(1237, 'Energy')">
												<b-form-select v-model="myProfile.vehicule.energy" :options="vehicleEnergies" />
											</b-form-group>
											<b-form-group>
												<label>{{ FormMSG(1238, 'Kg CO2 per') }} {{ distanceUnit }}</label>
												<b-form-input
													v-model="myProfile.vehicule.kgCoTwoPerKm"
													type="number"
													step="0.01"
													min="0"
													placeholder="0"
													@keydown.enter.prevent
												/>
											</b-form-group>
										</div>
									</div>
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'finance'">
								<div class="personal-data">
									<h2 v-if="financeDisplayBankSection">{{ FormMSG(1188, 'Bank Information') }}</h2>
									<b-form-group v-if="useField('Iban')" :class="{ 'is-warning': userBankWarning.iban }">
										<template slot="label">
											<label>{{ FormMSG(49, 'IBAN') }}</label>
											<span class="required" v-if="isMandatory('Iban')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.iban"
											:placeholder="FormMSG(1189, 'Enter IBAN')"
											:class="{ 'is-warning': userBankWarning.iban }"
											@input="handleSupplierIbanChange($event, 'iban')"
											@keydown.enter.prevent
										/>
										<div v-if="userBankWarning.iban" class="warning-feedback">
											{{ FormMSG(97986, "IBAN seems not valid, please make sure it's corrrect") }}
										</div>
									</b-form-group>
									<b-form-group v-if="useField('Bic')" :class="{ 'is-warning': userBankWarning.bic }">
										<template slot="label">
											<label>{{ FormMSG(51, 'BIC') }}</label>
											<span class="required" v-if="isMandatory('Bic')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.bic"
											:placeholder="FormMSG(1190, 'Enter BIC')"
											@input="handleSupplierBicChange($event, 'bic')"
											@keydown.enter.prevent
										/>
										<div v-if="userBankWarning.bic" class="warning-feedback">
											{{ FormMSG(909097986, "BIC seems not valid, please make sure it's corrrect") }}
										</div>
									</b-form-group>
									<b-form-group v-if="useField('BankAccountOwnerName')">
										<template slot="label">
											<label for="bic">{{ this.FormMSG(1245, 'Bank account owner name') }}</label>
											<span class="required" v-if="isMandatory('BankAccountOwnerName')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.bankAccountOwnerName"
											type="text"
											id="bic"
											:placeholder="this.FormMSG(1246, 'Enter your bank account owner name')"
											@keydown.enter.prevent
										></b-form-input>
									</b-form-group>
									<b-form-group v-if="useField('BankDetailDocument')">
										<template slot="label">
											<label for="bankDetailDocument">{{ FormMSG(1247, 'Bank detail document') }}</label>
											<span class="required" v-if="isMandatory('BankDetailDocument')"> *</span>
										</template>
										<b-row class="ml-0">
											<div
												@click="onOpenUrl(getImage(myProfile.bankDetailXid, 'bankDetailXid'))"
												class="d-flex justify-content-center align-items-center cursor-pointer"
												v-if="checkFile(myProfile.bankDetailXid)"
											>
												<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
											</div>
											<b-img
												v-else
												v-bind="imgPropSignatureId"
												rounded
												alt="Bank detail document"
												class="cursor-pointer"
												:src="getImage(myProfile.bankDetailXid, 'bankDetailXid')"
												@click="showImage(myProfile.bankDetailXid)"
												:title="FormMSG(254, 'View Image')"
											></b-img>
											<div class="d-flex ml-3 justify-content-center align-items-center">
												<MobileProfilePicCaptureBtn
													parent-type="user"
													id="pictureRib"
													@click="changeFieldPic('bankDetailXid')"
													:parentId="myProfile.id"
													@change="handleImgsTaked"
												/>
												<button
													class="btn-transparent pl-0 ml-2"
													@click.prevent.stop="deleteImg('bankDetailXid')"
													v-if="myProfile.bankDetailXid && myProfile.bankDetailXid.length > 0"
												>
													<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
												</button>
											</div>
										</b-row>
									</b-form-group>
									<!-- Second bank information -->
									<div class="text-mobile-sub-title sub-title">{{ FormMSG(5000, 'Expense Rembursement Bank Information') }}</div>
									<b-form-group v-if="useField('SecondIban')" :class="{ 'is-warning': userBankWarning.secondIban }">
										<template slot="label">
											<label>{{ FormMSG(49, 'IBAN') }}</label>
											<span class="required" v-if="isMandatory('SecondIban')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.secondIban"
											:placeholder="FormMSG(1189, 'Enter IBAN')"
											:class="{ 'is-warning': userBankWarning.secondIban }"
											@input="handleSupplierIbanChange($event, 'secondIban')"
											@keydown.enter.prevent
										/>
										<div v-if="userBankWarning.secondIban" class="warning-feedback">
											{{ FormMSG(97986, "IBAN seems not valid, please make sure it's corrrect") }}
										</div>
									</b-form-group>
									<b-form-group v-if="useField('SecondBic')" :class="{ 'is-warning': userBankWarning.secondBic }">
										<template slot="label">
											<label>{{ FormMSG(51, 'BIC') }}</label>
											<span class="required" v-if="isMandatory('SecondBic')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.secondBic"
											:placeholder="FormMSG(1190, 'Enter BIC')"
											@input="handleSupplierBicChange($event, 'secondBic')"
											@keydown.enter.prevent
										/>
										<div v-if="userBankWarning.secondBic" class="warning-feedback">
											{{ FormMSG(909097986, "BIC seems not valid, please make sure it's corrrect") }}
										</div>
									</b-form-group>
									<b-form-group v-if="useField('SecondBankAccountOwnerName')">
										<template slot="label">
											<label for="bic">{{ this.FormMSG(1245, 'Bank account owner name') }}</label>
											<span class="required" v-if="isMandatory('SecondBankAccountOwnerName')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.secondBankAccountOwnerName"
											type="text"
											id="bic"
											:placeholder="this.FormMSG(1246, 'Enter your bank account owner name')"
											@keydown.enter.prevent
										></b-form-input>
									</b-form-group>
									<b-form-group v-if="useField('SecondBankDetailDocument')">
										<template slot="label">
											<label for="bankDetailDocument">{{ FormMSG(1247, 'Bank detail document') }}</label>
											<span class="required" v-if="isMandatory('SecondBankDetailDocument')"> *</span>
										</template>
										<b-row class="ml-0">
											<div
												@click="onOpenUrl(getImage(myProfile.secondBankDetailXid, 'bankDetailXid'))"
												class="d-flex justify-content-center align-items-center cursor-pointer"
												v-if="checkFile(myProfile.secondBankDetailXid)"
											>
												<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
											</div>
											<b-img
												v-else
												v-bind="imgPropSignatureId"
												rounded
												alt="Bank detail document"
												class="cursor-pointer"
												:src="getImage(myProfile.secondBankDetailXid, 'secondBankDetailXid')"
												@click="showImage(myProfile.secondBankDetailXid)"
												:title="FormMSG(254, 'View Image')"
											></b-img>
											<div class="d-flex ml-3 justify-content-center align-items-center">
												<MobileProfilePicCaptureBtn
													parent-type="user"
													id="pictureRib"
													@click="changeFieldPic('secondBankDetailXid')"
													:parentId="myProfile.id"
													@change="handleImgsTaked"
												/>
												<button
													class="btn-transparent pl-0 ml-2"
													@click.prevent.stop="deleteImg('secondBankDetailXid')"
													v-if="myProfile.secondBankDetailXid && myProfile.secondBankDetailXid.length > 0"
												>
													<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
												</button>
											</div>
										</b-row>
									</b-form-group>
									<!-- Second bank information -->
									<div class="divider" v-if="financeDisplayBankSection && useField('Taxes')" />
									<h2 v-if="useField('Taxes')">{{ FormMSG(260, 'Taxes') }}</h2>
									<b-form-group v-if="useField('Taxes')">
										<template slot="label">
											<label>{{ FormMSG(1310, 'Taxes percentage') }}</label>
											<span class="required" v-if="isMandatory('Taxes')"> *</span>
										</template>
										<b-input-group>
											<b-form-input v-model="myProfile.taxesPercentage" type="number" step="1" min="0" max="100" @keydown.enter.prevent />
											<b-input-group-append>
												<b-input-group-text>
													<component :is="getIconLucide('Percent')" color="#06263E" :size="16" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
									<b-form-group v-if="useField('TaxDeclarationCountry')">
										<template slot="label">
											<label for="bic">{{ this.FormMSG(1248, 'Tax declaration country') }}</label>
											<span class="required" v-if="isMandatory('TaxDeclarationCountry')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.taxCountry"
											type="text"
											id="taxCountry"
											:placeholder="this.FormMSG(1249, 'Enter your tax declaration country')"
											@keydown.enter.prevent
										></b-form-input>
									</b-form-group>
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'address'">
								<div class="personal-data">
									<h2>{{ FormMSG(1308, 'Legal Address') }}</h2>
									<b-form-group v-if="useField('Country')">
										<template slot="label">
											<label for="country">{{ FormMSG(106, 'Country') }}</label>
											<span class="required" v-if="isMandatory('Country')"> *</span>
										</template>
										<v-select
											:clearable="false"
											v-model="countryKey"
											:options="countries"
											label="text"
											:reduce="(option) => option.value"
											@input="getCountry"
										/>
									</b-form-group>
									<b-form-group v-if="useField('InternetResearch')">
										<google-auto-complete
											ref="google-search-address"
											:label="FormMSG(12, 'Internet search')"
											:country="countryCode"
											:clear-input="clearLegalAddress"
											@placechanged="(item) => handleAutoComplete(item, 'legalAddress')"
										/>
									</b-form-group>
									<b-form-group v-if="useField('Street')">
										<template slot="label">
											<label>{{ FormMSG(100, 'Street') }}</label>
											<span class="required" v-if="isMandatory('Street')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.address.street"
											:placeholder="FormMSG(1156, 'Enter Street Name')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group v-if="useField('Number')">
										<template slot="label">
											<label>{{ FormMSG(101, 'Number') }}</label>
											<span class="required" v-if="isMandatory('Number')"> *</span>
										</template>
										<b-form-input v-model="myProfile.address.number" :placeholder="FormMSG(1157, 'Enter Number')" @keydown.enter.prevent />
									</b-form-group>
									<b-form-group v-if="useField('StateRegion')">
										<template slot="label">
											<label>{{ FormMSG(1170, 'State/Region') }}</label>
											<span class="required" v-if="isMandatory('StateRegion')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.address.state"
											:placeholder="FormMSG(1171, 'Enter State/Region')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group v-if="useField('City')">
										<template slot="label">
											<label>{{ FormMSG(103, 'City') }}</label>
											<span class="required" v-if="isMandatory('City')"> *</span>
										</template>
										<b-form-input v-model="myProfile.address.city" :placeholder="FormMSG(1172, 'Enter City')" @keydown.enter.prevent />
									</b-form-group>
									<b-form-group v-if="useField('ZipCode')">
										<template slot="label">
											<label>{{ FormMSG(1167, 'ZIP Code') }}</label>
											<span class="required" v-if="isMandatory('ZipCode')"> *</span>
										</template>
										<b-form-input v-model="myProfile.address.zip" :placeholder="FormMSG(1168, 'Enter ZIP Code')" @keydown.enter.prevent />
									</b-form-group>
									<b-form-group v-if="useField('Box')">
										<template slot="label">
											<label>{{ FormMSG(102, 'Box') }}</label>
											<span class="required" v-if="isMandatory('Box')"> *</span>
										</template>
										<b-form-input v-model="myProfile.address.box" :placeholder="FormMSG(1169, 'Enter Box Number')" @keydown.enter.prevent />
									</b-form-group>
								</div>
								<div class="personal-data mb-4">
									<b-collapse v-model="isShowPostalAddress">
										<h2>{{ FormMSG(1312, 'Postal address') }}</h2>
										<b-form-group>
											<template slot="label">
												<label for="country">{{ FormMSG(106, 'Country') }}</label>
												<span class="required" v-if="isMandatory('Country')"> *</span>
											</template>
											<v-select
												:clearable="false"
												v-model="countryKeyPostalAddress"
												:options="countries"
												label="text"
												:reduce="(option) => option.value"
												@input="getCountryPostalAddress"
											/>
										</b-form-group>
										<b-form-group>
											<google-auto-complete
												ref="google-search-address"
												:label="FormMSG(12, 'Internet search')"
												:country="countryCodePostalAddress"
												:clear-input="clearPostalAddress"
												@placechanged="(item) => handleAutoComplete(item, 'postalAddress')"
											/>
										</b-form-group>
										<b-form-group>
											<template slot="label">
												<label for="address">{{ FormMSG(100, 'Street') }}</label>
												<span class="required" v-if="isMandatory('Street')"> *</span>
											</template>
											<b-form-input
												v-model="myProfile.postalAddress.street"
												type="text"
												id="street"
												:placeholder="FormMSG(1103, 'Enter your street')"
												@keydown.enter.prevent
											></b-form-input>
										</b-form-group>
										<b-form-group>
											<template slot="label">
												<label for="number">{{ FormMSG(101, 'Number') }}</label>
												<span class="required" v-if="isMandatory('Number')"> *</span>
											</template>
											<b-form-input
												v-model="myProfile.postalAddress.number"
												type="text"
												id="number"
												@keydown.enter.prevent
											></b-form-input>
										</b-form-group>
										<b-form-group>
											<template slot="label">
												<label for="state">{{ FormMSG(105, 'State') }}</label>
												<span class="required" v-if="isMandatory('StateRegion')"> *</span>
											</template>
											<b-form-input v-model="myProfile.postalAddress.state" type="text" id="state" @keydown.enter.prevent></b-form-input>
										</b-form-group>
										<b-form-group>
											<template slot="label">
												<label for="city">{{ FormMSG(103, 'City') }}</label>
												<span class="required" v-if="isMandatory('City')"> *</span>
											</template>
											<b-form-input v-model="myProfile.postalAddress.city" type="text" id="city" @keydown.enter.prevent></b-form-input>
										</b-form-group>
										<b-form-group>
											<template slot="label">
												<label for="zip">{{ this.FormMSG(104, 'Postal Code') }}</label>
												<span class="required" v-if="isMandatory('ZipCode')"> *</span>
											</template>
											<b-form-input v-model="myProfile.postalAddress.zip" type="text" id="zip" @keydown.enter.prevent></b-form-input>
										</b-form-group>
										<b-form-group>
											<template slot="box">
												<label for="zip">{{ this.FormMSG(102, 'Box') }}</label>
												<span class="required" v-if="isMandatory('Box')"> *</span>
											</template>
											<b-form-input v-model="myProfile.postalAddress.box" type="text" id="box" @keydown.enter.prevent></b-form-input>
										</b-form-group>
									</b-collapse>
									<b-row>
										<div class="ml-3">
											<b-button
												size="sm"
												:variant="isShowPostalAddress ? 'outline-danger' : 'outline-secondary'"
												class="d-flex flex-row justify-content-center gap-2 align-items-center w-auto h-auto"
												style="height: 40px; border-radius: 0.5rem; background-color: #fff; cursor: pointer"
												@click="isShowPostalAddress = !isShowPostalAddress"
											>
												<div class="d-flex flex-row align-items-center justify-content-center btn-address" style="color: #06263e">
													<span v-if="isShowPostalAddress">
														<component :is="getLucideIcon(ICONS.X_CLEAR.name)" :color="ICONS.X_CLEAR.color" :size="22" />
														{{ FormMSG(1313, 'Close') }}
													</span>
													<span v-else>
														<component :is="getLucideIcon('PlusCircle')" color="#47C7BF" :size="22" />
														{{ FormMSG(1251, 'Add postal address (if different)') }}
													</span>
												</div>
											</b-button>
										</div>
									</b-row>
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'health'">
								<div class="health">
									<b-form-group v-if="useField('SocialSecurityNumber')">
										<template slot="label">
											<label>{{ FormMSG(54, 'Social Security Number') }}</label>
											<span class="required" v-if="isMandatory('SocialSecurityNumber')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.socSecNumber"
											:class="{ 'is-warning': isSocSecNumberWarning }"
											:placeholder="FormMSG(154, 'Enter your Social Security Number')"
											@input="handleSocSecNumberChange"
											@keydown.enter.prevent
										/>
										<div v-if="isSocSecNumberWarning" class="warning-feedback">
											{{ FormMSG(8979997, 'The social security number seems not valid') }}
										</div>
									</b-form-group>
									<b-form-group v-if="useField('SocialSecurityCenter')">
										<template slot="label">
											<label for="socialSecurityCenter">{{ this.FormMSG(1700, 'Social security center') }}</label>
											<span class="required" v-if="isMandatory('SocialSecurityCenter')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.socialSecurityCenter"
											type="text"
											id="socialSecurityCenter"
											:placeholder="this.FormMSG(1258, 'Enter your social security center')"
											@keydown.enter.prevent
										></b-form-input>
									</b-form-group>
									<b-form-group v-if="useField('SocialSecurityCenterAddress')">
										<template slot="label">
											<label>{{ FormMSG(1259, 'Social security center address') }}</label>
											<span class="required" v-if="isMandatory('SocialSecurityCenterAddress')"> *</span>
										</template>
										<v-address
											:params="{ title: 'Social security center address' }"
											:address-id="myProfile.socialSecurityCenterAddressId"
											@change="handleDynamicAddressChange"
											@delete="handleDynamicAddressDelete"
											@address-maplink-change="handleAddressMapLinkChange"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-row>
										<b-col cols="6" v-if="useField('SocialSecurityCard')">
											<b-form-group>
												<template slot="label">
													<label for="socialSecurityCardXid">{{ FormMSG(1255, 'Social security card') }}</label>
													<span class="required" v-if="isMandatory('SocialSecurityCard')"> *</span>
												</template>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.socialSecurityCardXid, 'socialSecurityCardXid'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.socialSecurityCardXid)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropSignatureId"
														rounded
														alt="socialSecurityCardXid"
														class="cursor-pointer"
														:src="getImage(myProfile.socialSecurityCardXid, 'socialSecurityCardXid')"
														@click="showImage(myProfile.socialSecurityCardXid)"
														:title="FormMSG(254, 'View Image')"
													></b-img>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="pictureRib"
															@click="changeFieldPic('socialSecurityCardXid')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('socialSecurityCardXid')"
															v-if="myProfile.socialSecurityCardXid && myProfile.socialSecurityCardXid.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
										<b-col cols="6" v-if="useField('InsuranceCertificate')">
											<b-form-group>
												<template slot="label">
													<label for="insuranceCertificate">{{ FormMSG(1256, 'Insurance certificate') }}</label>
													<span class="required" v-if="isMandatory('InsuranceCertificate')"> *</span>
												</template>
												<b-row class="ml-0">
													<div
														@click="onOpenUrl(getImage(myProfile.insuranceCertificateXid, 'insuranceCertificateXid'))"
														class="d-flex justify-content-center align-items-center cursor-pointer"
														v-if="checkFile(myProfile.insuranceCertificateXid)"
													>
														<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
													</div>
													<b-img
														v-else
														v-bind="imgPropSignatureId"
														rounded
														alt="insuranceCertificate"
														class="cursor-pointer"
														:src="getImage(myProfile.insuranceCertificateXid, 'insuranceCertificateXid')"
														@click="showImage(myProfile.insuranceCertificateXid)"
														:title="FormMSG(254, 'View Image')"
													></b-img>
													<div class="d-flex ml-3 justify-content-center align-items-center">
														<MobileProfilePicCaptureBtn
															parent-type="user"
															id="insuranceCertificateXid"
															@click="changeFieldPic('insuranceCertificateXid')"
															:parentId="myProfile.id"
															@change="handleImgsTaked"
														/>
														<button
															class="btn-transparent pl-0 ml-2"
															@click.prevent.stop="deleteImg('insuranceCertificateXid')"
															v-if="myProfile.insuranceCertificateXid && myProfile.insuranceCertificateXid.length > 0"
														>
															<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
														</button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
									</b-row>
									<b-form-group v-if="useField('LastMedicalVisitDate')">
										<template slot="label">
											<label>{{ FormMSG(1260, 'Last medical visit date') }}</label>
											<span class="required" v-if="isMandatory('LastMedicalVisitDate')"> *</span>
										</template>
										<v-date-picker
											v-model="myProfile.lastMedicalVisit"
											:locale="lang"
											:masks="masks"
											:attributes="attrs"
											@dayclick="(payload) => handleInputCalendar(payload, 'lastMedicalVisit')"
										>
											<template v-slot="{ inputValue, togglePopover }">
												<b-input-group>
													<b-form-input
														:value="inputValue"
														:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
														v-mask="'##/##/####'"
														readonly
														:class="{
															'temp-invalid':
																isMandatory('LastMedicalVisitDate') && !isDateValid('lastMedicalVisit')['lastMedicalVisit']
														}"
														@click="togglePopover()"
														@input="(value) => handleChangeDate(value, 'lastMedicalVisit')"
														@keydown.enter.prevent
													/>
													<b-input-group-append>
														<b-input-group-text
															class="cursor-pointer bg-color-white input-group-text-bg-white"
															@click="togglePopover()"
															:class="{
																'temp-invalid':
																	isMandatory('LastMedicalVisitDate') && !isDateValid('lastMedicalVisit')['lastMedicalVisit']
															}"
														>
															<component
																:is="getLucideIcon('Calendar')"
																color="rgba(6, 38, 62, .6)"
																:size="18"
																:stroke-width="2.25"
															/>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
												<!-- <div
													v-if="isMandatory('LastMedicalVisitDate') && !isDateValid('lastMedicalVisit')['lastMedicalVisit']"
													class="invalid-feedback"
												>
													{{ FormMSG(898, 'Last medical visit date is required') }}
												</div> -->
											</template>
										</v-date-picker>
									</b-form-group>
									<b-form-group v-if="useField('MedicalVisitValidity')">
										<template slot="label">
											<label>{{ FormMSG(1262, 'Medical visit validity') }}</label>
											<span class="required" v-if="isMandatory('MedicalVisitValidity')"> *</span>
										</template>
										<v-date-picker
											v-model="myProfile.medicalVisitValidity"
											:locale="lang"
											:masks="masks"
											:attributes="attrs"
											@dayclick="(payload) => handleInputCalendar(payload, 'medicalVisitValidity')"
										>
											<template v-slot="{ inputValue, togglePopover }">
												<b-input-group>
													<b-form-input
														:value="inputValue"
														:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
														v-mask="'##/##/####'"
														readonly
														:class="{
															'temp-invalid':
																isMandatory('MedicalVisitValidity') &&
																!isDateValid('medicalVisitValidity')['medicalVisitValidity']
														}"
														@click="togglePopover()"
														@input="(value) => handleChangeDate(value, 'medicalVisitValidity')"
														@keydown.enter.prevent
													/>
													<b-input-group-append>
														<b-input-group-text
															class="cursor-pointer bg-color-white input-group-text-bg-white"
															@click="togglePopover()"
														>
															<component
																:is="getLucideIcon('Calendar')"
																color="rgba(6, 38, 62, .6)"
																:size="18"
																:stroke-width="2.25"
															/>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
												<!-- <div
													v-if="isMandatory('MedicalVisitValidity') && !isDateValid('medicalVisitValidity')['medicalVisitValidity']"
													class="invalid-feedback"
												>
													{{ FormMSG(903, 'Medical visit validity is required') }}
												</div> -->
											</template>
										</v-date-picker>
									</b-form-group>
									<b-form-group v-if="useField('MedicalVisitDocument')">
										<template slot="label">
											<label for="MedicalVisitDocument">{{ FormMSG(1264, 'Medical visit document') }}</label>
											<span class="required" v-if="isMandatory('MedicalVisitDocument')"> *</span>
										</template>
										<b-row class="ml-0">
											<div
												@click="onOpenUrl(getImage(myProfile.medicalVisitDocumentXid, 'medicalVisitDocumentXid'))"
												class="d-flex justify-content-center align-items-center cursor-pointer"
												v-if="checkFile(myProfile.medicalVisitDocumentXid)"
											>
												<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
											</div>
											<b-img
												v-else
												v-bind="imgPropSignatureId"
												rounded
												alt="MedicalVisitDocument"
												class="cursor-pointer"
												:src="getImage(myProfile.medicalVisitDocumentXid, 'medicalVisitDocumentXid')"
												@click="showImage(myProfile.medicalVisitDocumentXid)"
												:title="FormMSG(254, 'View Image')"
											></b-img>
											<div class="d-flex ml-3 justify-content-center align-items-center">
												<MobileProfilePicCaptureBtn
													parent-type="user"
													id="medicalVisitDocumentXid"
													@click="changeFieldPic('medicalVisitDocumentXid')"
													:parentId="myProfile.id"
													@change="handleImgsTaked"
												/>
												<button
													class="btn-transparent pl-0 ml-2"
													@click.prevent.stop="deleteImg('medicalVisitDocumentXid')"
													v-if="myProfile.medicalVisitDocumentXid && myProfile.medicalVisitDocumentXid.length > 0"
												>
													<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
												</button>
											</div>
										</b-row>
									</b-form-group>
									<b-form-group v-if="useField('WorkerWithADisability')" class="text-start">
										<template slot="label">
											<template slot="label">
												<label>{{ FormMSG(1265, 'Worker with a disability') }}</label>
												<span class="required" v-if="isMandatory('WorkerWithADisability')"> *</span>
											</template>
											<span class="required" v-if="isMandatory('WorkerWithADisability')"> *</span>
										</template>
										<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.disabled" switch>
											<span class="text-redired">{{ myProfile.disabled ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
										</b-form-checkbox>
									</b-form-group>
									<!---->
									<div v-if="myProfile.disabled" class="card-gray">
										<b-form-group v-if="myProfile.disabled">
											<label>{{ FormMSG(1266, 'Disability notification date') }}</label>
											<v-date-picker
												v-model="myProfile.disabilityNotification"
												:locale="lang"
												:masks="masks"
												:attributes="attrs"
												@dayclick="(payload) => handleInputCalendar(payload, 'disabilityNotification')"
											>
												<template v-slot="{ inputValue, togglePopover }">
													<b-input-group>
														<b-form-input
															:value="inputValue"
															:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
															v-mask="'##/##/####'"
															readonly
															:class="{
																'temp-invalid':
																	isMandatory('DisabilityNotificationDate') &&
																	!isDateValid('disabilityNotification')['disabilityNotification']
															}"
															@click="togglePopover()"
															@input="(value) => handleChangeDate(value, 'disabilityNotification')"
															@keydown.enter.prevent
														/>
														<b-input-group-append>
															<b-input-group-text
																class="cursor-pointer bg-color-white input-group-text-bg-white"
																@click="togglePopover()"
															>
																<component
																	:is="getLucideIcon('Calendar')"
																	color="rgba(6, 38, 62, .6)"
																	:size="18"
																	:stroke-width="2.25"
																/>
															</b-input-group-text>
														</b-input-group-append>
													</b-input-group>
													<!-- <div
														v-if="
															isMandatory('DisabilityNotificationDate') &&
														!isDateValid('disabilityNotification')['disabilityNotification']
												"
														class="invalid-feedback"
													>
														{{ FormMSG(900, 'Disability notification date is required') }}
													</div> -->
												</template>
											</v-date-picker>
										</b-form-group>
										<b-form-group v-if="myProfile.disabled">
											<label for="type">{{ this.FormMSG(1268, 'Type') }}</label>
											<b-form-input
												v-model="myProfile.disabilityType"
												type="text"
												id="type"
												:placeholder="this.FormMSG(1269, 'Enter type')"
												@keydown.enter.prevent
											></b-form-input>
										</b-form-group>
										<b-form-group v-if="myProfile.disabled">
											<label for="status">{{ this.FormMSG(1270, 'Status') }}</label>
											<b-form-input
												v-model="myProfile.disabilityStatus"
												type="text"
												id="status"
												:placeholder="this.FormMSG(1271, 'Enter status')"
												@keydown.enter.prevent
											></b-form-input>
										</b-form-group>
										<b-form-group v-if="myProfile.disabled" :label="FormMSG(1272, 'Invalidity')">
											<b-form-select v-model="myProfile.invalidity">
												<b-form-select-option v-for="(gender, index) in menuInvalidity" :key="index" :value="gender.value">
													{{ gender.text }}
												</b-form-select-option>
											</b-form-select>
										</b-form-group>
										<b-form-group v-if="myProfile.disabled" class="text-start">
											<label>{{ FormMSG(1273, 'RQTH') }}</label>
											<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.rqth" :value="true" switch>
												<span class="text-redired">{{ myProfile.rqth ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
											</b-form-checkbox>
										</b-form-group>
										<b-form-group v-if="myProfile.disabled" :label="FormMSG(1274, 'Invalidity percentage')">
											<b-input-group>
												<b-form-input
													v-model="myProfile.invalidityPourcentage"
													type="number"
													step="1"
													min="0"
													max="100"
													@keydown.enter.prevent
												/>
												<b-input-group-append>
													<b-input-group-text style="background-color: #00a09c">
														<component :is="getIconLucide('Percent')" color="#ffffff" :size="16" :stroke-width="3.5" />
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</div>
									<b-form-group v-if="useField('HaveAllergies')" class="text-start">
										<template slot="label">
											<label>{{ FormMSG(25, 'Have Allergies?') }}</label>
											<span class="required" v-if="isMandatory('HaveAllergies')"> *</span>
										</template>
										<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="haveAllergies" switch>
											<span class="text-redired">{{ haveAllergies ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
										</b-form-checkbox>
									</b-form-group>
									<div v-if="haveAllergies && useField('AllergiesDetails')" class="card-gray">
										<b-form-group>
											<template slot="label">
												<label>{{ FormMSG(26, 'Allergies details') }}</label>
												<span class="required" v-if="isMandatory('AllergiesDetails')"> *</span>
											</template>
											<b-form-textarea
												v-model="myProfile.allergies"
												type="textarea"
												rows="2"
												:placeholder="FormMSG(6, 'Enter allergies details...')"
												autocomplete="off"
												:disabled="haveAllergies === false"
											/>
										</b-form-group>
									</div>

									<!-- <div class="mt-4">&nbsp;</div> -->
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'family'">
								<div class="personal-data">
									<h2>{{ FormMSG(14, 'Family composition') }}</h2>
									<b-form-group v-if="useField('CivilStatus')">
										<template slot="label">
											<label for="optionsForCivilStatus">{{ FormMSG(53, 'Civil status') }}</label>
											<span class="required" v-if="isMandatory('CivilStatus')"> *</span>
										</template>
										<b-form-select
											v-model="myProfile.civilStatus"
											:options="optionsForCivilStatus"
											id="optionsForCivilStatus"
										></b-form-select>
									</b-form-group>

									<div class="card-gray" v-if="myProfile.civilStatus === 3">
										<b-form-group v-if="useField('SpouseFirstName')">
											<template slot="label">
												<label>{{ FormMSG(174, 'Spouse first name') }}</label>
												<span class="required" v-if="isMandatory('SpouseFirstName')"> *</span>
											</template>
											<b-form-input
												v-model="myProfile.spouseFirstName"
												type="text"
												:placeholder="FormMSG(176, 'Enter your Spouse first name ')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group v-if="useField('SpouseLastName')">
											<template slot="label">
												<label>{{ FormMSG(172, 'Spouse name') }}</label>
												<span class="required" v-if="isMandatory('SpouseLastName')"> *</span>
											</template>
											<b-form-input
												v-model="myProfile.spouseName"
												type="text"
												:placeholder="FormMSG(173, 'Enter your Spouse name ')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group v-if="useField('SpouseBirthdate')">
											<label
												>{{ FormMSG(177, 'Spouse birth date') }}
												<span class="required" v-if="isMandatory('SpouseBirthdate')"> *</span>
											</label>
											<v-date-picker
												v-model="myProfile.spouseBirthDate"
												:locale="lang"
												:masks="masks"
												:attributes="attrs"
												@dayclick="(payload) => handleInputCalendar(payload, 'spouseBirthDate')"
											>
												<template v-slot="{ inputValue, togglePopover }">
													<b-input-group>
														<b-form-input
															:value="inputValue"
															:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
															v-mask="'##/##/####'"
															readonly
															:class="{
																'temp-invalid':
																	isMandatory('SpouseBirthdate') && !isDateValid('spouseBirthDate')['spouseBirthDate']
															}"
															@click="togglePopover()"
															@change="(value) => handleChangeDate(value, 'spouseBirthDate')"
															@keydown.enter.prevent
														/>
														<b-input-group-append>
															<b-input-group-text
																:class="{
																	'temp-invalid':
																		isMandatory('SpouseBirthdate') && !isDateValid('spouseBirthDate')['spouseBirthDate']
																}"
																class="cursor-pointer bg-color-white input-group-text-bg-white"
																@click="togglePopover()"
															>
																<component
																	:is="getLucideIcon('Calendar')"
																	color="rgba(6, 38, 62, .6)"
																	:size="18"
																	:stroke-width="2.25"
																/>
															</b-input-group-text>
														</b-input-group-append>
													</b-input-group>
												</template>
											</v-date-picker>
										</b-form-group>
										<b-form-group v-if="useField('SpouseHasSalary')" class="text-start">
											<template slot="label">
												<label for="firstname">{{ FormMSG(181, 'Spouse has salary') }}</label>
												<span class="required" v-if="isMandatory('SpouseHasSalary')"> *</span>
											</template>
											<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.spouseHasSalary" switch>
												<span class="text-redired">{{ myProfile.spouseHasSalary ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
											</b-form-checkbox>
										</b-form-group>
										<b-form-group v-if="useField('SpouseProfession')">
											<label
												>{{ FormMSG(179, 'Spouse profession')
												}}<span class="required" v-if="isMandatory('SpouseProfession')"> *</span></label
											>
											<b-form-input
												v-model="myProfile.spouseProfession"
												type="text"
												:placeholder="FormMSG(180, 'Enter your Spouse profession ')"
												@keydown.enter.prevent
											/>
										</b-form-group>
									</div>
									<div class="mt-3">
										<b-form-group class="text-start" v-if="useField('NumberOfDependentChildren') || useField('NumberOfDisabledChildren')">
											<label>{{ FormMSG(1192, 'Do you have children ?') }}</label>
											<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="haveChild" switch>
												<span class="text-redired">{{ haveChild ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
											</b-form-checkbox>
										</b-form-group>
									</div>
									<div class="card-gray" v-if="haveChild">
										<div v-if="useField('NumberOfDependentChildren')" class="d-flex flex-row align-items-center">
											<b-col cols="4" class="px-0 d-flex justify-content-start align-items-center">
												<b-button variant="light" class="little-btn mr-2 px-1" @click="decrementValue('numberOfDependentChild')">
													<component :is="getIconLucide('Minus')" color="#06263EA3" :size="16" />
												</b-button>
												<b-form-input
													:value="myProfile.numberOfDependentChild"
													type="number"
													step="1"
													min="0"
													placeholder="0"
													class="little-input mt-2"
													@keydown.enter.prevent
												/>
												<b-button variant="light" class="little-btn ml-2 px-1" @click="incrementValue('numberOfDependentChild')">
													<component :is="getIconLucide('Plus')" color="#06263EA3" :size="16" />
												</b-button>
											</b-col>
											<b-col cols="8" class="d-flex align-items-center" style="margin-top: 0.85rem">
												<p class="m-0 pl-2">
													{{ FormMSG(183, 'Number of dependent child') }}
													<span class="required" v-if="isMandatory('NumberOfDependentChildren')"> *</span>
												</p>
											</b-col>
										</div>
										<div v-if="useField('NumberOfDisabledChildren')" class="mt-3 d-flex flex-row align-items-center">
											<b-col cols="4" class="px-0 d-flex justify-content-start align-items-center">
												<b-button variant="light" class="little-btn mr-2 px-1" @click="decrementValue('numberOfDisabledChild')">
													<component :is="getIconLucide('Minus')" color="#06263EA3" :size="16" />
												</b-button>
												<b-form-input
													:value="myProfile.numberOfDisabledChild"
													type="number"
													step="1"
													min="0"
													placeholder="0"
													class="little-input mt-2"
													@keydown.enter.prevent
												/>
												<b-button variant="light" class="little-btn ml-2 px-1" @click="incrementValue('numberOfDisabledChild')">
													<component :is="getIconLucide('Plus')" color="#06263EA3" :size="16" />
												</b-button>
											</b-col>
											<b-col cols="8" class="d-flex align-items-center" style="margin-top: 0.85rem">
												<p class="m-0 pl-2">
													{{ FormMSG(184, 'Number of disabled child') }}
													<span class="required" v-if="isMandatory('NumberOfDisabledChildren')"> *</span>
												</p>
											</b-col>
										</div>
									</div>
									<div class="mt-3" v-if="useField('NumberOfDependentPerson') || useField('NumberOfDisabledPerson')">
										<b-form-group class="text-start">
											<label>{{ FormMSG(1193, 'Do you have other dependents ?') }}</label>
											<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="haveDependents" switch>
												<span class="text-redired">{{ haveDependents ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
											</b-form-checkbox>
										</b-form-group>
									</div>
									<div class="card-gray" v-if="haveDependents">
										<div v-if="useField('NumberOfDependentPerson')" class="d-flex flex-row align-items-center">
											<b-col cols="4" class="px-0 d-flex justify-content-start align-items-center">
												<b-button variant="light" class="little-btn mr-2 px-1" @click="decrementValue('numberOfDependentPerson')">
													<component :is="getIconLucide('Minus')" color="#06263EA3" :size="16" />
												</b-button>
												<b-form-input
													:value="myProfile.numberOfDependentPerson"
													type="number"
													step="1"
													min="0"
													placeholder="0"
													class="little-input mt-2"
													@keydown.enter.prevent
												/>
												<b-button variant="light" class="little-btn ml-2 px-1" @click="incrementValue('numberOfDependentPerson')">
													<component :is="getIconLucide('Plus')" color="#06263EA3" :size="16" />
												</b-button>
											</b-col>
											<b-col cols="8" class="d-flex align-items-center" style="margin-top: 0.85rem">
												<p class="m-0 pl-2">
													{{ FormMSG(1221, 'Number of dependent persons') }}
													<span class="required" v-if="isMandatory('NumberOfDependentPerson')"> *</span>
												</p>
											</b-col>
										</div>
										<div v-if="useField('NumberOfDisabledPerson')" class="mt-3 d-flex flex-row align-items-center">
											<b-col cols="4" class="px-0 d-flex justify-content-start align-items-center">
												<b-button variant="light" class="little-btn mr-2 px-1" @click="decrementValue('numberOfDisabledPerson')">
													<component :is="getIconLucide('Minus')" color="#06263EA3" :size="16" />
												</b-button>
												<b-form-input
													:value="myProfile.numberOfDisabledPerson"
													type="number"
													step="1"
													min="0"
													placeholder="0"
													class="little-input mt-2"
													@keydown.enter.prevent
												/>
												<b-button variant="light" class="little-btn ml-2 px-1" @click="incrementValue('numberOfDisabledPerson')">
													<component :is="getIconLucide('Plus')" color="#06263EA3" :size="16" />
												</b-button>
											</b-col>
											<b-col cols="8" class="d-flex align-items-center" style="margin-top: 0.85rem">
												<p class="m-0 pl-2">
													{{ FormMSG(1222, 'Number of disabled persons') }}
													<span class="required" v-if="isMandatory('NumberOfDisabledPerson')"> *</span>
												</p>
											</b-col>
										</div>
									</div>
								</div>
								<div class="divider mr-4 ml-4" v-if="useField('ContactName') || useField('ContactPhoneNumber')"></div>
								<div class="personal-data" v-if="useField('ContactName') || useField('ContactPhoneNumber')">
									<h2>{{ FormMSG(1176, 'Emergency Contact') }}</h2>
									<b-form-group v-if="useField('ContactName')">
										<template slot="label">
											<label>{{ FormMSG(1177, 'Contact Name') }}</label>
											<span class="required" v-if="isMandatory('ContactName')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.personToContact"
											:placeholder="FormMSG(1178, 'Enter Contact Name')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group v-if="useField('ContactPhoneNumber')">
										<template slot="label">
											<label>{{ FormMSG(1179, 'Contact Phone Number') }}</label>
											<span class="required" v-if="isMandatory('ContactPhoneNumber')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.personToContactPhone"
											:placeholder="FormMSG(1180, 'Enter Contact Phone Number')"
											@keydown.enter.prevent
										/>
									</b-form-group>
								</div>
								<!-- <div class="mt-4">&nbsp;</div> -->
							</div>
							<div v-if="menuActiveForMobile === 'personal-vehicle'">
								<div class="personal-data">
									<h2>{{ FormMSG(1226, 'Personal vehicle') }}</h2>
									<vehicule-form
										:vehicule-data="myProfile.vehicule"
										:useVehicleName="useVehicleName"
										:useVehicleBrand="useVehicleBrand"
										:useVehicleModel="useVehicleModel"
										:useVehiclePlateNumber="useVehiclePlateNumber"
										:useVehicleTransportationMean="useVehicleTransportationMean"
										:useVehicleTransportationMeanDetail="useVehicleTransportationMeanDetail"
										:useVehicleKgCoTwo="useVehicleKgCoTwo"
										:usePower="userFieldOption.usePower"
										:useGrayCardFrontPicture="userFieldOption.useGrayCardFrontPicture"
										:useGrayCardBackPicture="userFieldOption.useGrayCardBackPicture"
										:mandatoryVehicleName="userFieldOption.mandatoryVehicleName"
										:mandatoryVehicleBrand="userFieldOption.mandatoryVehicleBrand"
										:mandatoryVehicleModel="userFieldOption.mandatoryVehicleModel"
										:mandatoryVehiclePlateNumber="userFieldOption.mandatoryVehiclePlateNumber"
										:mandatoryVehicleTransportationMean="userFieldOption.mandatoryVehicleTransportationMean"
										:mandatoryVehicleKgCoTwo="userFieldOption.mandatoryVehicleKgCoTwo"
										:mandatoryPower="userFieldOption.mandatoryPower"
										:mandatoryGrayCardFrontPicture="userFieldOption.mandatoryGrayCardFrontPicture"
										:mandatoryGrayCardBackPicture="userFieldOption.mandatoryGrayCardBackPicture"
										@vehicule-form:change="handleVehiculeFormChange"
									/>
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'other'">
								<div v-if="useField('showMyEmailInCrewList') || useField('showMyPhoneInCrewList')" class="personal-data">
									<h2 v-if="useField('showMyEmailInCrewList') || useField('showMyPhoneInCrewList')">{{ FormMSG(16, 'Other') }}</h2>
									<b-form-group v-if="useField('showMyEmailInCrewList')" class="text-start">
										<label>{{ FormMSG(18, 'Show my email in crew list') }}</label>
										<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.showMyEmailInCrew" switch>
											<span class="text-redired">{{ myProfile.showMyEmailInCrew ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
										</b-form-checkbox>
									</b-form-group>
									<b-form-group v-if="useField('showMyPhoneInCrewList')" class="text-start">
										<label>{{ FormMSG(19, 'Show my phone in crew list') }}</label>
										<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.showMyPhoneInCrew" switch>
											<span class="text-redired">{{ myProfile.showMyPhoneInCrew ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
										</b-form-checkbox>
									</b-form-group>
								</div>
								<div
									class="divider mr-4 ml-4"
									v-if="
										useField('SncfLoyaltyCard') ||
										useField('SncfDiscountCard') ||
										useField('FlyingBlueCard') ||
										useField('OtherAirlineCard')
									"
								></div>
								<div
									class="personal-data"
									v-if="
										useField('SncfLoyaltyCard') ||
										useField('SncfDiscountCard') ||
										useField('FlyingBlueCard') ||
										useField('OtherAirlineCard')
									"
								>
									<h2
										v-if="
											useField('SncfLoyaltyCard') ||
											useField('SncfDiscountCard') ||
											useField('FlyingBlueCard') ||
											useField('OtherAirlineCard')
										"
									>
										{{ FormMSG(1280, 'Loyalty cards') }}
									</h2>
									<b-form-group v-if="useField('SncfLoyaltyCard')">
										<template #label>
											<div>
												<span>
													{{ FormMSG(1281, 'SNCF loyalty card') }}
													<span class="required" v-if="isMandatory('SncfLoyaltyCard')"> *</span>
													<span class="text-fin">{{ FormMSG(1282, '(Type + number)') }}</span>
												</span>
											</div>
										</template>
										<b-form-input
											v-model="myProfile.trainLoyaltyCard"
											type="text"
											:placeholder="FormMSG(1283, 'Enter your sncf loyalty card')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group v-if="useField('SncfDiscountCard')">
										<template #label>
											<div>
												<span>
													{{ FormMSG(1284, 'SNCF discount card') }}
													<span class="required" v-if="isMandatory('SncfDiscountCard')"> *</span>
													<span class="text-fin">{{ FormMSG(1282, '(Type + number)') }}</span>
												</span>
											</div>
										</template>
										<b-form-input
											v-model="myProfile.trainDiscountCard"
											type="text"
											:placeholder="FormMSG(1285, 'Enter your sncf discount card')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group v-if="useField('FlyingBlueCard')">
										<template #label>
											<div>
												<span>
													{{ FormMSG(1286, 'Flying blue card') }}
													<span class="required" v-if="isMandatory('FlyingBlueCard')"> *</span>
													<span class="text-fin">{{ FormMSG(1287, '(Number)') }}</span>
												</span>
											</div>
										</template>
										<b-form-input
											v-model="myProfile.flyingLoyaltyCard"
											type="text"
											:placeholder="FormMSG(1288, 'Enter your flying blue card')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group v-if="useField('OtherAirlineCard')">
										<template #label>
											<div>
												<span>
													{{ FormMSG(1289, 'Other airline card') }}
													<span class="required" v-if="isMandatory('OtherAirlineCard')"> *</span>
													<span class="text-fin">{{ FormMSG(1287, '(Number)') }}</span>
												</span>
											</div>
										</template>
										<b-form-input
											v-model="myProfile.otherAirlineCard"
											type="text"
											:placeholder="FormMSG(1288, 'Enter your other airline card')"
											@keydown.enter.prevent
										/>
									</b-form-group>
								</div>
								<div
									class="divider mr-4 ml-4"
									v-if="
										useField('ResidencePermitNumber') ||
										useField('ResidenceValidityEnd') ||
										useField('ResidencePermit') ||
										useField('WorkCard')
									"
								></div>
								<div v-if="!hideInfoOther">
									<div
										class="personal-data"
										v-if="
											useField('ResidencePermitNumber') ||
											useField('ResidenceValidityEnd') ||
											useField('ResidencePermit') ||
											useField('WorkCard')
										"
									>
										<h2
											v-if="
												useField('ResidencePermitNumber') ||
												useField('ResidenceValidityEnd') ||
												useField('ResidencePermit') ||
												useField('WorkCard')
											"
										>
											<span>
												{{ FormMSG(1290, 'Foreign worker') }}
												<span class="required" v-if="isMandatory('ResidencePermitNumber')"> *</span>
												<span class="text-fin">{{ FormMSG(1309, "(section to fill only if you're a foreign worker)") }}</span>
											</span>
										</h2>
										<b-form-group v-if="useField('ResidencePermitNumber')" :label="FormMSG(1292, 'Residence permit number')">
											<b-form-input
												v-model="myProfile.residencePermitNumber"
												type="text"
												:placeholder="FormMSG(1293, 'Enter your residence permit number')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group v-if="useField('ResidenceValidityEnd')">
											<template slot="label">
												<label>{{ FormMSG(1294, 'Residence permit validity end') }}</label>
												<span class="required" v-if="isMandatory('ResidenceValidityEnd')"> *</span>
											</template>
											<v-date-picker
												v-model="myProfile.residencePermitEndValidity"
												:locale="lang"
												:masks="masks"
												:attributes="attrs"
												@dayclick="(payload) => handleInputCalendar(payload, 'residencePermitEndValidity')"
											>
												<template v-slot="{ inputValue, togglePopover }">
													<b-input-group>
														<b-form-input
															:value="inputValue"
															:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
															v-mask="'##/##/####'"
															readonly
															:class="{
																'temp-invalid':
																	isMandatory('ResidenceValidityEnd') &&
																	!isDateValid('residencePermitEndValidity')['residencePermitEndValidity']
															}"
															@click="togglePopover()"
															@input="(value) => handleChangeDate(value, 'residencePermitEndValidity')"
															@keydown.enter.prevent
														/>
														<b-input-group-append>
															<b-input-group-text
																class="cursor-pointer bg-color-white input-group-text-bg-white"
																@click="togglePopover()"
																:class="{
																	'temp-invalid':
																		isMandatory('ResidenceValidityEnd') &&
																		!isDateValid('residencePermitEndValidity')['residencePermitEndValidity']
																}"
															>
																<component
																	:is="getLucideIcon('Calendar')"
																	color="rgba(6, 38, 62, .6)"
																	:size="18"
																	:stroke-width="2.25"
																/>
															</b-input-group-text>
														</b-input-group-append>
													</b-input-group>
													<!-- <div
														v-if="
															isMandatory('ResidenceValidityEnd') &&
															!isDateValid('residencePermitEndValidity')['residencePermitEndValidity']
														"
														class="invalid-feedback"
													>
														{{ FormMSG(901, 'Residence validity end is required') }}
													</div> -->
												</template>
											</v-date-picker>
										</b-form-group>
										<b-row>
											<b-col v-if="useField('ResidencePermit')" cols="6">
												<b-form-group>
													<label for="residentPermitXid"
														>{{ FormMSG(1296, 'Resident permit') }}
														<span class="required" v-if="isMandatory('ResidencePermit')"> *</span>
													</label>
													<b-row class="ml-0">
														<div
															@click="onOpenUrl(getImage(myProfile.residentPermitXid, 'residentPermitXid'))"
															class="d-flex justify-content-center align-items-center cursor-pointer"
															v-if="checkFile(myProfile.residentPermitXid)"
														>
															<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
														</div>
														<b-img
															v-else
															v-bind="imgPropSignatureId"
															rounded
															alt="residentPermitXid"
															class="cursor-pointer"
															:src="getImage(myProfile.residentPermitXid, 'residentPermitXid')"
															@click="showImage(myProfile.residentPermitXid)"
															:title="FormMSG(254, 'View Image')"
														></b-img>
														<div class="d-flex ml-3 justify-content-center align-items-center">
															<MobileProfilePicCaptureBtn
																parent-type="user"
																id="residentPermitXid"
																@click="changeFieldPic('residentPermitXid')"
																:parentId="myProfile.id"
																@change="handleImgsTaked"
															/>
															<button
																class="btn-transparent pl-0 ml-2"
																@click.prevent.stop="deleteImg('residentPermitXid')"
																v-if="myProfile.residentPermitXid && myProfile.residentPermitXid.length > 0"
															>
																<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
															</button>
														</div>
													</b-row>
												</b-form-group>
											</b-col>
											<b-col v-if="useField('WorkCard')" cols="6">
												<b-form-group>
													<label for="workCardXid"
														>{{ FormMSG(1297, 'Work card') }}
														<span class="required" v-if="isMandatory('WorkCard')"> *</span>
													</label>
													<b-row class="ml-0">
														<div
															@click="onOpenUrl(getImage(myProfile.workCardXid, 'workCardXid'))"
															class="d-flex justify-content-center align-items-center cursor-pointer"
															v-if="checkFile(myProfile.workCardXid)"
														>
															<b-icon icon="file-earmark-pdf-fill" :font-scale="1.6" />
														</div>
														<b-img
															v-else
															v-bind="imgPropSignatureId"
															rounded
															alt="workCardXid"
															class="cursor-pointer"
															:src="getImage(myProfile.workCardXid, 'workCardXid')"
															@click="showImage(myProfile.workCardXid)"
															:title="FormMSG(254, 'View Image')"
														></b-img>
														<div class="d-flex ml-3 justify-content-center align-items-center">
															<MobileProfilePicCaptureBtn
																parent-type="user"
																id="workCardXid"
																@click="changeFieldPic('workCardXid')"
																:parentId="myProfile.id"
																@change="handleImgsTaked"
															/>
															<button
																class="btn-transparent pl-0 ml-2"
																@click.prevent.stop="deleteImg('workCardXid')"
																v-if="myProfile.workCardXid && myProfile.workCardXid.length > 0"
															>
																<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
															</button>
														</div>
													</b-row>
												</b-form-group>
											</b-col>
										</b-row>
									</div>
								</div>

								<!-- <div class="divider mr-4 ml-4"></div>
								<div class="mt-1">&nbsp;</div> -->
								<div class="personal-data">
									<h2 v-if="useField('GenericMentionProfessionalAssociation') || useField('Pseudonym')">{{ FormMSG(24, 'Miscellanous') }}</h2>
									<b-form-group v-if="useField('Pseudonym')">
										<template slot="label">
											<label>{{ FormMSG(1298, 'Pseudonym') }}</label>
											<span class="required" v-if="isMandatory('Pseudonym')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.pseudonym"
											type="text"
											:placeholder="FormMSG(1299, 'Enter your pseudonym')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<b-form-group v-if="useField('GenericMentionProfessionalAssociation')">
										<template slot="label">
											<label>{{ FormMSG(1300, 'Generic mention professional association') }}</label>
											<span class="required" v-if="isMandatory('GenericMentionProfessionalAssociation')"> *</span>
										</template>
										<b-form-input
											v-model="myProfile.professionalAssociation"
											type="text"
											:placeholder="FormMSG(1301, 'Enter your generic mention ...')"
											@keydown.enter.prevent
										/>
									</b-form-group>
									<div class="divider" v-if="useField('Retired')"></div>
									<div class="mt-3" v-if="useField('Retired')">
										<h2>{{ FormMSG(1705, 'Retirement') }}</h2>
										<b-form-group class="text-start">
											<label>{{ FormMSG(1275, 'Retired ?') }}</label>
											<b-form-checkbox
												class="d-flex justify-content-start"
												size="lg"
												v-model="myProfile.retired"
												:value="true"
												switch
												@change="handleChangeRetired"
											>
												<span class="text-redired">{{ myProfile.retired ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No') }}</span>
											</b-form-checkbox>
										</b-form-group>
									</div>
									<div v-if="myProfile.retired && useField('Retired')" class="card-gray">
										<b-form-group>
											<label>{{ FormMSG(1278, 'Retirement date') }}</label>
											<v-date-picker
												v-model="myProfile.retirementDate"
												:locale="lang"
												:masks="masks"
												:attributes="attrs"
												@dayclick="(payload) => handleInputCalendar(payload, 'retirementDate')"
											>
												<template v-slot="{ inputValue, togglePopover }">
													<b-input-group>
														<b-form-input
															:value="inputValue"
															:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
															v-mask="'##/##/####'"
															readonly
															:class="{
																'temp-invalid': !isDateValid('retirementDate')['retirementDate']
															}"
															@click="togglePopover()"
															@input="(value) => handleChangeDate(value, 'retirementDate')"
															@keydown.enter.prevent
														/>
														<b-input-group-append>
															<b-input-group-text
																class="cursor-pointer bg-color-white input-group-text-bg-white"
																@click="togglePopover()"
															>
																<component
																	:is="getLucideIcon('Calendar')"
																	color="rgba(6, 38, 62, .6)"
																	:size="18"
																	:stroke-width="2.25"
																/>
															</b-input-group-text>
														</b-input-group-append>
													</b-input-group>
													<!-- <div v-if="!isDateValid('retirementDate')['retirementDate']" class="invalid-feedback">
														{{ FormMSG(902, 'Retirement date is required') }}
													</div> -->
												</template>
											</v-date-picker>
										</b-form-group>
									</div>
									<div>
										<div v-if="useField('StudyLevel')" class="divider"></div>
										<!-- <div v-if="useField('StudyLevel')" class="mt-1">&nbsp;</div> -->
										<h2 v-if="useField('StudyLevel')">{{ FormMSG(1706, 'Study') }}</h2>
										<b-form-group v-if="useField('StudyLevel')">
											<template slot="label">
												<label>{{ FormMSG(262, 'Study level') }}</label>
												<span class="required" v-if="isMandatory('StudyLevel')"> *</span>
											</template>
											<b-form-select v-model="myProfile.studyLevel" :options="studyLeveOptions" />
										</b-form-group>
									</div>
									<div>
										<div
											class="divider"
											v-if="
												useField('Size') ||
												useField('PantSize') ||
												useField('JacketSize') ||
												useField('ShoeSize') ||
												useField('TshirtSize')
											"
										></div>
										<h2
											v-if="
												useField('Size') ||
												useField('PantSize') ||
												useField('JacketSize') ||
												useField('ShoeSize') ||
												useField('TshirtSize')
											"
										>
											{{ FormMSG(1707, 'Clothes') }}
										</h2>
										<b-form-group v-if="useField('Size')">
											<template slot="label">
												<label>{{ FormMSG(263, 'Size') }}</label>
												<span class="required" v-if="isMandatory('Size')"> *</span>
											</template>
											<b-form-input
												v-model="myProfile.size"
												type="text"
												:placeholder="FormMSG(267, 'Enter your size ...')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group v-if="useField('PantSize')">
											<template slot="label">
												<label>{{ FormMSG(264, 'Pant size') }}</label>
												<span class="required" v-if="isMandatory('PantSize')"> *</span>
											</template>
											<b-form-input
												v-model="myProfile.pantSize"
												type="text"
												:placeholder="FormMSG(268, 'Enter your pant size ...')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group v-if="useField('JacketSize')">
											<template slot="label">
												<label>{{ FormMSG(265, 'Jacket size') }}</label>
												<span class="required" v-if="isMandatory('JacketSize')"> *</span>
											</template>
											<b-form-input
												v-model="myProfile.jacketSize"
												type="text"
												:placeholder="FormMSG(269, 'Enter your jacket size ...')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group v-if="useField('ShoeSize')">
											<template slot="label">
												<label>{{ FormMSG(27, 'Shoe size') }}</label>
												<span class="required" v-if="isMandatory('ShoeSize')"> *</span>
											</template>
											<b-form-input
												v-model="myProfile.shoeSize"
												type="text"
												:placeholder="FormMSG(28, 'Enter your shoe size ...')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<b-form-group v-if="useField('TshirtSize')">
											<template slot="label">
												<label>{{ FormMSG(29, 'Teeshirt size') }}</label>
												<span class="required" v-if="isMandatory('TshirtSize')"> *</span>
											</template>
											<b-form-input
												v-model="myProfile.tShirtSize"
												type="text"
												:placeholder="FormMSG(30, 'Enter your teeshirt size ...')"
												@keydown.enter.prevent
											/>
										</b-form-group>
										<div v-if="isEditable">
											<div class="divider"></div>
											<h2>
												{{ FormMSG(1714, 'Custom fields') }}
											</h2>
											<b-row class="pb-2">
												<b-col sm="12" class="d-flex mb-2 w-100" v-for="(customField, index) in customFields" :key="index">
													<custom-field-template
														v-model="customField[customFieldModels[index]]"
														:field="customField"
														:name-model="customFieldModels[index]"
														:name-group="customField.nameGroup"
														:index="index"
														class="w-100"
														@custom-field-template:payload="onFieldTemplateChanged"
													/>
												</b-col>
											</b-row>
										</div>
									</div>
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'settings'">
								<div class="settings">
									<h2>{{ FormMSG(1198, 'Language') }}</h2>
									<b-form-group :label="FormMSG(1209, 'Select a communication language')">
										<b-form-select v-model="myProfile.language" :options="optionsForLanguages" />
									</b-form-group>
									<div class="divider" />
									<h2 class="mt-3">{{ FormMSG(22, 'Change Password') }}</h2>
									<p class="mt-3">
										{{
											FormMSG(
												107,
												"Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter."
											)
										}}
									</p>
									<b-button
										size="lg"
										variant="outline-primary"
										:disabled="statusSendCodeValidation.global"
										block
										@click="handleChangePassword"
									>
										<span v-if="!statusSendCodeValidation.progress && !statusSendCodeValidation.success">
											{{ FormMSG(22, 'Change Password') }}
										</span>
										<span v-if="statusSendCodeValidation.progress && !statusSendCodeValidation.success">
											<b-spinner label="Loading..." small /> {{ FormMSG(146, 'Send code validation') }}
										</span>
										<span v-if="!statusSendCodeValidation.progress && statusSendCodeValidation.success">
											{{ FormMSG(147, 'Code validation sended') }}
										</span>
									</b-button>
								</div>
							</div>
							<div v-if="menuActiveForMobile === 'team-session' && isDeterProject === true">
								<div class="settings">
									<p class="fw-400">
										{{ FormMSG(814, 'Here the list of sessions and teams') }}
									</p>
									<div>
										<b-table-simple id="deterSessionTeamTable">
											<b-thead head-variant="dark">
												<b-tr>
													<b-th></b-th>
													<b-th>{{ FormMSG(465, 'Team A') }}</b-th>
													<b-th>{{ FormMSG(466, 'Team B') }}</b-th>
												</b-tr>
											</b-thead>
											<b-tbody>
												<b-tr>
													<b-th>
														{{ FormMSG(457, 'Session 1') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session1A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session1B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(458, 'Session 2') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session2A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session2B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(459, 'Session 3') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session3A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session3B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(460, 'Session 4') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session4A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session4B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(461, 'Session 5') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session5A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session5B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(462, 'Session 6') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session6A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session6B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(463, 'Session 7') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session7A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session7B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
												<b-tr>
													<b-th>
														{{ FormMSG(464, 'Session 8') }}
													</b-th>
													<b-td>
														<b-form-checkbox v-model="myProfile.session8A" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
													<b-td>
														<b-form-checkbox v-model="myProfile.session8B" size="lg" :value="true" :unchecked-value="false" />
													</b-td>
												</b-tr>
											</b-tbody>
										</b-table-simple>
									</div>
								</div>
							</div>
							<b-row v-if="menuActiveForMobile !== ''" class="footer-fixed">
								<b-col cols="12">
									<b-button variant="primary" type="submit" block style="font-size: 16px; font-weight: 700">
										{{ FormMSG(1210, 'Save Changes') }}
									</b-button>
								</b-col>
							</b-row>
						</form>
					</div>
					<loading :active.sync="isLoading" :is-full-page="true" color="#00A09C"></loading>
				</div>
			</div>
			<div v-else>
				<div class="animated fadeIn">
					<b-row>
						<div class="container-layout">
							<div class="card py-4">
								<div v-if="!allMandatoryIsFilled" class="alert alert-warning">
									{{ FormMSG(55055, 'Field with * are mandatory field you must fill. Some field are still empty.') }}
								</div>

								<div class="p-2" v-if="isDeterProject && isDeterAction">
									<b-alert variant="warning" show dismissible>
										<div class="d-flex align-items-center flex-row" style="gap: 10px">
											<div>
												<component :is="getIconLucide(ICONS.ALERT_TRIANGLE.name)" color="#ffc107" :size="22" />
											</div>
											<div>
												{{ FormMSG(1708, 'Dear') }} {{ userNameActif }}, {{ FormMSG(1709, 'Please add') }}
												<strong>{{ messageContext }}</strong>
												{{ FormMSG(1710, 'information in your profile. Thank you for using TheGreenShot services.') }}
											</div>
										</div>
									</b-alert>
								</div>
								<b-form @submit="submitForm">
									<!-- Personal data -->
									<div class="card-collapsible">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('personnal-data')"
										>
											<div class="title w-90">
												<b-icon icon="person-check-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{
													FormMSG(3, 'Personal data')
												}}</span>
											</div>
											<div class="d-flex justify-content-end w-20">
												<b-button v-if="personalDataMandatoryCount > 0" pill variant="warning" class="mr-2">{{
													personalDataMandatoryCount + ' ' + FormMSG(2365, 'field(s) left')
												}}</b-button>
												<component
													v-if="!configAccordion['personnal-data'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['personnal-data'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['personnal-data'].isVisible">
											<div class="content-card-collapsible">
												<b-row>
													<b-col sm="8" md="8" lg="9" xl="9">
														<b-row>
															<b-col v-if="useField('PersonalNumber')" sm="12" md="12" lg="4" xl="4">
																<b-form-group>
																	<template slot="label">
																		<label for="reference">{{ this.FormMSG(1, 'Reference') }}</label>
																		<span class="required" v-if="isMandatory('PersonalNumber')"> *</span>
																	</template>
																	<b-form-input
																		v-model="myProfile.reference"
																		type="text"
																		id="reference"
																		:placeholder="FormMSG(2, 'Enter your personal number.')"
																		@keydown.enter.prevent
																		disabled
																	></b-form-input>
																</b-form-group>
															</b-col>
															<b-col v-if="useField('Email')" sm="12" md="12" lg="4" xl="4">
																<b-form-group>
																	<template slot="label">
																		<label for="email">{{ FormMSG(42, 'Email') }}</label>
																		<span class="required" v-if="isMandatory('Email')"> *</span>
																	</template>
																	<b-input-group>
																		<b-form-input
																			:value="myProfile.email"
																			type="text"
																			id="email"
																			:placeholder="FormMSG(1102, 'Enter your email')"
																			style="border-right: none"
																			@keydown.enter.prevent
																			disabled
																			:class="{ 'is-invalid': isSubmitted && $v.myProfile.email.$error }"
																		/>
																		<b-input-group-append>
																			<b-input-group-text
																				style="border-radius: 0; background-color: #d5d6d7"
																				v-if="myProfile.email && myProfile.email.length <= 0"
																			>
																				<component :is="getIconLucide('Check')" color="#007A77" :size="16" />
																			</b-input-group-text>
																			<b-input-group-text
																				class="cursor-pointer"
																				@click="showChangeMailModal = true"
																				style="background-color: #00a09c"
																			>
																				<component :is="getIconLucide('Pencil')" color="#FFFFFF" :size="16" />
																			</b-input-group-text>
																		</b-input-group-append>
																		<div v-if="isSubmitted && !$v.myProfile.email.emailValid" class="invalid-feedback">
																			{{ FormMSG(252, 'Please, enter valid email') }}
																		</div>
																	</b-input-group>
																</b-form-group>
															</b-col>
															<b-col v-if="useField('Phone')" sm="12" md="12" lg="4" xl="4">
																<b-form-group>
																	<template slot="label">
																		<label for="phone">{{ FormMSG(44, 'Phone') }}</label>
																		<span class="required" v-if="isMandatory('Phone')"> *</span>
																	</template>
																	<b-input-group>
																		<b-form-input
																			:value="myProfile.phone"
																			type="text"
																			id="phone"
																			:placeholder="FormMSG(45, 'Enter your phone number')"
																			style="border-right: none"
																			@keydown.enter.prevent
																			disabled
																		/>
																		<b-input-group-append>
																			<b-input-group-text style="border-radius: 0; background-color: #d5d6d7">
																				<component :is="getIconLucide('Check')" color="#007A77" :size="16" />
																			</b-input-group-text>
																			<b-input-group-text
																				class="cursor-pointer"
																				@click="showChangePhoneModal = true"
																				style="background-color: #00a09c"
																			>
																				<component :is="getIconLucide('Pencil')" color="#FFFFFF" :size="16" />
																			</b-input-group-text>
																		</b-input-group-append>
																	</b-input-group>
																</b-form-group>
															</b-col>
														</b-row>
														<b-row>
															<b-col v-if="useField('FirstName')" sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<template slot="label">
																		<label for="firstname">{{ FormMSG(41, 'First Name') }}</label>
																		<span class="required" v-if="isMandatory('FirstName')"> *</span>
																	</template>
																	<b-form-input
																		v-model="myProfile.firstName"
																		type="text"
																		id="firstname"
																		:placeholder="FormMSG(1101, 'Enter your first name.')"
																		:class="{
																			'is-invalid': isMandatory('FirstName') && myProfile.firstName.length <= 1,
																			'bg-color-grams-hair': disableAll
																		}"
																		:disabled="disableAll"
																		@keydown.enter.prevent
																	></b-form-input>
																	<div
																		v-if="isMandatory('FirstName') && myProfile.firstName.length <= 1"
																		class="invalid-feedback"
																	>
																		{{ FormMSG(251, 'Please, length should be at least 2') }}
																	</div>
																</b-form-group>
															</b-col>
															<b-col v-if="useField('LastName')" sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<template slot="label">
																		<label for="name">{{ FormMSG(40, 'Last Name') }}</label>
																		<span class="required" v-if="isMandatory('LastName')"> *</span>
																	</template>
																	<b-form-input
																		v-model="myProfile.name"
																		type="text"
																		id="name"
																		:placeholder="FormMSG(1100, 'Enter your name.')"
																		:class="{
																			'bg-color-grams-hair': disableAll,
																			'is-invalid': isMandatory('LastName') && myProfile.name.length <= 1
																		}"
																		:disabled="disableAll"
																		@keydown.enter.prevent
																	></b-form-input>
																	<div v-if="isMandatory('LastName') && myProfile.name.length <= 1" class="invalid-feedback">
																		{{ FormMSG(251, 'Please, length should be at least 2') }}
																	</div>
																</b-form-group>
															</b-col>
															<b-col v-if="useField('MaidenName') && !hideMaidenName" sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<template slot="label">
																		<label for="maidenName">{{ FormMSG(1239, ' Maiden name (if different)') }}</label>
																		<span class="required" v-if="isMandatory('MaidenName')"> *</span>
																	</template>
																	<b-form-input
																		v-model="myProfile.maidenName"
																		type="text"
																		id="maidenName"
																		:class="{ 'bg-color-grams-hair': disableAll }"
																		:disabled="disableAll"
																		@keydown.enter.prevent
																	></b-form-input>
																</b-form-group>
															</b-col>
															<b-col
																v-if="useField('Color') && !hidePlanningColor"
																sm="12"
																md="12"
																lg="3"
																xl="3"
																class="d-flex justify-content-center"
															>
																<b-form-group>
																	<template slot="label">
																		<label>{{ FormMSG(525, 'Color') }}</label>
																		<span class="required" v-if="isMandatory('Color')"> *</span>
																	</template>
																	<div class="w-100 d-flex justify-content-start">
																		<verte
																			v-model="myProfile.color"
																			:disabled="disableAll"
																			picker="square"
																			model="hex"
																			menuPosition="top"
																		/>
																	</div>
																</b-form-group>
															</b-col>
														</b-row>
														<b-row>
															<b-col v-if="useField('Nationality')" sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<template slot="label">
																		<label for="nationality">{{ FormMSG(46, 'Nationality') }}</label>
																		<span class="required" v-if="isMandatory('Nationality')"> *</span>
																	</template>
																	<b-form-input
																		v-model="myProfile.nationality"
																		type="text"
																		id="nationality"
																		:placeholder="this.FormMSG(47, 'Enter your nationality')"
																		:class="{ 'bg-color-grams-hair': disableAll }"
																		:disabled="disableAll"
																		@keydown.enter.prevent
																	></b-form-input>
																</b-form-group>
															</b-col>
															<b-col v-if="useField('Language')" sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<template slot="label">
																		<label for="optionsForLanguages">{{ FormMSG(80, 'Langue') }}</label>
																		<span class="required" v-if="isMandatory('Language')"> *</span>
																	</template>
																	<b-form-select
																		v-model="myProfile.language"
																		:options="optionsForLanguages"
																		:disabled="disableAll"
																		id="optionsForLanguages"
																	></b-form-select>
																</b-form-group>
															</b-col>
															<b-col v-if="useField('Gender')" sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<template slot="label">
																		<label>{{ FormMSG(164, 'Gender') }}</label>
																		<span class="required" v-if="isMandatory('Gender')"> *</span>
																	</template>
																	<v-select
																		v-model="myProfile.gender"
																		:options="menuGenders"
																		label="text"
																		:reduce="(option) => option.value"
																		:placeholder="FormMSG(700, 'Select a type')"
																		:clearable="false"
																		:disabled="disableAll"
																		:selectable="selectableTypeItem"
																	>
																		<template #option="option">
																			<div
																				:class="`${
																					(option.disabled && option.disabled === true) ||
																					(option.selectable && option.selectable === true)
																						? 'text-bold'
																						: 'text-color ml-3'
																				}`"
																			>
																				{{ option.text }}
																			</div>
																		</template>
																	</v-select>
																</b-form-group>
															</b-col>
															<b-col v-if="useField('Title')" sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<template slot="label">
																		<label>{{ FormMSG(1240, 'Title') }}</label>
																		<span class="required" v-if="isMandatory('Title')"> *</span>
																	</template>
																	<b-form-select v-model="myProfile.title" :disabled="disableAll">
																		<b-form-select-option
																			v-for="(title, index) in menuTitle"
																			:key="index"
																			:value="title.value"
																		>
																			{{ title.text }}
																		</b-form-select-option>
																	</b-form-select>
																</b-form-group>
															</b-col>
														</b-row>
														<b-row>
															<b-col v-if="useField('Birthdate')" sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<template slot="label">
																		<label for="birthDate">{{ FormMSG(48, 'Birthdate') }}</label>
																		<span class="required" v-if="isMandatory('Birthdate')"> *</span>
																	</template>
																	<v-date-picker
																		v-model="myProfile.birthDate"
																		:locale="lang"
																		:masks="masks"
																		:attributes="attrs"
																		@dayclick="(payload) => handleInputCalendar(payload, 'birthDate')"
																	>
																		<template v-slot="{ inputValue, togglePopover }">
																			<b-input-group>
																				<b-form-input
																					:value="inputValue"
																					:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
																					v-mask="'##/##/####'"
																					readonly
																					:class="{
																						'temp-invalid':
																							isMandatory('Birthdate') && !isDateValid('birthDate')['birthDate']
																					}"
																					@click="togglePopover()"
																					@input="(value) => handleChangeDate(value, 'birthDate')"
																					@keydown.enter.prevent
																				/>
																				<b-input-group-append>
																					<b-input-group-text
																						class="cursor-pointer bg-color-white input-group-text-bg-white"
																						@click="togglePopover()"
																						:class="{
																							'temp-invalid':
																								isMandatory('Birthdate') &&
																								!isDateValid('birthDate')['birthDate']
																						}"
																					>
																						<component
																							:is="getLucideIcon('Calendar')"
																							color="rgba(6, 38, 62, .6)"
																							:size="18"
																							:stroke-width="2.25"
																						/>
																					</b-input-group-text>
																				</b-input-group-append>
																			</b-input-group>
																			<!-- <div
																				v-if="isMandatory('Birthdate') && !isDateValid('birthDate')['birthDate']"
																				class="invalid-feedback"
																			>
																				{{ FormMSG(897, 'Birthdate is required') }}
																			</div> -->
																		</template>
																	</v-date-picker>
																</b-form-group>
															</b-col>
															<b-col v-if="useField('PlaceOfBirth')" sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<template slot="label">
																		<label for="placeOfBirth">{{ FormMSG(159, 'Place of birth') }}</label>
																		<span class="required" v-if="isMandatory('PlaceOfBirth')"> *</span>
																	</template>
																	<b-form-input
																		v-model="myProfile.placeOfBirth"
																		id="placeOfBirth"
																		type="text"
																		:placeholder="FormMSG(161, 'Place of birthstartCapture')"
																		:disabled="disableAll"
																		:class="{ 'bg-color-grams-hair': disableAll }"
																		@keydown.enter.prevent
																	/>
																</b-form-group>
															</b-col>
															<b-col v-if="useField('CityOfBirthZip') && !hidePlaceOfBirthZip" sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<template slot="label">
																		<label for="placeOfBirthZip">{{ FormMSG(160, 'Place of birth ZIP') }}</label>
																		<span class="required" v-if="isMandatory('CityOfBirthZip')"> *</span>
																	</template>

																	<b-form-input
																		v-model="myProfile.placeOfBirthZip"
																		id="placeOfBirthZip"
																		type="text"
																		:placeholder="FormMSG(162, 'Place of birth zip')"
																		:disabled="disableAll"
																		:class="{ 'bg-color-grams-hair': disableAll }"
																		@keydown.enter.prevent
																	/>
																</b-form-group>
															</b-col>
															<b-col v-if="useField('CountryOfBirth')" sm="12" md="12" lg="3" xl="3">
																<b-form-group>
																	<template slot="label">
																		<label>{{ FormMSG(1241, 'Country of birth') }}</label>
																		<span class="required" v-if="isMandatory('CountryOfBirth')"> *</span>
																	</template>
																	<b-form-input
																		v-model="myProfile.birthCountry"
																		:placeholder="FormMSG(1315, 'Enter your country of ...')"
																		:disabled="disableAll"
																		:class="{ 'bg-color-grams-hair': disableAll }"
																		@keydown.enter.prevent
																	/>
																</b-form-group>
															</b-col>
														</b-row>
													</b-col>
													<b-col v-if="useField('ProfilePicture')" class="text-center" sm="4" md="4" lg="3" xl="3">
														<div
															class="profile-picture-block"
															style="display: flex; flex-direction: column; align-items: center; justify-content: center"
														>
															<b-form-group v-model="myProfile.picture">
																<div
																	v-if="myProfile.picture && myProfile.picture.length > 0"
																	style="position: relative; height: 100%; width: 100%"
																>
																	<b-img
																		v-bind="imgPropPicture"
																		rounded="circle"
																		alt="My picture"
																		class="cursor-pointer"
																		style="
																			display: flex;
																			justify-content: center;
																			align-items: center;
																			width: 175px;
																			height: 175px;
																			border-radius: 50%;
																			border: 1px solid #cad0d7;
																			background-color: #f5f7f9;
																		"
																		:src="getImage(myProfile.picture, 'picture')"
																		@click="showImage(myProfile.picture)"
																		:title="FormMSG(254, 'View Image')"
																	></b-img>
																	<component
																		:is="getIconLucide('XCircle')"
																		:size="32"
																		:stroke-width="1.75"
																		color="#ea4e2c"
																		class="cursor-pointer icon-remove-img-profile"
																		style="bottom: 85%; right: 10%"
																		id="tooltip-remove-picture"
																		@click="deleteImg('picture')"
																		v-if="myProfile.picture && myProfile.picture.length > 0"
																	/>
																	<b-tooltip
																		target="tooltip-remove-picture"
																		triggers="hover"
																		v-if="myProfile.picture && myProfile.picture.length > 0"
																	>
																		{{ FormMSG(255, 'Remove picture') }}
																	</b-tooltip>
																</div>
																<div
																	v-else
																	class="avatar"
																	style="
																		display: flex;
																		justify-content: center;
																		align-items: center;
																		width: 175px;
																		height: 175px;
																		border-radius: 50%;
																		border: 1px solid #cad0d7;
																		background-color: #f5f7f9;
																	"
																>
																	<component
																		:is="getIconLucide('User')"
																		fill="#E2E6EB"
																		color="#E2E6EB"
																		:size="120"
																		class="vertical-center"
																	/>
																</div>
															</b-form-group>
															<b-form-group>
																<b-button
																	v-if="!$isPwa() && !hideActionChangePicture"
																	variant="outline-secondary"
																	size="md"
																	@click="startCapture('picture')"
																>
																	<i class="icon-camera"></i>
																	{{ FormMSG(43, 'Change your picture') }}
																</b-button>
																<span class="required" v-if="isMandatory('ProfilePicture')"> *</span>
																<MobileProfilePicCaptureBtn
																	v-if="$isPwa()"
																	crop-type="avatar"
																	:label-button="FormMSG(43, 'Change your picture')"
																	parent-type="user"
																	:parentId="myProfile.id"
																	@click="startCapture('picture')"
																	@change="handleImgsTaked"
																/>
															</b-form-group>
														</div>
														<ShareInformation
															v-if="isFilmSingle"
															:btn-label="FormMSG(169, 'Share my Information')"
															:title-modal="FormMSG(170, 'Send your personal informations sheet')"
															:msg-success="FormMSG(250, 'Profile successfully sent')"
															send-a-copy
														/>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<!-- Identification-->
									<div
										v-if="
											useField('NationalRegistrationNumber') ||
											useField('CompanyNumber') ||
											useField('CongesSpectacleId') ||
											useField('SignaturePicture') ||
											useField('IdName') ||
											useField('IdNumber') ||
											useField('IdPictureFront') ||
											useField('IdPictureBack') ||
											useField('DriverLicense') ||
											useField('DriverLicenseNumber') ||
											useField('DriverLicensePictureFront') ||
											useField('DriverLicensePictureBack')
										"
										class="card-collapsible mt-3"
									>
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('identification')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('ClipboardList')" color="rgba(6, 38, 62, 0.84)" :size="22" />
												<span class="ml-3">{{ FormMSG(4, 'Identification') }}</span>
											</div>
											<div class="d-flex justify-content-end w-20">
												<b-button pill variant="warning" class="mr-2" v-if="identificationMandatoryCount > 0">{{
													identificationMandatoryCount + ' ' + FormMSG(2365, 'field(s) left')
												}}</b-button>
												<component
													v-if="!configAccordion['identification'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['identification'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['identification'].isVisible">
											<div class="sub-title">
												{{ FormMSG(5, 'Identification Numbers') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col v-if="useField('NationalRegistrationNumber')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(55, 'National Registration Number') }}</label>
																<span class="required" v-if="isMandatory('NationalRegistrationNumber')"> *</span>
															</template>
															<InputMask
																class="form-control"
																v-model="myProfile.nationalRegistryNumber"
																mask="99.99.99-999-99"
																:class="{ 'bg-color-grams-hair': disableAll }"
																:placeholder="FormMSG(56, 'Enter your National Registration Number ')"
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('CompanyNumber') && !hideInfoIdentification" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label for="companyNumber">{{ FormMSG(57, 'Company Number') }}</label>
																<span class="required" v-if="isMandatory('CompanyNumber')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.companyNumber"
																type="text"
																id="companyNumber"
																:disabled="disableAll"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('CongesSpectacleId') && !hideInfoIdentification" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label for="conge_spectacles_id">{{ FormMSG(1242, '"Congés spectacles" ID') }}</label>
																<span class="required" v-if="isMandatory('CongesSpectacleId')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.congesSpectacle"
																type="text"
																id="conge_spectacles_id"
																:placeholder="FormMSG(1243, 'Enter your conges spectacles id')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('SignaturePicture') && !hideInfoIdentification" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label for="signatureId">{{ FormMSG(223, 'My signature') }}</label>
																<span class="required" v-if="isMandatory('SignaturePicture')"> *</span>
															</template>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.signatureId, 'signatureId'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.signatureId)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	rounded="circle"
																	alt="My signature"
																	class="cursor-pointer"
																	:src="getImage(myProfile.signatureId, 'signatureId')"
																	@click="showImage(myProfile.signatureId)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button variant="outline-secondary" class="ml-2" @click="startCapture('signatureId')">
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('signatureId')"
																	v-if="myProfile.signatureId && myProfile.signatureId.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col v-if="useField('IdName') && !hideInfoIdentification" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label for="idName">{{ FormMSG(216, 'ID Name') }}</label>
																<span class="required" v-if="isMandatory('IdName')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.idName"
																type="text"
																id="idName"
																:placeholder="FormMSG(217, 'Enter your ID Name ...')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('IdNumber') && !hideInfoIdentification" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label for="idNumber">{{ FormMSG(7, 'ID Number') }}</label>
																<span class="required" v-if="isMandatory('IdNumber')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.idNumber"
																type="text"
																id="idNumber"
																:placeholder="FormMSG(8, 'Enter your ID Number ...')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('IdPictureFront') && !hideInfoIdentification" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label for="pictureId">{{ FormMSG(218, 'ID picture - Front') }}</label>
																<span class="required" v-if="isMandatory('IdPictureFront')"> *</span>
															</template>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.pictureId, 'pictureId'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.pictureId)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropPictureId"
																	rounded="circle"
																	alt="ID picture - front"
																	class="cursor-pointer"
																	:src="getImage(myProfile.pictureId, 'pictureId')"
																	@click="showImage(myProfile.pictureId)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button variant="outline-secondary" class="ml-2" @click="startCapture('pictureId')">
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('pictureId')"
																	v-if="myProfile.pictureId && myProfile.pictureId.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('IdPictureBack') && !hideInfoIdentification" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label for="pictureIdVerso">{{ FormMSG(219, 'ID picture - Back') }}</label>
																<span class="required" v-if="isMandatory('IdPictureBack')"> *</span>
															</template>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.pictureIdVerso, 'pictureIdVerso'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.pictureIdVerso)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropPictureIdVerso"
																	rounded="circle"
																	alt="ID picture - back"
																	class="cursor-pointer"
																	:src="getImage(myProfile.pictureIdVerso, 'pictureIdVerso')"
																	@click="showImage(myProfile.pictureIdVerso)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button variant="outline-secondary" class="ml-2" @click="startCapture('pictureIdVerso')">
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('pictureIdVerso')"
																	v-if="myProfile.pictureIdVerso && myProfile.pictureIdVerso.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col v-if="useField('DriverLicense') && !hideInfoIdentification" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(166, 'Driver licence') }}</label>
																<span class="required" v-if="isMandatory('DriverLicense')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.driverLicense"
																type="text"
																:placeholder="FormMSG(167, 'Enter your driver license')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('DriverLicenseNumber') && !hideInfoIdentification" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(1314, 'Driver licence Number') }}</label>
																<span class="required" v-if="isMandatory('DriverLicenseNumber')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.driverLicenseNumber"
																type="text"
																:placeholder="FormMSG(1316, 'Enter your driver license number')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col
														v-if="useField('DriverLicensePictureFront') && !hideInfoIdentification"
														sm="12"
														md="12"
														lg="3"
														xl="3"
													>
														<b-form-group>
															<template slot="label">
																<label for="pictureDriverLicense">{{ FormMSG(221, 'Driver license picture - Front') }}</label>
																<span class="required" v-if="isMandatory('DriverLicensePictureFront')"> *</span>
															</template>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.pictureDriverLicense, 'pictureDriverLicense'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.pictureDriverLicense)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropPictureDriverLicense"
																	rounded
																	alt="Driver license picture - Front"
																	class="cursor-pointer"
																	:src="getImage(myProfile.pictureDriverLicense, 'pictureDriverLicense')"
																	@click="showImage(myProfile.pictureDriverLicense)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button
																	variant="outline-secondary"
																	class="ml-2"
																	@click="startCapture('pictureDriverLicense')"
																>
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('pictureDriverLicense')"
																	v-if="myProfile.pictureDriverLicense && myProfile.pictureDriverLicense.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('DriverLicensePictureBack') && !hideInfoIdentification" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label for="pictureDriverLicenseVerso">{{
																	FormMSG(222, 'Driver license picture - Back')
																}}</label>
																<span class="required" v-if="isMandatory('DriverLicensePictureBack')"> *</span>
															</template>
															<b-row class="ml-0">
																<div
																	@click="
																		onOpenUrl(getImage(myProfile.pictureDriverLicenseVerso, 'pictureDriverLicenseVerso'))
																	"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.pictureDriverLicenseVerso)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropPictureDriverLicenseVerso"
																	rounded
																	alt="Driver license picture - Back"
																	class="cursor-pointer"
																	:src="getImage(myProfile.pictureDriverLicenseVerso, 'pictureDriverLicenseVerso')"
																	@click="showImage(myProfile.pictureDriverLicenseVerso)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button
																	variant="outline-secondary"
																	class="ml-2"
																	@click="startCapture('pictureDriverLicenseVerso')"
																>
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('pictureDriverLicenseVerso')"
																	v-if="myProfile.pictureDriverLicenseVerso && myProfile.pictureDriverLicenseVerso.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<!--Finance-->
									<div class="card-collapsible mt-3" v-if="financeDisplaySection">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('finance')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('Wallet')" color="rgba(6, 38, 62, 0.84)" :size="22" />
												<span class="ml-3">{{ FormMSG(1244, 'Finance') }}</span>
											</div>
											<div class="d-flex justify-content-end w-20">
												<b-button pill variant="warning" class="mr-2" v-if="financeMandatoryCount > 0">{{
													financeMandatoryCount + ' ' + FormMSG(2365, 'field(s) left')
												}}</b-button>
												<component
													v-if="!configAccordion['finance'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['finance'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['finance'].isVisible">
											<div class="sub-title" v-if="financeDisplayBankSection">{{ FormMSG(9, 'Bank information') }}</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col
														v-if="useField('Iban')"
														sm="12"
														md="12"
														:lg="hideBankAccountOwnerName ? 4 : 6"
														:xl="hideBankAccountOwnerName ? 4 : 6"
													>
														<b-form-group label-for="iban" :class="{ 'is-warning': userBankWarning.iban }">
															<template slot="label">
																<label>{{ FormMSG(49, 'IBAN') }}</label>
																<span class="required" v-if="isMandatory('Iban')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.iban"
																type="text"
																id="iban"
																:placeholder="FormMSG(50, 'Enter your bank account number')"
																:class="{ 'bg-color-grams-hair': disableAll }"
																:disabled="disableAll"
																@input="handleSupplierIbanChange($event, 'iban')"
																@keydown.enter.prevent
															></b-form-input>
															<div v-if="userBankWarning.iban" class="warning-feedback">
																{{ FormMSG(97986, "IBAN seems not valid, please make sure it's corrrect") }}
															</div>
														</b-form-group>
													</b-col>
													<b-col
														v-if="useField('Bic')"
														sm="12"
														md="12"
														:lg="hideBankAccountOwnerName ? 4 : 6"
														:xl="hideBankAccountOwnerName ? 4 : 6"
													>
														<b-form-group :class="{ 'is-warning': userBankWarning.bic }">
															<template slot="label">
																<label for="bic">{{ FormMSG(51, 'BIC') }}</label>
																<span class="required" v-if="isMandatory('Bic')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.bic"
																type="text"
																id="bic"
																:placeholder="FormMSG(52, 'Enter your bank BIC')"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																@input="handleSupplierBicChange($event, 'bic')"
																@keydown.enter.prevent
															></b-form-input>
															<div v-if="userBankWarning.bic" class="warning-feedback">
																{{ FormMSG(909097986, "BIC seems not valid, please make sure it's corrrect") }}
															</div>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('BankDetailDocument') && hideBankAccountOwnerName" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label for="bankDetailDocument">{{
																FormMSG(1247, 'Bank detail document') + `${isMandatory('BankDetailDocument') ? ' *' : ''}`
															}}</label>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.bankDetailXid, 'bankDetailXid'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.bankDetailXid)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	v-model="myProfile.bankDetailXid"
																	rounded
																	alt="Bank detail document"
																	class="cursor-pointer"
																	:src="getImage(myProfile.bankDetailXid, 'bankDetailXid')"
																	@click="showImage(myProfile.bankDetailXid)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button variant="outline-secondary" class="ml-2" @click="startCapture('bankDetailXid')">
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('bankDetailXid')"
																	v-if="myProfile.bankDetailXid && myProfile.bankDetailXid.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
																&nbsp;
																<div
																	class="d-flex align-items-center"
																	v-if="isDeterAction && myProfile.bankDetailXid.length === 0"
																>
																	<b-button
																		size="sm"
																		class="btn bg-transparent border-0"
																		v-b-tooltip.hover.bottom.html="FormMSG(1750, 'Bank detail document is not downloaded')"
																	>
																		<component :is="getIconLucide(ICONS.ALERT_TRIANGLE.name)" color="#ffc107" :size="30" />
																	</b-button>
																</div>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row v-if="!hideBankAccountOwnerName">
													<b-col v-if="useField('BankAccountOwnerName')" sm="12" md="12" lg="6" xl="6">
														<b-form-group>
															<template slot="label">
																<label for="bic">{{ this.FormMSG(1245, 'Bank account owner name') }}</label>
																<span class="required" v-if="isMandatory('BankAccountOwnerName')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.bankAccountOwnerName"
																type="text"
																id="bic"
																:placeholder="this.FormMSG(1246, 'Enter your bank account owner name')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('BankDetailDocument')" sm="12" md="12" lg="6" xl="6">
														<b-form-group>
															<template slot="label">
																<label for="bankDetailDocument">{{ FormMSG(1247, 'Bank detail document') }}</label>
																<span class="required" v-if="isMandatory('BankDetailDocument')"> *</span>
															</template>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.bankDetailXid, 'bankDetailXid'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.bankDetailXid)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	v-model="myProfile.bankDetailXid"
																	rounded
																	alt="Bank detail document"
																	class="cursor-pointer"
																	:src="getImage(myProfile.bankDetailXid, 'bankDetailXid')"
																	@click="showImage(myProfile.bankDetailXid)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button variant="outline-secondary" class="ml-2" @click="startCapture('bankDetailXid')">
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('bankDetailXid')"
																	v-if="myProfile.bankDetailXid && myProfile.bankDetailXid.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
																&nbsp;
																<div
																	class="d-flex align-items-center"
																	v-if="isDeterAction && myProfile.bankDetailXid.length === 0"
																>
																	<b-button
																		size="sm"
																		class="btn bg-transparent border-0"
																		v-b-tooltip.hover.bottom.html="FormMSG(1750, 'Bank detail document is not downloaded')"
																	>
																		<component :is="getIconLucide(ICONS.ALERT_TRIANGLE.name)" color="#ffc107" :size="30" />
																	</b-button>
																</div>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<!-- Second bank information -->
											<div class="sub-title" v-if="financeDisplayBankSection">
												{{ FormMSG(1945, 'Expense Reimbursement Bank Information') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col
														v-if="useField('SecondIban')"
														sm="12"
														md="12"
														:lg="useField('SecondBankAccountOwnerName') ? 6 : 4"
														:xl="useField('SecondBankAccountOwnerName') ? 6 : 4"
													>
														<b-form-group label-for="iban" :class="{ 'is-warning': userBankWarning.secondIban }">
															<template slot="label">
																<label>{{ FormMSG(49, 'IBAN') }}</label>
																<span class="required" v-if="isMandatory('SecondIban')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.secondIban"
																type="text"
																id="secondIban"
																:placeholder="FormMSG(50, 'Enter your bank account number')"
																:class="{ 'bg-color-grams-hair': disableAll }"
																:disabled="disableAll"
																@input="handleSupplierIbanChange($event, 'secondIban')"
																@keydown.enter.prevent
															></b-form-input>
															<div v-if="userBankWarning.secondIban" class="warning-feedback">
																{{ FormMSG(97986, "IBAN seems not valid, please make sure it's corrrect") }}
															</div>
														</b-form-group>
													</b-col>
													<b-col
														v-if="useField('SecondBic')"
														sm="12"
														md="12"
														:lg="useField('SecondBankAccountOwnerName') ? 6 : 4"
														:xl="useField('SecondBankAccountOwnerName') ? 6 : 4"
													>
														<b-form-group :class="{ 'is-warning': userBankWarning.secondBic }">
															<template slot="label">
																<label>{{ FormMSG(51, 'BIC') }}</label>
																<span class="required" v-if="isMandatory('SecondBic')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.secondBic"
																type="text"
																id="secondBic"
																:placeholder="FormMSG(52, 'Enter your bank BIC')"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																@input="handleSupplierBicChange($event, 'secondBic')"
																@keydown.enter.prevent
															></b-form-input>
															<div v-if="userBankWarning.secondBic" class="warning-feedback">
																{{ FormMSG(909097986, "BIC seems not valid, please make sure it's corrrect") }}
															</div>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col v-if="useField('SecondBankAccountOwnerName')" sm="12" md="12" lg="6" xl="6">
														<b-form-group>
															<template slot="label">
																<label>{{ this.FormMSG(1245, 'Bank account owner name') }}</label>
																<span class="required" v-if="isMandatory('SecondBankAccountOwnerName')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.secondBankAccountOwnerName"
																type="text"
																id="bic"
																:placeholder="this.FormMSG(1246, 'Enter your bank account owner name')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('SecondBankDetailDocument')" sm="12" md="12" lg="6" xl="6">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(1247, 'Bank detail document') }}</label>
																<span class="required" v-if="isMandatory('SecondBankDetailDocument')"> *</span>
															</template>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.secondBankDetailXid, 'secondBankDetailXid'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.secondBankDetailXid)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	v-model="myProfile.secondBankDetailXid"
																	rounded
																	alt="Bank detail document"
																	class="cursor-pointer"
																	:src="getImage(myProfile.secondBankDetailXid, 'secondBankDetailXid')"
																	@click="showImage(myProfile.secondBankDetailXid)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button variant="outline-secondary" class="ml-2" @click="startCapture('secondBankDetailXid')">
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('secondBankDetailXid')"
																	v-if="myProfile.secondBankDetailXid && myProfile.secondBankDetailXid.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
																&nbsp;
																<div
																	class="d-flex align-items-center"
																	v-if="isDeterAction && myProfile.secondBankDetailXid.length === 0"
																>
																	<b-button
																		size="sm"
																		class="btn bg-transparent border-0"
																		v-b-tooltip.hover.bottom.html="FormMSG(1750, 'Bank detail document is not downloaded')"
																	>
																		<component :is="getIconLucide(ICONS.ALERT_TRIANGLE.name)" color="#ffc107" :size="30" />
																	</b-button>
																</div>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<!-- Second bank information -->
											<div v-if="(useField('Taxes') && useField('Iban')) || useField('Bic')" class="divider"></div>
											<div v-if="useField('Taxes')" class="sub-title">
												{{ FormMSG(260, 'Taxes') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col v-if="useField('Taxes')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(261, 'Taxe') }}</label>
																<span class="required" v-if="isMandatory('Taxes')"> *</span>
															</template>
															<b-input-group>
																<b-form-input
																	v-model="myProfile.taxesPercentage"
																	type="number"
																	step="1"
																	min="0"
																	max="100"
																	@keydown.enter.prevent
																/>
																<b-input-group-append>
																	<b-input-group-text style="background-color: #00a09c">
																		<component
																			:is="getIconLucide('Percent')"
																			color="#ffffff"
																			:size="16"
																			:stroke-width="3.5"
																		/>
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('TaxDeclarationCountry') && !hideDeclarationCountry" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label for="bic">{{ this.FormMSG(1248, 'Tax declaration country') }}</label>
																<span class="required" v-if="isMandatory('TaxDeclarationCountry')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.taxCountry"
																type="text"
																id="taxCountry"
																:placeholder="this.FormMSG(1249, 'Enter your tax declaration country')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<!-- Address-->
									<div v-if="addressDisplaySection" class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('address')"
										>
											<div class="title w-90">
												<b-icon icon="geo-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{
													FormMSG(11, 'Address')
												}}</span>
											</div>
											<div class="d-flex justify-content-end w-20">
												<b-button pill variant="warning" class="mr-2" v-if="addressMandatoryCount > 0">{{
													addressMandatoryCount + ' ' + FormMSG(2365, 'field(s) left')
												}}</b-button>
												<component
													v-if="!configAccordion['address'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['address'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['address'].isVisible" v-if="myProfile.address">
											<div class="sub-title">
												{{ FormMSG(1250, 'Legal address') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col v-if="useField('Country')" sm="12" md="12" lg="6" xl="6">
														<b-form-group>
															<template slot="label">
																<label for="country">{{ FormMSG(106, 'Country') }}</label>
																<span class="required" v-if="isMandatory('Country')"> *</span>
															</template>
															<v-select
																:clearable="false"
																v-model="countryKey"
																:options="countries"
																label="text"
																:reduce="(option) => option.value"
																:disabled="disableAll"
																@input="getCountry"
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('InternetResearch')" sm="12" md="12" lg="6" xl="6">
														<b-form-group>
															<google-auto-complete
																ref="google-search-address"
																:label="FormMSG(12, 'Internet search')"
																:country="countryCode"
																:disabled="disableAll"
																:clear-input="clearLegalAddress"
																@placechanged="(item) => handleAutoComplete(item, 'legalAddress')"
															/>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col v-if="useField('Street')" sm="10" md="10" lg="4" xl="4">
														<b-form-group>
															<template slot="label">
																<label for="address">{{ FormMSG(100, 'Street') }}</label>
																<span class="required" v-if="isMandatory('Street')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.address.street"
																type="text"
																id="street"
																:placeholder="FormMSG(1103, 'Enter your street')"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('Number')" sm="2" md="2" lg="4" xl="4">
														<b-form-group>
															<template slot="label">
																<label for="number">{{ FormMSG(101, 'Number') }}</label>
																<span class="required" v-if="isMandatory('Number')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.address.number"
																type="text"
																id="number"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('Box')" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<template slot="label">
																<label for="box">{{ this.FormMSG(102, 'Box') }}</label>
																<span class="required" v-if="isMandatory('Box')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.address.box"
																type="text"
																id="box"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col v-if="useField('ZipCode')" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<template slot="label">
																<label for="zip">{{ FormMSG(104, 'Postal Code') }}</label>
																<span class="required" v-if="isMandatory('ZipCode')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.address.zip"
																type="text"
																id="zip"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('City')" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<template slot="label">
																<label for="city">{{ FormMSG(103, 'City') }}</label>
																<span class="required" v-if="isMandatory('City')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.address.city"
																type="text"
																id="city"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('StateRegion')" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<template slot="label">
																<label for="state">{{ FormMSG(105, 'State') }}</label>
																<span class="required" v-if="isMandatory('StateRegion')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.address.state"
																type="text"
																id="state"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<b-collapse v-model="isShowPostalAddress">
												<div class="sub-title">
													{{ FormMSG(1312, 'Postal address') }}
												</div>
												<div class="content-card-collapsible" v-if="myProfile.postalAddress">
													<b-row>
														<b-col sm="12" md="12" lg="6" xl="6">
															<b-form-group>
																<template slot="label">
																	<label for="country">{{ FormMSG(106, 'Country') }}</label>
																	<span class="required" v-if="isMandatory('Country')"> *</span>
																</template>
																<v-select
																	:clearable="false"
																	v-model="countryKeyPostalAddress"
																	:options="countries"
																	label="text"
																	:reduce="(option) => option.value"
																	@input="getCountryPostalAddress"
																/>
															</b-form-group>
														</b-col>
														<b-col sm="12" md="12" lg="6" xl="6">
															<b-form-group>
																<google-auto-complete
																	ref="google-search-address"
																	:label="FormMSG(12, 'Internet search')"
																	:country="countryCodePostalAddress"
																	:clear-input="clearPostalAddress"
																	@placechanged="(item) => handleAutoComplete(item, 'postalAddress')"
																/>
															</b-form-group>
														</b-col>
													</b-row>
													<b-row>
														<b-col sm="10" md="10" lg="4" xl="4">
															<b-form-group>
																<template slot="label">
																	<label for="address">{{ FormMSG(100, 'Street') }}</label>
																	<span class="required" v-if="isMandatory('Street')"> *</span>
																</template>
																<b-form-input
																	v-model="myProfile.postalAddress.street"
																	type="text"
																	id="street"
																	:placeholder="FormMSG(1103, 'Enter your street')"
																	@keydown.enter.prevent
																></b-form-input>
															</b-form-group>
														</b-col>
														<b-col sm="2" md="2" lg="4" xl="4">
															<b-form-group>
																<template slot="label">
																	<label for="number">{{ FormMSG(101, 'Number') }}</label>
																	<span class="required" v-if="isMandatory('Number')"> *</span>
																</template>
																<b-form-input
																	v-model="myProfile.postalAddress.number"
																	type="text"
																	id="number"
																	@keydown.enter.prevent
																></b-form-input>
															</b-form-group>
														</b-col>

														<b-col sm="12" md="12" lg="4" xl="4">
															<b-form-group>
																<template slot="label">
																	<label for="box">{{ this.FormMSG(102, 'Box') }}</label>
																	<span class="required" v-if="isMandatory('Box')"> *</span>
																</template>
																<b-form-input
																	v-model="myProfile.postalAddress.box"
																	type="text"
																	id="box"
																	@keydown.enter.prevent
																></b-form-input>
															</b-form-group>
														</b-col>
													</b-row>
													<b-row>
														<b-col sm="12" md="12" lg="4" xl="4">
															<b-form-group>
																<template slot="label">
																	<label for="zip">{{ FormMSG(104, 'Postal Code') }}</label>
																	<span class="required" v-if="isMandatory('ZipCode')"> *</span>
																</template>
																<b-form-input
																	v-model="myProfile.postalAddress.zip"
																	type="text"
																	id="zip"
																	@keydown.enter.prevent
																></b-form-input>
															</b-form-group>
														</b-col>
														<b-col sm="12" md="12" lg="4" xl="4">
															<b-form-group>
																<template slot="label">
																	<label for="city">{{ FormMSG(103, 'City') }}</label>
																	<span class="required" v-if="isMandatory('City')"> *</span>
																</template>
																<b-form-input
																	v-model="myProfile.postalAddress.city"
																	type="text"
																	id="city"
																	@keydown.enter.prevent
																></b-form-input>
															</b-form-group>
														</b-col>
														<b-col v-if="useField('StateRegion')" sm="12" md="12" lg="4" xl="4">
															<b-form-group>
																<template slot="label">
																	<label for="state">{{ FormMSG(105, 'State') }}</label>
																	<span class="required" v-if="isMandatory('StateRegion')"> *</span>
																</template>
																<b-form-input
																	v-model="myProfile.postalAddress.state"
																	type="text"
																	id="state"
																	@keydown.enter.prevent
																></b-form-input>
															</b-form-group>
														</b-col>
													</b-row>
												</div>
											</b-collapse>
											<b-row>
												<div style="margin-left: 28px">
													<b-button
														v-if="!hideAddPostalAddress"
														size="sm"
														:variant="isShowPostalAddress ? 'outline-danger' : 'outline-secondary'"
														class="d-flex flex-row justify-content-center gap-2 align-items-center w-auto h-auto"
														style="height: 40px; border-radius: 0.5rem; background-color: #fff; cursor: pointer"
														@click="isShowPostalAddress = !isShowPostalAddress"
													>
														<div
															class="d-flex flex-row align-items-center justify-content-center btn-address"
															style="color: #06263e"
														>
															<span v-if="isShowPostalAddress">
																<component :is="getLucideIcon(ICONS.X_CLEAR.name)" :color="ICONS.X_CLEAR.color" :size="22" />
																{{ FormMSG(1313, 'Close') }}
															</span>
															<span v-else>
																<component :is="getLucideIcon('PlusCircle')" color="#47C7BF" :size="22" />
																{{ FormMSG(1251, 'Add postal address (if different)') }}
															</span>
														</div>
													</b-button>
												</div>
											</b-row>
										</b-collapse>
									</div>
									<!--Health-->
									<div class="card-collapsible mt-3" v-if="healthDisplaySection">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('health')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('HeartPulse')" color="rgba(6, 38, 62, 0.84)" :size="22" />
												<span class="ml-3">{{ FormMSG(1252, 'Health') }}</span>
											</div>
											<div class="d-flex justify-content-end w-20">
												<b-button pill variant="warning" class="mr-2" v-if="healthMandatoryCount > 0">{{
													healthMandatoryCount + ' ' + FormMSG(2365, 'field(s) left')
												}}</b-button>
												<component
													v-if="!configAccordion['health'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['health'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['health'].isVisible">
											<div class="content-card-collapsible">
												<b-row>
													<b-col v-if="useField('SocialSecurityNumber')" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(54, 'Social Security Number') }}</label>
																<span class="required" v-if="isMandatory('SocialSecurityNumber')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.socSecNumber"
																:class="{ 'is-warning': isSocSecNumberWarning, 'bg-color-grams-hair': disableAll }"
																:disabled="disableAll"
																:placeholder="FormMSG(154, 'Enter your Social Security Number')"
																@input="handleSocSecNumberChange"
																@keydown.enter.prevent
															/>
															<div v-if="isSocSecNumberWarning" class="warning-feedback">
																{{ FormMSG(8979997, 'The social security number seems not valid') }}
															</div>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('SocialSecurityCard') && !hideInfoHealth" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<template slot="label">
																<label for="socialSecurityCardXid">{{ FormMSG(1255, 'Social security card') }}</label>
																<span class="required" v-if="isMandatory('SocialSecurityCard')"> *</span>
															</template>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.socialSecurityCardXid, 'socialSecurityCardXid'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.socialSecurityCardXid)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	rounded
																	alt="socialSecurityCardXid"
																	class="cursor-pointer"
																	:src="getImage(myProfile.socialSecurityCardXid, 'socialSecurityCardXid')"
																	@click="showImage(myProfile.socialSecurityCardXid)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button
																	variant="outline-secondary"
																	class="ml-2"
																	@click="startCapture('socialSecurityCardXid')"
																>
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('socialSecurityCardXid')"
																	v-if="myProfile.socialSecurityCardXid && myProfile.socialSecurityCardXid.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('InsuranceCertificate') && !hideInfoHealth" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<template slot="label">
																<label for="insuranceCertificate">{{ FormMSG(1256, 'Insurance certificate') }}</label>
																<span class="required" v-if="isMandatory('InsuranceCertificate')"> *</span>
															</template>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.insuranceCertificateXid, 'insuranceCertificateXid'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.insuranceCertificateXid)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	rounded
																	alt="inscuranceCertificate"
																	class="cursor-pointer"
																	:src="getImage(myProfile.insuranceCertificateXid, 'insuranceCertificateXid')"
																	@click="showImage(myProfile.insuranceCertificateXid)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button
																	variant="outline-secondary"
																	class="ml-2"
																	@click="startCapture('insuranceCertificateXid')"
																>
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('insuranceCertificateXid')"
																	v-if="myProfile.insuranceCertificateXid && myProfile.insuranceCertificateXid.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col v-if="useField('SocialSecurityCenter') && !hideInfoHealth" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<template slot="label">
																<label for="socialSecurityCenter">{{ this.FormMSG(1700, 'Social security center') }}</label>
																<span class="required" v-if="isMandatory('SocialSecurityCenter')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.socialSecurityCenter"
																type="text"
																id="socialSecurityCenter"
																:placeholder="this.FormMSG(1258, 'Enter your social security center')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('SocialSecurityCenterAddress') && !hideInfoHealth" sm="12" md="12" lg="8" xl="8">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(1259, 'Social security center address') }}</label>
																<span class="required" v-if="isMandatory('SocialSecurityCenterAddress')"> *</span>
															</template>
															<v-address
																:params="{ title: 'Social security center address' }"
																:address-id="myProfile.socialSecurityCenterAddressId"
																@change="handleDynamicAddressChange"
																@delete="handleDynamicAddressDelete"
																@address-maplink-change="handleAddressMapLinkChange"
															/>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col v-if="useField('LastMedicalVisitDate') && !hideInfoHealth" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(1260, 'Last medical visit date') }}</label>
																<span class="required" v-if="isMandatory('LastMedicalVisitDate')"> *</span>
															</template>
															<v-date-picker
																v-model="myProfile.lastMedicalVisit"
																:locale="lang"
																:masks="masks"
																:attributes="attrs"
																@dayclick="(payload) => handleInputCalendar(payload, 'lastMedicalVisit')"
															>
																<template v-slot="{ inputValue, togglePopover }">
																	<b-input-group>
																		<b-form-input
																			:value="inputValue"
																			:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
																			v-mask="'##/##/####'"
																			readonly
																			:class="{
																				'temp-invalid':
																					isMandatory('LastMedicalVisitDate') &&
																					!isDateValid('lastMedicalVisit')['lastMedicalVisit']
																			}"
																			@click="togglePopover()"
																			@input="(value) => handleChangeDate(value, 'lastMedicalVisit')"
																			@keydown.enter.prevent
																		/>
																		<b-input-group-append>
																			<b-input-group-text
																				class="cursor-pointer bg-color-white input-group-text-bg-white"
																				@click="togglePopover()"
																				:class="{
																					'temp-invalid':
																						isMandatory('LastMedicalVisitDate') &&
																						!isDateValid('lastMedicalVisit')['lastMedicalVisit']
																				}"
																			>
																				<component
																					:is="getLucideIcon('Calendar')"
																					color="rgba(6, 38, 62, .6)"
																					:size="18"
																					:stroke-width="2.25"
																				/>
																			</b-input-group-text>
																		</b-input-group-append>
																	</b-input-group>
																	<!-- <div
																		v-if="
																			isMandatory('LastMedicalVisitDate') &&
																			!isDateValid('lastMedicalVisit')['lastMedicalVisit']
																		"
																		class="invalid-feedback"
																	>
																		{{ FormMSG(898, 'Last medical visit date is required') }}
																	</div> -->
																</template>
															</v-date-picker>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('MedicalVisitValidity') && !hideInfoHealth" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(1262, 'Medical visit validity') }}</label>
																<span class="required" v-if="isMandatory('MedicalVisitValidity')"> *</span>
															</template>
															<v-date-picker
																v-model="myProfile.medicalVisitValidity"
																:locale="lang"
																:masks="masks"
																:attributes="attrs"
																@dayclick="(payload) => handleInputCalendar(payload, 'medicalVisitValidity')"
															>
																<template v-slot="{ inputValue, togglePopover }">
																	<b-input-group>
																		<b-form-input
																			:value="inputValue"
																			:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
																			v-mask="'##/##/####'"
																			readonly
																			:class="{
																				'temp-invalid':
																					isMandatory('MedicalVisitValidity') &&
																					!isDateValid('medicalVisitValidity')['medicalVisitValidity']
																			}"
																			@click="togglePopover()"
																			@input="(value) => handleChangeDate(value, 'medicalVisitValidity')"
																			@keydown.enter.prevent
																		/>
																		<b-input-group-append>
																			<b-input-group-text
																				:class="{
																					'temp-invalid':
																						isMandatory('MedicalVisitValidity') &&
																						!isDateValid('medicalVisitValidity')['medicalVisitValidity']
																				}"
																				class="cursor-pointer bg-color-white input-group-text-bg-white"
																				@click="togglePopover()"
																			>
																				<component
																					:is="getLucideIcon('Calendar')"
																					color="rgba(6, 38, 62, .6)"
																					:size="18"
																					:stroke-width="2.25"
																				/>
																			</b-input-group-text>
																		</b-input-group-append>
																	</b-input-group>
																	<!-- <div
																		v-if="
																			isMandatory('MedicalVisitValidity') &&
																			!isDateValid('medicalVisitValidity')['medicalVisitValidity']
																		"
																		class="invalid-feedback"
																	>
																		{{ FormMSG(903, 'Medical visit validity is required') }}
																	</div> -->
																</template>
															</v-date-picker>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('MedicalVisitDocument') && !hideInfoHealth" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label for="MedicalVisitDocument">{{ FormMSG(1264, 'Medical visit document') }}</label>
																<span class="required" v-if="isMandatory('MedicalVisitDocument')"> *</span>
															</template>
															<b-row class="ml-0">
																<div
																	@click="onOpenUrl(getImage(myProfile.medicalVisitDocumentXid, 'medicalVisitDocumentXid'))"
																	class="d-flex justify-content-center align-items-center cursor-pointer"
																	v-if="checkFile(myProfile.medicalVisitDocumentXid)"
																>
																	<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																</div>
																<b-img
																	v-else
																	v-bind="imgPropSignatureId"
																	v-model="myProfile.medicalVisitDocumentXid"
																	rounded
																	alt="medicalVisitDocument"
																	class="cursor-pointer"
																	:src="getImage(myProfile.medicalVisitDocumentXid, 'medicalVisitDocumentXid')"
																	@click="showImage(myProfile.medicalVisitDocumentXid)"
																	:title="FormMSG(254, 'View Image')"
																></b-img>
																<b-button
																	variant="outline-secondary"
																	class="ml-2"
																	@click="startCapture('medicalVisitDocumentXid')"
																>
																	<div class="d-flex justify-content-center align-items-center">
																		<component :is="getIconLucide('Camera')" :size="20" />
																		<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																	</div>
																</b-button>
																<button
																	class="btn-transparent pl-0 ml-2"
																	@click.prevent.stop="deleteImg('medicalVisitDocumentXid')"
																	v-if="myProfile.medicalVisitDocumentXid && myProfile.medicalVisitDocumentXid.length > 0"
																>
																	<component :is="getIconLucide('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
																</button>
																&nbsp;
																<div
																	class="d-flex align-items-center"
																	v-if="isDeterAction && myProfile.medicalVisitDocumentXid.length === 0"
																>
																	<b-button
																		size="sm"
																		class="btn bg-transparent border-0"
																		v-b-tooltip.hover.bottom.html="
																			FormMSG(1751, 'Medical visit document is not downloaded')
																		"
																	>
																		<component :is="getIconLucide(ICONS.ALERT_TRIANGLE.name)" color="#ffc107" :size="30" />
																	</b-button>
																</div>
															</b-row>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col
														v-if="useField('WorkerWithADisability') && !hideInfoHealth"
														sm="12"
														md="12"
														lg="3"
														xl="3"
														class="d-flex justify-content-start"
													>
														<b-form-group class="text-start">
															<template slot="label">
																<label>{{ FormMSG(1265, 'Worker with a disability') }}</label>
																<span class="required" v-if="isMandatory('WorkerWithADisability')"> *</span>
															</template>
															<b-form-checkbox class="d-flex justify-content-start" size="lg" v-model="myProfile.disabled" switch>
																<span class="text-redired">{{
																	myProfile.disabled ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No')
																}}</span>
															</b-form-checkbox>
														</b-form-group>
													</b-col>
													<b-col v-if="myProfile.disabled && useField('DisabilityNotificationDate')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label>{{ FormMSG(1266, 'Disability notification date') }}</label>
															<span class="required" v-if="isMandatory('DisabilityNotificationDate')"> *</span>
															<v-date-picker
																v-model="myProfile.disabilityNotification"
																:locale="lang"
																:masks="masks"
																:attributes="attrs"
																@dayclick="(payload) => handleInputCalendar(payload, 'disabilityNotification')"
															>
																<template v-slot="{ inputValue, togglePopover }">
																	<b-input-group>
																		<b-form-input
																			:value="inputValue"
																			:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
																			v-mask="'##/##/####'"
																			readonly
																			:class="{
																				'temp-invalid':
																					isMandatory('DisabilityNotificationDate') &&
																					!isDateValid('disabilityNotification')['disabilityNotification']
																			}"
																			@click="togglePopover()"
																			@input="(value) => handleChangeDate(value, 'disabilityNotification')"
																			@keydown.enter.prevent
																		/>
																		<b-input-group-append>
																			<b-input-group-text
																				class="cursor-pointer bg-color-white input-group-text-bg-white"
																				@click="togglePopover()"
																			>
																				<component
																					:is="getLucideIcon('Calendar')"
																					color="rgba(6, 38, 62, .6)"
																					:size="18"
																					:stroke-width="2.25"
																				/>
																			</b-input-group-text>
																		</b-input-group-append>
																	</b-input-group>
																	<div
																		v-if="
																			isMandatory('DisabilityNotificationDate') &&
																			!isDateValid('disabilityNotification')['disabilityNotification']
																		"
																		class="invalid-feedback"
																	>
																		{{ FormMSG(900, 'Disability notification date is required') }}
																	</div>
																</template>
															</v-date-picker>
														</b-form-group>
													</b-col>
													<b-col v-if="myProfile.disabled && useField('Type')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="type">{{ this.FormMSG(1268, 'Type') }}</label>
															<span class="required" v-if="isMandatory('Type')"> *</span>

															<b-form-input
																v-model="myProfile.disabilityType"
																type="text"
																id="type"
																:placeholder="this.FormMSG(1269, 'Enter type')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col v-if="myProfile.disabled && useField('Status')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="status">{{ this.FormMSG(1270, 'Status') }}</label>
															<span class="required" v-if="isMandatory('Status')"> *</span>

															<b-form-input
																v-model="myProfile.disabilityStatus"
																type="text"
																id="status"
																:placeholder="this.FormMSG(1271, 'Enter status')"
																@keydown.enter.prevent
															></b-form-input>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col v-if="myProfile.disabled && useField('Invalidity')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="status">{{ this.FormMSG(1272, 'Invalidity') }}</label>
															<span class="required" v-if="isMandatory('Invalidity')"> *</span>
															<b-form-select v-model="myProfile.invalidity">
																<b-form-select-option
																	v-for="(gender, index) in menuInvalidity"
																	:key="index"
																	:value="gender.value"
																>
																	{{ gender.text }}
																</b-form-select-option>
															</b-form-select>
														</b-form-group>
													</b-col>
													<b-col
														v-if="myProfile.disabled && useField('Rqth')"
														sm="12"
														md="12"
														lg="3"
														xl="3"
														class="d-flex align-items-center mt-2"
													>
														<b-form-checkbox v-model="myProfile.rqth" size="lg" :value="true" class="d-flex align-items-center">
															<div class="mt-1">
																{{ FormMSG(1273, 'RQTH') }}
																<span class="required" v-if="isMandatory('Rqth')"> *</span>
															</div>
														</b-form-checkbox>
													</b-col>
													<b-col v-if="myProfile.disabled && useField('InvalidityPercentage')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label for="status">{{ this.FormMSG(1274, 'Invalidity percentage') }}</label>
															<span class="required" v-if="isMandatory('InvalidityPercentage')"> *</span>
															<b-input-group>
																<b-form-input
																	v-model="myProfile.invalidityPourcentage"
																	type="number"
																	step="1"
																	min="0"
																	max="100"
																	@keydown.enter.prevent
																/>
																<b-input-group-append>
																	<b-input-group-text style="background-color: #00a09c">
																		<component
																			:is="getIconLucide('Percent')"
																			color="#ffffff"
																			:size="16"
																			:stroke-width="3.5"
																		/>
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col v-if="useField('HaveAllergies') && !hideInfoHealth" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(25, 'Have Allergies?') }}</label>
																<span class="required" v-if="isMandatory('HaveAllergies')"> *</span>
															</template>
															<b-form-checkbox size="lg" v-model="haveAllergies" @change="handleChangeHaveAllergies" switch />
														</b-form-group>
													</b-col>
													<b-col v-if="useField('AllergiesDetails') && !hideInfoHealth" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(26, 'Allergies details') }}</label>
																<span class="required" v-if="isMandatory('AllergiesDetails')"> *</span>
															</template>
															<b-form-textarea
																v-model="myProfile.allergies"
																type="textarea"
																rows="2"
																:placeholder="FormMSG(6, 'Enter allergies details...')"
																autocomplete="off"
																:disabled="haveAllergies === false"
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<!-- Family -->
									<div v-if="familyDisplaySection" class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('family')"
										>
											<div class="title w-90">
												<b-icon icon="people-fill" style="color: rgba(6, 38, 62, 0.84)" />
												<span class="ml-3">{{ FormMSG(13, 'Family') }}</span>
											</div>
											<div class="d-flex justify-content-end w-20">
												<b-button pill variant="warning" class="mr-2" v-if="familyMandatoryCount > 0">{{
													familyMandatoryCount + ' ' + FormMSG(2365, 'field(s) left')
												}}</b-button>
												<component
													v-if="!configAccordion['family'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['family'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['family'].isVisible">
											<div
												class="sub-title"
												v-if="
													useField('CivilStatus') ||
													useField('SpouseFirstName') ||
													useField('SpouseLastName') ||
													useField('SpouseBirthdate') ||
													useField('SpouseHasSalary') ||
													useField('SpouseProfession') ||
													useField('NumberOfDependentChildren') ||
													useField('NumberOfDisabledChildren') ||
													useField('NumberOfDependentPerson') ||
													useField('NumberOfDisabledPerson')
												"
											>
												{{ FormMSG(14, 'Family composition') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col v-if="useField('CivilStatus')" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<template slot="label">
																<label for="optionsForCivilStatus">{{ FormMSG(53, 'Civil status') }}</label>
																<span class="required" v-if="isMandatory('CivilStatus')"> *</span>
															</template>
															<b-form-select
																v-model="myProfile.civilStatus"
																:options="optionsForCivilStatus"
																:disabled="disableAll"
																id="optionsForCivilStatus"
															></b-form-select>
														</b-form-group>
													</b-col>
													<b-col
														v-if="(myProfile.civilStatus === 3 || myProfile.civilStatus === 8) && useField('SpouseFirstName')"
														sm="12"
														md="12"
														lg="4"
														xl="4"
													>
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(174, 'Spouse first name') }}</label>
																<span class="required" v-if="isMandatory('SpouseFirstName')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.spouseFirstName"
																type="text"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																:placeholder="FormMSG(176, 'Enter your Spouse first name ')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col
														v-if="(myProfile.civilStatus === 3 || myProfile.civilStatus === 8) && useField('SpouseLastName')"
														sm="12"
														md="12"
														lg="4"
														xl="4"
													>
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(172, 'Spouse name') }}</label>
																<span class="required" v-if="isMandatory('SpouseLastName')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.spouseName"
																type="text"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																:placeholder="FormMSG(173, 'Enter your Spouse name ')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row v-if="myProfile.civilStatus === 3 || myProfile.civilStatus === 8">
													<b-col v-if="useField('SpouseBirthdate')" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<label
																>{{ FormMSG(177, 'Spouse birth date')
																}}<span class="required" v-if="isMandatory('SpouseBirthdate')"> *</span></label
															>
															<v-date-picker
																v-model="myProfile.spouseBirthDate"
																:locale="lang"
																:masks="masks"
																:attributes="attrs"
																@dayclick="(payload) => handleInputCalendar(payload, 'spouseBirthDate')"
															>
																<template v-slot="{ inputValue, togglePopover }">
																	<b-input-group>
																		<b-form-input
																			:value="inputValue"
																			:disabled="disableAll"
																			:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
																			v-mask="'##/##/####'"
																			readonly
																			:class="{
																				'temp-invalid':
																					isMandatory('SpouseBirthdate') &&
																					!isDateValid('spouseBirthDate')['spouseBirthDate']
																			}"
																			@click="togglePopover()"
																			@change="(value) => handleChangeDate(value, 'spouseBirthDate')"
																			@keydown.enter.prevent
																		/>
																		<b-input-group-append>
																			<b-input-group-text
																				:class="{
																					'temp-invalid':
																						isMandatory('SpouseBirthdate') &&
																						!isDateValid('spouseBirthDate')['spouseBirthDate']
																				}"
																				class="cursor-pointer bg-color-white input-group-text-bg-white"
																				@click="togglePopover()"
																			>
																				<component
																					:is="getLucideIcon('Calendar')"
																					color="rgba(6, 38, 62, .6)"
																					:size="18"
																					:stroke-width="2.25"
																				/>
																			</b-input-group-text>
																		</b-input-group-append>
																	</b-input-group>
																</template>
															</v-date-picker>
															<!-- <b-form-input
																type="date"
																:value="displayDate('spouseBirthDate')"
																
																:class="{ 'bg-color-grams-hair': disableAll }"
																:placeholder="FormMSG(178, 'Enter your Spouse birth date ')"
																@input="(e) => updateDate(e, 'spouseBirthDate')"
																@keydown.enter.prevent
															/> -->
														</b-form-group>
													</b-col>
													<b-col
														v-if="myProfile.civilStatus === 3 || myProfile.civilStatus === 8 || useField('SpouseHasSalary')"
														sm="12"
														md="12"
														lg="4"
														xl="4"
													>
														<b-form-group class="text-start">
															<template slot="label">
																<label for="firstname">{{ FormMSG(181, 'Spouse has salary') }}</label>
																<span class="required" v-if="isMandatory('SpouseHasSalary')"> *</span>
															</template>
															<b-form-checkbox
																class="d-flex justify-content-start"
																size="lg"
																v-model="myProfile.spouseHasSalary"
																switch
																:disabled="disableAll"
															>
																<span class="text-redired">{{
																	myProfile.spouseHasSalary ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No')
																}}</span>
															</b-form-checkbox>
														</b-form-group>
													</b-col>
													<b-col
														v-if="myProfile.civilStatus === 3 || myProfile.civilStatus === 8 || useField('SpouseProfession')"
														sm="12"
														md="12"
														lg="4"
														xl="4"
													>
														<b-form-group>
															<label
																>{{ FormMSG(179, 'Spouse profession') }}
																<span class="required" v-if="isMandatory('SpouseProfession')"> *</span>
															</label>
															<b-form-input
																v-model="myProfile.spouseProfession"
																type="text"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																:placeholder="FormMSG(180, 'Enter your Spouse profession ')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col v-if="useField('NumberOfDependentChildren')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(183, 'Number of dependent child') }}</label>
																<span class="required" v-if="isMandatory('NumberOfDependentChildren')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.numberOfDependentChild"
																type="number"
																step="1"
																min="0"
																placeholder="0"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('NumberOfDisabledChildren')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(184, 'Number of disabled child') }}</label>
																<span class="required" v-if="isMandatory('NumberOfDisabledChildren')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.numberOfDisabledChild"
																type="number"
																step="1"
																min="0"
																placeholder="0"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('NumberOfDependentPerson')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(185, 'Number of dependent person') }}</label>
																<span class="required" v-if="isMandatory('NumberOfDependentPerson')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.numberOfDependentPerson"
																type="number"
																step="1"
																min="0"
																placeholder="0"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('NumberOfDisabledPerson')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(186, 'Number of disabled person') }}</label>
																<span class="required" v-if="isMandatory('NumberOfDisabledPerson')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.numberOfDisabledPerson"
																type="number"
																step="1"
																min="0"
																placeholder="0"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div class="divider" v-if="useField('ContactName') || useField('ContactPhoneNumber')" />
											<div class="sub-title" v-if="useField('ContactName') || useField('ContactPhoneNumber')">
												{{ FormMSG(15, 'Emergency contact') }}
											</div>
											<div class="content-card-collapsible">
												<b-row>
													<b-col v-if="useField('ContactName')" sm="12" md="12" lg="6" xl="6">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(188, 'Person to contact') }}</label>
																<span class="required" v-if="isMandatory('ContactName')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.personToContact"
																type="text"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																:placeholder="FormMSG(189, 'Enter person to contact')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('ContactPhoneNumber')" sm="12" md="12" lg="6" xl="6">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(190, 'Person to contact phone') }}</label>
																<span class="required" v-if="isMandatory('ContactPhoneNumber')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.personToContactPhone"
																type="text"
																:disabled="disableAll"
																:class="{ 'bg-color-grams-hair': disableAll }"
																:placeholder="FormMSG(191, 'Enter person to contact phone')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<!-- Personal vehicle -->
									<!--									<div class="card-collapsible mt-3" v-if="isPme && myProfile.vehicule">-->
									<div class="card-collapsible mt-3" v-if="personalVehicleDisplaySection && !hidePersonalVehicleSection">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('personal-vehicle')"
										>
											<div class="title w-90">
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="1em"
														height="1.15em"
														fill="rgba(6, 38, 62, 0.84)"
														viewBox="0 0 16 16"
													>
														<path
															d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z"
														/>
													</svg>
												</span>
												<span class="ml-3">{{ FormMSG(1226, 'Personal vehicle') }}</span>
											</div>
											<div class="d-flex justify-content-end w-20">
												<b-button pill variant="warning" class="mr-2" v-if="personalVehicleMandatoryCount > 0">{{
													personalVehicleMandatoryCount + ' ' + FormMSG(2365, 'field(s) left')
												}}</b-button>
												<component
													v-if="!configAccordion['personal-vehicle'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['personal-vehicle'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse v-if="!hidePersonalVehicleSection" :visible="configAccordion['personal-vehicle'].isVisible">
											<div class="content-card-collapsible">
												<vehicule-form
													:useVehicleName="userFieldOption.useVehicleName"
													:useVehicleBrand="userFieldOption.useVehicleBrand"
													:useVehicleModel="userFieldOption.useVehicleModel"
													:useVehiclePlateNumber="userFieldOption.useVehiclePlateNumber"
													:useVehicleTransportationMean="userFieldOption.useVehicleTransportationMean"
													:useVehicleTransportationMeanDetail="userFieldOption.useVehicleTransportationMeanDetail"
													:useVehicleKgCoTwo="userFieldOption.useVehicleKgCoTwo"
													:usePower="userFieldOption.usePower"
													:useGrayCardFrontPicture="userFieldOption.useGrayCardFrontPicture"
													:useGrayCardBackPicture="userFieldOption.useGrayCardBackPicture"
													:vehicule-data="myProfile.vehicule"
													:mandatoryVehicleName="userFieldOption.mandatoryVehicleName"
													:mandatoryVehicleBrand="userFieldOption.mandatoryVehicleBrand"
													:mandatoryVehicleModel="userFieldOption.mandatoryVehicleModel"
													:mandatoryVehiclePlateNumber="userFieldOption.mandatoryVehiclePlateNumber"
													:mandatoryVehicleTransportationMean="userFieldOption.mandatoryVehicleTransportationMean"
													:mandatoryVehicleKgCoTwo="userFieldOption.mandatoryVehicleKgCoTwo"
													:mandatoryPower="userFieldOption.mandatoryPower"
													:mandatoryGrayCardFrontPicture="userFieldOption.mandatoryGrayCardFrontPicture"
													:mandatoryGrayCardBackPicture="userFieldOption.mandatoryGrayCardBackPicture"
													@vehicule-form:change="handleVehiculeFormChange"
												/>
											</div>
										</b-collapse>
									</div>
									<!-- Other -->
									<div class="card-collapsible mt-3" v-if="otherDisplaySection">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('other')"
										>
											<div class="title w-90">
												<b-icon icon="list" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{ FormMSG(16, 'Other') }}</span>
											</div>
											<div class="d-flex justify-content-end w-20">
												<b-button pill variant="warning" class="mr-2" v-if="otherMandatoryCount > 0">{{
													otherMandatoryCount + ' ' + FormMSG(2365, 'field(s) left')
												}}</b-button>
												<component
													v-if="!configAccordion['other'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['other'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['other'].isVisible">
											<div class="sub-title" v-if="otherDisplayPrivacyOption && !hideInfoOther">
												{{ FormMSG(17, 'Privacy options') }}
											</div>
											<div v-if="otherDisplayPrivacyOption" class="content-card-collapsible">
												<b-row v-if="!hideInfoOther">
													<b-col v-if="useField('showMyEmailInCrewList')" sm="12" md="12" lg="3" xl="3">
														<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="myProfile.showMyEmailInCrew">{{
															FormMSG(18, 'Show my email in crew list')
														}}</b-form-checkbox>
													</b-col>
													<b-col v-if="useField('showMyPhoneInCrewList')" sm="12" md="12" lg="3" xl="3">
														<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="myProfile.showMyPhoneInCrew">{{
															FormMSG(19, 'Show my phone in crew list')
														}}</b-form-checkbox>
													</b-col>
												</b-row>
											</div>
											<div class="divider" v-show="!hideInfoOther" v-if="otherDisplayLoyaltyCard"></div>
											<div v-if="!hideInfoOther">
												<div class="sub-title" v-if="otherDisplayLoyaltyCard">
													{{ FormMSG(1280, 'Loyalty cards') }}
												</div>
												<div v-if="otherDisplayLoyaltyCard" class="content-card-collapsible">
													<b-row>
														<b-col v-if="useField('SncfLoyaltyCard')" sm="12" md="12" lg="3" xl="3">
															<b-form-group>
																<template #label>
																	<div>
																		<span>
																			{{ FormMSG(1281, 'SNCF loyalty card') }}

																			<span class="required" v-if="isMandatory('SncfLoyaltyCard')"> *</span>

																			<span class="text-fin">{{ FormMSG(1282, '(Type + number)') }}</span>
																		</span>
																	</div>
																</template>
																<b-form-input
																	v-model="myProfile.trainLoyaltyCard"
																	type="text"
																	:placeholder="FormMSG(1283, 'Enter your sncf loyalty card')"
																	@keydown.enter.prevent
																/>
															</b-form-group>
														</b-col>
														<b-col v-if="useField('SncfDiscountCard')" sm="12" md="12" lg="3" xl="3">
															<b-form-group>
																<template #label>
																	<div>
																		<span>
																			{{ FormMSG(1284, 'SNCF discount card') }}
																			<span class="required" v-if="isMandatory('SncfDiscountCard')"> *</span>
																			<span class="text-fin">{{ FormMSG(1282, '(Type + number)') }}</span>
																		</span>
																	</div>
																</template>
																<b-form-input
																	v-model="myProfile.trainDiscountCard"
																	type="text"
																	:placeholder="FormMSG(1285, 'Enter your sncf discount card')"
																	@keydown.enter.prevent
																/>
															</b-form-group>
														</b-col>
														<b-col v-if="useField('FlyingBlueCard')" sm="12" md="12" lg="3" xl="3">
															<b-form-group>
																<template #label>
																	<div>
																		<span>
																			{{ FormMSG(1286, 'Flying blue card') }}
																			<span class="required" v-if="isMandatory('FlyingBlueCard')"> *</span>
																			<span class="text-fin">{{ FormMSG(1287, '(Number)') }}</span>
																		</span>
																	</div>
																</template>
																<b-form-input
																	v-model="myProfile.flyingLoyaltyCard"
																	type="text"
																	:placeholder="FormMSG(1288, 'Enter your flying blue card')"
																	@keydown.enter.prevent
																/>
															</b-form-group>
														</b-col>
														<b-col v-if="useField('OtherAirlineCard')" sm="12" md="12" lg="3" xl="3">
															<b-form-group>
																<template #label>
																	<div>
																		<span>
																			{{ FormMSG(1289, 'Other airline card') }}
																			<span class="required" v-if="isMandatory('OtherAirlineCard')"> *</span>
																			<span class="text-fin">{{ FormMSG(1287, '(Number)') }}</span>
																		</span>
																	</div>
																</template>
																<b-form-input
																	v-model="myProfile.otherAirlineCard"
																	type="text"
																	:placeholder="FormMSG(1288, 'Enter your other airline card')"
																	@keydown.enter.prevent
																/>
															</b-form-group>
														</b-col>
													</b-row>
												</div>
											</div>
											<div class="divider" v-show="!hideInfoOther" v-if="otherDisplayForeignWorker"></div>
											<div v-if="!hideInfoOther">
												<div class="sub-title" v-if="otherDisplayForeignWorker">
													{{ FormMSG(1290, 'Foreign worker')
													}}<span class="text-fin">{{ FormMSG(1291, "(to fill only if you're a foraign worker)") }}</span>
												</div>
												<div v-if="otherDisplayForeignWorker" class="content-card-collapsible">
													<b-row>
														<b-col v-if="useField('ResidencePermitNumber')" sm="12" md="12" lg="3" xl="3">
															<b-form-group>
																<template slot="label">
																	<label>{{ FormMSG(1292, 'Residence permit number') }}</label>
																	<span class="required" v-if="isMandatory('ResidencePermitNumber')"> *</span>
																</template>
																<b-form-input
																	v-model="myProfile.residencePermitNumber"
																	type="text"
																	:placeholder="FormMSG(1293, 'Enter your residence permit number')"
																	@keydown.enter.prevent
																/>
															</b-form-group>
														</b-col>
														<b-col v-if="useField('ResidenceValidityEnd')" sm="12" md="12" lg="3" xl="3">
															<b-form-group>
																<template slot="label">
																	<label>{{ FormMSG(1294, 'Residence permit validity end') }}</label>
																	<span class="required" v-if="isMandatory('ResidenceValidityEnd')"> *</span>
																</template>
																<v-date-picker
																	v-model="myProfile.residencePermitEndValidity"
																	:locale="lang"
																	:masks="masks"
																	:attributes="attrs"
																	@dayclick="(payload) => handleInputCalendar(payload, 'residencePermitEndValidity')"
																>
																	<template v-slot="{ inputValue, togglePopover }">
																		<b-input-group>
																			<b-form-input
																				:value="inputValue"
																				:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
																				v-mask="'##/##/####'"
																				readonly
																				:class="{
																					'temp-invalid':
																						isMandatory('ResidenceValidityEnd') &&
																						!isDateValid('residencePermitEndValidity')['residencePermitEndValidity']
																				}"
																				@click="togglePopover()"
																				@input="(value) => handleChangeDate(value, 'residencePermitEndValidity')"
																				@keydown.enter.prevent
																			/>
																			<b-input-group-append>
																				<b-input-group-text
																					class="cursor-pointer bg-color-white input-group-text-bg-white"
																					@click="togglePopover()"
																					:class="{
																						'temp-invalid':
																							isMandatory('ResidenceValidityEnd') &&
																							!isDateValid('residencePermitEndValidity')[
																								'residencePermitEndValidity'
																							]
																					}"
																				>
																					<component
																						:is="getLucideIcon('Calendar')"
																						color="rgba(6, 38, 62, .6)"
																						:size="18"
																						:stroke-width="2.25"
																					/>
																				</b-input-group-text>
																			</b-input-group-append>
																		</b-input-group>
																		<!-- <div
																			v-if="
																				isMandatory('ResidenceValidityEnd') &&
																				!isDateValid('residencePermitEndValidity')['residencePermitEndValidity']
																			"
																			class="invalid-feedback"
																		>
																			{{ FormMSG(901, 'Residence validity end is required') }}
																		</div> -->
																	</template>
																</v-date-picker>
															</b-form-group>
														</b-col>
														<b-col v-if="useField('ResidencePermit')" sm="12" md="12" lg="3" xl="3">
															<b-form-group>
																<label for="residentPermitXid"
																	>{{ FormMSG(1296, 'Resident permit') }}
																	<span class="required" v-if="isMandatory('ResidencePermit')"> *</span>
																</label>
																<b-row class="ml-0">
																	<div
																		@click="onOpenUrl(getImage(myProfile.residentPermitXid, 'residentPermitXid'))"
																		class="d-flex justify-content-center align-items-center cursor-pointer"
																		v-if="checkFile(myProfile.residentPermitXid)"
																	>
																		<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																	</div>
																	<b-img
																		v-else
																		v-bind="imgPropSignatureId"
																		rounded
																		alt="residentPermitXid"
																		class="cursor-pointer"
																		:src="getImage(myProfile.residentPermitXid, 'residentPermitXid')"
																		@click="showImage(myProfile.residentPermitXid)"
																		:title="FormMSG(254, 'View Image')"
																	></b-img>
																	<b-button
																		variant="outline-secondary"
																		class="ml-2"
																		@click="startCapture('residentPermitXid')"
																	>
																		<div class="d-flex justify-content-center align-items-center">
																			<component :is="getIconLucide('Camera')" :size="20" />
																			<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																		</div>
																	</b-button>
																	<button
																		class="btn-transparent pl-0 ml-2"
																		@click.prevent.stop="deleteImg('residentPermitXid')"
																		v-if="myProfile.residentPermitXid && myProfile.residentPermitXid.length > 0"
																	>
																		<component
																			:is="getIconLucide('Trash2')"
																			:size="24"
																			:stroke-width="1.5"
																			color="#ea4e2c"
																		/>
																	</button>
																</b-row>
															</b-form-group>
														</b-col>
														<b-col v-if="useField('WorkCard')" sm="12" md="12" lg="3" xl="3">
															<b-form-group>
																<label for="workCardXid"
																	>{{ FormMSG(1297, 'Work card') }}
																	<span class="required" v-if="isMandatory('WorkCard')"> *</span>
																</label>
																<b-row class="ml-0">
																	<div
																		@click="onOpenUrl(getImage(myProfile.workCardXid, 'workCardXid'))"
																		class="d-flex justify-content-center align-items-center cursor-pointer"
																		v-if="checkFile(myProfile.workCardXid)"
																	>
																		<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
																	</div>
																	<b-img
																		v-else
																		v-bind="imgPropSignatureId"
																		rounded
																		alt="workCardXid"
																		class="cursor-pointer"
																		:src="getImage(myProfile.workCardXid, 'workCardXid')"
																		@click="showImage(myProfile.workCardXid)"
																		:title="FormMSG(254, 'View Image')"
																	></b-img>
																	<b-button variant="outline-secondary" class="ml-2" @click="startCapture('workCardXid')">
																		<div class="d-flex justify-content-center align-items-center">
																			<component :is="getIconLucide('Camera')" :size="20" />
																			<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
																		</div>
																	</b-button>
																	<button
																		class="btn-transparent pl-0 ml-2"
																		@click.prevent.stop="deleteImg('workCardXid')"
																		v-if="myProfile.workCardXid && myProfile.workCardXid.length > 0"
																	>
																		<component
																			:is="getIconLucide('Trash2')"
																			:size="24"
																			:stroke-width="1.5"
																			color="#ea4e2c"
																		/>
																	</button>
																</b-row>
															</b-form-group>
														</b-col>
													</b-row>
												</div>
											</div>
											<div class="divider" v-show="!hideInfoOther" v-if="otherDisplayMiscellanous"></div>
											<div v-if="!hideInfoOther">
												<div class="sub-title" v-if="!hideInfoOther && otherDisplayMiscellanous">
													{{ FormMSG(24, 'Miscellanous') }}
												</div>
											</div>
											<div v-if="!hideInfoOther" class="content-card-collapsible">
												<b-row>
													<b-col v-if="useField('Pseudonym')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(1298, 'Pseudonym') }}</label>
																<span class="required" v-if="isMandatory('Pseudonym')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.pseudonym"
																type="text"
																:placeholder="FormMSG(1299, 'Enter your pseudonym')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('GenericMentionProfessionalAssociation')" sm="12" md="12" lg="4" xl="4">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(1300, 'Generic mention professional association') }}</label>
																<span class="required" v-if="isMandatory('GenericMentionProfessionalAssociation')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.professionalAssociation"
																type="text"
																:placeholder="FormMSG(1301, 'Enter your generic mention ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div class="divider" v-if="!hideInfoOther && useField('Retired')"></div>
											<div class="sub-title" v-if="!hideInfoOther && useField('Retired')">{{ FormMSG(1705, 'Retirement') }}</div>
											<div v-if="!hideInfoOther && useField('Retired')" class="content-card-collapsible">
												<b-row>
													<b-col v-if="useField('Retired')" sm="12" md="12" lg="3" xl="3" class="d-flex justify-content-start">
														<b-form-group class="text-center">
															<template slot="label">
																<label>{{ FormMSG(1275, 'Retired ?') }}</label>
																<span class="required" v-if="isMandatory('Retired')"> *</span>
															</template>
															<b-form-checkbox
																class="d-flex justify-content-start"
																size="lg"
																v-model="myProfile.retired"
																:value="true"
																switch
																@change="handleChangeRetired"
															>
																<span class="text-redired">{{
																	myProfile.retired ? FormMSG(1276, 'Yes') : FormMSG(1277, 'No')
																}}</span>
															</b-form-checkbox>
														</b-form-group>
													</b-col>
													<b-col v-if="myProfile.retired" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<label>{{ FormMSG(1278, 'Retirement date') }}</label>
															<v-date-picker
																v-model="myProfile.retirementDate"
																:locale="lang"
																:masks="masks"
																:attributes="attrs"
																@dayclick="(payload) => handleInputCalendar(payload, 'retirementDate')"
															>
																<template v-slot="{ inputValue, togglePopover }">
																	<b-input-group>
																		<b-form-input
																			:value="inputValue"
																			:placeholder="FormMSG(8960, 'DD/MM/YYYY')"
																			v-mask="'##/##/####'"
																			readonly
																			:class="{
																				'temp-invalid': !isDateValid('retirementDate')['retirementDate']
																			}"
																			@click="togglePopover()"
																			@input="(value) => handleChangeDate(value, 'retirementDate')"
																			@keydown.enter.prevent
																		/>
																		<b-input-group-append>
																			<b-input-group-text
																				class="cursor-pointer bg-color-white input-group-text-bg-white"
																				@click="togglePopover()"
																			>
																				<component
																					:is="getLucideIcon('Calendar')"
																					color="rgba(6, 38, 62, .6)"
																					:size="18"
																					:stroke-width="2.25"
																				/>
																			</b-input-group-text>
																		</b-input-group-append>
																	</b-input-group>
																	<!-- <div v-if="!isDateValid('retirementDate')['retirementDate']" class="invalid-feedback">
																		{{ FormMSG(902, 'Retirement date is required') }}
																	</div> -->
																</template>
															</v-date-picker>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div class="divider" v-if="!hideInfoOther && useField('StudyLevel')"></div>
											<div class="sub-title" v-if="useField('StudyLevel')">{{ FormMSG(1706, 'Study') }}</div>
											<div v-if="useField('StudyLevel')" class="content-card-collapsible">
												<b-row>
													<b-col v-if="useField('StudyLevel')" sm="12" md="12" lg="4" xl="4">
														<b-form-group :label="FormMSG(1587, 'Are you currently a student ?')">
															<b-form-checkbox v-model="myProfile.studyCurrently" switch size="lg" :disabled="disableAll" />
														</b-form-group>
													</b-col>
													<b-col v-if="useField('StudyLevel')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(262, 'Study level') }}</label>
																<span class="required" v-if="isMandatory('StudyLevel')"> *</span>
															</template>
															<b-form-select v-model="myProfile.studyLevel" :options="studyLeveOptions" :disabled="disableAll" />
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div class="divider" v-if="!hideInfoOther && otherDisplayClothes"></div>
											<div class="sub-title" v-if="!hideInfoOther && otherDisplayClothes">
												{{ FormMSG(1707, 'Clothes') }}
											</div>
											<div v-if="!hideInfoOther" class="content-card-collapsible">
												<b-row>
													<b-col v-if="useField('Size')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(263, 'Size') }}</label>
																<span class="required" v-if="isMandatory('Size')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.size"
																type="text"
																:placeholder="FormMSG(267, 'Enter your size ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('PantSize')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(264, 'Pant size') }}</label>
																<span class="required" v-if="isMandatory('PantSize')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.pantSize"
																type="text"
																:placeholder="FormMSG(268, 'Enter your pant size ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('JacketSize')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(265, 'Jacket size') }}</label>
																<span class="required" v-if="isMandatory('JacketSize')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.jacketSize"
																type="text"
																:placeholder="FormMSG(269, 'Enter your jacket size ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row>
													<b-col v-if="useField('ShoeSize')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(27, 'Shoe size') }}</label>
																<span class="required" v-if="isMandatory('ShoeSize')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.shoeSize"
																type="text"
																:placeholder="FormMSG(28, 'Enter your shoe size ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
													<b-col v-if="useField('TshirtSize')" sm="12" md="12" lg="3" xl="3">
														<b-form-group>
															<template slot="label">
																<label>{{ FormMSG(29, 'Teeshirt size') }}</label>
																<span class="required" v-if="isMandatory('TshirtSize')"> *</span>
															</template>
															<b-form-input
																v-model="myProfile.tShirtSize"
																type="text"
																:placeholder="FormMSG(30, 'Enter your teeshirt size ...')"
																@keydown.enter.prevent
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div v-if="isEditable">
												<div class="divider"></div>
												<div class="sub-title">{{ FormMSG(1714, 'Custom fields') }}</div>
												<div class="content-card-collapsible">
													<b-row class="pb-2">
														<b-col
															:xl="sizeFlagInput(customFields.length)"
															:lg="sizeFlagInput(customFields.length)"
															class="d-flex mb-2 w-100"
															v-for="(customField, index) in customFields"
															:key="index"
														>
															<custom-field-template
																v-model="customField[customFieldModels[index]]"
																:field="customField"
																:name-model="customFieldModels[index]"
																:name-group="customField.nameGroup"
																:index="index"
																class="w-100"
																@custom-field-template:payload="onFieldTemplateChanged"
															/>
														</b-col>
													</b-row>
												</div>
											</div>
										</b-collapse>
									</div>
									<!-- Password-->
									<div v-if="!hidePasswordSection" class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('password')"
										>
											<div class="title w-90">
												<b-icon icon="key-fill" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{
													FormMSG(31, 'Password')
												}}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['password'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['password'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse v-if="!hidePasswordSection" :visible="configAccordion['password'].isVisible">
											<div class="content-card-collapsible">
												<p class="text-muted">
													*
													{{
														FormMSG(
															107,
															"Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter."
														)
													}}
												</p>
												<b-button variant="outline-primary" :disabled="statusSendCodeValidation.global" @click="handleChangePassword">
													<span v-if="!statusSendCodeValidation.progress && !statusSendCodeValidation.success">
														{{ FormMSG(22, 'Change Password') }}
													</span>
													<span v-if="statusSendCodeValidation.progress && !statusSendCodeValidation.success">
														<b-spinner label="Loading..." small /> {{ FormMSG(146, 'Send code validation') }}
													</span>
													<span v-if="!statusSendCodeValidation.progress && statusSendCodeValidation.success">
														{{ FormMSG(147, 'Code validation sended') }}
													</span>
												</b-button>
											</div>
										</b-collapse>
									</div>
									<!-- TEAM SESSION -->
									<div v-if="isDeterProject === true" class="card-collapsible mt-3">
										<div
											class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center"
											@click="handleClickHeader('deter')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('UserCog')" :size="20" /><span class="ml-3">{{
													FormMSG(131, 'Team session')
												}}</span>
											</div>
											<div class="d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['deter'].isVisible"
													:is="getIconLucide('ChevronDown')"
													color="#06263E"
													:size="22"
												/>
												<component
													v-if="configAccordion['deter'].isVisible"
													:is="getIconLucide('ChevronUp')"
													color="#06263E"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['deter'].isVisible">
											<div class="content-card-collapsible">
												<b-table-simple id="deterSessionTeamTable">
													<b-thead head-variant="dark">
														<b-tr>
															<b-th></b-th>
															<b-th>{{ FormMSG(457, 'Session 1') }}</b-th>
															<b-th>{{ FormMSG(458, 'Session 2') }}</b-th>
															<b-th>{{ FormMSG(459, 'Session 3') }}</b-th>
															<b-th>{{ FormMSG(460, 'Session 4') }}</b-th>
															<b-th>{{ FormMSG(461, 'Session 5') }}</b-th>
															<b-th>{{ FormMSG(462, 'Session 6') }}</b-th>
															<b-th>{{ FormMSG(463, 'Session 7') }}</b-th>
															<b-th>{{ FormMSG(464, 'Session 8') }}</b-th>
														</b-tr>
													</b-thead>
													<b-tbody>
														<b-tr>
															<b-th>{{ FormMSG(465, 'Team A') }}</b-th>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session1A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session2A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session3A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session4A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session5A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session6A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session7A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session8A"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
														</b-tr>
														<b-tr>
															<b-th>{{ FormMSG(466, 'Team B') }}</b-th>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session1B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session2B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session3B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session4B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session5B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session6B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session7B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
															<b-td>
																<b-form-checkbox
																	v-model="myProfile.session8B"
																	size="lg"
																	:value="true"
																	:unchecked-value="false"
																	disabled
																/>
															</b-td>
														</b-tr>
													</b-tbody>
												</b-table-simple>
											</div>
										</b-collapse>
									</div>
									<div v-if="!hideBtnSave" class="mt-4 px-2 d-flex justify-content-end">
										<b-button
											class="mr-1 w-200-px"
											type="submit"
											variant="primary"
											size="md"
											style="font-size: 1.1rem; letter-spacing: 0.05rem"
											>{{ FormMSG(23, 'Save my changes') }}</b-button
										>
									</div>
								</b-form>
							</div>
						</div>
					</b-row>
					<NewCapture
						v-model="capturePict"
						:key="refreshTokenCaptureItem"
						parentType="user"
						:parentId="myProfile.id"
						auto-crop-capture
						cam-mode-first
						only-one-crop-mode
						@change="handleImgsTaked"
					/>
					<b-modal
						header-class="header-class-modal-doc-package"
						:title="FormMSG(8979800, 'Success!')"
						class="modal-warning"
						v-model="successModal"
						@ok="successModal = false"
						ok-variant="success"
						ok-only
						cancel-title="close"
					>
						<h6>
							{{ FormMSG(8979799, 'Your information has been saved.') }}
						</h6>
					</b-modal>
					<b-modal
						header-class="header-class-modal-doc-package"
						:title="FormMSG(8979801, 'Success with warning!')"
						class="modal-warning"
						v-model="warningModal"
						@ok="warningModal = false"
						header-bg-variant="danger"
						header-border-variant="danger"
						ok-variant="danger"
						ok-only
						cancel-title="close"
					>
						<h6>{{ FormMSG(12205, 'Profile updated successfully, but some fields are still incomplete. Please consider filling them') }}</h6>
					</b-modal>
					<!-- <loading :active.sync="isLoading" :is-full-page="true"></loading> -->
				</div>
			</div>
		</transition>
		<!-- bg-variant="secondary" text-variant="white" -->
		<b-modal
			header-class="header-class-modal-doc-package"
			ref="my-modal"
			title="Crop Picture"
			size="cropSize"
			v-model="cropPict"
			@ok="endCrop"
			@cancel="endCropCancel"
			ok-variant="success"
			ok-title="Confirm Save"
			cancel-title="Cancel"
		>
			<CropPicture ref="myCropPicture"> </CropPicture>
		</b-modal>
		<change-mail-modal
			:open="showChangeMailModal"
			:profile="myProfile"
			@modal:close="showChangeMailModal = false"
			@change-mail-modal:save="handleMailModalSave"
		/>
		<change-phone-modal
			:open="showChangePhoneModal"
			:profile="myProfile"
			@modal:close="showChangePhoneModal = false"
			@change-phone-modal:save="handlePhoneModalSave"
		/>
		<validation-code-modal
			:open="showValidationCodeModal"
			:email-for-validation="myProfile.email"
			@validation-code-modal:save="handleValidationCodeModalSave"
			@modal:close="handleValidationCodeModalClose"
		/>
		<b-modal
			header-class="header-class-modal-doc-package"
			v-model="openPictureModal"
			:title="FormMSG(253, 'Picture')"
			class="modal-success"
			ok-variant="success"
			ok-only
			@ok="openPictureModal = false"
		>
			<div>
				<b-img :src="imgToShow" fluid-grow />
			</div>
		</b-modal>
	</errorContainer>
</template>

<script>
// import gql from 'graphql-tag';
// import { Camera, CameraResultType } from '@capacitor/camera'
import { store } from '@/store';
import { isValidIBANNumber, isBic } from '@/shared/iban-validator';
import { isSecuritySocialNumberValid } from '@/shared/validators/security-solcial.validator';
import Vue from 'vue';
// import Capture from "@/components/ExpenseService/Capture";
import NewCapture from '@/components/CaptureModule/Main';
import languageMessages from '@/mixins/languageMessages';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import VueScrollTo from 'vue-scrollto';
import contract from './users/contract';
import CropPicture from '../components/CropPicture';
import CapturePicture from '../components/CapturePicture';
import { myProfile, updateUserProjectReference } from '@/cruds/users.crud';
import ShareInformation from '@/components/InfoSheet/ShareInformation';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import GlobalMixin from '@/mixins/global.mixin';
import verte from 'verte';
import 'verte/dist/verte.css';
import { isNil, getObjectFromValue, emailValid, noTilde } from '~utils';
// import HomeProfileMobile from "@/components/MyProfileMobile/HomeProfileMobile";
import ListMenu from '@/components/MyProfileMobile/ListMenu';
import * as icons from 'lucide-vue';
import { Pencil } from 'lucide-vue';
import ChangeMailModal from '@/components/MyProfileMobile/ChangeMailModal';
import ChangePhoneModal from '@/components/MyProfileMobile/ChangePhoneModal';
import ValidationCodeModal from '@/components/MyProfileMobile/ValidationCodeModal';
import MobileProfilePicCaptureBtn from '@/components/CaptureModule/MobileProfilePicCaptureBtn';
// import { emailValidationCode } from '@/cruds/registration.crud'
// import { ChevronDown, ChevronUp } from "lucide-vue";
import GoogleAutoComplete from '@/components/GoogleAutoComplete';
import { convertIsoToKey } from '@/shared/helpers';
import { updMyProfile, updatePictureProfile, updatePhoneProfile, updateEmailProfile, updatePictureVehicule } from '@/cruds/profile.crud';
import { validationMixin } from 'vuelidate';
import { required, minLength, requiredIf } from 'vuelidate/lib/validators';
import MainAddressLocation from '@/components/LocationService/MainAddressLocation';
import moment from 'moment';
import DeterMixin from '@/mixins/deter.mixin';
import VehiculeForm from '@/modules/transports/components/VehiculeForm.vue';
import { getProjectFieldOption } from '@/cruds/project.crud';
import _ from 'lodash';
import { getUserById } from '../cruds/users.crud';
import CustomLabelMandatory from '../modules/job-request/components/CustomLabelMandatory.vue';
import InputMask from 'primevue/inputmask';
import { CUSTOM_FIELD_PARENT_TYPE, CUSTOM_FIELD_TYPE, STUDY_LEVEL_MENU, ROUTE_NAME } from '@/shared/constants';
import { getCustomFields } from '@/cruds/custom-field.crud';
import CustomFieldTemplate from '@/components/CustomField/CustomFieldTemplate';
import { generateSecureId } from '@/shared/utils';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

Vue.use(VueScrollTo);

const axios = require('axios').create();

export default {
	name: 'Profile',
	components: {
		CustomLabelMandatory,
		Loading,
		contract,
		CropPicture,
		CapturePicture,
		// Capture,
		NewCapture,
		ShareInformation,
		verte,
		// HomeProfileMobile,
		ListMenu,
		Pencil,
		ChangeMailModal,
		ChangePhoneModal,
		ValidationCodeModal,
		MobileProfilePicCaptureBtn,
		GoogleAutoComplete,
		'v-address': MainAddressLocation,
		VehiculeForm,
		InputMask,
		CustomFieldTemplate,
		'v-date-picker': DatePicker
	},

	// THESE PROPS ARE USED ONLY IN DETAILS JOB REQUEST NOW
	props: {
		forJobRequest: { type: Boolean, default: false, required: false },
		externalUserId: { type: Number, default: 0, required: false },
		hidePasswordSection: { type: Boolean, default: false, required: false },
		hideBtnSave: { type: Boolean, default: false, required: false },
		hideActionChangePicture: { type: Boolean, default: false, required: false },
		hidePlaceOfBirthZip: { type: Boolean, default: false, required: false },
		hideMaidenName: { type: Boolean, default: false, required: false },
		hidePlanningColor: { type: Boolean, default: false, required: false },
		hideInfoIdentification: { type: Boolean, default: false, required: false },
		hideBankAccountOwnerName: { type: Boolean, default: false, required: false },
		hideDeclarationCountry: { type: Boolean, default: false, required: false },
		hideAddPostalAddress: { type: Boolean, default: false, required: false },
		hideInfoHealth: { type: Boolean, default: false, required: false },
		hidePersonalVehicleSection: { type: Boolean, default: false, required: false },
		hideInfoOther: { type: Boolean, default: false, required: false }
	},
	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin, validationMixin, DeterMixin],
	data() {
		return {
			userBankWarning: {
				iban: false,
				bic: false,
				secondIban: false,
				secondBic: false
			},
			imageStyle: {
				width: '175px',
				height: '175px'
			},
			masks: {
				input: 'DD/MM/YYYY'
			},
			attrs: [
				{
					highlight: {
						fillMode: 'light'
					},
					dates: new Date()
				}
			],
			warningModal: false,
			userFieldOption: {
				projectId: false,
				usePersonalNumber: false,
				mandatoryPersonalNumber: false,
				useEmail: false,
				mandatoryEmail: false,
				usePhone: false,
				mandatoryPhone: false,
				useFirstName: false,
				mandatoryFirstName: false,
				useLastName: false,
				mandatoryLastName: false,
				useNationality: false,
				mandatoryNationality: false,
				useLanguage: false,
				mandatoryLanguage: false,
				useBirthdate: false,
				mandatoryBirthdate: false,
				usePlaceOfBirth: false,
				mandatoryPlaceOfBirth: false,
				useCityOfBirthZip: false,
				mandatoryCityOfBirthZip: false,
				useGender: false,
				mandatoryGender: false,
				useProfilePicture: false,
				mandatoryProfilePicture: false,
				useSocialSecurityNumber: false,
				mandatorySocialSecurityNumber: false,
				useNationalRegistrationNumber: false,
				mandatoryNationalRegistrationNumber: false,
				useCompanyNumber: false,
				mandatoryCompanyNumber: false,
				useIdName: false,
				mandatoryIdName: false,
				useIdNumber: false,
				mandatoryIdNumber: false,
				useIdPictureFront: false,
				mandatoryIdPictureFront: false,
				useIdPictureBack: false,
				mandatoryIdPictureBack: false,
				useDriverLicenseNumber: false,
				mandatoryDriverLicenseNumber: false,
				useDriverLicensePictureFront: false,
				mandatoryDriverLicensePictureFront: false,
				useDriverLicensePictureBack: false,
				mandatoryDriverLicensePictureBack: false,
				useSignaturePicture: false,
				mandatorySignaturePicture: false,
				useIban: false,
				mandatoryIban: false,
				useBic: false,
				mandatoryBic: false,
				useCountry: false,
				mandatoryCountry: false,
				useInternetResearch: false,
				mandatoryInternetResearch: false,
				useStreet: false,
				mandatoryStreet: false,
				useNumber: false,
				mandatoryNumber: false,
				useBox: false,
				mandatoryBox: false,
				useZipCode: false,
				mandatoryZipCode: false,
				useCity: false,
				mandatoryCity: false,
				useStateRegion: false,
				mandatoryStateRegion: false,
				useCivilStatus: false,
				mandatoryCivilStatus: false,
				useSpouseFirstName: false,
				mandatorySpouseFirstName: false,
				useSpouseLastName: false,
				mandatorySpouseLastName: false,
				useSpouseBirthdate: false,
				mandatorySpouseBirthdate: false,
				useSpouseHasSalary: false,
				mandatorySpouseHasSalary: false,
				useSpouseProfession: false,
				mandatorySpouseProfession: false,
				useNumberOfDependentChildren: false,
				mandatoryNumberOfDependentChildren: false,
				useNumberOfDisabledChildren: false,
				mandatoryNumberOfDisabledChildren: false,
				useNumberOfDependentPerson: false,
				mandatoryNumberOfDependentPerson: false,
				useNumberOfDisabledPerson: false,
				mandatoryNumberOfDisabledPerson: false,
				useContactName: false,
				mandatoryContactName: false,
				useContactPhoneNumber: false,
				mandatoryContactPhoneNumber: false,
				useTaxes: false,
				mandatoryTaxes: false,
				useTaxDeclarationCountry: false,
				mandatoryTaxDeclarationCountry: false,
				showMyEmailInCrewList: false,
				showMyPhoneInCrewList: false,
				useWorkerWithADisability: false,
				mandatoryWorkerWithADisability: false,
				useHaveAllergies: false,
				mandatoryHaveAllergies: false,
				useAllergiesDetails: false,
				mandatoryAllergiesDetails: false,
				useColor: false,
				mandatoryColor: false,
				useStudyLevel: false,
				mandatoryStudyLevel: false,
				useSize: false,
				mandatorySize: false,
				usePantSize: false,
				mandatoryPantSize: false,
				useJacketSize: false,
				mandatoryJacketSize: false,
				useShoeSize: false,
				mandatoryShoeSize: false,
				useTshirtSize: false,
				mandatoryTshirtSize: false,
				useMaidenName: false,
				mandatoryMaidenName: false,
				useTitle: false,
				mandatoryTitle: false,
				useCountryOfBirth: false,
				mandatoryCountryOfBirth: false,
				useCongesSpectacleId: false,
				mandatoryCongesSpectacleId: false,
				useDriverLicense: false,
				mandatoryDriverLicense: false,
				useBankAccountOwnerName: false,
				mandatoryBankAccountOwnerName: false,
				useBankDetailDocument: false,
				mandatoryBankDetailDocument: false,
				useSocialSecurityCard: false,
				mandatorySocialSecurityCard: false,
				useInsuranceCertificate: false,
				mandatoryInsuranceCertificate: false,
				useSocialSecurityCenter: false,
				mandatorySocialSecurityCenter: false,
				useSocialSecurityCenterAddress: false,
				mandatorySocialSecurityCenterAddress: false,
				useLastMedicalVisitDate: false,
				mandatoryLastMedicalVisitDate: false,
				useMedicalVisitValidity: false,
				mandatoryMedicalVisitValidity: false,
				useMedicalVisitDocument: false,
				mandatoryMedicalVisitDocument: false,
				useDisabilityNotificationDate: false,
				mandatoryDisabilityNotificationDate: false,
				useType: false,
				mandatoryType: false,
				useStatus: false,
				mandatoryStatus: false,
				useInvalidity: false,
				mandatoryInvalidity: false,
				useRqth: false,
				mandatoryRqth: false,
				useInvalidityPercentage: false,
				mandatoryInvalidityPercentage: false,
				useVehicleName: false,
				mandatoryVehicleName: false,
				useVehicleBrand: false,
				mandatoryVehicleBrand: false,
				useVehicleModel: false,
				mandatoryVehicleModel: false,
				useVehiclePlateNumber: false,
				mandatoryVehiclePlateNumber: false,
				useVehicleTransportationMean: false,
				mandatoryVehicleTransportationMean: false,
				useVehicleTransportationMeanDetail: false,
				mandatoryVehicleTransportationMeanDetail: false,
				useVehicleKgCoTwo: false,
				mandatoryVehicleKgCoTwo: false,
				usePower: false,
				mandatoryPower: false,
				useGrayCardFrontPicture: false,
				mandatoryGrayCardFrontPicture: false,
				useGrayCardBackPicture: false,
				mandatoryGrayCardBackPicture: false,
				useSncfLoyaltyCard: false,
				mandatorySncfLoyaltyCard: false,
				useSncfDiscountCard: false,
				mandatorySncfDiscountCard: false,
				useFlyingBlueCard: false,
				mandatoryFlyingBlueCard: false,
				useOtherAirlineCard: false,
				mandatoryOtherAirlineCard: false,
				useResidencePermitNumber: false,
				mandatoryResidencePermitNumber: false,
				useResidenceValidityEnd: false,
				mandatoryResidenceValidityEnd: false,
				useResidencePermit: false,
				mandatoryResidencePermit: false,
				useWorkCard: false,
				mandatoryWorkCard: false,
				usePseudonym: false,
				mandatoryPseudonym: false,
				useGenericMentionProfessionalAssociation: false,
				mandatoryGenericMentionProfessionalAssociation: false,
				useRetired: false,
				mandatoryRetired: false
			},
			isStartMobilePwaCaptureLoading: false,
			isSocSecNumberWarning: false,
			// Initialize your apollo data
			isShowPostalAddress: false,
			myProfile: {
				id: 0,
				showMyEmailInCrew: true,
				showMyPhoneInCrew: true,
				taxesPercentage: 0,
				pictureRib: '',
				vehicule: {
					name: '',
					brand: '',
					model: '',
					numberPlate: '',
					type: 0,
					energy: null,
					kgCoTwo: '',
					subType: 0,
					additionalSubType: 0,
					taxHorsepower: 0,
					grayCardXid: '',
					grayCardVersoXid: ''
				},
				driverLicenseNumber: '',
				postalAddressID: 0,
				postalAddress: {
					id: null,
					street: '',
					number: '',
					box: '',
					zip: '',
					state: '',
					city: '',
					country: '',
					countryCode: '',
					countryKey: 0,
					countryCodePostalAddress: '',
					countryKeyPostalAddress: 0,
					other: '',
					lat: 0,
					lng: 0
				},
				maidenName: '',
				title: 1,
				birthCountry: '',
				congesSpectacle: '',
				bankAccountOwnerName: '',
				bankDetailXid: '',
				taxCountry: '',
				socialSecurityCardXid: '',
				insuranceCertificateXid: '',
				socialSecurityCenter: '',
				socialSecurityCenterAddressId: 0,
				// to change
				social_security_number: '',
				lastMedicalVisit: '0001-01-01T00:00:00Z',
				medicalVisitValidity: '0001-01-01T00:00:00Z',
				medicalVisitDocumentXid: '',
				// to change
				disabled: false,
				disabilityNotification: '0001-01-01T00:00:00Z',
				disabilityType: '',
				disabilityStatus: '',
				invalidity: 1,
				rqth: false,
				invalidityPourcentage: 0,
				retired: false,
				retirementDate: '0001-01-01T00:00:00Z',
				trainLoyaltyCard: '',
				trainDiscountCard: '',
				flyingLoyaltyCard: '',
				otherAirlineCard: '',
				residencePermitNumber: '',
				residencePermitEndValidity: '0001-01-01T00:00:00Z',
				residentPermitXid: '',
				workCardXid: '',
				pseudonym: '',
				professionalAssociation: '',
				messageContext: '',
				incompleteProlile: false,
				studyLevel: 0,
				studyCurrently: false
			},
			erreur: {},
			errors: [],
			successModal: false,
			capturePict: false,
			cropPict: false,
			isLoading: false,
			pdfFile: '',
			disableSaveButton: true,
			refreshTokenCaptureItem: 0,
			accordion: {},
			menuActiveForMobile: '',
			oldValue: {
				numberOfDependentPerson: 0,
				numberOfDisabledPerson: 0,
				numberOfDependentChild: 0,
				numberOfDisabledChild: 0,
				allergies: ''
			},
			haveAllergies: false,
			haveDependents: false,
			haveChild: false,
			showChangeMailModal: false,
			showChangePhoneModal: false,
			showValidationCodeModal: false,
			codeValidation: 0,
			statusSendCodeValidation: {
				global: false,
				progress: false,
				success: false
			},
			isMyProfileLoading: false,
			showInfoCapture: false,
			configAccordion: {
				'personnal-data': {
					isVisible: true
				},
				identification: {
					isVisible: false
				},
				address: {
					isVisible: false
				},
				family: {
					isVisible: false
				},
				other: {
					isVisible: false
				},
				password: {
					isVisible: false
				},
				'personal-vehicle': {
					isVisible: false
				},
				finance: {
					isVisible: false
				},
				health: {
					isVisible: false
				},
				deter: {
					isVisible: false
				}
			},
			countryKey: 16965, // Belgium's countryKey
			countryKeyPostalAddress: 16965,
			countryCode: 'BE',
			countryCodePostalAddress: 'BE',
			isSubmitted: false,
			imgPropPicture: { blank: true, blankColor: '#d5d6d7', class: 'myprofile-picture-preview' },
			imgPropPictureId: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropPictureIdVerso: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropPictureDriverLicense: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropPictureDriverLicenseVerso: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropPictureRib: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			imgPropSignatureId: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			currentImgField: 'picture',
			openPictureModal: false,
			imgToShow: '',
			// fillSearch: this.myProfile.address || null
			vehiculeData: {},
			CUSTOM_FIELD_PARENT_TYPE,
			defaultUserCustomFieldParentType: CUSTOM_FIELD_PARENT_TYPE.user,
			customFields: [],
			customFieldModels: [],
			customFieldValueInputs: [],
			// for cleaning internet address search inputs
			clearLegalAddress: false,
			clearPostalAddress: false
		};
	},
	directives: {
		'limit-100': {
			bind(el) {
				el.addEventListener('input', (event) => {
					const inputValue = event.target.value;
					const numericValue = parseFloat(inputValue);
					if (!isNaN(numericValue)) {
						const compare = Math.min(Math.max(numericValue, 0), 100);
						if (compare !== numericValue) {
							event.target.value = compare;
							event.target.dispatchEvent(new Event('input'));
						}
					}
				});
			}
		}
	},
	computed: {
		disableAll() {
			return this.forJobRequest === true;
		},
		studyLeveOptions() {
			return [{ value: 0, text: this.FormMSG(896, 'Please select ...') }, ...this.FormMenu(STUDY_LEVEL_MENU)];
		},
		personalDataMandatoryCount() {
			let emptyFieldCount = 0;
			if (this.userFieldOption.mandatoryEmail && this.myProfile.email.length <= 0) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryPhone && this.myProfile.phone.length <= 0) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryFirstName && this.myProfile.firstName.length <= 0) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryLastName && this.myProfile.name.length <= 0) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryNationality && this.myProfile.nationality.length <= 0) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryLanguage && this.myProfile.language.length <= 0) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryBirthdate && this.isDateValid('birthDate').birthDate === false) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryPlaceOfBirth && this.myProfile.placeOfBirth.length <= 0) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryCityOfBirthZip && this.myProfile.placeOfBirthZip.length <= 0) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryGender && this.myProfile.gender.length <= 0) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryProfilePicture && this.myProfile.picture.length <= 0) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryMaidenName && this.myProfile.maidenName.length <= 0) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryTitle && this.myProfile.title.length <= 0) {
				emptyFieldCount++;
			}
			if (this.userFieldOption.mandatoryCountryOfBirth && this.myProfile.birthCountry.length <= 0) {
				emptyFieldCount++;
			}
			return emptyFieldCount;
		},
		identificationMandatoryCount() {
			let identCount = 0;
			if (this.userFieldOption.mandatoryNationalRegistrationNumber && this.myProfile.nationalRegistryNumber.length <= 0) {
				identCount++;
			}
			if (this.userFieldOption.mandatoryCongesSpectacleId && this.myProfile.congesSpectacle.length <= 0) {
				identCount++;
			}
			if (this.userFieldOption.mandatoryCompanyNumber && this.myProfile.companyNumber.length <= 0) {
				identCount++;
			}
			if (this.userFieldOption.mandatoryIdName && this.myProfile.idName.length <= 0) {
				identCount++;
			}
			if (this.userFieldOption.mandatoryIdNumber && this.myProfile.idNumber.length <= 0) {
				identCount++;
			}
			if (this.userFieldOption.mandatoryIdPictureFront && this.myProfile.pictureId.length <= 0) {
				identCount++;
			}
			if (this.userFieldOption.mandatoryIdPictureBack && this.myProfile.pictureIdVerso.length <= 0) {
				identCount++;
			}
			if (this.userFieldOption.mandatoryDriverLicenseNumber && this.myProfile.driverLicenseNumber.length <= 0) {
				identCount++;
			}
			if (this.userFieldOption.mandatoryDriverLicensePictureFront && this.myProfile.pictureDriverLicense.length <= 0) {
				identCount++;
			}
			if (this.userFieldOption.mandatoryDriverLicensePictureBack && this.myProfile.pictureDriverLicenseVerso.length <= 0) {
				identCount++;
			}
			if (this.userFieldOption.mandatorySignaturePicture && this.myProfile.signatureId.length <= 0) {
				identCount++;
			}
			if (this.userFieldOption.mandatoryDriverLicense && this.myProfile.driverLicense.length <= 0) {
				identCount++;
			}
			return identCount;
		},
		financeMandatoryCount() {
			let financeCount = 0;
			if (this.userFieldOption.mandatoryIban && this.myProfile.iban.length <= 0) {
				financeCount++;
			}
			if (this.userFieldOption.mandatoryBic && this.myProfile.bic.length <= 0) {
				financeCount++;
			}
			if (this.userFieldOption.mandatoryTaxes && this.myProfile.taxesPercentage.length <= 0) {
				financeCount++;
			}
			if (this.userFieldOption.mandatoryTaxDeclarationCountry && this.myProfile.taxCountry.length <= 0) {
				financeCount++;
			}
			if (this.userFieldOption.mandatoryBankAccountOwnerName && this.myProfile.bankAccountOwnerName.length <= 0) {
				financeCount++;
			}
			if (this.userFieldOption.mandatoryBankDetailDocument && this.myProfile.bankDetailXid.length <= 0) {
				financeCount++;
			}
			if (this.userFieldOption.mandatorySecondIban && this.myProfile.secondIban.length <= 0) {
				financeCount++;
			}
			if (this.userFieldOption.mandatorySecondBic && this.myProfile.secondBic.length <= 0) {
				financeCount++;
			}
			if (this.userFieldOption.mandatorySecondBankAccountOwnerName && this.myProfile.secondBankAccountOwnerName.length <= 0) {
				financeCount++;
			}
			if (this.userFieldOption.mandatorySecondBankDetailDocument && this.myProfile.secondBankDetailXid.length <= 0) {
				financeCount++;
			}
			return financeCount;
		},
		financeDisplaySection() {
			return (
				this.useField('Iban') ||
				this.useField('Bic') ||
				this.useField('BankAccountOwnerName') ||
				this.useField('BankDetailDocument') ||
				this.useField('Taxes')
			);
		},
		financeDisplayBankSection() {
			return this.useField('Iban') || this.useField('Bic') || this.useField('BankAccountOwnerName') || this.useField('BankDetailDocument');
		},
		addressMandatoryCount() {
			let addressCount = 0;
			if (this.userFieldOption.mandatoryCountry && this.myProfile.address.countryKey.length <= 0) {
				addressCount++;
			}
			if (this.userFieldOption.mandatoryStreet && this.myProfile.address.street.length <= 0) {
				addressCount++;
			}
			if (this.userFieldOption.mandatoryNumber && this.myProfile.address.number.length <= 0) {
				addressCount++;
			}
			if (this.userFieldOption.mandatoryBox && this.myProfile.address.box.length <= 0) {
				addressCount++;
			}
			if (this.userFieldOption.mandatoryZipCode && this.myProfile.address.zip.length <= 0) {
				addressCount++;
			}
			if (this.userFieldOption.mandatoryCity && this.myProfile.address.city.length <= 0) {
				addressCount++;
			}
			if (this.userFieldOption.mandatoryStateRegion && this.myProfile.address.state.length <= 0) {
				addressCount++;
			}
			return addressCount;
		},
		addressDisplaySection() {
			return (
				this.useField('Country') ||
				this.useField('InternetResearch') ||
				this.useField('Street') ||
				this.useField('Number') ||
				this.useField('Box') ||
				this.useField('ZipCode') ||
				this.useField('City') ||
				this.useField('StateRegion')
			);
		},
		healthMandatoryCount() {
			let healthCount = 0;
			if (this.userFieldOption.mandatorySocialSecurityNumber && this.myProfile.socSecNumber.length <= 0) {
				healthCount++;
			}
			if (this.userFieldOption.mandatorySocialSecurityCard && this.myProfile.socialSecurityCardXid.length <= 0) {
				healthCount++;
			}
			if (this.userFieldOption.mandatoryInsuranceCertificate && this.myProfile.insuranceCertificateXid.length <= 0) {
				healthCount++;
			}
			if (this.userFieldOption.mandatorySocialSecurityCenter && this.myProfile.socialSecurityCenter.length <= 0) {
				healthCount++;
			}
			if (this.userFieldOption.mandatorySocialSecurityCenterAddress && this.myProfile.socialSecurityCenterAddressId.length <= 0) {
				healthCount++;
			}
			if (this.userFieldOption.mandatoryLastMedicalVisitDate && this.myProfile.lastMedicalVisit === '0001-01-01T00:00:00Z') {
				healthCount++;
			}
			if (this.userFieldOption.mandatoryMedicalVisitValidity && this.myProfile.medicalVisitValidity === '0001-01-01T00:00:00Z') {
				healthCount++;
			}
			if (this.userFieldOption.mandatoryMedicalVisitDocument && this.myProfile.medicalVisitDocumentXid.length <= 0) {
				healthCount++;
			}
			return healthCount + this.healthSubSectionMandatoryCount;
		},
		healthSubSectionMandatoryCount() {
			let healthCount = 0;
			if (this.myProfile.disabled === true) {
				if (this.userFieldOption.mandatoryDisabilityNotificationDate && this.myProfile.disabilityNotification === '0001-01-01T00:00:00Z') {
					healthCount++;
				}
				if (this.userFieldOption.mandatoryType && this.myProfile.disabilityType.length <= 0) {
					healthCount++;
				}
				if (this.userFieldOption.mandatoryStatus && this.myProfile.disabilityStatus.length <= 0) {
					healthCount++;
				}
				if (this.userFieldOption.mandatoryInvalidity && this.myProfile.invalidity.length <= 0) {
					healthCount++;
				}
				if (this.userFieldOption.mandatoryRqth && this.myProfile.rqth.length <= 0) {
					healthCount++;
				}
				if (this.userFieldOption.mandatoryInvalidityPercentage && this.myProfile.invalidityPourcentage.length <= 0) {
					healthCount++;
				}
			}
			return healthCount;
		},
		healthDisplaySection() {
			return (
				this.useField('SocialSecurityNumber') ||
				this.useField('SocialSecurityCard') ||
				this.useField('InsuranceCertificate') ||
				this.useField('SocialSecurityCenter') ||
				this.useField('SocialSecurityCenterAddress') ||
				this.useField('LastMedicalVisitDate') ||
				this.useField('MedicalVisitValidity') ||
				this.useField('MedicalVisitDocument') ||
				this.useField('WorkerWithADisability') ||
				this.useField('HaveAllergies') ||
				this.useField('AllergiesDetails') ||
				this.useField('DisabilityNotificationDate') ||
				this.useField('Type') ||
				this.useField('Status') ||
				this.useField('Invalidity') ||
				this.useField('Rqth') ||
				this.useField('InvalidityPercentage')
			);
		},
		familyMandatoryCount() {
			let familyCount = 0;
			if (this.userFieldOption.mandatoryCivilStatus && this.myProfile.civilStatus.length <= 0) {
				familyCount++;
			}
			if (this.userFieldOption.mandatorySpouseFirstName && this.myProfile.civilStatus === 3 && this.myProfile.spouseFirstName.length <= 0) {
				familyCount++;
			}
			if (this.userFieldOption.mandatorySpouseLastName && this.myProfile.civilStatus === 3 && this.myProfile.spouseName.length <= 0) {
				familyCount++;
			}
			if (
				this.userFieldOption.mandatorySpouseBirthdate &&
				this.myProfile.civilStatus === 3 &&
				this.myProfile.spouseBirthDate === '0001-01-01T00:00:00Z'
			) {
				familyCount++;
			}
			if (this.userFieldOption.mandatorySpouseProfession && this.myProfile.civilStatus === 3 && this.myProfile.spouseProfession.length <= 0) {
				familyCount++;
			}
			if (this.userFieldOption.mandatoryContactName && this.myProfile.personToContact.length <= 0) {
				familyCount++;
			}
			if (this.userFieldOption.mandatoryContactPhoneNumber && this.myProfile.personToContactPhone.length <= 0) {
				familyCount++;
			}
			return familyCount;
		},
		familyDisplaySection() {
			return (
				this.useField('CivilStatus') ||
				this.useField('SpouseFirstName') ||
				this.useField('SpouseLastName') ||
				this.useField('SpouseBirthdate') ||
				this.useField('SpouseHasSalary') ||
				this.useField('SpouseProfession') ||
				this.useField('NumberOfDependentChildren') ||
				this.useField('NumberOfDisabledChildren') ||
				this.useField('NumberOfDependentPerson') ||
				this.useField('NumberOfDisabledPerson') ||
				this.useField('ContactName') ||
				this.useField('ContactPhoneNumber')
			);
		},
		personalVehicleMandatoryCount() {
			let vehicleCount = 0;
			if (this.userFieldOption.mandatoryVehicleName && this.myProfile.vehicule.name.length <= 0) {
				vehicleCount++;
			}
			if (this.userFieldOption.mandatoryVehicleBrand && this.myProfile.vehicule.brand.length <= 0) {
				vehicleCount++;
			}
			if (this.userFieldOption.mandatoryVehicleModel && this.myProfile.vehicule.model.length <= 0) {
				vehicleCount++;
			}
			if (this.userFieldOption.mandatoryVehiclePlateNumber && this.myProfile.vehicule.numberPlate.length <= 0) {
				vehicleCount++;
			}
			if (this.userFieldOption.mandatoryVehicleTransportationMean && this.myProfile.vehicule.subType.length <= 0) {
				vehicleCount++;
			}
			if (this.userFieldOption.mandatoryVehicleTransportationMeanDetail && this.myProfile.vehicule.additionalSubType.length <= 0) {
				vehicleCount++;
			}
			if (this.userFieldOption.mandatoryVehicleKgCoTwo && this.myProfile.vehicule.kgCoTwoPerKm.length <= 0) {
				vehicleCount++;
			}
			if (this.userFieldOption.mandatoryPower && this.myProfile.vehicule.taxHorsepower.length <= 0) {
				vehicleCount++;
			}
			if (this.userFieldOption.mandatoryGrayCardFrontPicture && this.myProfile.vehicule.grayCardXid.length <= 0) {
				vehicleCount++;
			}
			if (this.userFieldOption.mandatoryGrayCardBackPicture && this.myProfile.vehicule.grayCardVersoXid.length <= 0) {
				vehicleCount++;
			}
			return vehicleCount;
		},
		personalVehicleDisplaySection() {
			return (
				this.myProfile.vehicule &&
				(this.useField('VehicleTransportationMeanDetail') ||
					this.useField('VehicleBrand') ||
					this.useField('VehicleKgCoTwo') ||
					this.useField('VehicleModel') ||
					this.useField('VehicleName') ||
					this.useField('VehiclePlateNumber') ||
					this.useField('Power') ||
					this.useField('GrayCardFrontPicture') ||
					this.useField('GrayCardBackPicture'))
			);
		},
		otherMandatoryCount() {
			let otherCount = 0;
			if (this.userFieldOption.mandatorySize && this.myProfile.size.length <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatoryStudyLevel && this.myProfile.studyLevel <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatoryPantSize && this.myProfile.pantSize.length <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatoryJacketSize && this.myProfile.jacketSize.length <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatoryShoeSize && this.myProfile.shoeSize.length <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatoryTshirtSize && this.myProfile.tShirtSize.length <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatorySncfLoyaltyCard && this.myProfile.trainLoyaltyCard.length <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatorySncfDiscountCard && this.myProfile.trainDiscountCard.length <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatoryFlyingBlueCard && this.myProfile.flyingLoyaltyCard.length <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatoryOtherAirlineCard && this.myProfile.otherAirlineCard.length <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatoryResidencePermitNumber && this.myProfile.residencePermitNumber.length <= 0) {
				otherCount++;
			}
			if (
				this.userFieldOption.mandatoryResidenceValidityEnd &&
				!_.isNil(this.myProfile.residencePermitEndValidity) &&
				this.myProfile.residencePermitEndValidity.length <= 0
			) {
				otherCount++;
			}
			if (this.userFieldOption.mandatoryResidencePermit && this.myProfile.residentPermitXid.length <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatoryWorkCard && this.myProfile.workCardXid.length <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatoryPseudonym && this.myProfile.pseudonym.length <= 0) {
				otherCount++;
			}
			if (this.userFieldOption.mandatoryGenericMentionProfessionalAssociation && this.myProfile.professionalAssociation.length <= 0) {
				otherCount++;
			}
			return otherCount;
		},
		otherDisplaySection() {
			return (
				this.otherDisplayPrivacyOption ||
				this.otherDisplayLoyaltyCard ||
				this.otherDisplayForeignWorker ||
				this.otherDisplayMiscellanous ||
				this.useField('Retired') ||
				this.useField('StudyLevel') ||
				this.otherDisplayClothes
			);
		},
		otherDisplayPrivacyOption() {
			return this.useField('showMyEmailInCrewList') || this.useField('showMyPhoneInCrewList');
		},
		otherDisplayLoyaltyCard() {
			return (
				this.useField('SncfLoyaltyCard') || this.useField('SncfDiscountCard') || this.useField('FlyingBlueCard') || this.useField('OtherAirlineCard')
			);
		},
		otherDisplayForeignWorker() {
			return (
				this.useField('ResidencePermitNumber') || this.useField('ResidenceValidityEnd') || this.useField('ResidencePermit') || this.useField('WorkCard')
			);
		},
		otherDisplayMiscellanous() {
			return this.useField('GenericMentionProfessionalAssociation') || this.useField('Pseudonym');
		},
		otherDisplayClothes() {
			return (
				this.useField('Size') || this.useField('PantSize') || this.useField('JacketSize') || this.useField('ShoeSize') || this.useField('TshirtSize')
			);
		},

		allMandatoryIsFilled() {
			if (
				this.personalDataMandatoryCount > 0 ||
				this.identificationMandatoryCount > 0 ||
				this.financeMandatoryCount > 0 ||
				this.addressMandatoryCount > 0 ||
				this.healthMandatoryCount > 0 ||
				this.otherMandatoryCount > 0 ||
				this.personalVehicleMandatoryCount > 0
			) {
				return false;
			} else {
				return true;
			}
		},
		userNameActif() {
			return store.state.myProfile.firstName;
		},
		isDeterAction() {
			if (+this.myProfile.bankDetailXid.length !== 0 && +this.myProfile.medicalVisitDocumentXid.length === 0) {
				this.messageContext = this.FormMSG(1711, 'visit medical document');
				this.incompleteProlile = true;
				return true;
			}
			if (+this.myProfile.bankDetailXid.length === 0 && +this.myProfile.medicalVisitDocumentXid.length !== 0) {
				this.messageContext = this.FormMSG(1712, 'bank detail document');
				this.incompleteProlile = true;
				return true;
			}
			if (+this.myProfile.bankDetailXid.length === 0 && +this.myProfile.medicalVisitDocumentXid.length === 0) {
				this.messageContext = this.FormMSG(1713, 'visit medical document and bank detail document');
				this.incompleteProlile = true;
				return true;
			} else {
				this.messageContext = '';
				this.incompleteProlile = false;
				return false;
			}
		},
		pageMobileTitle() {
			if (this.menuActiveForMobile === 'personal-data') {
				return this.FormMSG(1211, 'Personal Data');
			} else if (this.menuActiveForMobile === 'legal-information') {
				return this.FormMSG(1213, 'Legal Information');
			} else if (this.menuActiveForMobile === 'finance') {
				return this.FormMSG(1306, 'Finance');
			} else if (this.menuActiveForMobile === 'address') {
				return this.FormMSG(11, 'Address');
			} else if (this.menuActiveForMobile === 'health') {
				return this.FormMSG(1252, 'Health');
			} else if (this.menuActiveForMobile === 'family') {
				return this.FormMSG(13, 'Family');
			} else if (this.menuActiveForMobile === 'personal-vehicle') {
				return this.FormMSG(1226, 'Personal vehicle');
			} else if (this.menuActiveForMobile === 'other') {
				return this.FormMSG(16, 'Other');
			} else if (this.menuActiveForMobile === 'settings') {
				return this.FormMSG(1215, 'Settings');
			} else if (this.menuActiveForMobile === 'team-session') {
				return this.FormMSG(131, 'Team session');
			}
		},
		menuGenders() {
			const res = this.FormMenu(1002);
			return [{ value: null, text: this.FormMSG(165, 'Please select an option'), disabled: true }, ...res];
		},
		menuTitle() {
			let titles = this.FormMenu(1338);
			return titles;
		},
		menuInvalidity() {
			let invalidity = this.FormMenu(1339);
			return invalidity;
		},
		optionsForCivilStatus() {
			return this.FormMenu(1001);
		},
		optionsForLanguages() {
			let menus = this.FormMenu(1);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return menus;
		},
		useTask() {
			return store.getCurrentProjectConfig().useTask;
		},
		countries() {
			let countries = this.FormMenu(8);

			countries.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			countries = [
				{
					value: 0,
					text: this.FormMSG(187, 'Select a country...')
				},
				...countries
			];

			return countries;
		},
		countryCodes() {
			return this.FormMenu(9);
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		isPme() {
			return store.isPme();
		},
		vehicleTypes() {
			let types = this.FormMenu(10003);

			types.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return types;
		},
		vehicleEnergies() {
			let energies = this.FormMenu(10004);

			energies.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return energies;
		},
		isEditable() {
			return this.$route.name !== ROUTE_NAME.jobRequestDetail;
		}
	},
	async created() {
		// console.log('created', this.userFieldOption);
		// console.log('created', this.myProfile);
		await this.initProfile();
		await this.getUserFieldOption();
		// console.log('profil', this.myProfile);
		// console.log('profil option', this.userFieldOption);
	},
	async mounted() {
		await this.initProfile();
		await this.getUserFieldOption();
		this.customFields = await this.customFieldList();
		this.$nextTick(async () => {
			this.haveAllergies = this.myProfile.allergies !== '';
			this.oldValue.allergies = this.myProfile.allergies;
			this.oldValue.numberOfDependentPerson = this.myProfile.numberOfDependentPerson;
			this.oldValue.numberOfDisabledPerson = this.myProfile.numberOfDisabledPerson;
			this.oldValue.numberOfDependentChild = this.myProfile.numberOfDependentChild;
			this.oldValue.numberOfDisabledChild = this.myProfile.numberOfDisabledChild;
			this.haveDependents = parseInt(this.myProfile.numberOfDependentPerson) > 0 || parseInt(this.myProfile.numberOfDisabledPerson) > 0;
			this.haveChild = parseInt(this.myProfile.numberOfDependentChild) > 0 || parseInt(this.myProfile.numberOfDisabledChild) > 0;
		});
	},
	watch: {
		externalData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.myProfile = newVal;
					this.initCountry();
					if (this.myProfile.postalAddressID !== 0) {
						this.isShowPostalAddress = true;
					} else {
						this.isShowPostalAddress = false;
					}
				}
			},
			deep: true,
			immediate: true
		},
		'myProfile.picture'(picture) {
			this.$store.dispatch('setAuthUser', { picture });
		}
	},
	methods: {
		async getUserFieldOption() {
			await getProjectFieldOption().then((res) => {
				this.userFieldOption = res;
			});
		},
		handleChangeRetired(value) {
			if (!value) {
				this.myProfile['retirementDate'] = null;
			}
		},
		handleChangeDate(value, field) {
			if (value.length === 0) {
				this.myProfile[field] = null;
			}
		},
		handleInputCalendar(payload, field) {
			const activeDateNow = moment(this.myProfile[field]).format('YYYY-MM-DD');
			const id = payload.id;
			if (activeDateNow === id) {
				this.myProfile[field] = new Date(payload.id);
			} else {
				this.myProfile[field] = null;
			}
		},
		isDateValid(field) {
			const obj = {};
			const invalidDate = '0001-01-01T00:00:00Z';
			const invalidSubstring = 'T00:00:00Z';
			const value = this.myProfile[field];

			if (_.isNil(value) || value === invalidDate || value === invalidSubstring || value.length <= 0) {
				obj[field] = false;
			} else {
				obj[field] = true;
			}

			return obj;
		},
		useField(field) {
			if (field === 'showMyEmailInCrewList' || field === 'showMyPhoneInCrewList') {
				return this.userFieldOption[`${field}`];
			} else {
				return this.userFieldOption[`use${field}`];
			}
		},
		isMandatory(field) {
			return this.userFieldOption[`mandatory${field}`];
		},
		async handleVehiculeFormChange(payload, isImage) {
			this.myProfile.vehicule = {
				...this.myProfile.vehicule,
				...payload
			};

			if (isImage === true) {
				try {
					await updatePictureVehicule({ vehicule: payload });
					this.successModal = true;
				} catch (error) {
					this.erreur = error;
				} finally {
					this.isLoading = false;
				}
			}
		},
		/**
		 * @param  {!String} iban
		 */
		onOpenUrl(url) {
			return window.open(url);
		},
		checkFile(file) {
			if (file !== undefined) {
				let extension = file.toString().match(/.pdf/gi);
				if (extension !== null && extension.join`` === '.pdf') {
					return true;
				}
				return false;
			}
			return false;
		},
		handleSupplierIbanChange(payload, fieldName) {
			this.userBankWarning[fieldName] = !isValidIBANNumber(payload);
		},
		/**
		 * @param  {!String} bic
		 */
		handleSupplierBicChange(payload, fieldName) {
			this.userBankWarning[fieldName] = !isBic(payload);
		},
		handleDynamicAddressChange(payload) {
			this.myProfile.socialSecurityCenterAddressId = +payload.id;
		},
		handleDynamicAddressDelete() {
			this.myProfile.socialSecurityCenterAddressId = 0;
		},
		handleAddressMapLinkChange(payload) {
			console.log({ mapAddress: payload });
		},
		changeFieldPic(field) {
			this.currentImgField = field;
		},
		async initProfile() {
			this.isMyProfileLoading = true;
			let result = null;
			if (this.externalUserId > 0) {
				result = await getUserById(this.externalUserId);
			} else {
				result = await myProfile();
			}
			const profileItem = result;
			let obj = {};
			for (let key in profileItem) {
				const isCheckDate = profileItem[key].toString().includes('0001-01-01T00:00:00Z');
				if (isCheckDate) {
					obj[key] = null;
				} else {
					obj[key] = profileItem[key];
				}
			}
			this.myProfile = obj;
			this.initCountry();
			if (this.myProfile.postalAddressID !== 0) {
				this.isShowPostalAddress = true;
			} else {
				this.isShowPostalAddress = false;
			}
			this.isMyProfileLoading = false;
		},
		initCountry() {
			let project = null;

			if (isNil(store.state) || isNil(store.getCurrentProjectConfig())) {
				project = store.getCurrentProjectConfig();
			}

			if (!isNil(this.myProfile) && !isNil(this.myProfile.address) && this.myProfile.address.countryKey > 0) {
				this.countryKey = this.myProfile.address.countryKey;
				this.countryCode = this.myProfile.address.countryCode;
			} else if (!isNil(project) && !isNil(project.legalEntity) && !isNil(project.legalEntity.address) && project.legalEntity.address.countryKey > 0) {
				this.countryKey = project.legalEntity.address.countryKey;
				this.countryCode = project.legalEntity.address.countryCode;
			}

			if (!isNil(this.myProfile) && !isNil(this.myProfile.postalAddress) && this.myProfile.postalAddress.countryKey > 0) {
				this.countryKeyPostalAddress = this.myProfile.postalAddress.countryKey;
				this.countryCodePostalAddress = this.myProfile.postalAddress.countryCode;
			}
		},
		handleValidationCodeModalSave() {
			this.$router.push('/password');
		},
		async handleChangePassword() {
			this.statusSendCodeValidation.global = true;
			this.statusSendCodeValidation.progress = true;

			setTimeout(() => {
				this.statusSendCodeValidation.progress = false;
				this.statusSendCodeValidation.success = true;
				this.showValidationCodeModal = true;
			}, 100);
		},
		handleValidationCodeModalClose() {
			this.showValidationCodeModal = false;
			this.codeValidation = '';
			this.statusSendCodeValidation.global = false;
			this.statusSendCodeValidation.progress = false;
			this.statusSendCodeValidation.success = false;
		},
		async handlePhoneModalSave(payload) {
			const newPhone = payload.newPhoneE164;
			try {
				await updatePhoneProfile(newPhone);
				store.state.myProfile.phone = newPhone;
				this.myProfile.phone = newPhone;
				if (this.$screen.width <= 567) {
					this.menuActiveForMobile = '';
					this.createToastForMobile(this.FormMSG(1225, 'Profile updated successfully'), '', '');
				} else {
					this.createToastForMobile(this.FormMSG(368, 'Success'), this.FormMSG(1225, 'Profile updated successfully'), '', 'success');
				}
			} catch (e) {
				this.erreur = e;
			}
		},
		async handleMailModalSave(payload) {
			const newEmail = payload.newEmail;

			try {
				await updateEmailProfile(newEmail);
				store.state.myProfile.email = newEmail;
				this.myProfile.email = newEmail;
				if (this.$screen.width <= 567) {
					this.menuActiveForMobile = '';
				}
				this.createToastForMobile(this.FormMSG(1225, 'Profile updated successfully'), '', '');
			} catch (e) {
				this.erreur = e;
			}
		},
		incrementValue(data) {
			let value = parseInt(this.myProfile[data]);
			this.myProfile[data] = value + 1;
		},
		decrementValue(data) {
			let value = parseInt(this.myProfile[data]);
			if (value > 0) {
				this.myProfile[data] = value - 1;
			}
		},
		handleChangeHaveAllergies(payload) {
			if (!payload) {
				this.myProfile.allergies = '';
			}
		},
		handleChangeHaveDependents(payload) {
			if (!payload) {
				this.myProfile.numberOfDependentPerson = 0;
				this.myProfile.numberOfDisabledPerson = 0;
			}
		},
		handleChangeHaveChild(payload) {
			if (!payload) {
				this.myProfile.numberOfDependentChild = 0;
				this.myProfile.numberOfDisabledChild = 0;
			}
		},
		getIconLucide(name) {
			return icons[name];
		},
		handleMenuMobileClick(payload) {
			this.menuActiveForMobile = payload;
		},
		updateDate($event, field) {
			this.myProfile[field] = null;
			if ($event && !_.isEmpty($event)) {
				this.myProfile[field] = $event + 'T00:00:00Z';
			}
		},
		enableSaveButton() {
			this.disableSaveButton = false;
		},
		startCapture(fieldName) {
			// initialise global store variable and open dialog to capture and crop
			//console.log("instartcapture");
			store.state.capturedImage = '';
			store.state.imageCropped = '';
			this.disableSaveButton = true;
			this.capturePict = true;
			this.currentImgField = fieldName;
		},
		cancelCapture() {
			store.state.capturedImage = '';
			// stop camera
			this.$refs.CaptCompo.onStop();
			this.capturePict = false;
		},
		endCapture(item) {
			// stop camera
			this.$refs.CaptCompo.onStop();
			// copy store image name into profile data
			this.capturePict = false;
			// start cropping if image was captured
			if (store.state.capturedImage.length > 0) {
				// an image was captured
				// console.log("in End capture");
				store.state.imageToCrop = store.state.capturedImage;
				this.$refs.myCropPicture.bind(); // force the component to link the new image to the display
				this.cropPict = true;
			}
		},
		async endCrop(item) {
			// save image to server
			//console.log("this.$refs.myCropPicture.cropped:",this.$refs.myCropPicture.cropped);
			if (store.state.imageCropped.length > 0) {
				await this.saveImage(store.state.imageCropped);
			}
			this.cropPict = false;
		},
		endCropCancel(item) {
			store.state.imageCropped = '';
			this.cropPict = false;
		},
		b64toFile(b64Data, filename, contentType) {
			const sliceSize = 512;
			const byteCharacters = atob(b64Data);
			let byteArrays = [];
			for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				const slice = byteCharacters.slice(offset, offset + sliceSize);
				let byteNumbers = new Array(slice.length);

				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}

			const file = new File(byteArrays, filename, {
				type: contentType
			});

			return file;
		},
		async saveImage(imgData) {
			this.isLoading = true;
			let retStr = '';
			// remove header data:image...
			const base64Data = imgData.replace(/^data:image\/jpeg;base64,/, '');
			// create in memory file
			const fil = this.b64toFile(base64Data, 'test.jpg', 'jpg');
			let formData = new FormData(); // instantiate it
			// suppose you have your file ready
			formData.set('uploadimage', fil);
			const config = {
				headers: {
					Authorization: `Bearer ${store.state.token}`,
					'content-type': 'multipart/form-data'
				}
			};
			const urlStr = process.env.VUE_APP_GQL + '/upload';
			await axios
				.post(urlStr, formData, config)
				.then((response) => {
					retStr = response.data;
					// console.log('response.data:', retStr)
					this.myProfile[this.currentImgField] = retStr;
					store.state.imageCropped = '';
					// console.log('response.data:', retStr)
					let variables = {};
					variables[this.currentImgField] = retStr;
					this.updateImg(variables);

					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => (this.isLoading = false));

			return retStr;
		},
		showToken() {
			console.log(store.state.token);
		},
		checkAuthorized() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				return false;
			}
			return true;
		},
		formatNullDate(value, propName) {
			if (_.isNil(value)) {
				return '0001-01-01T00:00:00Z';
			}

			return value;
		},
		async submitForm(e) {
			e.preventDefault();
			this.errors = [];
			// let isAuthorized = this.checkAuthorized();
			let isAllowed = this.allMandatoryIsFilled;
			let profileItemToSend = {};
			profileItemToSend = {
				...this.myProfile,
				taxesPercentage: +this.myProfile.taxesPercentage,
				invalidityPourcentage: +this.myProfile.invalidityPourcentage,
				numberOfDependentChild: +this.myProfile.numberOfDependentChild,
				numberOfDependentPerson: +this.myProfile.numberOfDependentPerson,
				numberOfDisabledChild: +this.myProfile.numberOfDisabledChild,
				numberOfDisabledPerson: +this.myProfile.numberOfDisabledPerson,
				birthDate: this.formatNullDate(this.myProfile.birthDate, 'birthDate'),
				spouseBirthDate: this.formatNullDate(this.myProfile.spouseBirthDate, 'spouseBirthDate'),
				lastMedicalVisit: this.formatNullDate(this.myProfile.lastMedicalVisit, 'lastMedicalVisit'),
				medicalVisitValidity: this.formatNullDate(this.myProfile.medicalVisitValidity, 'medicalVisitValidity'),
				disabilityNotification: this.formatNullDate(this.myProfile.disabilityNotification, 'disabilityNotification'),
				retirementDate: this.formatNullDate(this.myProfile.retirementDate, 'retirementDate'),
				residencePermitEndValidity: this.formatNullDate(this.myProfile.residencePermitEndValidity, 'residencePermitEndValidity'),
				vehicule: {
					...this.myProfile.vehicule,
					kgCoTwoPerKm: +this.myProfile.vehicule.kgCoTwoPerKm
				}
			};

			if (isAllowed) {
				this.isSubmitted = true;
				profileItemToSend = {
					...profileItemToSend,
					vehicleName: this.myProfile.vehicule.name,
					vehicleBrand: this.myProfile.vehicule.brand,
					vehicleModel: this.myProfile.vehicule.model,
					vehicleNumberPlate: this.myProfile.vehicule.numberPlate,
					vehicleType: +this.myProfile.vehicule.type,
					vehicleEnergy: +this.myProfile.vehicule.energy,
					vehicleKgCoTwo: parseFloat(this.myProfile.vehicule.kgCoTwoPerKm),
					vehicleSubType: parseInt(this.myProfile.vehicule.subType),
					vehicleAdditionalSubType: parseInt(this.myProfile.vehicule.additionalSubType),
					vehicleTaxHorsepower: parseFloat(this.myProfile.vehicule.taxHorsepower),
					vehicleGrayCardXid: this.myProfile.vehicule.grayCardXid,
					vehicleGrayCardVersoXid: this.myProfile.vehicule.grayCardVersoXid
				};

				const result = await updMyProfile(profileItemToSend, this.customFieldValueInputs)
					.then(async (result) => {
						await updateUserProjectReference({ userId: parseInt(profileItemToSend.id, 10), reference: profileItemToSend.reference })
							.then((bool) => {
								if (bool) {
									// this.isLoading = false;
									this.successModal = true;
								}
							})
							.catch((e) => {
								console.log({ e });
							});
						if (this.$screen.width <= 567) {
							this.menuActiveForMobile = '';
							this.createToastForMobile(this.FormMSG(1225, 'Profile updated successfully'), '', '');
						}
					})
					.catch((error) => {
						this.isLoading = false;
						this.erreur = error;
					})
					.finally(() => {
						this.customFieldValueInputs = [];
					});

				return result;
			} else {
				this.isSubmitted = true;
				profileItemToSend = {
					...profileItemToSend,
					vehicleName: this.myProfile.vehicule.name,
					vehicleBrand: this.myProfile.vehicule.brand,
					vehicleModel: this.myProfile.vehicule.model,
					vehicleNumberPlate: this.myProfile.vehicule.numberPlate,
					vehicleType: +this.myProfile.vehicule.type,
					vehicleEnergy: +this.myProfile.vehicule.energy,
					vehicleKgCoTwo: parseFloat(this.myProfile.vehicule.kgCoTwoPerKm),
					vehicleSubType: parseInt(this.myProfile.vehicule.subType),
					vehicleAdditionalSubType: parseInt(this.myProfile.vehicule.additionalSubType)
				};

				const result = await updMyProfile(profileItemToSend, this.customFieldValueInputs)
					.then(async (result) => {
						await updateUserProjectReference({ userId: parseInt(profileItemToSend.id, 10), reference: profileItemToSend.reference })
							.then((bool) => {
								if (bool) {
									// this.isLoading = false;
									this.warningModal = true;
								}
							})
							.catch((e) => {
								console.log({ e });
							});
						if (this.$screen.width <= 567) {
							this.menuActiveForMobile = '';
							this.createToastForMobile(
								this.FormMSG(12205, 'Profile updated successfully, but some fields are still incomplete. Please consider filling them'),
								'',
								'',
								'danger'
							);
						}
					})
					.catch((error) => {
						this.isLoading = false;
						this.erreur = error;
					})
					.finally(() => {
						this.customFieldValueInputs = [];
					});

				return result;
			}
		},
		async updateImg(variables) {
			this.errors = [];
			this.isLoading = true;

			try {
				await updatePictureProfile(variables, this.currentImgField);
				this.successModal = true;
			} catch (error) {
				console.log('>>>>>>>>>>>>>>>', error);
				this.erreur = error;
			} finally {
				this.isLoading = false;
			}
		},
		/**
		 * @param {Array} imgs
		 */
		handleImgsTaked(imgs) {
			const retStr = imgs[0];
			this.myProfile[this.currentImgField] = retStr;
			store.state.imageCropped = '';
			let variables = {};
			variables[this.currentImgField] = retStr;
			this.updateImg(variables);
			this.isLoading = false;
			this.refreshTokenCaptureItem = this.refreshTokenCaptureItem + 1;
		},
		handleClickHeader(key) {
			this.configAccordion[key].isVisible = !this.configAccordion[key].isVisible;
			// console.log({data});
		},
		getImage(picture, fieldName = '') {
			if (!isNil(picture) && picture.length > 0) {
				if (fieldName === 'picture') {
					this.imgPropPicture.blank = false;
				} else if (fieldName === 'pictureId') {
					this.imgPropPictureId.blank = false;
				} else if (fieldName === 'pictureIdVerso') {
					this.imgPropPictureIdVerso.blank = false;
				} else if (fieldName === 'pictureDriverLicense') {
					this.imgPropPictureDriverLicense.blank = false;
				} else if (fieldName === 'pictureDriverLicenseVerso') {
					this.imgPropPictureDriverLicenseVerso.blank = false;
				} else if (fieldName === 'pictureRib') {
					this.imgPropPictureRib.blank = false;
				} else if (fieldName === 'signatureId') {
					this.imgPropSignatureId.blank = false;
				}
			}

			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		handleAutoComplete(addressData, field) {
			if (field === 'legalAddress') {
				this.myProfile.address = {
					...this.myProfile.address,
					street: addressData.route || addressData.place.name,
					city: addressData.locality ? addressData.locality : '',
					number: addressData.street_number ? addressData.street_number : '',
					state: addressData.administrative_area_level_1 ? addressData.administrative_area_level_1 : '',
					zip: addressData.postal_code ? addressData.postal_code : '',
					country: addressData.country ? addressData.country : '',
					countryCode: addressData.country_code ? addressData.country_code : '',
					countryKey: convertIsoToKey(addressData.country_code),
					lat: addressData.latitude,
					lng: addressData.longitude
				};
			}
			if (field === 'postalAddress') {
				this.myProfile.postalAddress = {
					...this.myProfile.postalAddress,
					street: addressData.route || addressData.place.name,
					city: addressData.locality ? addressData.locality : '',
					number: addressData.street_number ? addressData.street_number : '',
					state: addressData.administrative_area_level_1 ? addressData.administrative_area_level_1 : '',
					zip: addressData.postal_code ? addressData.postal_code : '',
					country: addressData.country ? addressData.country : '',
					countryCode: addressData.country_code ? addressData.country_code : '',
					countryKey: convertIsoToKey(addressData.country_code),
					lat: addressData.latitude,
					lng: addressData.longitude
				};
			}
		},
		getCountry() {
			const country = getObjectFromValue(this.countryCodes, 'value', this.countryKey);
			const newCountryKey = country ? convertIsoToKey(country.text) : '';
			this.countryCode = country ? country.text.toLowerCase() : '';
			this.myProfile.address = this.reinitializeAddressData(country, newCountryKey, this.countries);
			this.clearLegalAddress = true;
			this.$nextTick(() => {
				this.clearLegalAddress = false;
			});
		},
		getCountryPostalAddress() {
			const country = getObjectFromValue(this.countryCodes, 'value', this.countryKeyPostalAddress);
			const newCountryKey = country ? convertIsoToKey(country.text) : '';
			this.countryCodePostalAddress = country ? country.text.toLowerCase() : '';
			this.myProfile.postalAddress = this.reinitializeAddressData(country, newCountryKey, this.countries);
			this.clearPostalAddress = true;
			this.$nextTick(() => {
				this.clearPostalAddress = false;
			});
		},
		showImage(picture) {
			if (isNil(picture) || (!isNil(picture) && picture.length === 0)) return;
			this.imgToShow = `${process.env.VUE_APP_GQL}/images/${picture}`;
			this.openPictureModal = true;
		},
		deleteImg(fieldName) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(256, 'Are you sure to remove this picture ?'), {
					title: this.FormMSG(257, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(258, 'YES'),
					cancelTitle: this.FormMSG(259, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;

					let variables = {};
					variables[fieldName] = '';
					this.currentImgField = fieldName;
					this.updateImg(variables);
					this.myProfile[fieldName] = '';
					// this[`imgProp${capitalize(fieldName)}`].blank = true;
					if (fieldName === 'picture') {
						this.imgPropPicture.blank = true;
					} else if (fieldName === 'pictureId') {
						this.imgPropPictureId.blank = true;
					} else if (fieldName === 'pictureIdVerso') {
						this.imgPropPictureIdVerso.blank = true;
					} else if (fieldName === 'pictureDriverLicense') {
						this.imgPropPictureDriverLicense.blank = true;
					} else if (fieldName === 'pictureDriverLicenseVerso') {
						this.imgPropPictureDriverLicenseVerso.blank = true;
					} else if (fieldName === 'signatureId') {
						this.imgPropSignatureId.blank = true;
					}
				})
				.catch((err) => console.log(err));
		},
		handleSocSecNumberChange(value) {
			if (isNil(value) || value.trim().length === 0) return;
			this.isSocSecNumberWarning = !isSecuritySocialNumberValid(value);
		},
		isDate(valeur) {
			return valeur instanceof Date;
		},
		selectableTypeItem(item) {
			return _.isNil(item.disabled) || (!_.isNil(item.disabled) && item.disabled === false);
		},
		async customFieldList() {
			let items = await getCustomFields({ type: this.defaultUserCustomFieldParentType, userId: +store.state.myProfile.id });

			return items.map((item, index) => {
				const prefixModel = 'custom-field-model-',
					prefixNameGroup = 'custom-field-name-group-';
				const model = generateSecureId(prefixModel + item.id);
				const nameGroup = generateSecureId(prefixNameGroup + item.id);
				const customFieldValues = item.customFieldValues;

				let value = null,
					ids = null;

				switch (+item.type) {
					case CUSTOM_FIELD_TYPE.Checkbox:
						value = [];
						if (customFieldValues.length > 0) {
							ids = [];
							for (const customFieldValue of customFieldValues) {
								ids.push(+customFieldValue.customFieldMenuChoiceValue);
							}
							value = ids;
						}

						break;
					case CUSTOM_FIELD_TYPE.Date:
						// value = moment().format('YYYY-MM-DD');
						value = null;
						if (customFieldValues.length > 0) {
							value = moment(customFieldValues[0].value).format('YYYY-MM-DD');
						}

						break;
					case CUSTOM_FIELD_TYPE.Dropdown:
						value = null;
						if (customFieldValues.length > 0) {
							value = customFieldValues[0].customFieldMenuChoiceValue;
						}

						break;
					case CUSTOM_FIELD_TYPE.Freetext:
						value = null;
						if (customFieldValues.length > 0) {
							value = customFieldValues[0].value;
						}

						break;
					case CUSTOM_FIELD_TYPE.Number:
						value = 0;
						if (customFieldValues.length > 0) {
							value = customFieldValues[0].value;
						}

						break;
					case CUSTOM_FIELD_TYPE.Percentage:
						value = 0;
						if (customFieldValues.length > 0) {
							value = customFieldValues[0].value;
						}

						break;
					case CUSTOM_FIELD_TYPE.Radiobutton:
						value = null;
						if (customFieldValues.length > 0) {
							value = +customFieldValues[0].customFieldMenuChoiceValue;
						}

						break;
				}

				this.customFieldModels[index] = model;

				item = {
					...item,
					[model]: value,
					nameGroup
				};

				return item;
			});
		},
		sizeFlagInput(length) {
			let size = 3;

			// if (length >= 4) {
			// 	size = 3;
			// }

			return size;
		},
		async onUserCustomFieldMainModalReloaded() {
			this.customFields = await this.customFieldList();
		},
		onFieldTemplateChanged(payload) {
			// console.log({ simpleUserPayload: payload });
			const findIndex = this.customFieldValueInputs.findIndex((customFieldValue) => +customFieldValue.index === +payload.index);
			let value = null;

			if (payload.type === CUSTOM_FIELD_TYPE.Checkbox) {
				value = payload[this.customFieldModels[payload.index]].join(',');
			} else {
				value = payload[this.customFieldModels[payload.index]].toString();
			}

			const customFieldValue = {
				index: payload.index,
				userId: null,
				customFieldId: +payload.customFieldId,
				value
				// customFieldValueId: 0
			};

			if (findIndex === -1) {
				this.customFieldValueInputs.push(customFieldValue);
			} else {
				this.customFieldValueInputs[findIndex] = {
					...this.customFieldValueInputs[findIndex],
					...customFieldValue
				};
			}
			// console.log({ simpleUserCustomFieldValueInputs: this.customFieldValueInputs });

			this.customFields = this.customFields.map((customField, index) => {
				if (+customField.id === +payload.id) {
					customField = {
						...payload
					};
				}

				return customField;
			});
		},
		displayDate(propName) {
			const splitDate = this.myProfile[propName] ? this.myProfile[propName].substring(0, 10) : '';
			if (splitDate !== '0001-01-01') {
				return moment(splitDate).format('YYYY-MM-DD');
			}

			return '';
		}
	},
	validations: {
		myProfile: {
			firstName: {
				required,
				min: minLength(2)
			},
			name: {
				required,
				min: minLength(2)
			},
			email: {
				required,
				emailValid
			}
			// socSecNumber: {
			// 	required: requiredIf(function () {
			// 		return this.myProfile.socSecNumber && this.myProfile.socSecNumber.trim().length > 0;
			// 	}),
			// 	validSocialSecurityNumber(value) {
			// 		if (!value || value.trim().length === 0) return true;
			// 		return isSecuritySocialNumberValid(value);
			// 	}
			// }
		}
	}
};
</script>

<style scoped>
.modal .modal-captSize {
	max-width: 600px;
	width: 500px;
	max-height: 1500px;
	height: 1500px;
}

.modal .modal-cropSize {
	max-width: 600px;
	width: 500px;
}

.modal-header .close {
	display: none;
}

.myprofile-picture-preview {
	margin-top: 0.8rem;
	width: 175px;
	height: 175px;
	object-fit: cover;
	border-radius: 50%;
}

.app-body {
	overflow-x: unset;
}

.header-fixed .app-header {
	z-index: 1021;
}

.sidenav-sticky {
	top: 68px;
}

.custom-file-input {
	cursor: pointer;
}
.text-redired {
	font-size: 1rem;
	text-transform: initial;
}
.text-fin {
	font-weight: 10;
	font-size: 12px;
}
.btn-address {
	cursor: pointer;
	height: 28px;
	gap: 10px;
}

.required {
	color: red;
}
</style>
