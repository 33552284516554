<script>
export default {
	name: 'CustomLabelMandatory',

	props: {
		label: { type: String, required: true },
		notRequired: { type: Boolean, default: false, required: false }
	}
};
</script>

<template>
	<div class="d-flex">
		<div class="mr-1">
			{{ label }}
		</div>
		<div v-if="!notRequired" class="text-color-negative">*</div>
	</div>
</template>

<style scoped></style>
