var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "mr-1" }, [
      _vm._v("\n\t\t" + _vm._s(_vm.label) + "\n\t"),
    ]),
    !_vm.notRequired
      ? _c("div", { staticClass: "text-color-negative" }, [_vm._v("*")])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }